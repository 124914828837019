import {
  changeCurrentContentOfEditor,
  compileCodeUsingAgent,
  toggleCompiledOutputDrawerVisibility,
} from '../../redux/microService/actions';

export const mapStateToProps = state => ({
  codeEditorContent: state.microService.editorContent.CODE_EDITOR.content,
  codeEditorSelectedLanguage: state.microService.editorContent.CODE_EDITOR.language,
  shouldShowDownloadAndShareOptions: state.microService.editorContent.CODE_EDITOR.showDownloadAndShareOptions,
  showCompileButton: state.microService.editorContent.CODE_EDITOR.showCompileButton,
  isFetchingCompileAgent: state.microService.isFetchingCompileAgent,
  isAwaitingCompileAgentResponse: state.microService.isAwaitingCompileAgentResponse,
  isCompiledOutputVisible: state.microService.isCompiledOutputVisible,
  compiledOutput: state.microService.editorContent.CODE_EDITOR.output,
  doesCompiledOutputContainAnError: state.microService.editorContent.CODE_EDITOR.isError,
});

export const mapDispatchToProps = dispatch => ({
  changeCurrentContentOfEditor: (editor,content,language) => {
    dispatch(changeCurrentContentOfEditor(editor,content,language));
  },
  getCompileAgentUrl: () => {
    dispatch(compileCodeUsingAgent());
  },
  toggleCompiledOutputVisibility: (isCompiledOutputVisible) => {
    dispatch(toggleCompiledOutputDrawerVisibility(isCompiledOutputVisible));
  },
});