import { toast } from 'react-toastify';

export const showSuccessToast = (message, position) => {
  toast.success(message || 'success', {
    position: position || toast.POSITION.BOTTOM_RIGHT,
  });
};

export const showWarningToast = (message, position) => {
  toast.warn(message || 'success', {
    position: position || toast.POSITION.BOTTOM_RIGHT,
  });
};

export const showErrorToast = (message, position) => {
  toast.error(message || 'error', {
    position: position || toast.POSITION.BOTTOM_RIGHT,
  });
};

