import {
  getCodeCompileSandboxUrlWithStoredCode,
} from '../../redux/sandbox/actions';

export const mapStateToProps = (state) => ({
  isFetchingCodeCompileSandboxUrl: state.sandbox.isFetchingCodeCompileSandboxUrl,
});

export const mapDispatchToProps = dispatch => ({
  getSandboxLink: (url, history) => {
    dispatch(getCodeCompileSandboxUrlWithStoredCode(url, history));
  },
});
