import { toast } from 'react-toastify';
import { backendUrl } from '../../configFiles/config';
import {
  // get,
  post,
} from '../../utils/fetchRequests';

import {
  CHANGE_LANGUAGE,
  VALIDATE_USER_FOR_CODESHARE,
} from './reducers';


export const changeLanguage = (language) => (dispatch) => {
  dispatch({
    type: CHANGE_LANGUAGE,
    payload: {
      data: {
        language,
      },
    },
  });
};

export const validateUserForCodeShare = (meetingId, participantToken, moderatorToken = undefined) => (dispatch) => {
  dispatch({
    type: VALIDATE_USER_FOR_CODESHARE,
    payload: post(
      `${backendUrl}/api/v1/code/share/fetch/codeShareToken`,
      {
        meetingId,
        participantToken,
        moderatorToken,
      },
    ),
  })
    .catch(() => {
      toast.error('Unable to start codeshare board, Please try again', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    });
};