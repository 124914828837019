import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { validExtensionForFiles, initialPathForFileStructure,validFileNameRegularExpression } from '../../configFiles/config';

import './CreateAFileView.css';
import PropTypes from 'prop-types';
import FileStructureView from '../FileStructureView';
import { toast } from 'react-toastify';



export class CreateAFileView extends Component {
  constructor (props) {
    super(props);
    const { openDialogByDefault,frameworkId } = this.props;

    let extensionChosenForFile = validExtensionForFiles['HTML'].html;
    if (frameworkId === 'REACT_JS') {
      extensionChosenForFile = validExtensionForFiles['REACT_JS'].js;
    }

    this.state = {
      dailogModal: openDialogByDefault,
      nameOfFile: '',
      extensionChosen: extensionChosenForFile,
    };
  }
  openDailogModal = () => {
    this.setState(() => ({
      dailogModal: true,
    }));
  };

  closeDailogModal = () => {
    const { closeDialog } = this.props;
    this.setState(() => ({
      dailogModal: false,
    }));
    if (closeDialog) {
      closeDialog();
    }
  };

  handleNameChangeOfFile = (evt) => {
    evt.persist();
    this.setState(() => ({
      nameOfFile: evt.target.value,
    }));
  };

  handleExtensionSelect = (evt) => {
    evt.persist();
    this.setState(() => ({
      extensionChosen: evt.target.value,
    }));
  };

  componentDidMount () {
    const { changePathOftheFile } = this.props;
    changePathOftheFile(initialPathForFileStructure);
  }

  createNewFile = () => {
    const { pathOfTheFile,fileDescriptorsMappedWithPath,createNewFileOrFolder,filesInFileSystemSandbox } = this.props;
    const {
      extensionChosen,
      nameOfFile,
    } = this.state;
    const nameOfTheFileWithExtension = `${nameOfFile}.${extensionChosen}`;
    // createNewFileOrFolder(nameOfTheFileWithExtension, false, fileDescriptorsMappedWithPath[pathOfTheFile], '', extensionChosen);
    const pathofNewFile = `${pathOfTheFile}${nameOfTheFileWithExtension}`;
    const isFileExist = filesInFileSystemSandbox[fileDescriptorsMappedWithPath[pathofNewFile]] !== undefined;
    if (isFileExist){
      toast.error('Can not create two files with the same name at the same folder level');
    } else if ((nameOfFile.length === 0) || nameOfFile.match(validFileNameRegularExpression )) {
      toast.error('Please enter valid file name');
    } else {
      createNewFileOrFolder(nameOfTheFileWithExtension, false, fileDescriptorsMappedWithPath[pathOfTheFile], '', extensionChosen);
      this.setState(() => ({
        nameOfFile: '',
      }));
    }
  }

  render () {
    const { dailogModal, nameOfFile, extensionChosen } = this.state;
    const { pathOfTheFile, frameworkId } = this.props;
    // console.log(':::::::::::::', pathOfTheFile);
    return (
      <div style = {{ scrollbarWidth: 'none' }}>
        <div
          style = {{
            display: 'flex',
            justifyContent: 'center',
            // marginTop: '2%',
          }}
        >
          {/* <Button
            variant="contained"
            onClick={this.openDailogModal}
            style={{ width: '10vw', background: 'var(--primaryColor)', color: 'white',height: '6vh' }}
            id = 'createFileButton'
          >
            Create File
          </Button> */}
        </div>

        <Dialog
          open={dailogModal}
          onClose={this.closeDailogModal}
          fullWidth={true}
        >
          <DialogTitle
            id="alert-dialog-title"
            className="header_CreateAFileView"
            style={{
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            {' Create a new file '}
          </DialogTitle>
          <DialogContent>

            <div>
              <input value={pathOfTheFile}
                className='inputFieldForPathFile'
                readOnly
              ></input>
            </div>

            <div style={{ display: 'flex'  }}>
              
              <div
                style={{
                  backgroundColor: 'var(--primaryColor)',
                  display: 'flex',
                  height: '35vh',
                  overflow: 'scroll',
                  width: '30vw',
                  scrollbarWidth: 'none',
                }}
              >
                {/* <FileStructureView changeSelectedFileOnFileOpen={ () => {}} /> */}
                <FileStructureView changeSelectedFileOnFileOpen={ () => {}}/>
              </div>
              &nbsp;
              &nbsp;
              &nbsp;

              <div style={{ whiteSpace: 'nowrap' , justifyContent: 'center',display: 'flex',marginTop: '1%' }}
              >
                <span className='styleForNameLabel'>Name:</span>
                &nbsp;
                <input className='inputFieldForNameLabel' value={nameOfFile} onChange={this.handleNameChangeOfFile}></input>
                
                &nbsp;
                &nbsp;
                &nbsp;
                &nbsp;
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={extensionChosen}
                  onChange={this.handleExtensionSelect}
                  style={{ height: '4vh', width: '6vw' }}
                >
                  {
                    frameworkId === 'REACT_JS' ?
                      Object.keys(validExtensionForFiles['REACT_JS']).map((extension) => {
                        return (
                          <MenuItem value = {extension}  key = {extension}> {`.${extension}`} </MenuItem>
                        );
                      })
                      :
                      Object.keys(validExtensionForFiles['HTML']).map((extension) => {
                        return (
                          <MenuItem value = {extension}  key = {extension}> {`.${extension}`} </MenuItem>
                        );
                      })
                  }
                </Select>
                
              </div>

            </div>
            
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              className="baseButton_CreateAFileView createButton_CreateAFileView"
              onClick={this.createNewFile}
            >
              Create
            </Button>
            <Button onClick={this.closeDailogModal} color="primary" className="baseButton_CreateAFileView cancelButton_CreateAFileView">
              Cancel
            </Button>
            
          </DialogActions>
         
        </Dialog>
      </div>
    );
  }
}

CreateAFileView.propTypes = {
  pathOfTheFile: PropTypes.string.isRequired,
  openDialogByDefault: PropTypes.bool,
  closeDialog: PropTypes.func,
  changePathOftheFile: PropTypes.func,
  fileDescriptorsMappedWithPath: PropTypes.object,
  createNewFileOrFolder: PropTypes.func,
  frameworkId: PropTypes.string,
};

CreateAFileView.defaultProps = {
  pathOfTheFile: initialPathForFileStructure,
  openDialogByDefault: false,
  closeDialog: () => {

  },
  changePathOftheFile: () => {
  },
  fileDescriptorsMappedWithPath: {},
  createNewFileOrFolder: () => {
  },
};

export default CreateAFileView;
