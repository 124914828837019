import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
// import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';
import ReplayIcon from '@material-ui/icons/Replay';
import ResetToDefaultCode from '../ResetToDefaultCode';
import ForkCode from '../ForkCode';
import ForkedCodeView from '../ForkedCodeView';
// import ThemeButton from '../ThemeButton';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import SaveButton from '../SaveButton';
import SaveIcon from '@material-ui/icons/Save';
// import Brightness4Icon from '@material-ui/icons/Brightness4';
import ArchiveIcon from '@material-ui/icons/Archive';
import './SideNav.css';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import PropTypes from 'prop-types';
// import ChangeIndentationView from '../ChangeIndentationView';
// import DescriptionIcon from '@material-ui/icons/Description';
import SettingsIcon from '@material-ui/icons/Settings';
import EditorPreferences from '../EditorPreferences';
import ExitSandboxButton from '../ExitSandboxButton';
import { myanatomyHomePageUrl } from '../../configFiles/config';

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 25,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(5) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 1),
    backgroundColor: 'var(--primaryColor)',
    color: 'white',
    cursor: 'pointer',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function SideNav (props) {
  const classes = useStyles();
  const theme = useTheme();

  const openDrawerOnButtonClick = () => {
    props.setButtonClick(true);
    props.setOpen(true);
  };

  const handleDrawerOpen = () => {
    props.setOpen(true);
  };

  const handleDrawerClose = () => {
    if (props.open && !props.onButtonClick) {
      props.setOpen(false);
    }
    props.setButtonClick(false);
  };

  const forkCount = props.forkCounter;
  const isFileSystemEnabled = props.isFileSystemEnabled;
  const currentlySelectedFile = props.currentlySelectedFile;
  return (
    <div className={classes.root}>
      <CssBaseline />

      <div>
        {
          window.location !== window.parent.location ?
            null
            :
            <AppBar
              id='appBarStyleForSideNav'
              position="fixed"
              className={clsx(classes.appBar, {
                [classes.appBarShift]: props.open,
              })}
            >

              <Toolbar className='toolbarStyleForSideNav'>

                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={openDrawerOnButtonClick}
                  edge="start"
                  className={clsx(classes.menuButton, {
                    [classes.hide]: props.open,
                  })}
                  id='iconButtonForOpenDrawer'
                >
                  <MenuIcon/>
                </IconButton>
                <div className='company'>
                  <div className='company-logo'>
                    <img
                      src="/images/myAnatomyLogo.png"
                      className='companyLogoStyleForSideNav'
                      alt='Myanatomy' onClick={() => {
                        window.open(myanatomyHomePageUrl, '_blank');
                      }} />
                  </div>
                </div>

              </Toolbar>

            </AppBar>
        }
      </div>



      <ClickAwayListener onClickAway={handleDrawerClose} >
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: props.open,
            [classes.drawerClose]: !props.open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: props.open,
              [classes.drawerClose]: !props.open,
            }),
          }}
          open={props.open}
        >
          <div
            className={classes.toolbar}
            onClick={handleDrawerClose}
            title="Click to close"
          >
            <IconButton>
              {theme.direction === 'rtl' ? <ChevronRightIcon style={{ color: 'white' }} /> : <ChevronLeftIcon style={{ color: 'white' }} />}
            </IconButton>
          </div>
          <Divider />
          {/* <List>
          <ListItem >
            <ResetToDefaultCode />
          </ListItem>
        </List> */}
          {
            isFileSystemEnabled ?
              null
              :
              <div>
                <ListItem button onClick={handleDrawerOpen} title="Reset to default">
                  <ListItemIcon className="MuiIcon-fontSizeInherit"><ReplayIcon /></ListItemIcon>
                  <ResetToDefaultCode />
                </ListItem>
                <Divider />
              </div>
          }
          {/* <List>
          <ListItem  >
            <ForkCode />
          </ListItem>
        </List> */}
          {
            isFileSystemEnabled ?
              null
              :
              <div>
                <ListItem
                  button
                  onClick={handleDrawerOpen}
                  title={forkCount === 0 ? 'No more saves left' : 'Save current code as'}
                  style={
                    forkCount === 0 ?
                      {
                        cursor: 'not-allowed',
                      }
                      :
                      {

                      }
                  }
                >
                  <ListItemIcon className="MuiIcon-fontSizeInherit" title=""><CallSplitIcon /></ListItemIcon>
                  <ForkCode />
                </ListItem>
                <Divider />
              </div>
          }

          <ListItem button onClick={handleDrawerOpen} title="Save current code">
            <ListItemIcon className="MuiIcon-fontSizeInherit"><SaveIcon /></ListItemIcon>
            <SaveButton currentlySelectedFile={currentlySelectedFile} />
          </ListItem>
          <Divider />

          {/* <ListItem button onClick={handleDrawerOpen} title="Change theme">
            <ListItemIcon className="MuiIcon-fontSizeInherit"><Brightness4Icon /></ListItemIcon>
            <ThemeButton />
          </ListItem>
          <Divider /> */}

          {
            isFileSystemEnabled ?
              null
              :
              <div>
                <ListItem button onClick={handleDrawerOpen} title="Use previously saved code">
                  <ListItemIcon className="MuiIcon-fontSizeInherit"><ArchiveIcon /></ListItemIcon>
                  <ForkedCodeView />
                </ListItem>
                <Divider />
              </div>
          }

          {/* <ListItem button onClick={handleDrawerOpen} title="Change Indentation for Code">
            <ListItemIcon className="MuiIcon-fontSizeInherit"><DescriptionIcon /></ListItemIcon>
            <ChangeIndentationView />
          </ListItem>
          <Divider /> */}

          <ListItem button onClick={handleDrawerOpen} title="Change Editor Preferences">
            <ListItemIcon className="MuiIcon-fontSizeInherit"><SettingsIcon /></ListItemIcon>
            <EditorPreferences isAutoCompleteDisabled={props.isAutoCompleteDisabled}/>
          </ListItem>
          <Divider />

          <div>
            {
              window.location !== window.parent.location ?
                null
                :
                <ListItem button onClick={handleDrawerOpen} title="Exit sandbox">
                  <ListItemIcon className="MuiIcon-fontSizeInherit"><ExitToAppIcon /></ListItemIcon>
                  <ExitSandboxButton />
                </ListItem>
            }
          </div>
          <Divider />
        </Drawer>
      </ClickAwayListener>
    </div>
  );
}

SideNav.propTypes = {
  forkCounter: PropTypes.number,
  isFileSystemEnabled: PropTypes.bool,
  currentlySelectedFile: PropTypes.string,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  onButtonClick: PropTypes.bool,
  setButtonClick: PropTypes.func,
  isAutoCompleteDisabled: PropTypes.bool,
};
