import { frontEndUrl, rootLevelPathIndentifier } from '../configFiles/config';

export const openFilesInFileSystemSandbox = {
  descriptor_1: 'descriptor_1',
  descriptor_7: 'descriptor_7',
  descriptor_8: 'descriptor_8',
};

export const dummyFilesInSandbox = {
  'descriptor_1': {
    name: 'index.html',
    path: `/${rootLevelPathIndentifier}/`,
    content: '',
    isDirectory: false,
    fileDescriptor: 'descriptor_1',
    extension: 'html',
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_2': {
    name: 'folder_1',
    path: `/${rootLevelPathIndentifier}/`,
    content: null,
    isDirectory: true,
    fileDescriptor: 'descriptor_2',
    extension: null,
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_3': {
    name: 'folder_2',
    path: `/${rootLevelPathIndentifier}/`,
    content: null,
    isDirectory: true,
    fileDescriptor: 'descriptor_3',
    extension: null,
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_4': {
    name: 'folder_3',
    path: `/${rootLevelPathIndentifier}/folder_1/`,
    content: null,
    isDirectory: true,
    fileDescriptor: 'descriptor_4',
    extension: null,
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_5': {
    name: 'folder_4',
    path: `/${rootLevelPathIndentifier}/folder_2/`,
    content: null,
    isDirectory: true,
    fileDescriptor: 'descriptor_5',
    extension: null,
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_6': {
    name: 'folder_5',
    path: `/${rootLevelPathIndentifier}/folder_1/folder_3/`,
    content: '',
    isDirectory: true,
    fileDescriptor: 'descriptor_6',
    extension: null,
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_7': {
    name: 'style.css',
    path: `/${rootLevelPathIndentifier}/folder_1/folder_3/`,
    content: '.class1{}',
    isDirectory: false,
    fileDescriptor: 'descriptor_7',
    extension: 'css',
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_8': {
    name: 'index.js',
    path: `/${rootLevelPathIndentifier}/folder_1/folder_3/folder_5/`,
    content: 'function a() {console.log("Method called")}',
    isDirectory: false,
    fileDescriptor: 'descriptor_8',
    extension: 'js',
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
};

export const dummyFilesInSandboxForReactSandbox = {

  'descriptor_1': {
    name: 'index.js',
    path: `/${rootLevelPathIndentifier}/`,
    // content: `import message from './message.js';
    // console.log(message);`,
    content: `import message from '../folder_2/newMessage.js';
              import AdeleComponent from '../folder_1/folder_3/AdeleComponent.js';
              import '../folder_1/folder_3/style.css';
              
              
              
    console.log(message);

    class App extends React.Component {
      render() {
        return (
          <div>
          <AdeleComponent />
          <p className='styleClass'>Hello world!</p>
          </div>
        )
      }
    }
    ReactDOM.render(<App />, document.querySelector('#root'));

    `,

    isDirectory: false,
    fileDescriptor: 'descriptor_1',
    extension: 'html',
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_2': {
    name: 'message.js',
    path: `/${rootLevelPathIndentifier}/`,
    // content: `import {name} from './name.js';
    // export default name;`,
    content: `import {name} from './folder_1/checkingPath.js';
    export default name;`,
    isDirectory: false,
    fileDescriptor: 'descriptor_2',
    extension: 'js',
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },

  'descriptor_3': {
    name: 'name.js',
    path: `/${rootLevelPathIndentifier}/`,
    content: `export const name = 'Bond...... James bond';`,
    isDirectory: false,
    fileDescriptor: 'descriptor_3',
    extension: 'js',
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  
  'descriptor_4': {
    name: 'folder_1',
    path: `/${rootLevelPathIndentifier}/`,
    content: null,
    isDirectory: true,
    fileDescriptor: 'descriptor_4',
    extension: null,
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_5': {
    name: 'folder_2',
    path: `/${rootLevelPathIndentifier}/`,
    content: null,
    isDirectory: true,
    fileDescriptor: 'descriptor_5',
    extension: null,
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_6': {
    name: 'checkingPath.js',
    path: `/${rootLevelPathIndentifier}/folder_1/`,
    content: `export const name = 'Shubh';`,
    isDirectory: false,
    fileDescriptor: 'descriptor_6',
    extension: 'js',
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_7': {
    name: 'newMessage.js',
    path: `/${rootLevelPathIndentifier}/folder_2/`,
    content: `import {name} from '../../../${rootLevelPathIndentifier}/name.js';
    export default name; `,
    isDirectory: false,
    fileDescriptor: 'descriptor_7',
    extension: 'js',
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_8': {
    name: 'AdeleComponent.js',
    path: `/${rootLevelPathIndentifier}/folder_1/folder_3/`,
    content: `import '../folder_5/newStyle.css';
    class AdeleComponent extends React.Component {
      render() {
        return <p>Hello from the other side!</p>
      }
    }
    export default AdeleComponent;`,
    isDirectory: false,
    fileDescriptor: 'descriptor_8',
    extension: 'js',
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_9': {
    name: 'style.css',
    path: `/${rootLevelPathIndentifier}/folder_1/folder_3/`,
    content: `.styleClass{
      color: red;
    }`,
    isDirectory: false,
    fileDescriptor: 'descriptor_9',
    extension: 'css',
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_10': {
    name: 'newStyle.css',
    path: `/${rootLevelPathIndentifier}/folder_1/folder_3/folder_5/`,
    content: `#button{
    
      width: 100%;
      height: 90%;
      border: 2px solid var(--primaryColor);
      
  }
  #button:hover{
      background-color: var(--primaryColor);
      color: white;
  }`,
    isDirectory: false,
    fileDescriptor: 'descriptor_10',
    extension: 'css',
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
};

export const dummyResolvedTree = '{"root":{' +
'"index.html":"descriptor_1","folder_1"' +
':{"folder_3":{"folder_5":{"index.js":"descriptor_8"},"style.css":"descriptor_7"}},"folder_2":{"folder_4":{}}}}';

export const dummyFilesInSandboxWithError = {
  'descriptor_1': {
    name: 'index.html',
    path: `/${rootLevelPathIndentifier}/`,
    content: '',
    isDirectory: false,
    fileDescriptor: 'descriptor_1',
  },
  'descriptor_2': {
    name: 'folder_1',
    path: `/${rootLevelPathIndentifier}/`,
    content: null,
    isDirectory: true,
    fileDescriptor: 'descriptor_2',
  },
  'descriptor_3': {
    name: 'folder_2',
    path: `/${rootLevelPathIndentifier}/index.html/`,
    content: null,
    isDirectory: true,
    fileDescriptor: 'descriptor_3',
  },
  'descriptor_4': {
    name: 'folder_3',
    path: `/${rootLevelPathIndentifier}/folder_1/`,
    content: null,
    isDirectory: true,
    fileDescriptor: 'descriptor_4',
  },
  'descriptor_5': {
    name: 'folder_4',
    path: `/${rootLevelPathIndentifier}/folder_2/`,
    content: null,
    isDirectory: true,
    fileDescriptor: 'descriptor_5',
  },
  'descriptor_6': {
    name: 'folder_5',
    path: `/${rootLevelPathIndentifier}/folder_1/folder_3/`,
    content: '',
    isDirectory: true,
    fileDescriptor: 'descriptor_6',
  },
  'descriptor_7': {
    name: 'style.css',
    path: `/${rootLevelPathIndentifier}/folder_1/folder_3/`,
    content: '.class1{}',
    isDirectory: false,
    fileDescriptor: 'descriptor_7',
  },
  'descriptor_8': {
    name: 'index.js',
    path: `/${rootLevelPathIndentifier}/folder_1/folder_3/folder_5/`,
    content: 'function a() {console.log("Method called")}',
    isDirectory: false,
    fileDescriptor: 'descriptor_8',
  },
};

export const dummyFilesInSandboxWithInvalidPath = {
  'descriptor_1': {
    name: 'index.html',
    path: '/folder_1/folder_3/folder_5/',
    content: '<html></html>',
    isDirectory: false,
    fileDescriptor: 'descriptor_1',
  },
};

export const rebundleTestFile = {
  'descriptor_1': {
    name: 'style.css',
    path: `/${rootLevelPathIndentifier}/`,
    content: '.class1{color: blue; cursor:pointer; font-size:100px}',
    isDirectory: false,
    fileDescriptor: 'descriptor_7',
    extension: 'css',
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
};

// eslint-disable-next-line max-len
const testHtml = `<html><head><link rel="stylesheet" href="${frontEndUrl}/style.css"><script src="${frontEndUrl}/index.js"></script></head><body></body></html>`;

export const dummyFilesInSandboxForBundler = {
  'descriptor_1': {
    name: 'index.html',
    path: `/${rootLevelPathIndentifier}/`,
    content: testHtml,
    isDirectory: false,
    fileDescriptor: 'descriptor_1',
    extension: 'html',
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_2': {
    name: 'folder_1',
    path: `/${rootLevelPathIndentifier}/`,
    content: null,
    isDirectory: true,
    fileDescriptor: 'descriptor_2',
    extension: null,
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_3': {
    name: 'folder_2',
    path: `/${rootLevelPathIndentifier}/`,
    content: null,
    isDirectory: true,
    fileDescriptor: 'descriptor_3',
    extension: null,
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_4': {
    name: 'folder_3',
    path: `/${rootLevelPathIndentifier}/folder_1/`,
    content: null,
    isDirectory: true,
    fileDescriptor: 'descriptor_4',
    extension: null,
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_5': {
    name: 'folder_4',
    path: `/${rootLevelPathIndentifier}/folder_2/`,
    content: null,
    isDirectory: true,
    fileDescriptor: 'descriptor_5',
    extension: null,
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_6': {
    name: 'folder_5',
    path: `/${rootLevelPathIndentifier}/folder_1/folder_3/`,
    content: '',
    isDirectory: true,
    fileDescriptor: 'descriptor_6',
    extension: null,
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_7': {
    name: 'style.css',
    path: `/${rootLevelPathIndentifier}/`,
    content: '.class1{}',
    isDirectory: false,
    fileDescriptor: 'descriptor_7',
    extension: 'css',
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_8': {
    name: 'index.js',
    path: `/${rootLevelPathIndentifier}/`,
    content: 'function a() {console.log("Method called")}',
    isDirectory: false,
    fileDescriptor: 'descriptor_8',
    extension: 'js',
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
};

// eslint-disable-next-line max-len
const testHtmlWithIncorrectImports = `<html><head><link rel="stylesheet" href="${frontEndUrl}/style.css"><script src="${frontEndUrl}/folder_1/index.js"></script></head><body></body></html>`;

export const dummyFilesInSandboxForBundlerWithIncorrectImports = {
  'descriptor_1': {
    name: 'index.html',
    path: `/${rootLevelPathIndentifier}/`,
    content: testHtmlWithIncorrectImports,
    isDirectory: false,
    fileDescriptor: 'descriptor_1',
    extension: 'html',
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_2': {
    name: 'folder_1',
    path: `/${rootLevelPathIndentifier}/`,
    content: null,
    isDirectory: true,
    fileDescriptor: 'descriptor_2',
    extension: null,
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_3': {
    name: 'folder_2',
    path: `/${rootLevelPathIndentifier}/`,
    content: null,
    isDirectory: true,
    fileDescriptor: 'descriptor_3',
    extension: null,
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_4': {
    name: 'folder_3',
    path: `/${rootLevelPathIndentifier}/folder_1/`,
    content: null,
    isDirectory: true,
    fileDescriptor: 'descriptor_4',
    extension: null,
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_5': {
    name: 'folder_4',
    path: `/${rootLevelPathIndentifier}/folder_2/`,
    content: null,
    isDirectory: true,
    fileDescriptor: 'descriptor_5',
    extension: null,
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_6': {
    name: 'folder_5',
    path: `/${rootLevelPathIndentifier}/folder_1/folder_3/`,
    content: '',
    isDirectory: true,
    fileDescriptor: 'descriptor_6',
    extension: null,
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_7': {
    name: 'style.css',
    path: `/${rootLevelPathIndentifier}/`,
    content: '.class1{}',
    isDirectory: false,
    fileDescriptor: 'descriptor_7',
    extension: 'css',
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_8': {
    name: 'index.js',
    path: `/${rootLevelPathIndentifier}/`,
    content: 'function a() {console.log("Method called")}',
    isDirectory: false,
    fileDescriptor: 'descriptor_8',
    extension: 'js',
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
};

export const expectedInitialBundleFile = '<head><style id="/root/style.css">.class1{}</style>' +
'<script id="/root/index.js">function a() {console.log("Method called")}</script></head><body></body>';

export const bundlerTestStyleElementId = '/root/style.css';
export const bundlerTestStyleElementContent = '.class1{}';

export const bundlerTestScriptElementId = '/root/index.js';
export const bundlerTestScriptElementContent = 'function a() {console.log("Method called")}';

export const fileResolutionErrorForTestingBundler = 'Cannot resolve module "root/folder_1/index.js"';

export const fileResolutionSuccessWhenFileIsNotInBundle =
      '<head><style id="/root/style.css">.class1{}</style><script id="/root/index.js">' +
      'function a() {console.log("Method called")}</script></head><body></body>';

export const rebundleTestDescriptor = 'descriptor_1';

export const expectedUpdatedBundleFile = '<head><style id="/root/style.css">.class1{color: blue; cursor:pointer; font-size:100px}</style>' +
'<script id="/root/index.js">function a() {console.log("Method called")}</script></head><body></body>';

export const descriptorForRebundlerForError = 'missing_descriptor';

export const expectedErrorMessageForRebundlerMissingDescriptor = {
  data: {
    errorMessage: `File not found for fileDescriptor : ${descriptorForRebundlerForError}`,
  },
};

// eslint-disable-next-line max-len
const testHtmlWithAbsolutePathImport = `<html><head><link rel="stylesheet" href="${frontEndUrl}/root/style.css"><script src="${frontEndUrl}/index.js"></script></head><body></body></html>`;

export const dummyFilesInSandboxForBundlerWithAbsolutePathImport = {
  'descriptor_1': {
    name: 'index.html',
    path: `/${rootLevelPathIndentifier}/`,
    content: testHtmlWithAbsolutePathImport,
    isDirectory: false,
    fileDescriptor: 'descriptor_1',
    extension: 'html',
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_2': {
    name: 'folder_1',
    path: `/${rootLevelPathIndentifier}/`,
    content: null,
    isDirectory: true,
    fileDescriptor: 'descriptor_2',
    extension: null,
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_3': {
    name: 'folder_2',
    path: `/${rootLevelPathIndentifier}/`,
    content: null,
    isDirectory: true,
    fileDescriptor: 'descriptor_3',
    extension: null,
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_4': {
    name: 'folder_3',
    path: `/${rootLevelPathIndentifier}/folder_1/`,
    content: null,
    isDirectory: true,
    fileDescriptor: 'descriptor_4',
    extension: null,
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_5': {
    name: 'folder_4',
    path: `/${rootLevelPathIndentifier}/folder_2/`,
    content: null,
    isDirectory: true,
    fileDescriptor: 'descriptor_5',
    extension: null,
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_6': {
    name: 'folder_5',
    path: `/${rootLevelPathIndentifier}/folder_1/folder_3/`,
    content: '',
    isDirectory: true,
    fileDescriptor: 'descriptor_6',
    extension: null,
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_7': {
    name: 'style.css',
    path: `/${rootLevelPathIndentifier}/`,
    content: '.class1{}',
    isDirectory: false,
    fileDescriptor: 'descriptor_7',
    extension: 'css',
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_8': {
    name: 'index.js',
    path: `/${rootLevelPathIndentifier}/`,
    content: 'function a() {console.log("Method called")}',
    isDirectory: false,
    fileDescriptor: 'descriptor_8',
    extension: 'js',
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
};

// eslint-disable-next-line max-len
const testHtmlWithRelativePathImport = `<html><head><link rel="stylesheet" href="${frontEndUrl}/./style.css"><script src="${frontEndUrl}/index.js"></script></head><body></body></html>`;

export const dummyFilesInSandboxForBundlerWithRelativePathImport = {
  'descriptor_1': {
    name: 'index.html',
    path: `/${rootLevelPathIndentifier}/`,
    content: testHtmlWithRelativePathImport,
    isDirectory: false,
    fileDescriptor: 'descriptor_1',
    extension: 'html',
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_2': {
    name: 'folder_1',
    path: `/${rootLevelPathIndentifier}/`,
    content: null,
    isDirectory: true,
    fileDescriptor: 'descriptor_2',
    extension: null,
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_3': {
    name: 'folder_2',
    path: `/${rootLevelPathIndentifier}/`,
    content: null,
    isDirectory: true,
    fileDescriptor: 'descriptor_3',
    extension: null,
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_4': {
    name: 'folder_3',
    path: `/${rootLevelPathIndentifier}/folder_1/`,
    content: null,
    isDirectory: true,
    fileDescriptor: 'descriptor_4',
    extension: null,
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_5': {
    name: 'folder_4',
    path: `/${rootLevelPathIndentifier}/folder_2/`,
    content: null,
    isDirectory: true,
    fileDescriptor: 'descriptor_5',
    extension: null,
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_6': {
    name: 'folder_5',
    path: `/${rootLevelPathIndentifier}/folder_1/folder_3/`,
    content: '',
    isDirectory: true,
    fileDescriptor: 'descriptor_6',
    extension: null,
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_7': {
    name: 'style.css',
    path: `/${rootLevelPathIndentifier}/`,
    content: '.class1{}',
    isDirectory: false,
    fileDescriptor: 'descriptor_7',
    extension: 'css',
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
  'descriptor_8': {
    name: 'index.js',
    path: `/${rootLevelPathIndentifier}/`,
    content: 'function a() {console.log("Method called")}',
    isDirectory: false,
    fileDescriptor: 'descriptor_8',
    extension: 'js',
    createdAt: 'Wed Oct 28 2020 15:22:58 GMT+0530',
  },
};


export const dummyDependentPackages = {
  0: {
    cdnUrl: 'https://unpkg.com/react@16.3.1/umd/react.production.min.js',
    packageName: 'react',
    packageVersion: '16.3.1',
  },
  1: {
    cdnUrl: 'https://unpkg.com/react-dom@16.3.1/umd/react-dom.production.min.js',
    packageName: 'react-dom',
    packageVersion: '16.3.1',
  },
  2: {
    cdnUrl: 'https://unpkg.com/babel-standalone@6.26.0/babel.js',
    packageName: 'babel',
    packageVersion: '6.26.0',
  },
};

export const dummyDependentPackagesForSandboxResolvedHtml = `<script crossorigin src = "https://unpkg.com/react@16.3.1/umd/react.production.min.js"></script><script crossorigin src = "https://unpkg.com/react-dom@16.3.1/umd/react-dom.production.min.js"></script><script crossorigin src = "https://unpkg.com/babel-standalone@6.26.0/babel.js"></script>`;

export const resolvedDependenciesForReactMicroServiceSandbox = '<script crossorigin src = "https://unpkg.com/react@16.3.1/umd/react.production.min.js"></script><script crossorigin src = "https://unpkg.com/react-dom@16.3.1/umd/react-dom.production.min.js"></script><script crossorigin src = "https://unpkg.com/babel-standalone@6.26.0/babel.js"></script>';

export const resolvedDependenciesForAngularJSMicroServiceSandbox = '<script crossorigin src = "https://ajax.googleapis.com/ajax/libs/angularjs/1.6.9/angular.min.js"></script>';
