import React from 'react';

// import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { IoIosInformationCircleOutline } from 'react-icons/io';

import './HomePageView.css';
import { cardsForHomePage } from '../../AvailableCards/availableCards';

import Loader from '../Loader';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import FileSystemSandboxImage from '../../images/iconForFileSystemSandbox.svg';
import BasicSandBoxImage from '../../images/iconForBasicSandbox.svg';
import Error404View from '../Error404View';
import { showSuccessToast } from '../../utils/toasts/showToasts';

// const styles = {
//   dialogPaper: {
//     minHeight: '100vh',
//     maxHeight: '100vh',
//   },
// };


class HomePageView extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      sandBoxDialogModal: false,
      frameworkIdForSandBox: undefined,
      raisedForPublicSandBoxPic: false,
      raisedForPrivateSandBoxPic: false,
    };
  }

  openSandBoxDialogModal = (frameworkId) => {
    this.setState(() => ({
      sandBoxDialogModal: true,
      frameworkIdForSandBox: frameworkId,
    }));
  };

  closeSandBoxDialogModal = () => {
    this.setState(() => ({
      sandBoxDialogModal: false,
    }));
  };

  createSandbox = (frameworkId, history, withFileSystem) => {
    const {
      create,
    } = this.props;
    create(frameworkId, history, withFileSystem);
  };

  toggleRaisedForPublicSandBox = () => this.setState({
    raisedForPublicSandBoxPic: !this.state.raisedForPublicSandBoxPic,
  });
  toggleRaisedForPrivateSandBox = () => this.setState({
    raisedForPrivateSandBoxPic: !this.state.raisedForPrivateSandBoxPic,
  });

  render () {
    const {
      history,
      showInitializingLoader,
    } = this.props;

    const {
      sandBoxDialogModal,
      frameworkIdForSandBox,
    } = this.state;

    return (
      <div>
        {
          window.top !== window.self ?
            <Error404View />
            :
            <div className='homePageViewContainer'>
              <div className='homePageViewWelcomeTextContainer'>
                <div className='homePageViewWelcomeTextFlexBox'>
                  <div className='welcomeTextHomePageView'>
                    Welcome to CodeFrame, a framework coding platform powered by&nbsp;
                    <div
                      className='myanatomyLinkWelcomePageText'
                      onClick={() => window.open('https://myanatomy.in', '_blank', 'noopener noreferrer')}
                      title='https://myanatomy.in'
                    >
                      MyAnatomy
                    </div>
                  </div>
                </div>
              </div>
              <div className='availableCardsGridContainer'>
                {Object.values(cardsForHomePage).map((card) => {
                  return (
                    <div
                      id = {card.frameworkId}
                      key={card.frameworkId}
                      onClick={() => {
                        if (card.isAvailable) {
                          this.openSandBoxDialogModal(card.frameworkId);
                        }
                      }}
                      title={card.isAvailable ? 'Click to start a sandbox' : 'Will be available soon'}
                    >
                      <div className={!card.isAvailable ? 'each-slideForComingSoon' : 'each-slide'}>
                        <Card style={!card.isAvailable ? { cursor: 'not-allowed' } : {}}
                          variant={!card.isAvailable ? 'elevation' : 'outlined'}
                          title={!card.isAvailable ? 'Coming soon' : `Click to create ${card.frameworkId} sandbox`}
                        >
                          <CardActionArea
                            style={
                              !card.isAvailable ?
                                {
                                  cursor: 'not-allowed',
                                  height: '80%',
                                }
                                :
                                {
                                  height: '80%',
                                }
                            }
                          >
                            <div
                              className='imageContainerHomePageView'
                            >
                              <CardMedia
                                className='CardsImg'
                                image={card.image}
                                title={card.name}
                              />
                            </div>
                            <div className='descriptionContainerHomePageView'>
                              <div className='styleForCardName'>{card.name}</div>
                              <div className='styleForCardContent'>{card.content}</div>
                            </div>
                            <div className='getStartedButtonStyle'>
                              <Button
                                className={!card.isAvailable ? 'homePageGetStartedButtonStyleDisable'
                                  : 'homePageGetStartedButtonStyle'}
                                color={
                                  !card.isAvailable ?
                                    'default'
                                    :
                                    'primary'
                                }
                                variant='contained'
                                onClick={() => {
                                  if (card.isAvailable) {
                                    this.openSandBoxDialogModal(card.frameworkId);
                                    // this.createSandbox(card.frameworkId, history);
                                  }

                                }}
                                id='frameworkButton'
                              >
                                {card.isAvailable ? 'Get Started' : 'Coming Soon'}
                              </Button>
                            </div>
                          </CardActionArea>
                        </Card>
                      </div>
                    </div>
                  );
                })}
                {
                  showInitializingLoader ?
                    <Loader />
                    :
                    null
                }

                <Dialog
                  id = 'chooseSandboxDialog'
                  open={sandBoxDialogModal}
                  onClose={this.closeSandBoxDialogModal}
                  fullWidth={true}
                  // fullScreen={true}
                  // autoDetectWindowHeight={true}
                  // autoScrollBodyContent={true}
                  // style={{ width: '120%' ,height:'100%' }}
                  // maxWidth = 'md'
                  // classes={{ paper: styles.dialogPaper }}
                >
                  <DialogTitle
                    id='alert-dialog-title'
                    className='headerExitSandboxView'
                    style={{
                      justifyContent: 'center',
                      display: 'flex',
                    }}
                  >
                    {' Choose Sandbox '}
                  </DialogTitle>
                  <DialogContent>
                    
                    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                      <Card
                        id = 'basicSandboxCard'
                        onClick={() => {
                          this.createSandbox(frameworkIdForSandBox, history, false);
                        }}
                        // onMouseOver={this.onMouseOver}
                        // onMouseOut={this.onMouseOut}
                        // zDepth={this.state.shadow}

                        onMouseOver={this.toggleRaisedForPublicSandBox}
                        onMouseOut={this.toggleRaisedForPublicSandBox}
                        raised={this.state.raisedForPublicSandBoxPic}
                        className= 'card-wrapper'
                      >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <div  className='sandBoxImage'>
                            <img src={BasicSandBoxImage} alt='Public SandBox'
                              style={{ height: '16vh', width: '50%', padding: '15%' }}
                            ></img>

                          </div>
                          
                          <div className='containerForSandBox'>
                            <div className='sandboxTypeContainer'>
                              <div className='sandboxName'>
                              Basic Sandbox
                              </div>
                              <div
                                // style = {{ display: 'flex', marginTop: '3px', marginLeft: '5px' }}
                                className = 'informationIcon'
                              >
                                <IoIosInformationCircleOutline
                                  title = 'Ideal for sandbox demonstrations'
                                  style = {{
                                    verticalAlign: 'center',
                                  }}
                                  // size = {18}
                                />
                              </div>
                            </div>
                          </div>
                          
                        </div>
                      </Card>
                      &nbsp;
                      &nbsp;

                      <Card
                        id = 'fileSystemSandboxCard'
                        onClick={() => {
                          if (frameworkIdForSandBox !== 'ANGULAR_JS') {
                            // showSuccessToast('Will be coming soon');
                            this.createSandbox(frameworkIdForSandBox, history, true);
                          }
                          else {
                            showSuccessToast('Will be coming soon');
                          }
                        }}
                        raised={this.state.raisedForPrivateSandBoxPic}
                        onMouseEnter= {this.toggleRaisedForPrivateSandBox}
                        onMouseLeave= {this.toggleRaisedForPrivateSandBox}
                        className= 'card-wrapper'
                        title = {frameworkIdForSandBox === 'ANGULAR_JS' ? 'Will be available soon' : ''}
                        style={
                          frameworkIdForSandBox === 'ANGULAR_JS' ?
                            {
                              cursor: 'not-allowed',
                            }
                            :
                            {}
                        }
                      >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <div  className='sandBoxImage'>
                            <img src={FileSystemSandboxImage} alt='Public SandBox'
                              // style={{ height: '25vh', width: '80%' }}
                              style={{ height: '16vh', width: '50%', padding: '15%' }}
                            ></img>
                          </div>
                          
                          <div className='containerForSandBox'>
                            <div className='sandboxTypeContainer'>
                              <div className='sandboxName'>
                                File System Sandbox
                              </div>
                              <div
                                // style = {{ display: 'flex', marginTop: '3px', marginLeft: '5px' }}
                                className = 'informationIcon'
                              >
                                <IoIosInformationCircleOutline
                                  title = 'Ideal for experiencing real-world project structure'
                                  style = {{
                                    verticalAlign: 'center',
                                  }}
                                  // size = {18}
                                />
                              </div>
                            </div>
                          </div>
                          
                        </div>
                      </Card>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      id = 'chooseSandboxDialogCancelButton'
                      onClick={this.closeSandBoxDialogModal}
                      color='primary'
                      className='buttonExitSandboxView button3ExitSandboxView'
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
        }
      </div>
    );
  }
}

HomePageView.propTypes = {
  create: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  showInitializingLoader: PropTypes.bool.isRequired,
};

HomePageView.defaultProps = {
  history: {
    push: () => {
    },
  },
  showInitializingLoader: false,
  create: () => {
  },
};

export default HomePageView;
// export default connect(mapStateToProps, mapDispatchToProps)(HomePageView);
