// ABSOLUTE IMPORTS
import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
// import { ThemeProvider } from 'styled-components';

// CSS
import './App.css';

// RELATIVE IMPORTS
import {
  // theme,
  muiTheme,
} from './utils/uiTheme';
import configureReduxStore from './utils/configureReduxStore';
import Routes from './Routes';


const reduxStore = configureReduxStore;

const App = () => (
  <Provider store={reduxStore}>
    {/* <ThemeProvider theme={theme}> */}
    <MuiThemeProvider theme={muiTheme}>
      <Routes />
    </MuiThemeProvider>
    {/* </ThemeProvider> */}
  </Provider>
);

export default App;
