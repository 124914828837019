// import moment from 'moment';

import { handleNavigation } from '../code/utils';

export const STORE_PACKED_FILE = 'bundle/STORE_PACKED_FILE';

export const CREATE_BUNDLE_FILE = 'bundle/CREATE_BUNDLE_FILE';
export const CREATE_BUNDLE_FILE_PENDING = 'bundle/CREATE_BUNDLE_FILE_PENDING';
export const CREATE_BUNDLE_FILE_FULFILLED = 'bundle/CREATE_BUNDLE_FILE_FULFILLED';
export const CREATE_BUNDLE_FILE_REJECTED = 'bundle/CREATE_BUNDLE_FILE_REJECTED';

export const CREATE_REACT_BUNDLE_FILE = 'bundle/CREATE_REACT_BUNDLE_FILE';
export const CREATE_REACT_BUNDLE_FILE_PENDING = 'bundle/CREATE_REACT_BUNDLE_FILE_PENDING';
export const CREATE_REACT_BUNDLE_FILE_FULFILLED = 'bundle/CREATE_REACT_BUNDLE_FILE_FULFILLED';
export const CREATE_REACT_BUNDLE_FILE_REJECTED = 'bundle/CREATE_REACT_BUNDLE_FILE_REJECTED';

export const REBUNDLE_FILES = 'bundle/REBUNDLE_FILES';
export const REBUNDLE_FILES_PENDING = 'bundle/REBUNDLE_FILES_PENDING';
export const REBUNDLE_FILES_FULFILLED = 'bundle/REBUNDLE_FILES_FULFILLED';
export const REBUNDLE_FILES_REJECTED = 'bundle/REBUNDLE_FILES_REJECTED';

export const REBUNDLE_REACT_FILES = 'bundle/REBUNDLE_REACT_FILES';
export const REBUNDLE_REACT_FILES_PENDING = 'bundle/REBUNDLE_REACT_FILES_PENDING';
export const REBUNDLE_REACT_FILES_FULFILLED = 'bundle/REBUNDLE_REACT_FILES_FULFILLED';
export const REBUNDLE_REACT_FILES_REJECTED = 'bundle/REBUNDLE_REACT_FILES_REJECTED';

export const REVOKE_BUNDLE_URL = 'bundle/REVOKE_BUNDLE_URL';

export const SET_BUNDLE_ERROR_MESSAGE = 'bundle/SET_BUNDLE_ERROR_MESSAGE';
export const SET_DEFAULT_VALUES_ON_UNMOUNT = 'bundle/SET_DEFAULT_VALUES_ON_UNMOUNT';

const defaultState = {
  packedFiles: {},
  isCreatingBundle: false,
  bundleCreationError: undefined,
  bundleString: '',
  isRebundling: false,
  isCreatingReactBundle: false,
  resultentModulesFromReactBundler: '',
  bundleStringForReactScript: '',
  bundleCssStringInReactFiles: '',
  bundleDocumentForHtmlWithCss: undefined,
};

// const createBundleFile = (bundleString) => {
//   const fileName = 'bundle.html';
//   const createdAt = moment.now();

//   const bundleObject = new Blob([bundleString], { type: 'text/html' });
//   bundleObject.name = fileName;
//   bundleObject.createdAt = createdAt;
  
//   const bundleFile = window.URL.createObjectURL(bundleObject);

//   return bundleFile;
// };

const revokeObjectURL = (url) => {
  window.URL.revokeObjectURL(url);
};

// const registerNewBundleFile = (bundleUrl, bundle) => {
//   revokeObjectURL(bundleUrl);
//   const bundleFile = createBundleFile(bundle);
//   return bundleFile;
// };

const bundle = (state = defaultState, action) => {
  switch (action.type) {
  case STORE_PACKED_FILE: {
    const { packedFiles } = state;
    const updatedPackedFiles = Object.assign({}, packedFiles);

    const { data } = action.payload;
    if (!data) {
      return {
        ...state,
      };
    }

    const {
      newPackedFile,
      filePath,
    } = data;

    updatedPackedFiles[filePath] = newPackedFile;
    return {
      ...state,
      packedFiles: updatedPackedFiles,
    };
  }

  case CREATE_BUNDLE_FILE: {
    return {
      ...state,
      isCreatingBundle: true,
      bundleCreationError: undefined,
    };
  }

  case CREATE_BUNDLE_FILE_PENDING: {
    return {
      ...state,
      isCreatingBundle: true,
      bundleCreationError: undefined,
    };
  }

  case CREATE_BUNDLE_FILE_FULFILLED: {
    const { data } = action.payload;

    if (!data) {
      return {
        ...state,
        isCreatingBundle: false,
      };
    }

    const { bundle } = data;
    const navigationEnabledHTML = handleNavigation(bundle);

    // const bundleFile = createBundleFile(bundle);

    const resultantBundle = `
      ${navigationEnabledHTML}
      ${bundle}
    `;

    return {
      ...state,
      isCreatingBundle: false,
      // bundleUrl: bundleFile,
      bundleCreationError: undefined,
      bundleString: resultantBundle,
      // bundleDocument,
    };
  }

  case CREATE_BUNDLE_FILE_REJECTED: {
    const { error } = action.payload;
    return {
      ...state,
      isCreatingBundle: false,
      bundleCreationError: error,
    };
  }

  case CREATE_REACT_BUNDLE_FILE: {
    return {
      ...state,
      isCreatingBundle: true,
      bundleCreationError: undefined,
    };
  }

  case CREATE_REACT_BUNDLE_FILE_PENDING: {
    return {
      ...state,
      isCreatingBundle: true,
      bundleCreationError: undefined,
    };
  }

  case CREATE_REACT_BUNDLE_FILE_FULFILLED: {
    const { data } = action.payload;

    if (!data) {
      return {
        ...state,
        isCreatingBundle: false,
      };
    }

    const { bundle,
      resultantCssBundle,
      resultantModules,
      bundleDocumentForHtmlWithCss,
    } = data;


    return {
      ...state,
      isCreatingBundle: false,
      // bundleUrl: bundleFile,
      bundleCreationError: undefined,
      bundleString: bundle,
      resultentModulesFromReactBundler: resultantModules,
      // bundleStringForReactScript: bundle,
      bundleCssStringInReactFiles: resultantCssBundle,
      bundleDocumentForHtmlWithCss,
      // bundleDocument,
    };
  }

  case CREATE_REACT_BUNDLE_FILE_REJECTED: {
    const { error } = action.payload;
    return {
      ...state,
      isCreatingBundle: false,
      bundleCreationError: error,
    };
  }

  case REBUNDLE_FILES: {
    return {
      ...state,
      isRebundling: true,
      bundleCreationError: undefined,
    };
  }

  case REBUNDLE_FILES_PENDING: {
    return {
      ...state,
      isRebundling: true,
      bundleCreationError: undefined,
    };
  }

  case REBUNDLE_FILES_FULFILLED: {
    const { data } = action.payload;
    
    if (!data) {
      return {
        ...state,
        isRebundling: false,
      };
    }

    const {
      bundle,
    } = data;

    const navigationEnabledHTML = handleNavigation(bundle);
    // const bundleFileUrl = registerNewBundleFile(bundleUrl, bundle);

    const resultantBundle = `
      ${navigationEnabledHTML}
      ${bundle}
    `;
    
    return {
      ...state,
      isRebundling: false,
      bundleCreationError: undefined,
      bundleString: resultantBundle,
    };
  }

  case REBUNDLE_FILES_REJECTED: {
    const { data } = action.payload;
    if (!data) {
      return {
        ...state,
        isRebundling: false,
      };
    }

    const {
      errorMessage,
    } = data;

    if (!errorMessage) {
      return {
        ...state,
        isRebundling: false,
      };
    }

    return {
      ...state,
      isRebundling: false,
      bundleCreationError: errorMessage,
    };
  }

  case REBUNDLE_REACT_FILES: {
    return {
      ...state,
      isRebundling: true,
      bundleCreationError: undefined,
    };
  }

  case REBUNDLE_REACT_FILES_PENDING: {
    return {
      ...state,
      isRebundling: true,
      bundleCreationError: undefined,
    };
  }

  case REBUNDLE_REACT_FILES_FULFILLED: {
    const { data } = action.payload;
    
    if (!data) {
      return {
        ...state,
        isRebundling: false,
      };
    }

    const {
      bundle,
      resultentModules,
      totalCssContent,
      bundleDocumentForHtmlWithCss,
    } = data;

    return {
      ...state,
      isRebundling: false,
      bundleCreationError: undefined,
      bundleString: bundle,
      resultentModulesFromReactBundler: resultentModules,
      // bundleStringForReactScript: bundle,
      bundleCssStringInReactFiles: totalCssContent,
      bundleDocumentForHtmlWithCss,
    };
  }

  case REBUNDLE_REACT_FILES_REJECTED: {
    const { data } = action.payload;
    if (!data) {
      return {
        ...state,
        isRebundling: false,
      };
    }

    const {
      errorMessage,
    } = data;

    if (!errorMessage) {
      return {
        ...state,
        isRebundling: false,
      };
    }

    return {
      ...state,
      isRebundling: false,
      bundleCreationError: errorMessage,
    };
  }

  case REVOKE_BUNDLE_URL: {
    const { bundleUrl } = state;
    revokeObjectURL(bundleUrl);
    return {
      ...state,
    };
  }

  case SET_BUNDLE_ERROR_MESSAGE: {
    const { data } = action.payload;
    if (!data) {
      return {
        ...state,
      };
    }

    const { bundleCreationError } = state;

    const { errorMessage } = data;
    return {
      ...state,
      bundleCreationError: bundleCreationError || errorMessage,
    };
  }

  case SET_DEFAULT_VALUES_ON_UNMOUNT: {
    return {
      ...state,
      bundleString: '',
    };
  }

  default:
    return state;
  }
};

export default bundle;
