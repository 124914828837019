import { combineReducers } from 'redux';

import editor from './editor';
import code from './code';
import sandbox from './sandbox';
import auth from './auth';
import microService from './microService';
import bundle from './bundle';
import codeShare from './codeShare';

const combinedReducers = combineReducers({
  editor,
  code,
  sandbox,
  auth,
  microService,
  bundle,
  codeShare,
});

export default combinedReducers;
