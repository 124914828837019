import { resetToDefaultCode } from '../../redux/code/actions';

export const mapStateToProps = () => ({
  // editorTheme: state.editor.selectedTheme,
});

export const mapDispatchToProps = dispatch => ({
  resetToDefaultCode: () => {
    dispatch(resetToDefaultCode());
  },
});
