import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import './EditorPreferences.css';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ThemeButton from '../ThemeButton';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import DescriptionIcon from '@material-ui/icons/Description';
import ChangeIndentationView from '../ChangeIndentationView';
import Divider from '@material-ui/core/Divider';
import AutoCompleteToggleView from '../AutoCompleteToggleView';
import WbAutoIcon from '@material-ui/icons/WbAuto';
import PropTypes from 'prop-types';

class EditorPreferences extends Component {
  constructor (props) {
    super(props);
    const { openDialogByDefault } = this.props;
    this.state = {
      dailogModalForShowingEditorPreferences: openDialogByDefault,
      name: '',
      copied: false,
    };
  }
      openDailogModalForShowingEditorPreferences = () => {
        this.setState(() => ({
          dailogModalForShowingEditorPreferences: true,
        }));
      };
    
      closeDailogModalForShowingEditorPreferences = () => {
        const { closeDialog } = this.props;
        this.setState(() => ({
          dailogModalForShowingEditorPreferences: false,
        }));
        if (closeDialog) {
          closeDialog();
        }
      };
    
    
      render () {
        const { dailogModalForShowingEditorPreferences } = this.state;
        const { isAutoCompleteDisabled } = this.props;
        return (
          <div>
            <div
              style = {{
                // justifyContent: 'center',
                // display: 'flex',
              }}
            >
              <Button
                variant="contained"
                onClick={this.openDailogModalForShowingEditorPreferences}
                style = {{
                  width: '110%',
                  background: 'var(--primaryColor)',
                  color: 'white',
                }}
              >
                Preferences
              </Button>

            </div>
           
    
            <Dialog
              open={dailogModalForShowingEditorPreferences}
              onClose={this.closeDailogModalForShowingEditorPreferences}
              
            >
              <DialogTitle
                id="alert-dialog-title"
                className="header_EditorPreferences"
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                {' Editor Preferences '}
              </DialogTitle>
              <DialogContent>
                
                <span style={{ display: 'flex',flexDirection: 'row' }}>
                  <p style={{ fontSize: '140%' }}>Theme:</p>
                  &nbsp;
                  <ListItem button title="Change theme" style={{ marginLeft: '17%', backgroundColor: 'transparent'  }}>
                    <ListItemIcon className="MuiIcon-fontSizeInherit"><Brightness4Icon /></ListItemIcon>
                    <ThemeButton />
                  </ListItem>
                </span>
                
                <Divider />

                <span style={{ display: 'flex',flexDirection: 'row' }}>
                  <p style={{ fontSize: '140%' }}>Indentation:</p>
                  &nbsp;
                  <ListItem button title="Change Indentation for Code" style={{ marginLeft: '6%', backgroundColor: 'transparent' }}>
                    <ListItemIcon className="MuiIcon-fontSizeInherit"><DescriptionIcon /></ListItemIcon>
                    <div className='changeIndentationView'>
                      <ChangeIndentationView />
                    </div>
                  </ListItem>
                </span>
                <Divider />

                <span style={{ display: 'flex',flexDirection: 'row' }}>
                  <p style={{ fontSize: '140%' }}>Autocomplete:</p>
                  &nbsp;
                  <ListItem button title={isAutoCompleteDisabled === true ? 'Toggle AutoComplete is disabled' : 'Toggle AutoComplete'}
                    style={{ backgroundColor: 'transparent' }}>
                    <ListItemIcon className="MuiIcon-fontSizeInherit"><WbAutoIcon fontSize='large' /></ListItemIcon>
                    <AutoCompleteToggleView />
                  </ListItem>
                </span>
                <Divider />

              </DialogContent>
              <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={this.closeDailogModalForShowingEditorPreferences }
                  color="primary" className="baseButton_EditorPreferences OkButton_EditorPreferences">
                  OK
                </Button>
              </DialogActions>
            </Dialog>
    
          </div>
        );
      }
}

EditorPreferences.propTypes = {
  openDialogByDefault: PropTypes.bool,
  closeDialog: PropTypes.func,
  isAutoCompleteDisabled: PropTypes.bool,
};

EditorPreferences.defaultProps = {
  openDialogByDefault: false,
  closeDialog: () => {
  },
};

export default  EditorPreferences;