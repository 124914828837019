import {
  openSelectedFileFromFileExplorerDrawer,
  changePathOftheFile,
  changePathAndDescriptorIdOfTheFileToOpen,
  createNewFileOrFolderInSandbox,
  deleteFileOfSandbox,
} from '../../redux/sandbox/actions';

export const mapStateToProps = state => ({
  pathOfTheFile: state.sandbox.pathOfTheFile,
  filesInFileSystemSandbox: state.sandbox.filesInFileSystemSandbox,
  isFetchingFiles: state.sandbox.isFetchingFiles,
  resolvedFilesForSandbox: state.sandbox.resolvedFilesForSandbox,
  fileDescriptorsMappedWithPath: state.sandbox.fileDescriptorsMappedWithPath,
  frameworkId: state.sandbox.frameworkId,
});
  
export const mapDispatchToProps = dispatch => ({
  openSelectedFileFromFileExplorerDrawer: (descriptorId) => {
    dispatch(openSelectedFileFromFileExplorerDrawer(descriptorId));
  },
  changePathOftheFile: (currentPath) => {
    dispatch(changePathOftheFile(currentPath));
  },
  changePathAndDescriptorIdOfTheFileToOpen: (path, descriptorId) => {
    dispatch(changePathAndDescriptorIdOfTheFileToOpen(path, descriptorId));
  },
  createNewFileOrFolder: (fileName, isDirectory, parent, content, extension) => {
    dispatch(createNewFileOrFolderInSandbox(fileName, isDirectory, parent, content, extension));
  },
  deleteFileOrFolderOfSandbox: (fileDescriptor,isAFolder) => {
    dispatch(deleteFileOfSandbox(fileDescriptor,isAFolder));
  },
});
  