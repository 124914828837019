export const dependencyResolverForSandbox = (dependentPackages) => {
  if (dependentPackages) {

    let resultantHTML = '';
    // let allDependentPackages = Object.values(dependentPackages);
    const dependencyKeys = Object.keys(dependentPackages).sort();

    const allDependentPackages = [];
    
    dependencyKeys.forEach((dependencyKey) => {
      const dependentPackage = dependentPackages[dependencyKey];
      allDependentPackages.push(dependentPackage);
    });

    allDependentPackages.forEach((packageObject) => {

      let packageUrl = packageObject.cdnUrl;
      resultantHTML += `<script crossorigin src = "${packageUrl}"></script>`;

      // return null;
    });

    return resultantHTML;
  }
  return '';
};