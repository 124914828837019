import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import PropTypes from 'prop-types';
import './MarkDownEditorView.css';
import { tinyMceAPIKey } from '../../configFiles/config';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import {
  validFolderNameRegularExpression,
} from '../../configFiles/config';
import GmailLogo from '../../AvailableLogos/logo-gmail.png';
import OutlookLogo from '../../AvailableLogos/Microsoft-Outlook-logo.jpg';

class MarkDownEditorView extends React.Component {
  constructor (props) {
    super( props );
    
    this.state = {
      editorContent: '',
      openDownloadDialogModal: false,
      fileName: '',
      fileNameValidation: false,
      contentToBeShared: '',
      openDialogModalForEmailSharing: false,
    };
  }
  
  handleDownLoadEditorContent = () => {
    const { markdownEditorContent }  = this.props;
    const { fileName } = this.state;

    var header = '<html xmlns:o=\'urn:schemas-microsoft-com:office:office\' ' +
          'xmlns:w=\'urn:schemas-microsoft-com:office:word\' ' +
          'xmlns=\'http://www.w3.org/TR/REC-html40\'>' +
          '<head><meta charset=\'utf-8\'><title>Editor Content</title></head><body>';
    var footer = '</body></html>';
    var sourceHTML = header + markdownEditorContent + footer;
    
    var source = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(sourceHTML);
    var fileDownload = document.createElement('a');
    document.body.appendChild(fileDownload);
    fileDownload.href = source;
    fileDownload.download = `${fileName}.doc`;
    fileDownload.click();
    document.body.removeChild(fileDownload);
    this.setState({
      fileName: '',
    });

  };
        
  handleEditorChange = (content, editor) => {
    // console.log(':::::::::::::::',editor.getContent({ format: 'text' }));
    const { changeCurrentContentOfEditor } = this.props;
    changeCurrentContentOfEditor('MARKDOWN',content,'');
    this.setState({
      fileName: '',
      contentToBeShared: editor.getContent({ format: 'text' }),
    });
  }


  handleOpenDownloadDialog = () => {
    this.setState({
      openDownloadDialogModal: true,
    });
  };

  handleCloseDownloadDialog = () => {
    this.setState({
      openDownloadDialogModal: false,
      fileName: '',
    });
  };

  handleOpenEmailSendDialog = () => {
    this.setState({
      openDialogModalForEmailSharing: true,
    });
  };

  handleCloseEmailSendDialog = () => {
    this.setState({
      openDialogModalForEmailSharing: false,
    });
  };

  handleFileNameInput = (evt) => {
    this.setState({
      fileName: evt.target.value,
    });
  
  };

  render () {
    const { openDownloadDialogModal,fileName,contentToBeShared,openDialogModalForEmailSharing } = this.state;
    const { markdownEditorContent, isBeingUsedForNotes }  = this.props;
    const styleDecideForMarkDown = (isBeingUsedForNotes ? 'markDownEditor-usedForNotes' : 'markDownEditor-outer-div');
    const styleDecideForDownloadButton = (fileName.length === 0 || fileName.match(validFolderNameRegularExpression)
      ?
      'buttonDownloadEditorContent button-disabled'
      :
      'buttonDownloadEditorContent downloadButton')
      ;
    const gmailLinkForsharing = `https://mail.google.com/mail/?view=cm&fs=1&to=someone@example.com&su=Editor Content &body=${contentToBeShared}`;
    const outlookLinkForSharing = `https://outlook.live.com/?path=/mail/action/compose&to=someone@example.com&subject=Editor Content&body=${contentToBeShared}`;
    
    return (
      <div>
        <div className={styleDecideForMarkDown}>
          
          <div className='header'>
            <Button
              onClick={this.handleOpenDownloadDialog}
              className='buttonForSendingAndDownloading'
            >
            Download Content
            </Button>
            <Button
              onClick={this.handleOpenEmailSendDialog}
              className='sendMailButtonStyle'
              id= 'sendMailButton'
            >
            Share Via Mail
            </Button>
          </div>
          
          <Editor
            apiKey= {tinyMceAPIKey}
            value={markdownEditorContent}
            init={{
              height: (isBeingUsedForNotes ? '92vh' : '82.5vh'),
              width: '100%',
              branding: false,
              menubar: false,
              resize: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime image table paste code help wordcount searchreplace media table ',
              ],
              toolbar:
                'undo redo| formatselect | bold italic backcolor \n'  +
                'alignleft aligncenter alignright alignjustify \n' +
                'bullist numlist outdent indent | removeformat | help | | searchreplace | image imagetools \n ' +
                'table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | \n ' +
                'tableinsertcolbefore tableinsertcolafter tabledeletecol',
              table_toolbar: '',
            }}
            
            onEditorChange={this.handleEditorChange}
            className = 'markDownEditor'
          />
        </div>

        <Dialog
          open = { openDialogModalForEmailSharing }
          onClose = {this.handleCloseEmailSendDialog}
        >
          <DialogTitle
            className = "headerEmailEditorContent"
            style = {{ justifyContent: 'center', display: 'flex' }}
          >
            {' Send Mail '}
          </DialogTitle>
          <DialogContent
            
          >
            <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '20px' }}>
              <a href={gmailLinkForsharing}
                target="_blank" rel="noopener noreferrer"  id = 'gmailSendOption' >
                <img src={GmailLogo} alt='Gmail Logo' className='gmailLogo' title='Share Via Gmail'
                >
                </img>
              </a>
              
                
              <a href={outlookLinkForSharing}
                className = 'anchorTagForOutlook'
                target="_blank" rel="noopener noreferrer" id = 'outlookSendOption'>
                <img src={OutlookLogo} alt='Gmail Logo' className='outlookLogo' title='Share Via Outlook'
                >
                </img>
              </a>
            </div>
            
                  
          </DialogContent>
          <DialogActions>
            <Button
              onClick = {this.handleCloseEmailSendDialog}
              color = "primary"
              className = "buttonEmailSendEditorContent emailCancelButton"
              id = 'cancelEmailSendDialogButton'
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleCloseEmailSendDialog}
              color = 'primary'
              className = 'buttonEmailSendEditorContent OkButton'
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open = { openDownloadDialogModal }
          onClose = {this.handleCloseDownloadDialog}
          maxWidth= 'lg'
        >
          <DialogTitle
            className = "headerDownloadEditorContent"
            style = {{ justifyContent: 'center', display: 'flex' }}
          >
            {' Download Content '}
          </DialogTitle>
          <DialogContent
            style = {{
              display: 'flex',
              color: 'black',
              justifyContent: 'center',
              fontSize: '18px',
            }}
          >
            <div style={{ display: 'flex' , flexDirection: 'row' , justifyContent: 'space-evenly', marginTop: '10px',marginBottom: '10px' }}>
              <span className='full-text' style={{ color: '#082030' }}>Enter the name for the file to be download (without extension):</span>
              <span className='short-text' style={{ color: '#082030' }}>Enter File Name</span>
              <input  value={fileName} onChange={this.handleFileNameInput} className='inputElement' id='fileNameInput'></input>
            </div>
                  
          </DialogContent>
          <DialogActions>
            <Button
              onClick = {this.handleCloseDownloadDialog}
              color = "primary"
              className = "buttonDownloadEditorContent cancelButton"
              id = 'cancelDownloadDialogButton'
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleDownLoadEditorContent}
              color = 'primary'
              className = {styleDecideForDownloadButton}
              disabled={(fileName.match(validFolderNameRegularExpression))}
              id = 'downloadButton'
            >
              Download
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

MarkDownEditorView.propTypes = {
  changeCurrentContentOfEditor: PropTypes.func.isRequired,
  markdownEditorContent: PropTypes.string.isRequired,
  isBeingUsedForNotes: PropTypes.bool,
};

MarkDownEditorView.defaultProps = {
  changeCurrentContentOfEditor: () => {
  },
  markdownEditorContent: '',
};

export default MarkDownEditorView;

