import { getSessionForSandbox } from '../../redux/sandbox/actions';

export const mapStateToProps = state => ({
  token: state.auth.token,
  isSavingCurrentCode: state.code.isSavingCurrentCode,
  isForkingCode: state.code.isForkingCode,
  isFetchingForks: state.sandbox.isFetchingForks,
  forkCounter: state.code.forkCounter,
  isFileSystemEnabled: state.sandbox.isFileSystemEnabled,
});

export const mapDispatchToProps = dispatch => ({
  getSandboxData: (sandboxId, history) => {
    dispatch(getSessionForSandbox(sandboxId, history));
  },
});