import { getSessionForSandbox } from '../../redux/sandbox/actions';

export const mapStateToProps = state => ({
  frameworkId: state.sandbox.frameworkId,
  token: state.auth.token,
  showInitializingLoader: state.sandbox.isInitializingSandbox,
  isFileSystemEnabled: state.sandbox.isFileSystemEnabled,
});

export const mapDispatchToProps = dispatch => ({
  getSandboxData: (sandboxId, history) => {
    dispatch(getSessionForSandbox(sandboxId, history));
  },
});