import React, { Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import PropTypes from 'prop-types';
import './ForkedCodeView.css';
import {
  tooltipForSelectPreviousSaves,
} from './testConstants.js';

class ForkedCodeView extends Component {
  constructor (props, context) {
    super(props, context);
    this.state = {
      selectedForkValue: '',
    };
  }

    showForkedCode = (forkId, forkname) => {
      toast.success(`Successfully selected ${forkname}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      this.setState({
        selectedForkValue: forkname,
      });
      const { fetchForkedCode, frameworkId } = this.props;
      fetchForkedCode(forkId, frameworkId);
    }

    backToCurrentCode = () => {
      toast.success(`You are on current code`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      const { fetchCurrentCodeAfterForkCodeView, frameworkId } = this.props;
      fetchCurrentCodeAfterForkCodeView(frameworkId);
    }

    handleOpen = () => {
      const { setOpen, setButtonClick } = this.props;
      setOpen(true);
      setButtonClick(true);
    }

    

    render () {
      const {
        forkedCode,
        copyOfCurrentCodeWhileShowingForkedCode,
        isEditorShowingCurrentCode,
      } = this.props;
      const { selectedForkValue } = this.state;
      const isRevertButtonDisable = ((copyOfCurrentCodeWhileShowingForkedCode && Object.keys(copyOfCurrentCodeWhileShowingForkedCode).length === 0)
        || isEditorShowingCurrentCode);
      return (
        <div className='forkedCodeViewContainer'>
          <FormControl
            variant="outlined"
            disabled={Object.keys(forkedCode).length === 0}
            title={Object.keys(forkedCode).length === 0 ? 'No saves available' : ''}

          >
            <InputLabel
              id="demo-simple-select-outlined-label"
              className={isRevertButtonDisable ?
                'disableInputLabelStyleForForkedCode' : null }
            >
              Previous saves
            </InputLabel>
            <Select
              className='forkCodeViewSelect'
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              menustyle={{ width: '300px' }}
              label="Previous saves"
              onOpen={this.handleOpen}
              title={isRevertButtonDisable ? tooltipForSelectPreviousSaves : selectedForkValue}
              value={isRevertButtonDisable ? '' : selectedForkValue}
            >
              {Object.keys(forkedCode).map((forkId) => {
                const fork = forkedCode[forkId];
                return (
                  <MenuItem
                    value={fork.name}
                    primarytext={fork.name}
                    key={fork._id}
                    title={fork.description}
                    onClick={() => this.showForkedCode(forkId, fork.name)}
                  >{fork.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
                &nbsp;&nbsp;
          <button
            className={isRevertButtonDisable ?
              'disableRevertButtonForForkCode' : 'revertButtonForForkCode'
            }
            disabled={isRevertButtonDisable}
            onClick={this.backToCurrentCode}
            title='Click to revert back to your original code'
            
          >
            {<ArrowBackIosRoundedIcon className='arrowBackIosRoundedIconStyle'/>}
          </button>
        </div>
      );
    }
}

ForkedCodeView.propTypes = {
  forkedCode: PropTypes.object,
  isEditorShowingCurrentCode: PropTypes.bool.isRequired,
  copyOfCurrentCodeWhileShowingForkedCode: PropTypes.object,
  fetchCurrentCodeAfterForkCodeView: PropTypes.func.isRequired,
  fetchForkedCode: PropTypes.func.isRequired,
  frameworkId: PropTypes.string,
  setOpen: PropTypes.func,
  setButtonClick: PropTypes.func,
};

ForkedCodeView.defaultProps = {
  isEditorShowingCurrentCode: false,
};

export default ForkedCodeView;