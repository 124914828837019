import {
  javascriptDefaultCode,
  htmlDefaultCode,
  cssDefaultCode,
  reactDefaultCode,
  angularJSDefaultCode,
  // emptyHTML,
  // emptyCSS,
  // emptyJS,
} from './defaultCode';
import { TOTAl_NUMBER_OF_FORKS_AVAILABLE } from '../../configFiles/config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  filterHTML,
  handleNavigation,
} from './utils';
import { cardsForHomePage } from '../../AvailableCards/availableCards';

export const FORK_CURRENT_CODE = 'code/FORK_CURRENT_CODE';
export const FORK_CURRENT_CODE_PENDING = 'code/FORK_CURRENT_CODE_PENDING';
export const FORK_CURRENT_CODE_FULFILLED = 'code/FORK_CURRENT_CODE_FULFILLED';
export const FORK_CURRENT_CODE_REJECTED = 'code/FORK_CURRENT_CODE_REJECTED';

export const DECREMENT_THE_FORK_COUNT = 'code/DECREMENT_THE_FORK_COUNT';
export const FETCH_FORKED_CODE = 'code/FETCH_FORKED_CODE';
export const FETCH_CURRENT_CODE_AFTER_VIEWING_FORKED_CODE = 'code/FETCH_CURRENT_CODE_AFTER_VIEWING_FORKED_CODE';
export const CHANGE_CURRENT_CODE = 'code/CHANGE_CURRENT_CODE';
export const RESET_TO_DEFAULT_CODE = 'code/RESET_TO_DEFAULT_CODE';
export const SAVE_CURRENT_CODE = 'code/SAVE_CURRENT_CODE';
export const SAVE_CURRENT_CODE_PENDING = 'code/SAVE_CURRENT_CODE_PENDING';
export const SAVE_CURRENT_CODE_FULFILLED = 'code/SAVE_CURRENT_CODE_FULFILLED';
export const SAVE_CURRENT_CODE_REJECTED = 'code/SAVE_CURRENT_CODE_REJECTED';

export const SET_CODE_FOR_HTML = 'code/SET_CODE_FOR_HTML';

export const SET_CODE_FOR_REACT = 'code/SET_CODE_FOR_REACT';

export const SET_EMPTY_CODE = 'code/SET_EMPTY_CODE';

export const SET_PREVIOUS_FORKS = 'code/SET_PREVIOUS_FORKS';

export const SET_COMPILED_CODE_FOR_MICROSERVICE_SANDBOX = 'code/SET_COMPILED_CODE_FOR_MICROSERVICE_SANDBOX';

export const SET_CODE_FOR_ANGULAR_JS = 'code/SET_CODE_FOR_ANGULAR_JS';

export const SET_IS_COMPONENT_UNMOUNTING_FOR_NORMAL = 'code/SET_IS_COMPONENT_UNMOUNTING_FOR_NORMAL';

export const SET_OPEN_DRAWER = 'code/SET_OPEN_DRAWER';

export const SET_DRAWER_BUTTON_CLICK = 'code/SET_DRAWER_BUTTON_CLICK';

toast.configure(
  {
    newestOnTop: true,
    enableMultiContainer: true,
    limit: 5,
    position: toast.POSITION.BOTTOM_RIGHT,
    toastId: 'TOAST_CONTAINER_FOR_CODE_ACTIONS',
    hideProgressBar: true,
  },
);

const defaultState = {
  forkedCode: {},
  forkCounter: TOTAl_NUMBER_OF_FORKS_AVAILABLE,
  compiledHTMLCode: `<script> ${javascriptDefaultCode} </script> <style> ${cssDefaultCode} </style> ${htmlDefaultCode}`,
  copyOfCurrentCodeWhileShowingForkedCode: {},
  isEditorShowingCurrentCode: true,
  defaultCode: {
    HTML: {
      javascript: javascriptDefaultCode,
      html: htmlDefaultCode,
      css: cssDefaultCode,
    },
    REACT_JS: {
      javascript: reactDefaultCode,
      css: cssDefaultCode,
    },
    ANGULAR_JS: {
      javascript: angularJSDefaultCode,
      css: cssDefaultCode,
    },
  },
  currentCode: {
    HTML: {
      javascript: javascriptDefaultCode,
      html: htmlDefaultCode,
      css: cssDefaultCode,
    },
    REACT_JS: {
      javascript: reactDefaultCode,
      css: cssDefaultCode,
    },
    ANGULAR_JS: {
      javascript: angularJSDefaultCode,
      css: cssDefaultCode,
    },
  },
  isSavingCurrentCode: false,
  isForkingCode: false,
  isComponentUnmounting: false,
  open: false,
  onButtonClick: false,
};

const code = (state = defaultState, action) => {
  switch (action.type) {
  case SAVE_CURRENT_CODE: {
    return {
      ...state,
      isSavingCurrentCode: true,
    };
  }

  case SAVE_CURRENT_CODE_PENDING: {
    return {
      ...state,
      isSavingCurrentCode: true,
    };
  }

  case SAVE_CURRENT_CODE_FULFILLED: {
    const { isComponentUnmounting } = state;

    if (!isComponentUnmounting) {
      toast.success('Code Saved Successfully', { position: toast.POSITION.BOTTOM_RIGHT });
    }
    return {
      ...state,
      isSavingCurrentCode: false,
      isComponentUnmounting: false,
    };
  }

  case SAVE_CURRENT_CODE_REJECTED: {
    // const { errors } = action.payload;
    // const { message } = errors[0];
    // toast.error('Could not save. Please try again', { position: toast.POSITION.BOTTOM_RIGHT });
    return {
      ...state,
      isSavingCurrentCode: false,
      isComponentUnmounting: false,
    };
  }

  case FORK_CURRENT_CODE: {
    return {
      ...state,
      isForkingCode: true,
    };
  }

  case FORK_CURRENT_CODE_PENDING: {
    return {
      ...state,
      isForkingCode: true,
    };
  }

  case FORK_CURRENT_CODE_FULFILLED: {
    const { data } = action.payload;

    const {
      forkedCode,
    } = state;

    const allForks = Object.assign({}, forkedCode);

    const {
      fork,
      totalForks,
    } = data;

    const forkKey = Object.keys(fork) && Object.keys(fork).length > 0 ? Object.keys(fork)[0] : undefined;

    if (!forkKey) {
      return {
        ...state,
        isForkingCode: false,
      };
    }

    allForks[forkKey] = fork[forkKey];
    toast.success(`Successfully saved ${fork[forkKey].name} `, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    const remainingForks = TOTAl_NUMBER_OF_FORKS_AVAILABLE - totalForks;
    return {
      ...state,
      isForkingCode: false,
      forkedCode: allForks,
      forkCounter: remainingForks,
    };
  }

  case FORK_CURRENT_CODE_REJECTED: {
    toast.error('could not save. Please try again!!!', {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    return {
      ...state,
      isForkingCode: false,
    };
  }

  case DECREMENT_THE_FORK_COUNT: {
    const { forkCounter } = state;
    return {
      ...state,
      forkCounter: forkCounter - 1,
    };
  }

  case FETCH_FORKED_CODE: {
    const {
      forkedCode,
      currentCode,
      copyOfCurrentCodeWhileShowingForkedCode,
    } = state;
    const { data } = action.payload;
    const { forkId, frameworkId, dependentPackagesForSandboxResolvedHtml } = data;

    const currentCodeWhileShowingForkedCode = {
      HTML: {
        javascript: '', html: '', css: '',
      },
      REACT_JS: {
        javascript: '', css: '',
      },
      ANGULAR_JS: {
        javascript: '', css: '',
      },
    };
    if ( frameworkId === 'HTML' ) {
      currentCodeWhileShowingForkedCode[frameworkId].javascript = forkedCode[forkId] && forkedCode[forkId].code && forkedCode[forkId].code.JS;
      currentCodeWhileShowingForkedCode[frameworkId].html = forkedCode[forkId] && forkedCode[forkId].code && forkedCode[forkId].code.HTML;
      currentCodeWhileShowingForkedCode[frameworkId].css = forkedCode[forkId] && forkedCode[forkId].code && forkedCode[forkId].code.CSS;
    } else if ( frameworkId === 'REACT_JS' ) {
      currentCodeWhileShowingForkedCode[frameworkId].javascript = forkedCode[forkId] && forkedCode[forkId].code && forkedCode[forkId].code.JS;
      currentCodeWhileShowingForkedCode[frameworkId].css = forkedCode[forkId] && forkedCode[forkId].code && forkedCode[forkId].code.CSS;
    } else if ( frameworkId === 'ANGULAR_JS' ) {
      currentCodeWhileShowingForkedCode[frameworkId].javascript = forkedCode[forkId] && forkedCode[forkId].code && forkedCode[forkId].code.JS;
      currentCodeWhileShowingForkedCode[frameworkId].css = forkedCode[forkId] && forkedCode[forkId].code && forkedCode[forkId].code.CSS;
    }

    let copyOfCurrentCode;

    if (Object.keys(copyOfCurrentCodeWhileShowingForkedCode).length === 0) {
      copyOfCurrentCode = Object.assign({}, currentCode);
    } else {
      copyOfCurrentCode = Object.assign({}, copyOfCurrentCodeWhileShowingForkedCode);
    }
    let valueForCompiledHTMLCode = '';
    if (frameworkId === 'HTML') {
      valueForCompiledHTMLCode = `
      <script>
        ${currentCodeWhileShowingForkedCode[frameworkId] && currentCodeWhileShowingForkedCode[frameworkId].javascript}
      </script>
      <style>
        ${currentCodeWhileShowingForkedCode[frameworkId] && currentCodeWhileShowingForkedCode[frameworkId].css}
      </style>
        ${currentCodeWhileShowingForkedCode[frameworkId] && currentCodeWhileShowingForkedCode[frameworkId].html}`;
    } else if (frameworkId === 'REACT_JS') {
      valueForCompiledHTMLCode = `
      <head>${dependentPackagesForSandboxResolvedHtml}</head>
      <div id='root'></div>
      <script type = 'text/babel'>
        ${currentCodeWhileShowingForkedCode[frameworkId] && currentCodeWhileShowingForkedCode[frameworkId].javascript}
      </script>
      <style>
        ${currentCodeWhileShowingForkedCode[frameworkId] && currentCodeWhileShowingForkedCode[frameworkId].css}
      </style>`;
    } else if (frameworkId === 'ANGULAR_JS') {
      valueForCompiledHTMLCode = `
      <html>
      ${dependentPackagesForSandboxResolvedHtml}
      ${currentCodeWhileShowingForkedCode[frameworkId] && currentCodeWhileShowingForkedCode[frameworkId].javascript}
      <style>
      ${currentCodeWhileShowingForkedCode[frameworkId] && currentCodeWhileShowingForkedCode[frameworkId].css}
      </style>
      </html>`;
    }
    
    return {
      ...state,
      copyOfCurrentCodeWhileShowingForkedCode: copyOfCurrentCode,
      currentCode: currentCodeWhileShowingForkedCode,
      isEditorShowingCurrentCode: false,
      compiledHTMLCode: valueForCompiledHTMLCode,
    };
  }

  case FETCH_CURRENT_CODE_AFTER_VIEWING_FORKED_CODE: {
    const { frameworkId, dependentPackagesForSandboxResolvedHtml } = action.payload.data;
    const { copyOfCurrentCodeWhileShowingForkedCode } = state;
    let valueForCompiledHTMLCode = '';
    if (frameworkId === 'HTML') {
      valueForCompiledHTMLCode = `
      <script>
        ${copyOfCurrentCodeWhileShowingForkedCode[frameworkId] && copyOfCurrentCodeWhileShowingForkedCode[frameworkId].javascript}
      </script>
      <style>
        ${copyOfCurrentCodeWhileShowingForkedCode[frameworkId] && copyOfCurrentCodeWhileShowingForkedCode[frameworkId].css}
      </style>
        ${copyOfCurrentCodeWhileShowingForkedCode[frameworkId] && copyOfCurrentCodeWhileShowingForkedCode[frameworkId].html}`;
    } else if (frameworkId === 'REACT_JS') {
      valueForCompiledHTMLCode = `
      <head>${dependentPackagesForSandboxResolvedHtml}</head>
      <div id='root'></div>
      <script type = 'text/babel'>
        ${copyOfCurrentCodeWhileShowingForkedCode[frameworkId] && copyOfCurrentCodeWhileShowingForkedCode[frameworkId].javascript}
      </script>
      <style>
        ${copyOfCurrentCodeWhileShowingForkedCode[frameworkId] && copyOfCurrentCodeWhileShowingForkedCode[frameworkId].css}
      </style>`;
    } else if (frameworkId === 'ANGULAR_JS') {
      valueForCompiledHTMLCode = `
      <html>
       ${dependentPackagesForSandboxResolvedHtml}
       ${copyOfCurrentCodeWhileShowingForkedCode[frameworkId] && copyOfCurrentCodeWhileShowingForkedCode[frameworkId].javascript}
      <style>
       ${copyOfCurrentCodeWhileShowingForkedCode[frameworkId] && copyOfCurrentCodeWhileShowingForkedCode[frameworkId].css}
      </style>
      </html>`;
    }
    return {
      ...state,
      currentCode: copyOfCurrentCodeWhileShowingForkedCode,
      isEditorShowingCurrentCode: true,
      compiledHTMLCode: valueForCompiledHTMLCode,
    };
  }

  case CHANGE_CURRENT_CODE: {
    const { currentCode } = state;
    const { data } = action.payload;
    const { language, frameworkId, code, dependentPackagesForSandboxResolvedHtml } = data;

    const copyOfCurrentCode = Object.assign({}, currentCode);
    copyOfCurrentCode[frameworkId][language] = code;

    // console.log('navigations', navigations);
    // console.log('code', code);
    // console.log('language', language);
    // console.log('copyOfCurrentCode', copyOfCurrentCode[frameworkId].javascript);

    let valueForCompiledHTMLCode = '';
    if (frameworkId === 'HTML') {
      const filteredHTML = filterHTML(copyOfCurrentCode[frameworkId] && copyOfCurrentCode[frameworkId].html);

      const navigations = handleNavigation(copyOfCurrentCode[frameworkId] && copyOfCurrentCode[frameworkId].html);

      valueForCompiledHTMLCode = `
      <script>
        ${copyOfCurrentCode[frameworkId] && copyOfCurrentCode[frameworkId].javascript}
      </script>
      ${navigations}
      <style>
        ${copyOfCurrentCode[frameworkId] && copyOfCurrentCode[frameworkId].css}
      </style>
        ${filteredHTML}`;
    } else if (frameworkId === 'REACT_JS') {
      valueForCompiledHTMLCode = `
      <head>${dependentPackagesForSandboxResolvedHtml}</head>
      <div id='root'></div>
      <script type = 'text/babel'>
        ${copyOfCurrentCode[frameworkId] && copyOfCurrentCode[frameworkId].javascript}
      </script>
      <style>
        ${copyOfCurrentCode[frameworkId] && copyOfCurrentCode[frameworkId].css}
      </style>`;
    } else if (frameworkId === 'ANGULAR_JS') {
      valueForCompiledHTMLCode = `
      <html>
       ${dependentPackagesForSandboxResolvedHtml}
       ${copyOfCurrentCode[frameworkId] && copyOfCurrentCode[frameworkId].javascript}
      <style>
       ${copyOfCurrentCode[frameworkId] && copyOfCurrentCode[frameworkId].css}
      </style>
      </html>`;
    }
    // console.log('valueForCompiledHTMLCode', valueForCompiledHTMLCode);
    return {
      ...state,
      currentCode: copyOfCurrentCode,
      compiledHTMLCode: valueForCompiledHTMLCode,
      copyOfCurrentCodeWhileShowingForkedCode: {},
    };
  }

  case RESET_TO_DEFAULT_CODE: {
    const { defaultCode } = state;
    // console.log('::::::::::::defaultCode',defaultCode);
    // let copiedDefaultCode = JSON.parse(JSON.stringify(defaultCode));
    let copiedDefaultCode = Object.assign({},defaultCode);
    copiedDefaultCode['HTML'] = Object.assign({},defaultCode['HTML']);
    copiedDefaultCode['REACT_JS'] = Object.assign({},defaultCode['REACT_JS']);
    copiedDefaultCode['ANGULAR_JS'] = Object.assign({},defaultCode['ANGULAR_JS']);

    return {
      ...state,
      currentCode: copiedDefaultCode,
      compiledHTMLCode: `<script> ${javascriptDefaultCode} </script> <style> ${cssDefaultCode} </style> ${htmlDefaultCode}`,
    };
  }

  case SET_CODE_FOR_HTML: {
    const { data } = action.payload;
    const { currentCode } = state;
    const { HTML, CSS, JS } = data;
    const frameworkId = 'HTML';
    const updatedCurrentCode = Object.assign({}, currentCode);
    updatedCurrentCode[frameworkId].html = HTML;
    updatedCurrentCode[frameworkId].css = CSS;
    updatedCurrentCode[frameworkId].javascript = JS;

    const filteredHTML = filterHTML(HTML);
    const navigations = handleNavigation(updatedCurrentCode[frameworkId].html);

    const valueForCompiledHTMLCode = `
      <script>
        ${JS}
      </script>
      ${navigations}
      <style>
        ${CSS}
      </style>
        ${filteredHTML}`;

    return {
      ...state,
      currentCode: updatedCurrentCode,
      compiledHTMLCode: valueForCompiledHTMLCode,
    };
  }

  case SET_CODE_FOR_REACT: {
    const { currentCode } = state;
    const { data } = action.payload;
    const { code, dependentPackagesForSandboxResolvedHtml } = data;

    const frameworkId = 'REACT_JS';

    const { CSS, JS } = code;

    const updatedCurrentCode = Object.assign({}, currentCode);
    updatedCurrentCode[frameworkId].css = CSS;
    updatedCurrentCode[frameworkId].javascript = JS;

    const copyOfCurrentCode = Object.assign({}, currentCode);
    // copyOfCurrentCode[frameworkId][language] = code;

    const valueForCompiledHTMLCode = `
      <head>${dependentPackagesForSandboxResolvedHtml}</head>
      <div id='root'></div>
      <script type = 'text/babel'>
        ${copyOfCurrentCode[frameworkId] && copyOfCurrentCode[frameworkId].javascript}
      </script>
      <style>
        ${copyOfCurrentCode[frameworkId] && copyOfCurrentCode[frameworkId].css}
      </style>`;
    return {
      ...state,
      currentCode: updatedCurrentCode,
      compiledHTMLCode: valueForCompiledHTMLCode,
    };
  }

  case SET_CODE_FOR_ANGULAR_JS: {
    const { currentCode } = state;
    const { data } = action.payload;
    const { code, dependentPackagesForSandboxResolvedHtml } = data;

    const frameworkId = 'ANGULAR_JS';

    const { CSS, JS } = code;

    const updatedCurrentCode = Object.assign({}, currentCode);
    updatedCurrentCode[frameworkId].css = CSS;
    updatedCurrentCode[frameworkId].javascript = JS;

    const copyOfCurrentCode = Object.assign({}, currentCode);
    // copyOfCurrentCode[frameworkId][language] = code;

    const valueForCompiledHTMLCode = `
    <html>
     ${dependentPackagesForSandboxResolvedHtml}
     ${copyOfCurrentCode[frameworkId] && copyOfCurrentCode[frameworkId].javascript}
    <style>
     ${copyOfCurrentCode[frameworkId] && copyOfCurrentCode[frameworkId].css}
    </style>
    </html>`;

    
    return {
      ...state,
      currentCode: updatedCurrentCode,
      compiledHTMLCode: valueForCompiledHTMLCode,
    };
  }

  case SET_EMPTY_CODE: {
    const { currentCode } = state;
    // const { data } = action.payload;
    // const { frameworkId } = data;
    const emptyCurrentCode = Object.assign({}, currentCode);

    Object.keys(emptyCurrentCode).forEach((frameworkId) => {
      const editorsForCurrentFrameworkId = emptyCurrentCode[frameworkId];
      Object.keys(editorsForCurrentFrameworkId).forEach((editorWindow) => {
        editorsForCurrentFrameworkId[editorWindow] = '';
      });
      emptyCurrentCode[frameworkId] = editorsForCurrentFrameworkId;
    });
    return {
      ...state,
      currentCode: emptyCurrentCode,
    };
  }

  case SET_PREVIOUS_FORKS: {
    const { data } = action.payload;

    if (!data) {
      return {
        ...state,
      };
    }

    const { forks } = data;

    const totalForks = Object.keys(forks).length;


    return {
      ...state,
      forkedCode: forks,
      forkCounter: TOTAl_NUMBER_OF_FORKS_AVAILABLE - totalForks,
    };
  }

  case SET_OPEN_DRAWER: {
    const { data } = action.payload;

    if (!data) {
      return {
        ...state,
      };
    }

    const { value } = data;
    return {
      ...state,
      open: value,
    };
  }

  case SET_DRAWER_BUTTON_CLICK: {
    const { data } = action.payload;

    if (!data) {
      return {
        ...state,
      };
    }

    const { value } = data;
    return {
      ...state,
      onButtonClick: value,
    };
  }

  case SET_COMPILED_CODE_FOR_MICROSERVICE_SANDBOX: {
    const { defaultCode } = state;
    const { data } = action.payload;
    const {
      frameworkId,
      dependentPackagesForSandboxResolvedHtml,
    } = data;

    let valueForCompiledHTMLCode = '';
    if (frameworkId === cardsForHomePage.HTML.frameworkId) {
      const filteredHTML = filterHTML(defaultCode[frameworkId] && defaultCode[frameworkId].html);

      const navigations = handleNavigation(defaultCode[frameworkId] && defaultCode[frameworkId].html);

      valueForCompiledHTMLCode = `
      <script>
        ${defaultCode[frameworkId] && defaultCode[frameworkId].javascript}
      </script>
      ${navigations}
      <style>
        ${defaultCode[frameworkId] && defaultCode[frameworkId].css}
      </style>
        ${filteredHTML}`;
    } else if (frameworkId === cardsForHomePage.ReactJS.frameworkId) {
      valueForCompiledHTMLCode = `
      <head>${dependentPackagesForSandboxResolvedHtml}</head>
      <div id='root'></div>
      <script type = 'text/babel'>
        ${defaultCode[frameworkId] && defaultCode[frameworkId].javascript}
      </script>
      <style>
        ${defaultCode[frameworkId] && defaultCode[frameworkId].css}
      </style>`;
    } else if (frameworkId === cardsForHomePage.AngularJS.frameworkId) {
      valueForCompiledHTMLCode = `
      <html>
       ${dependentPackagesForSandboxResolvedHtml}
       ${defaultCode[frameworkId] && defaultCode[frameworkId].javascript}
      <style>
       ${defaultCode[frameworkId] && defaultCode[frameworkId].css}
      </style>
      </html>`;
    }

    return {
      ...state,
      compiledHTMLCode: valueForCompiledHTMLCode,
    };
  }

  case SET_IS_COMPONENT_UNMOUNTING_FOR_NORMAL: {

    return {
      ...state,
      isComponentUnmounting: true,
    };
  }

  default:
    return state;
  }
};

export default code;
