import { changeEditorIndentation } from '../../redux/editor/actions';

export const mapStateToProps = state => ({
  indentationForEditors: state.editor.indentationForEditors,
});
  
export const mapDispatchToProps = dispatch => ({
  changeEditorIndentation: (spaces) => {
    dispatch(changeEditorIndentation(spaces));
  },
});
  