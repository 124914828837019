import React, { Component } from 'react';
import {
  AppBar,
  CssBaseline,
  Toolbar,
  Select,
  FormControl,
  MenuItem,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import './ToggleEditorView.css';
import MultiLanguageCodeEditorView from '../MultiLanguageCodeEditorView';
import MarkDownEditorView from '../MarkDownEditorView';
import JsHtmlCssPreviewWindow from '../JsHtmlCssPreviewWindow';
import queryString from 'query-string';
import { Available_Languages_In_Editor, myanatomyHomePageUrl, windowTypeSelectionDropdown } from '../../configFiles/config';
import DiagramsMicroserviceView from '../DiagramsMicroserviceView';
import ReactBasicSandbox from '../ReactBasicSandbox/ReactBasicSandbox';
import AngularJSBasicSandbox from '../AngularJSBasicSandbox';
import { cardsForHomePage } from '../../AvailableCards/availableCards';

class ToggleEditorView extends Component {
  constructor (props, context) {
    super(props, context);
    let url = this.props.history.location.search;
    let params = queryString.parse(url);

    let viewVisible = 'code';
    let modeDecideForEditor = 'c_cpp';

    const {
      windowType,
      purposeId,
      clientId,
    } = params;

    const {
      updateWindowType,
      setFrameworkIdForMicroServiceSandbox,
      frameworkId,
      resetPreview,
      setPurposeId,
    } = props;

    if (purposeId) {
      setPurposeId(purposeId, clientId);
    }

    if (windowType) {
      updateWindowType(windowType);
    }

    if (params.type === 'CODE_EDITOR'){
      if (!((params.language === 'c') || (params.language === 'cpp'))) {
        modeDecideForEditor = params.language;
      } else {
        modeDecideForEditor = 'c++';
      }
      const { changeCurrentContentOfEditor } = this.props;
      let isLanguageAvailable = (params && params.language) in Available_Languages_In_Editor;
      const defaultLangugageSelected = isLanguageAvailable ? params.language : 'c++';
      changeCurrentContentOfEditor('CODE_EDITOR', '', defaultLangugageSelected);
    } else if (params.type === 'MARKDOWN') {
      viewVisible = 'markdown';
      if (frameworkId) {
        setFrameworkIdForMicroServiceSandbox();
      }
    } else if (params.type === 'PAINT'){
      viewVisible = 'paint';
      if (frameworkId) {
        setFrameworkIdForMicroServiceSandbox();
      }
    } else if (params.type === 'HTML') {
      const frameworkIdForHtml = cardsForHomePage.HTML.frameworkId;
      setFrameworkIdForMicroServiceSandbox(frameworkIdForHtml);
      resetPreview(frameworkIdForHtml);
      viewVisible = 'codeframeHtml';
    } else if (params.type === 'REACT') {
      const frameworkIdForReact = cardsForHomePage.ReactJS.frameworkId;
      setFrameworkIdForMicroServiceSandbox(frameworkIdForReact);
      resetPreview(frameworkIdForReact);
      viewVisible = 'codeframeReact';
    } else if (params.type === 'ANGULAR') {
      const frameworkIdForAngular = cardsForHomePage.AngularJS.frameworkId;
      setFrameworkIdForMicroServiceSandbox(frameworkIdForAngular);
      resetPreview(frameworkIdForAngular);
      viewVisible = 'codeframeAngular';
    } else {
      modeDecideForEditor = 'c++';
      const { changeCurrentContentOfEditor } = this.props;
      let isLanguageAvailable = (params && params.language) in Available_Languages_In_Editor;
      const defaultLangugageSelected = isLanguageAvailable ? params.language : 'c++';
      changeCurrentContentOfEditor('CODE_EDITOR', '', defaultLangugageSelected);
      if (frameworkId) {
        setFrameworkIdForMicroServiceSandbox();
      }
    }

    this.state = {
      viewVisible,
      isAnEditor: false,
      isMarkDownEditor: false,
      extractedQueryParams: params,
      modeDecideForEditor,
    };
  }

  toggleChange = (e) => {
    const {
      frameworkId,
      setFrameworkIdForMicroServiceSandbox,
      resetPreview,
    } = this.props;
    const value = e.target.value;

    switch (value) {
    case 'codeframeHtml': {
      const frameworkIdForHtml = cardsForHomePage.HTML.frameworkId;
      setFrameworkIdForMicroServiceSandbox(frameworkIdForHtml);
      resetPreview(frameworkIdForHtml);
      break;
    }
    case 'codeframeAngular': {
      const frameworkIdForAngular = cardsForHomePage.AngularJS.frameworkId;
      setFrameworkIdForMicroServiceSandbox(frameworkIdForAngular);
      resetPreview(frameworkIdForAngular);
      break;
    }
    case 'codeframeReact': {
      const frameworkIdForReact = cardsForHomePage.ReactJS.frameworkId;
      setFrameworkIdForMicroServiceSandbox(frameworkIdForReact);
      resetPreview(frameworkIdForReact);
      break;
    }
    default: {
      if (frameworkId) {
        setFrameworkIdForMicroServiceSandbox();
        resetPreview();
      }
      break;
    }
    }
    this.setState({
      viewVisible: value,
    });
  }

  componentDidMount (){


    // const {
    //   saveClientDetails,
    // } = this.props;

    // Use variables here to pass client details
    // saveClientDetails('undefined', 'EDITOR', 'undefined');
    // window.addEventListener('beforeunload', this.updateEditorContentDetails);
  }

  // componentWillUnmount (){
  //   this.updateEditorContentDetails();
  //   window.removeEventListener('beforeunload', this.updateEditorContentDetails);
  // }

  // updateEditorContentDetails = () => {
  //   const { purposeId, clientDetails, windowType, updateEditorContent } = this.props;
  //   updateEditorContent(purposeId,clientDetails, 'CODE_EDITOR', windowType, 'content');
  // }

  render () {
    const {
      viewVisible,
      extractedQueryParams,
      modeDecideForEditor,
    } = this.state;
    const {
      windowType,
    } = this.props;
    let isLanguageAvailable = (extractedQueryParams && extractedQueryParams.language) in Available_Languages_In_Editor;
    return (
      <div>
        <CssBaseline />
        {windowType === 'WHITE_BOARD' ?
          <AppBar
            className='appBarStyleForToggleEditorView'
            position="fixed"
          >
        
            <Toolbar className='toolbarForToggleEditorView'>
              <div className='company_editorsView'>
                <div className='company-logo_editorsView'
                  onClick={() => window.open(myanatomyHomePageUrl,'_blank')}>
                  <img
                    src="/images/myAnatomyLogo.png"
                    className='companyLogoImageForToggleEditorView'
                    alt='Myanatomy'
                  />
                </div>
                {windowType === 'WHITE_BOARD' ?
                  // <div className = 'toggle-button_editorsView'>
                  //   <div className='inner-button_editorsView'>
                  //     <label className="switch_editorsView">
                  //       <input
                  //         className="switch-input_editorsView"
                  //         onChange={() => this.toggleChange(isEditorViewVisible ? false : true)}
                  //         type="checkbox"
                  //         checked={isEditorViewVisible}
                  //       />
                  //       <span className="switch-label_editorsView" data-on="Editor" data-off="Markdown"></span>
                  //       <span className="switch-handle_editorsView"></span>
                  //     </label>
                  //   </div>
                  // </div>
                  <div>
                    <FormControl
                      className="editorDropDown"
                    >
                      <Select
                        id='toggleEditorDropDown'
                        value={viewVisible}
                        onChange={this.toggleChange}
                        defaultValue={viewVisible}
                        variant="outlined"
                        className='toggleStyleForEditorDropDown'
                      >
                        {
                          Object.keys(windowTypeSelectionDropdown).map((windowType) =>
                            <MenuItem
                              value={windowType}
                              key={windowType}
                            >
                              {windowTypeSelectionDropdown[windowType]}
                            </MenuItem>
                          )
                        }
                      </Select>
                    </FormControl>
                  </div>
                  : null }
              </div>
            </Toolbar>
          
          </AppBar>
          :
          null}
        <div
        >
          {windowType === 'WHITE_BOARD' ?
            (
              viewVisible === 'code' ?
                <MultiLanguageCodeEditorView
                  languageSelected = {
                    isLanguageAvailable ?
                      extractedQueryParams && extractedQueryParams.language
                      :
                      'c++'
                  }
                  modeSelectedInEditor = {
                    isLanguageAvailable ?
                      modeDecideForEditor
                      :
                      'c_cpp'
                  }
                />
                :
                (
                  viewVisible === 'markdown' ?
                    <MarkDownEditorView />
                    :
                    (
                      viewVisible === 'paint' ?
                        <DiagramsMicroserviceView />
                        :
                        viewVisible === 'codeframeReact' ?
                          <ReactBasicSandbox isInMicroService={true}/>
                          :
                          viewVisible === 'codeframeAngular' ?
                            <AngularJSBasicSandbox isInMicroService={true}/>
                            :
                            <JsHtmlCssPreviewWindow isInMicroService={true}/>
                    )
                )
            )
            : <MarkDownEditorView isBeingUsedForNotes={true}/> }
        </div>
      </div>
    );
  }
}

ToggleEditorView.propTypes = {
  history: PropTypes.object.isRequired,
  updateWindowType: PropTypes.func.isRequired,
  changeCurrentContentOfEditor: PropTypes.func.isRequired,
  windowType: PropTypes.string.isRequired,
  setFrameworkIdForMicroServiceSandbox: PropTypes.func,
  frameworkId: PropTypes.string,
  resetPreview: PropTypes.func,
  setPurposeId: PropTypes.func,
};

ToggleEditorView.defaultProps = {
  history: {
    location: {
      search: '',
    },
  },
  updateWindowType: () => {
  },
  changeCurrentContentOfEditor: () => {
  },
  windowType: 'WHITE_BOARD',
  setFrameworkIdForMicroServiceSandbox: () => {
  },
  resetPreview: () => {
  },
  setPurposeId: () => {
  },
};


export default ToggleEditorView;