import { fetchPrivateSandboxUsingRedirection } from '../../redux/sandbox/actions';

export const mapStateToProps = () => ({

});

export const mapDispatchToProps = dispatch => ({
  fetchSandbox: (sessionId, history, sandboxId) => {
    dispatch(fetchPrivateSandboxUsingRedirection(sessionId, history, sandboxId));
  },
});