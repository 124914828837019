import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './FileSystemSandbox.css';
import { toast, ToastContainer } from 'react-toastify';
import CloseIcon from '@material-ui/icons/Close';
import AceEditor from 'react-ace';
import SettingsIcon from '@material-ui/icons/Settings';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/snippets/html';
import 'ace-builds/src-noconflict/snippets/css';
import 'ace-builds/src-noconflict/snippets/javascript';
import Loader from '../Loader';
import Preview from '../Preview';
import SideNav from '../SideNav';
import {
  TOTAl_NUMBER_OF_FORKS_AVAILABLE,
  extensionMap,
} from '../../configFiles/config';
import {
  Button,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import FileExplorerDrawer from '../FileExplorerDrawer';
import CreateAFileView from '../CreateAFileView/';
import OpenFileView from '../OpenFileView';
import EditorPreferences from '../EditorPreferences';
import { handleConnectionDown } from '../../utils/utilityFunctions';

class FileSystemSandbox extends Component {

  constructor (props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.changeSelectedFileOnFileOpen = this.changeSelectedFileOnFileOpen.bind(this);
    const { openFilesInFileSystemSandbox, filesInFileSystemSandbox } = this.props;
    this.state = {
      selectedFile: (Object.keys(openFilesInFileSystemSandbox).length > 0)
        ?
        Object.keys(openFilesInFileSystemSandbox)[0]
        :
        '',
      eventListenerTest: '',
      isOpenNewFileDialog: false,
      isOpenFileDialog: false,
      modeSelectedForAceEditor: (Object.keys(openFilesInFileSystemSandbox).length > 0)
        ?
        extensionMap[filesInFileSystemSandbox[Object.keys(openFilesInFileSystemSandbox)[0]]
        &&
        filesInFileSystemSandbox[Object.keys(openFilesInFileSystemSandbox)[0]].extension]
        :
        '',
      changesDialogModal: false,
      descriptorIdForChangedFileWhileClosing: '',
      isEditorPreferencesDialog: false,
    };
  }

  componentDidMount () {
    const { openFilesInFileSystemSandbox } = this.props;
    this.setState(() => ({
      selectedFile: (Object.keys(openFilesInFileSystemSandbox).length > 0)
        ?
        Object.keys(openFilesInFileSystemSandbox)[0]
        :
        '',
    }));
    window.addEventListener('offline', () => {
      handleConnectionDown( 'Internet connection is lost', toast.POSITION.TOP_RIGHT );
    });

    // window.onkeydown = this.keydown;
    window.addEventListener('keydown', this.keydown);
  }

  keydown = (evt) => {
    // console.log('::::::::::::',evt.keyCode);
    const { eventListnerName,isNotEditable } = this.props;
    // console.log('::::::::::::',eventListnerName)
    this.setState({
      eventListenerTest: eventListnerName,
    });
    
    // if (!evt) evt = event;

    if ((evt.metaKey || evt.ctrlKey) && evt.keyCode === 79) {

      evt.preventDefault();
      // console.log('CTRL+O');
      // var fileSelector = document.createElement('input');
      // fileSelector.setAttribute('type', 'file');
      // fileSelector.click();
      this.setState({
        isOpenFileDialog: true,
      });

    } else if ((evt.metaKey || evt.ctrlKey) && evt.keyCode === 80) {
      evt.preventDefault();
      //opertions for the Ctrl+P to be done here.
      // console.log('CTRL+P');


    } else if ((evt.metaKey || evt.ctrlKey) && evt.keyCode === 73) {
      if (isNotEditable){
        return;
      }
      evt.preventDefault();
      //opertions for the Ctrl+i to be done here.
      // console.log('CTRL+i');
      this.setState({
        isOpenNewFileDialog: true,
      });
    }

    else if ((evt.metaKey || evt.ctrlKey) && evt.keyCode === 83) {
      if (isNotEditable){
        return;
      }
      evt.preventDefault();
      //opertions for the Ctrl+S to be done here.
      this.saveFile(false);
      // const { selectedFile } = this.state;
      // const { saveFileContent } = this.props;
      // console.log('::::::::::::::::',selectedFile.fileDescriptor);
      // deleteDescriptorIdOfCurretlySelectedFile(selectedFile);
      
      //set state is used here for rendering the state again here.
      this.setState({});
    }

  };


  onChange = newValue => {
    // const language = 'html';
    const {
      addDescriptorIdOfCurretlySelectedFile,
      updateCurrentCode,
      currentSelectedDescriptorIdOfTheFile,
    } = this.props;
    const { selectedFile } = this.state;
    // console.log(':::::::::::::::::',!(selectedFile in currentSelectedDescriptorIdOfTheFile));
    // changeCurrentCode(language, newValue);
    if ( !(selectedFile in currentSelectedDescriptorIdOfTheFile)){
      addDescriptorIdOfCurretlySelectedFile(selectedFile);
    }
    updateCurrentCode(selectedFile, newValue);
  }

  changeSelectedFile = newSelectedFile => {
    const { filesInFileSystemSandbox } = this.props;
    newSelectedFile !== '' ?
      this.setState({
        selectedFile: newSelectedFile,
        modeSelectedForAceEditor: extensionMap[filesInFileSystemSandbox[newSelectedFile].extension],
      })
      :
      this.setState({
        selectedFile: newSelectedFile,
        modeSelectedForAceEditor: '',
      });
  }

  closeFile = fileId => {
    const { selectedFile } = this.state;
    const {
      closeSelectedFile,
      openFilesInFileSystemSandbox,
      // filesInFileSystemSandbox,
    } = this.props;
    if (selectedFile === fileId) {
      const copyOfOpenFilesInFileSystemSandbox = Object.assign({}, openFilesInFileSystemSandbox);
      delete copyOfOpenFilesInFileSystemSandbox[fileId];
      // console.log(copyOfOpenFilesInFileSystemSandbox);
      const newSelectedFile = Object.keys(copyOfOpenFilesInFileSystemSandbox).length > 0
        ?
        Object.keys(copyOfOpenFilesInFileSystemSandbox)[0]
        :
        '';
      // console.log('================>>>>>>>>>>>>>>>.',newSelectedFile);
      this.changeSelectedFile(newSelectedFile);
    }
    this.setState({
      descriptorIdForChangedFileWhileClosing: '',
    });
    closeSelectedFile(fileId);
  }

  changeSelectedFileOnFileOpen = (descriptorId) => {
    const {
      // openFilesInFileSystemSandbox,
      filesInFileSystemSandbox,
    } = this.props;
    this.setState({
      selectedFile: descriptorId,
      modeSelectedForAceEditor: extensionMap[filesInFileSystemSandbox[descriptorId].extension],
    });
  };

  closeNewFileDialogBox = () => {
    this.setState({
      isOpenNewFileDialog: false,
    });
  };
  closeOpenFileDialogBox = () => {
    this.setState({
      isOpenFileDialog: false,
    });
  };

  closeChangesDialogModal = () => {
    this.setState({
      changesDialogModal: false,
    });
  };

  openEditorPreferencesDialogModal = () => {
    this.setState({
      isEditorPreferencesDialog: true,
    });
  };

  closeEditorPreferencesDialogModal = () => {
    this.setState({
      isEditorPreferencesDialog: false,
    });
  };

  saveFile = (closeFile) => {
    const { selectedFile } = this.state;
    const { saveFileContent, filesWithUpdatedContentInSandbox } = this.props;
    // console.log('::::::::::::::::',selectedFile.fileDescriptor);
    if (selectedFile) {
      const fileContent = filesWithUpdatedContentInSandbox[selectedFile] && filesWithUpdatedContentInSandbox[selectedFile].content;
      saveFileContent(selectedFile, fileContent);
      // this.setState({
      //   // toggleFileChangeIndicator: (selectedFile.fileDescriptor in currentSelectedDescriptorIdOfTheFile),
      // });
      if (closeFile) {
        this.closeFile(selectedFile);
        this.closeChangesDialogModal();
      }
    }
  };

  discardChanges = (descriptorId) => {
    const { selectedFile } = this.state;
    const { deleteDescriptorIdOfCurretlySelectedFile } = this.props;
    deleteDescriptorIdOfCurretlySelectedFile(selectedFile);
    this.closeFile(descriptorId);
    this.closeChangesDialogModal();
  }

  render () {
    const {
      selectedFile,
      isOpenNewFileDialog,
      isOpenFileDialog,
      modeSelectedForAceEditor,
      changesDialogModal,
      descriptorIdForChangedFileWhileClosing,
      isEditorPreferencesDialog,
    } = this.state;
    const {
      isSavingCurrentCode,
      isForkingCode,
      isFetchingForks,
      forkCounter,
      editorTheme,
      isAutoCompleteEnabled,
      filesInFileSystemSandbox,
      isNotEditable,
      indentationForEditors,
      openFilesInFileSystemSandbox,
      currentSelectedDescriptorIdOfTheFile,
      isFetchingFiles,
      isFileSystemEnabled,
      isDeletingFile,
      isUpdatingFileContent,
      isCreatingNewFileOrFolder,
      filesWithUpdatedContentInSandbox,
    } = this.props;

    var styleDecideForTopNav =
      (window.location !== window.parent.location) ?
        'topNav-sideIframe_fileSystemSandbox'
        :
        (isNotEditable) ?
          ''
          :

          'topNav-side_fileSystemSandbox';

    let styleDecideForDefaultTable =
        (window.location !== window.parent.location) ?
          'defaultViewForNoOpenFileInIframe'
          :
          'defaultViewForNoOpenFile';
        
    // var styleDecideForSavingIndicator = (selectedFile.fileDescriptor in currentSelectedDescriptorIdOfTheFile) ? 'dot' : 'dot_invisible';
    // isFileChanged ? 'dot' : 'dot_invisible';
    return (
      <div>
        <div className='outer-div_fileSystemSandbox'>
          <div className={styleDecideForTopNav} >
        
          </div>
          {
            isNotEditable ?
              null
              :
              <div className='sideNav-side_fileSystemSandbox'>
                <SideNav
                  isFileSystemEnabled={isFileSystemEnabled}
                  forkCounter={forkCounter}
                  currentlySelectedFile={selectedFile}
                />
              </div>
          }
          <div>
            <FileExplorerDrawer
              changeSelectedFileOnFileOpen = {this.changeSelectedFileOnFileOpen}
              isNotEditable={isNotEditable}
              selectedFile = {selectedFile}
            />
          </div>
          <div>
            <div className='editor-side_fileSystemSandbox' style={isNotEditable ? { width: '50%' } : {}}>
              {Object.keys(openFilesInFileSystemSandbox).length === 0 ?
                (isNotEditable)
                  ?
                  <div className='defaultViewForNoOpenFileInHrView' style={isNotEditable ? { height: '99vh' } : {}}>
                    <Table style={{ color: 'white', border: '1px solid black', width: '22vw' }}>
                      <TableBody style={{ border: '1px solid black' }}>
                        <TableRow>
                          <TableCell style={{ borderBottom: '1px solid black' }}>
                            <div
                              onClick={() => {
                                this.setState({
                                  isOpenFileDialog: true,
                                });
                              }}
                              className = 'textInDefaultViewTable'
                            >Open File: </div>
                          </TableCell>
                          {(navigator.appVersion.indexOf('Mac') !== -1) ?
                            <TableCell style={{ borderBottom: '1px solid black' }}>Cmd + o </TableCell>
                            :
                            <TableCell style={{ borderBottom: '1px solid black' }}>Ctrl + o </TableCell>
                          }
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                  :
                  <div className={styleDecideForDefaultTable}>
                    {/* <img src="/images/favicon.png" alt='' className = 'companyLogoImage'/> */}
                    <Table style={{ color: 'white', border: '1px solid black', width: '22vw' }}>
                      <TableBody style={{ border: '1px solid black' }}>
                        <TableRow style={{ borderBottom: '1px solid black' }}>
                          <TableCell style={{ borderBottom: '1px solid black' }}>
                            <div
                              onClick={() => {
                                this.setState({
                                  isOpenNewFileDialog: true,
                                });
                              }}
                              className = 'textInDefaultViewTable'
                            >Create New File: </div>
                          </TableCell>
                          {(navigator.appVersion.indexOf('Mac') !== -1) ?
                            <TableCell style={{ borderBottom: '1px solid black' }}>Cmd + i </TableCell>
                            :
                            <TableCell style={{ borderBottom: '1px solid black' }}>Ctrl + i </TableCell>
                          }
                          {/* <TableCell style={{ borderBottom: '1px solid black' }}>Ctrl + i </TableCell> */}
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ borderBottom: '1px solid black' }}>
                            <div
                              onClick={() => {
                                this.setState({
                                  isOpenFileDialog: true,
                                });
                              }}
                              className = 'textInDefaultViewTable'
                            >Open File: </div>
                          </TableCell>
                          {(navigator.appVersion.indexOf('Mac') !== -1) ?
                            <TableCell style={{ borderBottom: '1px solid black' }}>Cmd + o </TableCell>
                            :
                            <TableCell style={{ borderBottom: '1px solid black' }}>Ctrl + o </TableCell>
                          }
                          {/* <TableCell style={{ borderBottom: '1px solid black' }}>Ctrl + o </TableCell> */}
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ borderBottom: '1px solid black' }}>
                            <div onClick={() => { }}>Save File: </div>
                          </TableCell>
                          {(navigator.appVersion.indexOf('Mac') !== -1) ?
                            <TableCell style={{ borderBottom: '1px solid black' }}>Cmd + s </TableCell>
                            :
                            <TableCell style={{ borderBottom: '1px solid black' }}>Ctrl + s </TableCell>
                          }
                          {/* <TableCell style={{ borderBottom: '1px solid black' }}>Ctrl + p </TableCell> */}
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{ borderBottom: '1px solid black' }}
                          >
                            <div
                              onClick={this.openEditorPreferencesDialogModal}
                              className = 'textInDefaultViewTable'
                            >Editor Preferences: </div>
                          </TableCell>
                          <TableCell
                            style={{ borderBottom: '1px solid black' }}
                          ><SettingsIcon /> </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                :
                <div>
                  <div className='navbar_fileSystemSandbox'>
                    <div style={{ marginLeft: '0.8vw', display: 'flex' }}>
                      {Object.keys(openFilesInFileSystemSandbox).map((fileId) => {
                        const openFile = filesInFileSystemSandbox[fileId];
                        return (
                          <div
                            key={fileId}
                            className={selectedFile !== fileId ? 'fileNamesUnselected' : 'fileNames'}
                          >
                            <Button className={selectedFile !== fileId ? 'fileNameUnselectedText_navbar' : 'fileNameText_navbar'}>
                              <div
                                // style={{ marginRight: '-15px' }}
                                onClick={() => this.changeSelectedFile(fileId)}
                                id='idForOpenFile'
                                style={{ display: 'flex',width: '80%',justifyContent: 'space-evenly' }}
                              >
                                <div>
                                  {openFile && openFile.name}
                                </div>
                                
                                {
                                  (
                                    fileId in currentSelectedDescriptorIdOfTheFile
                                  ) ?
                                    <span
                                      className='unsavedChangesInFileIndicator'
                                      title='File contains unsaved changes'
                                    ></span>
                                    :
                                    <span className='unsavedChangesInFileIndicator_invisible' ></span>
                                }
                              </div>
                              <div
                                style = {{
                                  marginLeft: '5px',
                                  marginTop: '1vh',
                                }}
                              >
                                <CloseIcon
                                  className={selectedFile !== fileId ? 'closeButton_navbar' : 'closeButton_navbar_selected'}
                                  fontSize='small'
                                  onClick={() => {
                                    if (fileId in currentSelectedDescriptorIdOfTheFile){
                                      this.setState({
                                        changesDialogModal: true,
                                        descriptorIdForChangedFileWhileClosing: fileId,
                                      });
                                    } else {
                                      this.closeFile(fileId);
                                    }
                                  }
                                  }
                                />
                              </div>
                            </Button>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className='editor-side-editor_fileSystemSandbox'>
                    <AceEditor
                      mode = {modeSelectedForAceEditor}
                      theme={editorTheme}
                      onChange={this.onChange}
                      name = 'FILE_SYSTEM_SANDBOX_ACE_EDITOR'
                      editorProps={{
                        $blockScrolling: true,
                      }}
                      value={(filesWithUpdatedContentInSandbox[selectedFile] && filesWithUpdatedContentInSandbox[selectedFile].content) || ''}
                      enableLiveAutocompletion={isAutoCompleteEnabled}
                      enableSnippets={isAutoCompleteEnabled}
                      setOptions={{
                        showLineNumbers: true,
                        showPrintMargin: false,
                      }}
                      autoIndentOnPaste={true}
                      tabSize={indentationForEditors}
                      height={(window.location !== window.parent.location) ? '95.2vh' : (isNotEditable) ? '94.2vh' : '85vh' }
                      width={isNotEditable ? '50vw' : '48.5%'}
                      className="AceEditor_fileSystemSandbox"
                      style={{ borderRight: 'none', borderTop: 'none' }}
                      readOnly={isNotEditable}
                      highlightActiveLine={!isNotEditable}
                      highlightGutterLine={!isNotEditable}
                    />
                  </div>
                </div>
              }
            </div>
            <div className='preview-side_fileSystemSandbox'>
              <Preview isSandBoxPrivate={true} isThePersonAnHr={isNotEditable} />
            </div>
          </div>
        </div>
        {
          isSavingCurrentCode
          || isForkingCode
          || isFetchingForks
          || isFetchingFiles
          || isDeletingFile
          || isUpdatingFileContent
          || isCreatingNewFileOrFolder ?
            <Loader />
            :
            null
        }
        <ToastContainer
          newestOnTop={true}
          enableMultiContainer={true}
          limit={5}
          position={toast.POSITION.BOTTOM_RIGHT}
          toastId="TOAST_CONTAINER_FOR_SAVING_CODE"
          hideProgressBar={true}
        />
        {isOpenNewFileDialog ? <CreateAFileView openDialogByDefault = {true} closeDialog = {this.closeNewFileDialogBox} /> : null}
        {
          isOpenFileDialog
            ?
            <OpenFileView
              openDialogByDefault = {true}
              closeDialog = {this.closeOpenFileDialogBox}
              changeSelectedFileOnFileOpen = {this.changeSelectedFileOnFileOpen}
            />
            :
            null
        }
        {isEditorPreferencesDialog ? <EditorPreferences openDialogByDefault = {true} closeDialog = {this.closeEditorPreferencesDialogModal} /> : null}
        <div>
          <Dialog
            open = { changesDialogModal }
            onClose = { this.closeChangesDialogModal }
            fullWidth = { true }
            // maxWidth = 'xs'
          >
            <DialogTitle
              className = "headerExitSandboxView"
              style = {{ justifyContent: 'center', display: 'flex' }}
            >
              {' Save changes '}
            </DialogTitle>
            <DialogContent
              style = {{
                display: 'flex',
                color: 'black',
                justifyContent: 'center',
                fontSize: '18px',
              }}
            >
              {descriptorIdForChangedFileWhileClosing !== '' ?
                <div>
                  {
                    `Do you want to save changes to the file 
                    ${filesInFileSystemSandbox[descriptorIdForChangedFileWhileClosing] &&
                    filesInFileSystemSandbox[descriptorIdForChangedFileWhileClosing].name}?`
                  }
                  {` Your changes will be lost if you don't save them.`}
                </div>
                :
                null
              }
            </DialogContent>
            <DialogActions>
              <Button
                onClick = {() => this.saveFile(true)}
                color = 'primary'
                className = 'buttonExitSandboxView button1ExitSandboxView'
                id = 'saveChangesButton'
                title = 'Save File Changes'
              >
                Save
              </Button>
              <Button
                onClick = {() => this.discardChanges(descriptorIdForChangedFileWhileClosing)}
                color = "primary"
                className = 'button3DiscardSandboxView'
                id = 'discardChangesButton'
              >
                Discard
              </Button>
              <Button
                onClick = {this.closeChangesDialogModal}
                color = "primary"
                className = "buttonExitSandboxView button3ExitSandboxView"
                id = 'cancelChangesButton'
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}

FileSystemSandbox.propTypes = {
  isSavingCurrentCode: PropTypes.bool.isRequired,
  forkCounter: PropTypes.number.isRequired,
  isFetchingForks: PropTypes.bool.isRequired,
  isForkingCode: PropTypes.bool.isRequired,
  currentCode: PropTypes.object.isRequired,
  isNotEditable: PropTypes.bool.isRequired,
  isAutoCompleteEnabled: PropTypes.bool.isRequired,
  editorTheme: PropTypes.string.isRequired,
  updateCurrentCode: PropTypes.func.isRequired,
  indentationForEditors: PropTypes.number.isRequired,
  openFilesInFileSystemSandbox: PropTypes.object,
  closeSelectedFile: PropTypes.func.isRequired,
  eventListnerName: PropTypes.string.isRequired,
  addDescriptorIdOfCurretlySelectedFile: PropTypes.func.isRequired,
  currentSelectedDescriptorIdOfTheFile: PropTypes.object.isRequired,
  deleteDescriptorIdOfCurretlySelectedFile: PropTypes.func.isRequired,
  filesInFileSystemSandbox: PropTypes.object.isRequired,
  isFetchingFiles: PropTypes.bool.isRequired,
  isFileSystemEnabled: PropTypes.bool,
  saveFileContent: PropTypes.func,
  filesWithUpdatedContentInSandbox: PropTypes.object,
  isDeletingFile: PropTypes.bool,
  isUpdatingFileContent: PropTypes.bool,
  isCreatingNewFileOrFolder: PropTypes.bool,
};

FileSystemSandbox.defaultProps = {
  isSavingCurrentCode: false,
  forkCounter: TOTAl_NUMBER_OF_FORKS_AVAILABLE,
  isFetchingForks: false,
  isForkingCode: false,
  currentCode: {
    html: '',
    css: '',
    javascript: '',
  },
  isNotEditable: false,
  isAutoCompleteEnabled: true,
  editorTheme: 'LIGHT',
  indentationForEditors: 2,
  closeSelectedFile: () => {

  },
  eventListnerName: '',
  addDescriptorIdOfCurretlySelectedFile: () => {

  },
  currentSelectedDescriptorIdOfTheFile: {},
  deleteDescriptorIdOfCurretlySelectedFile: () => {

  },
  filesInFileSystemSandbox: {},
  isFetchingFiles: false,
  updateCurrentCode: () => {

  },
};

export default FileSystemSandbox;