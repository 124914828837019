
import htmlImage from './htmlCssJs.jpg';
import reactImage from './reactJs.jpg';
import angularImage from './AngularJS.png';
import vueImage from './VueJS.png';

export const cardsForHomePage = {
  HTML: {
    image: htmlImage,
    name: 'HTML/CSS/JS',
    content: 'Create an awesome Web Application using basic HTML and let the world see your creativity.',
    isAvailable: true,
    frameworkId: 'HTML',
  },
  ReactJS: {
    image: reactImage,
    name: 'React JS',
    content: 'Create an awesome Web Application using React JS framework and let the world see your creativity.',
    isAvailable: true,
    frameworkId: 'REACT_JS',
  },
  AngularJS: {
    image: angularImage,
    name: 'Angular JS',
    content: 'Create an awesome Web Application using Angular JS framework and let the world see your creativity.',
    isAvailable: true,
    frameworkId: 'ANGULAR_JS',
  },
  VueJS: {
    image: vueImage,
    name: 'Vue JS',
    content: 'Create an awesome Web Application using Vue JS framework and let the world see your creativity.',
    isAvailable: false,
    frameworkId: 'VUE_JS',
  },
};