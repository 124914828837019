import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import myAnatomyLogo from '../../images/myAnatomyLogo.png';
// import ace from "ace-builds/src-noconflict/ace";
import ace from 'ace-builds';
// import { Range, EditSession } from 'ace-builds';
import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-csharp';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/mode-typescript';
import 'ace-builds/src-noconflict/mode-golang';
import 'ace-builds/src-noconflict/mode-mysql';
import 'ace-builds/src-noconflict/mode-ruby';
import 'ace-builds/src-noconflict/mode-sass';
import 'ace-builds/src-noconflict/mode-xml';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-handlebars';
import 'ace-builds/src-noconflict/mode-elixir';
import 'ace-builds/src-noconflict/mode-c_cpp';
import 'ace-builds/src-noconflict/mode-php';
import 'ace-builds/src-noconflict/mode-perl';
import 'ace-builds/src-noconflict/mode-r';
import 'ace-builds/src-noconflict/mode-scala';
import 'ace-builds/src-noconflict/mode-plain_text';
import 'ace-builds/src-noconflict/theme-crimson_editor';
import 'ace-builds/src-noconflict/ext-language_tools';

import './LiveCodeShareView.css';
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  IconButton,
} from '@material-ui/core';
// import PropTypes from 'prop-types';
import { io } from 'socket.io-client';

import {
  formattingForProgrammingLanguages,
  languagesToBeShownInDropdown,
  SOCKET_SAVE_INTERNAL,
  PAUSE_SAVE_TIMEOUT,
  codeshareBackendUrl,
  clientUrlForCodeShareSavingCompiledCode,
} from '../../configFiles/config';

import Loader from '../../Components/Loader';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import queryString from 'query-string';
let Document = ace.require('ace/document').Document;
let doc = new Document('Loading...');

//include as many of the libraries
// let js = require('brace/mode/javascript');
export class LiveCodeShareView extends Component {
  constructor (props) {
    super(props);


    let url = this.props.history.location.search;
    let params = queryString.parse(url);

    const {
      moderatorToken,
      meetingId,
      participantToken,
      externalMeetingId,
      participantName,
    } = params;

    const { validateUserForCodeShare } = this.props;
    validateUserForCodeShare (meetingId, participantToken, moderatorToken);

    this.ace = React.createRef();
    this.socket = undefined;
    this.editor = undefined;
    this.saveInterval = undefined;
    this.pauseTimeout = undefined;
    this.editor = undefined;
    this.moderatorToken = moderatorToken;
    

    this.state = {
      meetingId: meetingId,
      participantToken: participantToken,
      editor: undefined,
      externalMeetingId: externalMeetingId,
      participantName: participantName,
    };
  }

  changeLanguageHandler = (e) => {
    let value = e && e.target && e.target.value;
    const {
      changeLanguage,
      changeCurrentContentOfEditor,
    } = this.props;
    const { editor } = this.state;
    changeLanguage(value);
    editor.session.setMode(`ace/mode/${formattingForProgrammingLanguages[value]}`);
    this.editor && this.editor.setValue('');
    this.socket && this.socket.emit('lang-change', value);
    changeCurrentContentOfEditor('SHARE_CODE_EDITOR', '', value);
    // editor.session.setMode(`ace/mode/${formattingForProgrammingLanguages[value]}`);
  }

  pauseSaving = () => {
    clearInterval(this.saveInterval);
    this.saveInterval = undefined;
  }

  resumeSaving= (socket, editor) => {
    if (!socket || !editor) return;
    const {
      languageSelected,
    } = this.props;

    this.saveInterval = setInterval(() => {
      socket.emit('save-room-content', { language: languageSelected, code: editor.getSession().getValue() });
    }, SOCKET_SAVE_INTERNAL);
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (!prevProps.codeShareToken && this.props.codeShareToken) {
      const {
        saveClientDetails,
      } = this.props;

      const {
        externalMeetingId,
        participantToken,
        participantName,
      } = this.state;

      this.handleSocketConnection();
      saveClientDetails(clientUrlForCodeShareSavingCompiledCode, 'EDITOR', { id: participantToken, purposeId: externalMeetingId, name: participantName });
    }
    return null;
  }

  componentDidUpdate () {
    // do nothing
  }

  handleSocketConnection = () => {
    const { codeShareToken } = this.props;
    const socket = io(codeshareBackendUrl, { auth: { token: codeShareToken } });
    this.socket = socket;

    socket.emit('join-room');

    socket.once('load-room-content', roomContent => {
      this.editor && this.editor.setValue((roomContent && roomContent.sharedContent && roomContent.sharedContent.code) || '');
      const {
        changeLanguage,
        changeCurrentContentOfEditor,
      } = this.props;
      if (roomContent && roomContent.sharedContent && roomContent.sharedContent.language) {
        changeLanguage(roomContent.sharedContent.language);
        changeCurrentContentOfEditor(
          'SHARE_CODE_EDITOR',
          (roomContent && roomContent.sharedContent && roomContent.sharedContent.code),
          roomContent.sharedContent.language
        );
      }
    });
    
    const handler = delta => {
      this.editor && this.editor.getSession().getDocument().applyDeltas([delta]);
    };
    socket.on('receive-changes', handler);

    socket.on('update-to-lang-change', lang => {
      const {
        changeLanguage,
        changeCurrentContentOfEditor,
      } = this.props;
      changeLanguage(lang);
      this.editor && this.editor.setValue('');
      changeCurrentContentOfEditor('SHARE_CODE_EDITOR', '', lang);
    });
  }

  handleEditorContentChange = () => {
    const { editor } = this.state;
    const { changeCurrentContentOfEditor, languageSelected } = this.props;
    var editorValue = editor.getSession().getValue();
    // console.log('::::;;languageSelected',languageSelected);
    changeCurrentContentOfEditor('SHARE_CODE_EDITOR',editorValue, languageSelected);
  }



  componentDidMount () {
    // const { codeShareToken } = this.props;
    window.addEventListener('keypress',this.handleCtrlEnterPress);
    const {
      languageSelected,
    } = this.props;
    const changeHandler = (delta) => {
      // if (source !== "user") return
      // console.log(editor.curOp && editor.curOp.command.name);
      this.handleEditorContentChange();
      if (
        (editor.curOp && editor.curOp.command.name) === 'insertstring' ||
        (editor.curOp && editor.curOp.command.name) === 'backspace' ||
        (editor.curOp && editor.curOp.command.name) === 'undo' ||
        (editor.curOp && editor.curOp.command.name) === 'redo' ||
        (editor.curOp && editor.curOp.command.name) === 'del' ||
        (editor.curOp && editor.curOp.command.name) === 'paste' ||
        (editor.curOp && editor.curOp.command.name) === 'cut'
      ) {
        
        this.socket && this.socket.emit('send-changes', delta);

        // clear pauseTimeout and create a new timeout for pause if any change is detected
        if (this.pauseTimeout) {
          clearTimeout(this.pauseTimeout);
        }

        // if interval for saving does not exist create new interval
        if (!this.saveInterval) {
          this.resumeSaving(this.socket, this.editor);
        }

        // create timeout for pausing the save operation
        this.pauseTimeout = setTimeout(() => {
          this.pauseSaving();
        }, PAUSE_SAVE_TIMEOUT);
      }
    };

    const editor = ace.edit(this.ace.current, {
      selectionStyle: 'text',
    });
    this.setState({ editor: editor });
    this.editor = editor;
    editor.setTheme('ace/theme/crimson_editor');
    editor.session.setMode(`ace/mode/${formattingForProgrammingLanguages[languageSelected]}`);
    editor.setOptions({
      showLineNumbers: true,
      tabSize: 2,
      showPrintMargin: false,
    });
    editor.setReadOnly(false);
    editor.on('change', changeHandler);
    let session = ace.createEditSession(doc);
    editor.setSession(session);
  }

  componentWillUnmount () {
    this.socket.off('load-room-content');
    this.socket.off('send-changes');
    this.socket.off('join-room');
    this.socket.off('receive-changes');
    this.socket.emit('disconnect');
    this.socket.disconnect();
    clearInterval(this.saveInterval);
    clearTimeout(this.pauseTimeout);
    window.removeEventListener('keypress',this.handleCtrlEnterPress);
  }


  toggleOutputVisiblity = () => {
    const {
      toggleCompiledOutputDrawerVisibilityInCodeShare,
      isCompiledOutputVisibleInCodeShare,
    } = this.props;

    toggleCompiledOutputDrawerVisibilityInCodeShare(!isCompiledOutputVisibleInCodeShare);
  };
  handleCtrlEnterPress=(e) => {
    // console.log('event',e.keyCode,e.ctrlKey);
    if ((e.keyCode === 10 || e.keyCode === 13) && e.ctrlKey){
      this.handleCodeCompiling();
      // console.log('controlpressed');
    }
  }
  handleCodeCompiling = () => {
    // alert(' i have been clicked');
    const { getCompileAgentUrl } = this.props;
    const {
      participantToken,
      externalMeetingId,
    } = this.state;

    getCompileAgentUrl(true, externalMeetingId, participantToken);
  }

  render () {
    const {
      languageSelected,
      showCompileButton,
      codeEditorContent,
      isCompiledOutputVisibleInCodeShare,
      doesCompiledOutputContainAnError,
      compiledOutput,
      isValidatingUserForCodeShare,
      isAwaitingCompileAgentResponse,
      isFetchingCompileAgent,
    } = this.props;

    const isCompileButtonDisabled = (
      !codeEditorContent ||
      (codeEditorContent && codeEditorContent.length === 0) ||
      codeEditorContent === ''
    );

    return (
      <div className = 'liveCodeShareComponent'>
        {isValidatingUserForCodeShare && <Loader/>}
        {
          isFetchingCompileAgent ||
            isAwaitingCompileAgentResponse ?
            <Loader />
            :
            null
        }
        <div className="liveCodeEditorHeader">
          <div>
            <div className='company_editorsView'>
              <div className='company-logo_editorsView'>
                <a href='https://myanatomy.in/#/home'><img src="/images/myAnatomyLogo.png" alt='' /></a>
              </div>
              {
                showCompileButton ?
                  <div style = {{ display: 'flex' }}>
                    <Button id='compileButton'
                      onClick={() => this.handleCodeCompiling()}
                      className={
                        isCompileButtonDisabled ?
                          'compileButtonDisabled'
                          :
                          'compileButton'
                      }
                      disabled = { isCompileButtonDisabled }
                      title = {
                        isCompileButtonDisabled ?
                          'Type code to enable'
                          :
                          'Compile your code'
                      }
                    >
                      Compile
                    </Button>
                  </div>
                  :
                  null
              }
            </div>
            
          </div>
        
        </div>
      
        <div className='liveCodeEditor'>

          <div className="aceDivLiveCodeShare" ref={this.ace}>
          </div>
          <FormControl
            variant="outlined"
            className="languageDropDownCodeShare"
          >
            <InputLabel id='languageLabel' className='languageLabel' style={{ color: 'var(--primaryColor)' }}>Language</InputLabel>
            <Select id='languageDefault'
              value={languageSelected}
              onChange={this.changeLanguageHandler}
              label='Language'
              defaultValue='C++'
              style={{ color: 'var(--primaryColor)' }}
            >
              {
                Object.keys(languagesToBeShownInDropdown).map(languageKey =>
                  <MenuItem id= {
                    formattingForProgrammingLanguages[languageKey] ?
                      'compilableLanguage'
                      :
                      `nonCompilableLanguage${languageKey}`
                  }
                  value={languageKey}
                  key={languageKey}
                  className={
                    formattingForProgrammingLanguages[languageKey] ?
                      'selectableCompilableLanguageMenuItem'
                      :
                      'selectableNotCompilableLanguageMenuItem'
                  }
                  title={
                    formattingForProgrammingLanguages[languageKey] ?
                      null
                      :
                      'This language is not compilable'
                  }
                  >
                    {languagesToBeShownInDropdown[languageKey]}
                  </MenuItem>
                )
              }
            </Select>
          </FormControl>
      
          {
            showCompileButton ?
              isCompiledOutputVisibleInCodeShare ?
                <div>
                  <div className="viewCompiledOutput">
                    <div id= 'hideOutputButton'
                      className='hideCompiledOutputButton'
                      onClick={() => this.toggleOutputVisiblity()}
                    >
                      <strong id= 'hideOutputbuttonLabel'>
                        Hide output
                      </strong>
                      <IconButton
                        style={{
                          color: 'white',
                        }}
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </div>
                    <div className={
                      doesCompiledOutputContainAnError ?
                        'compiledOutputErrorText'
                        :
                        'compiledOutputText'
                    }>
                      <pre>
                        {compiledOutput}
                      </pre>
                    </div>
                  </div>
                </div>
                :
                <div>
                  <div id= 'viewOutPutDrawer'
                    className="compileOutputDrawer"
                    onClick={() => this.toggleOutputVisiblity()}
                  >
                    <div className='viewCompiledOutputButton'>
                      <strong id='viewOutPutButtonLabel'>
                        View output
                      </strong>
                      <IconButton
                        style={{
                          color: 'white',
                        }}
                      >
                        <ExpandLessIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              :
              null
          }
        </div>
      </div>
    );
  }
}

LiveCodeShareView.propTypes = {
  languageSelected: PropTypes.string,
  changeLanguage: PropTypes.func,
  getCompileAgentUrl: PropTypes.func,
  showCompileButton: PropTypes.bool,
  isCompiledOutputVisibleInCodeShare: PropTypes.bool,
  compiledOutput: PropTypes.string,
  doesCompiledOutputContainAnError: PropTypes.bool,
  toggleCompiledOutputDrawerVisibilityInCodeShare: PropTypes.func,
  changeCurrentContentOfEditor: PropTypes.func,
  codeEditorContent: PropTypes.string,
  history: PropTypes.object,
  validateUserForCodeShare: PropTypes.func,
  codeShareToken: PropTypes.string,
  isValidatingUserForCodeShare: PropTypes.bool,
  isAwaitingCompileAgentResponse: PropTypes.bool,
  isFetchingCompileAgent: PropTypes.bool,
  saveClientDetails: PropTypes.func,
};
LiveCodeShareView.defaultProps = {
  changeCurrentContentOfEditor: () => {},
};

export default LiveCodeShareView;