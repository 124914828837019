import React, { Component } from 'react';

class Error404View extends Component {
  render () {
    return (
      <div style = {{ fontSize: '20px', color: 'black', justifyContent: 'center', display: 'flex'  }}>
               The requested resource does not exists.
      </div>
    );
  }
}

export default Error404View;