import { toast } from 'react-toastify';
import { defaultCompileOutputMessage, formattingForProgrammingLanguages } from '../../configFiles/config';
import { showErrorToast } from '../../utils/toasts/showToasts';

export const SAVE_EXTERNAL_CLIENT_DETAILS = 'microService/SAVE_EXTERNAL_CLIENT_DETAILS';
export const SAVE_EXTERNAL_CLIENT_DETAILS_PENDING = 'microService/SAVE_EXTERNAL_CLIENT_DETAILS_PENDING';
export const SAVE_EXTERNAL_CLIENT_DETAILS_FULFILLED = 'microService/SAVE_EXTERNAL_CLIENT_DETAILS_FULFILLED';
export const SAVE_EXTERNAL_CLIENT_DETAILS_REJECTED = 'microService/SAVE_EXTERNAL_CLIENT_DETAILS_REJECTED';

export const UPDATE_EXTERNAL_EDITOR_CONTENT = 'microService/UPDATE_EXTERNAL_EDITOR_CONTENT';
export const UPDATE_EXTERNAL_EDITOR_CONTENT_PENDING = 'microService/UPDATE_EXTERNAL_EDITOR_CONTENT_PENDING';
export const UPDATE_EXTERNAL_EDITOR_CONTENT_FULFILLED = 'microService/UPDATE_EXTERNAL_EDITOR_CONTENT_FULFILLED';
export const UPDATE_EXTERNAL_EDITOR_CONTENT_REJECTED = 'microService/UPDATE_EXTERNAL_EDITOR_CONTENT_REJECTED';

export const UPDATE_CURRENT_EDITOR_CONTENT = 'microService/UPDATE_CURRENT_EDITOR_CONTENT';
export const UPDATE_WINDOW_TYPE_FOR_EDITOR_MICRO_SERVICE =  'microService/UPDATE_WINDOW_TYPE_FOR_EDITOR_MICRO_SERVICE';

export const SET_DOWNLOAD_AND_SHARE_BUTTONS_VISIBILITY = 'microService/SET_DOWNLOAD_AND_SHARE_BUTTONS_VISIBILITY';

export const GET_COMPILE_AGENT_FOR_SANDBOX = 'microService/GET_COMPILE_AGENT_FOR_SANDBOX';
export const GET_COMPILE_AGENT_FOR_SANDBOX_PENDING = 'microService/GET_COMPILE_AGENT_FOR_SANDBOX_PENDING';
export const GET_COMPILE_AGENT_FOR_SANDBOX_FULFILLED = 'microService/GET_COMPILE_AGENT_FOR_SANDBOX_FULFILLED';
export const GET_COMPILE_AGENT_FOR_SANDBOX_REJECTED = 'microService/GET_COMPILE_AGENT_FOR_SANDBOX_REJECTED';

export const COMPILE_CODE_USING_COMPILE_AGENT = 'microService/COMPILE_CODE_USING_COMPILE_AGENT';

export const UPDATE_COMPILE_OUTPUT = 'microService/UPDATE_COMPILE_OUTPUT';
export const UPDATE_COMPILE_OUTPUT_PENDING = 'microService/UPDATE_COMPILE_OUTPUT_PENDING';
export const UPDATE_COMPILE_OUTPUT_FULFILLED = 'microService/UPDATE_COMPILE_OUTPUT_FULFILLED';
export const UPDATE_COMPILE_OUTPUT_REJECTED = 'microService/UPDATE_COMPILE_OUTPUT_REJECTED';

export const UPDATE_COMPILE_OUTPUT_OF_CODESHARE = 'microService/UPDATE_COMPILE_OUTPUT_OF_CODESHARE';
export const UPDATE_COMPILE_OUTPUT_OF_CODESHARE_PENDING = 'microService/UPDATE_COMPILE_OUTPUT_OF_CODESHARE_PENDING';
export const UPDATE_COMPILE_OUTPUT_OF_CODESHARE_FULFILLED = 'microService/UPDATE_COMPILE_OUTPUT_OF_CODESHARE_FULFILLED';
export const UPDATE_COMPILE_OUTPUT_OF_CODESHARE_REJECTED = 'microService/UPDATE_COMPILE_OUTPUT_OF_CODESHARE_REJECTED';

export const TOGGLE_COMPILE_OUTPUT_DRAWER = 'microService/TOGGLE_COMPILE_OUTPUT_DRAWER';

export const TOGGLE_COMPILE_OUTPUT_DRAWER_IN_CODE_SHARE = 'microService/TOGGLE_COMPILE_OUTPUT_DRAWER_IN_CODE_SHARE';

export const SET_EDITOR_PURPOSE_ID = 'microService/SET_EDITOR_PURPOSE_ID';

const defaultState = {
  isSavingClientDetails: false,
  isUpdatingEditorContent: false,
  clientDetails: {},
  windowType: 'WHITE_BOARD',
  editorContent: {
    CODE_EDITOR: {
      content: '',
      language: 'c++',
      showCompileButton: true,
      showDownloadAndShareOptions: true,
      output: defaultCompileOutputMessage,
      isError: false,
    },
    MARKDOWN: {
      content: '',
    },
    SHARE_CODE_EDITOR: {
      content: '',
      language: 'c++',
      showCompileButton: true,
      output: defaultCompileOutputMessage,
      isError: false,
    },
  },
  isFetchingCompileAgent: false,
  isAwaitingCompileAgentResponse: false,
  isCompiledOutputVisible: false,
  purposeId: undefined,
  clientId: undefined,
  isCompiledOutputVisibleInCodeShare: false,

};

const microService = (state = defaultState, action) => {
  switch (action.type) {
  case SAVE_EXTERNAL_CLIENT_DETAILS: {
    return {
      ...state,
      isSavingClientDetails: true,
    };
  }
  
  case SAVE_EXTERNAL_CLIENT_DETAILS_PENDING: {
    return {
      ...state,
      isSavingClientDetails: true,
    };
  }
  
  case SAVE_EXTERNAL_CLIENT_DETAILS_FULFILLED: {
    // toast.success('Client details Saved Successfully',
    //   { position: toast.POSITION.BOTTOM_RIGHT });
    return {
      ...state,
      isSavingClientDetails: false,
    };
  }
  
  case SAVE_EXTERNAL_CLIENT_DETAILS_REJECTED: {
    return {
      ...state,
      isSavingClientDetails: false,
    };
  }
  case UPDATE_EXTERNAL_EDITOR_CONTENT: {
    return {
      ...state,
      isUpdatingEditorContent: true,
    };
  }
  
  case UPDATE_EXTERNAL_EDITOR_CONTENT_PENDING: {
    return {
      ...state,
      isUpdatingEditorContent: true,
    };
  }
  
  case UPDATE_EXTERNAL_EDITOR_CONTENT_FULFILLED: {
    toast.success('Editor content updated Successfully',
      { position: toast.POSITION.BOTTOM_RIGHT });
    return {
      ...state,
      isUpdatingEditorContent: false,
    };
  }
  
  case UPDATE_EXTERNAL_EDITOR_CONTENT_REJECTED: {
    return {
      ...state,
      isUpdatingEditorContent: false,
    };
  }

  case UPDATE_CURRENT_EDITOR_CONTENT: {

    const { editorContent } = state;
    const { data } = action.payload;
    const { editor, content, language } = data;
    let copyOfCurrentEditorContent;

    copyOfCurrentEditorContent = Object.assign({}, editorContent);
    const copyOfEditor = Object.assign({},copyOfCurrentEditorContent[editor]);
    
    if (editor === 'CODE_EDITOR') {
      if (formattingForProgrammingLanguages[language]) {
        copyOfEditor['showCompileButton'] = true;
      } else {
        copyOfEditor['showCompileButton'] = false;
      }
      copyOfEditor['content'] = content;
      copyOfEditor['language'] = language;
      copyOfEditor['output'] = defaultCompileOutputMessage;
      copyOfEditor['isError'] = false;
    } else if (editor === 'SHARE_CODE_EDITOR') {
      if (formattingForProgrammingLanguages[language]) {
        copyOfEditor['showCompileButton'] = true;
      } else {
        copyOfEditor['showCompileButton'] = false;
      }
      copyOfEditor['content'] = content;
      copyOfEditor['language'] = language;
      copyOfEditor['output'] = defaultCompileOutputMessage;
      copyOfEditor['isError'] = false;
    }
    else {
      // copyOfCurrentEditorContent[editor[content]] = content;
      copyOfEditor['content'] = content;
    }

    copyOfCurrentEditorContent[editor] = copyOfEditor;

    return {
      ...state,
      editorContent: copyOfCurrentEditorContent,
      isCompiledOutputVisible: false,
      isCompiledOutputVisibleInCodeShare: false,
    };
  }


  case UPDATE_WINDOW_TYPE_FOR_EDITOR_MICRO_SERVICE: {
    const { data } = action.payload;
    if (!data) {
      return {
        ...state,
      };
    }

    const { windowType } = data;
    return {
      ...state,
      windowType,
    };
  }

  case SET_DOWNLOAD_AND_SHARE_BUTTONS_VISIBILITY: {
    const { editorContent } = state;
    const { data } = action.payload;
    const { editorType, shouldShowButtons } = data;

    let copyOfCurrentEditorContent;

    if (editorType === 'CODE_EDITOR') {
      copyOfCurrentEditorContent = Object.assign({}, editorContent);
      const copyOfEditor = Object.assign({}, copyOfCurrentEditorContent[editorType]);

      copyOfEditor['showDownloadAndShareOptions'] = shouldShowButtons;
      copyOfCurrentEditorContent[editorType] = copyOfEditor;

      return {
        ...state,
        editorContent: copyOfCurrentEditorContent,
      };
    }

    return {
      ...state,
    };
  }

  case GET_COMPILE_AGENT_FOR_SANDBOX: {
    return {
      ...state,
      isFetchingCompileAgent: true,
    };
  }

  case GET_COMPILE_AGENT_FOR_SANDBOX_PENDING: {
    return {
      ...state,
      isFetchingCompileAgent: true,
    };
  }

  case GET_COMPILE_AGENT_FOR_SANDBOX_FULFILLED: {
    return {
      ...state,
      isFetchingCompileAgent: false,
    };
  }

  case GET_COMPILE_AGENT_FOR_SANDBOX_REJECTED: {
    showErrorToast('Cannot compile code at this time. Please try again');
    return {
      ...state,
      isFetchingCompileAgent: false,
    };
  }

  case UPDATE_COMPILE_OUTPUT: {
    return {
      ...state,
      isAwaitingCompileAgentResponse: true,
    };
  }

  case UPDATE_COMPILE_OUTPUT_PENDING: {
    return {
      ...state,
      isAwaitingCompileAgentResponse: true,
    };
  }

  case UPDATE_COMPILE_OUTPUT_FULFILLED: {
    const { editorContent } = state;
    let copyOfCurrentEditorContent;
    const editor = 'CODE_EDITOR';
    copyOfCurrentEditorContent = Object.assign({}, editorContent);
    const copyOfEditor = Object.assign({}, copyOfCurrentEditorContent[editor]);

    const { data } = action.payload;

    if (!data) {
      return {
        ...state,
        isAwaitingCompileAgentResponse: false,
      };
    }

    const {
      output,
    } = data;

    // Check for successul output.

    let outputToShow;
    var isHavingStream = output.includes('PRINTSTREAMOUTPUT->') || output.includes('ERRORSTREAMOUTOUT->');
    if (isHavingStream) {
      var outputArray = output.split('ERRORSTREAMOUTOUT->');
      var successfulOutputContent = outputArray[0];
      var errorStreamContent = outputArray[1];
      var lengthOfFirstPart = successfulOutputContent.length;

      if (errorStreamContent) {
        outputToShow = errorStreamContent;
        copyOfEditor['isError'] = true;
      } else {
        outputToShow = successfulOutputContent.substring(19,lengthOfFirstPart);
      }
    } else {
      outputToShow = output;
    }

    copyOfEditor['output'] = outputToShow;

    copyOfCurrentEditorContent[editor] = copyOfEditor;

    return {
      ...state,
      editorContent: copyOfCurrentEditorContent,
      isAwaitingCompileAgentResponse: false,
      isCompiledOutputVisible: true,
    };
  }

  case UPDATE_COMPILE_OUTPUT_REJECTED: {
    showErrorToast('Could not compile the code. Try again');
    return {
      ...state,
      isAwaitingCompileAgentResponse: false,
    };
  }

  case UPDATE_COMPILE_OUTPUT_OF_CODESHARE: {
    return {
      ...state,
      isAwaitingCompileAgentResponse: true,
    };
  }

  case UPDATE_COMPILE_OUTPUT_OF_CODESHARE_PENDING: {
    return {
      ...state,
      isAwaitingCompileAgentResponse: true,
    };
  }

  case UPDATE_COMPILE_OUTPUT_OF_CODESHARE_FULFILLED: {
    const { editorContent } = state;
    let copyOfCurrentEditorContent;
    const editor = 'SHARE_CODE_EDITOR';
    copyOfCurrentEditorContent = Object.assign({}, editorContent);
    const copyOfEditor = Object.assign({}, copyOfCurrentEditorContent[editor]);

    const { data } = action.payload;

    if (!data) {
      return {
        ...state,
        isAwaitingCompileAgentResponse: false,
      };
    }

    const {
      output,
    } = data;

    // Check for successul output.

    let outputToShow;
    var isHavingStreams = output.includes('PRINTSTREAMOUTPUT->') || output.includes('ERRORSTREAMOUTOUT->');
    if (isHavingStreams) {
      var arrayOutput = output.split('ERRORSTREAMOUTOUT->');
      var successfulOutputData = arrayOutput[0];
      var errorStreamData = arrayOutput[1];
      var lengthOfFirstPartOfContent = successfulOutputData.length;

      if (errorStreamData) {
        outputToShow = errorStreamData;
        copyOfEditor['isError'] = true;
      } else {
        outputToShow = successfulOutputData.substring(19,lengthOfFirstPartOfContent);
      }
    } else {
      outputToShow = output;
    }

    copyOfEditor['output'] = outputToShow;

    copyOfCurrentEditorContent[editor] = copyOfEditor;

    return {
      ...state,
      editorContent: copyOfCurrentEditorContent,
      isAwaitingCompileAgentResponse: false,
      isCompiledOutputVisibleInCodeShare: true,
    };
  }

  case UPDATE_COMPILE_OUTPUT_OF_CODESHARE_REJECTED: {
    showErrorToast('Could not compile the code. Try again');
    return {
      ...state,
      isAwaitingCompileAgentResponse: false,
    };
  }

  case COMPILE_CODE_USING_COMPILE_AGENT: {
    return {
      ...state,
      isAwaitingCompileAgentResponse: true,
    };
  }

  case TOGGLE_COMPILE_OUTPUT_DRAWER: {
    const { data } = action.payload;
    
    if (!data) {
      return {
        ...state,
      };
    }

    const {
      isCompiledOutputVisible,
    } = data;

    return {
      ...state,
      isCompiledOutputVisible,
    };
  }

  case TOGGLE_COMPILE_OUTPUT_DRAWER_IN_CODE_SHARE: {
    const { data } = action.payload;
    
    if (!data) {
      return {
        ...state,
      };
    }

    const {
      isCompiledOutputVisibleInCodeShare,
    } = data;

    return {
      ...state,
      isCompiledOutputVisibleInCodeShare,
    };
  }

  case SET_EDITOR_PURPOSE_ID: {
    const { data } = action.payload;

    if (!data) {
      return {
        ...state,
      };
    }

    const {
      purposeId,
      clientId,
    } = data;


    return {
      ...state,
      purposeId,
      clientId,
    };
  }

  default:
    return state;
  }
};

export default microService;


