import {
  setOpen,
  setButtonClick,
} from '../../redux/code/actions';
  
export const mapStateToProps = state => ({
  open: state.code.open,
  onButtonClick: state.code.onButtonClick,
  isAutoCompleteDisabled: state.editor.isAutoCompleteDisabled,
});
  
export const mapDispatchToProps = dispatch => ({
  setOpen: (value) => {
    dispatch(setOpen(value));
  },

  setButtonClick: (value) => {
    dispatch(setButtonClick(value));
  },
});