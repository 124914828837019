import { toast } from 'react-toastify';
import { backendUrl } from '../../configFiles/config';
// import configureReduxStore from '../../utils/configureReduxStore';
// import { backendUrl,} from '../../configFiles/config';
import {
  // get,
  post,
} from '../../utils/fetchRequests';
// import { changeEditorTheme } from '../editor/actions';
import {
  FORK_CURRENT_CODE,
  DECREMENT_THE_FORK_COUNT,
  RESET_TO_DEFAULT_CODE,
  CHANGE_CURRENT_CODE,
  FETCH_FORKED_CODE,
  FETCH_CURRENT_CODE_AFTER_VIEWING_FORKED_CODE,
  SAVE_CURRENT_CODE,
  SET_CODE_FOR_HTML,
  SET_EMPTY_CODE,
  SET_PREVIOUS_FORKS,
  SET_CODE_FOR_REACT,
  SET_COMPILED_CODE_FOR_MICROSERVICE_SANDBOX,
  SET_CODE_FOR_ANGULAR_JS,
  SET_IS_COMPONENT_UNMOUNTING_FOR_NORMAL,
  SET_OPEN_DRAWER,
  SET_DRAWER_BUTTON_CLICK,
} from './reducers';

export const saveCurrentCode = (theme, currentCode, frameworkId, indentationForEditors, autoCompleteEnabled) => (dispatch, getState) => {
  const { token } = getState().auth;
  dispatch({
    type: SAVE_CURRENT_CODE,
    payload: post(
      `${backendUrl}/api/v1/sandbox/save/sandboxData`,
      {
        currentTheme: theme,
        code: currentCode,
        frameworkId: frameworkId,
        indentation: indentationForEditors,
        autoCompleteEnabled,
      },
      {
        authorization: token,
      },
    ),
  })
    .catch(() => {
      toast.error('Could not save. Please try again');
    });
};


export const forkTheCurrentCode = (name, description, code, frameworkId) => (dispatch, getState) => {
  // console.log('::::::::::::::::::::',configureReduxStore.getState().editor.currentCode)
  const { token } = getState().auth;
  dispatch({
    type: FORK_CURRENT_CODE,
    payload: post(
      `${backendUrl}/api/v1/fork/save/newFork`,
      {
        name,
        description,
        code,
        frameworkId,
      },
      {
        authorization: token,
      }
    ),
  })
    .then(({ action: { payload: { success } } }) => {
      if (success){
        decrementTheForkCount();
      }
    });
};


export const decrementTheForkCount = () => (dispatch) => {
  dispatch({
    type: DECREMENT_THE_FORK_COUNT,
  });
};

export const fetchForkedCode = (forkId, frameworkId) => (dispatch, getState) => {
  // console.log('forkId========>>>>>>>', forkId);
  const { dependentPackagesForSandboxResolvedHtml } = getState().sandbox;
  dispatch({
    type: FETCH_FORKED_CODE,
    payload: {
      data: {
        forkId,
        frameworkId,
        dependentPackagesForSandboxResolvedHtml,
      },
    },
  });
};

export const fetchCurrentCodeAfterForkCodeView = (frameworkId) => (dispatch, getState) => {
  const { dependentPackagesForSandboxResolvedHtml } = getState().sandbox;
  dispatch({
    type: FETCH_CURRENT_CODE_AFTER_VIEWING_FORKED_CODE,
    payload: {
      data: {
        frameworkId,
        dependentPackagesForSandboxResolvedHtml,
      },
    },
  });
};

export const resetToDefaultCode = () => (dispatch) => {
  dispatch({
    type: RESET_TO_DEFAULT_CODE,
  });
};

export const changeCurrentCode = (language, frameworkId, code) => (dispatch, getState) => {
  const { dependentPackagesForSandboxResolvedHtml } = getState().sandbox;
  dispatch({
    type: CHANGE_CURRENT_CODE,
    payload: {
      data: {
        language,
        frameworkId,
        code,
        dependentPackagesForSandboxResolvedHtml,
      },
    },
  });
};

export const setCodeForFramework = (frameworkId, code) => (dispatch, getState) => {
  switch (frameworkId) {
  case 'HTML': {
    dispatch({
      type: SET_CODE_FOR_HTML,
      payload: {
        data: code,
      },
    });
    break;
  }
  case 'REACT_JS': {
    const { dependentPackagesForSandboxResolvedHtml } = getState().sandbox;
    dispatch({
      type: SET_CODE_FOR_REACT,
      payload: {
        data: {
          code,
          dependentPackagesForSandboxResolvedHtml,
        },
      },
    });
    break;
  }
  case 'ANGULAR_JS': {
    const { dependentPackagesForSandboxResolvedHtml } = getState().sandbox;
    dispatch({
      type: SET_CODE_FOR_ANGULAR_JS,
      payload: {
        data: {
          code,
          dependentPackagesForSandboxResolvedHtml,
        },
      },
    });
    break;
  }
  default:
    break;
  }
};

export const setEmptyCode = (frameworkId) => (dispatch) => {
  dispatch({
    type: SET_EMPTY_CODE,
    payload: {
      data: {
        frameworkId,
      },
    },
  });
};

export const setForks = (forks) => (dispatch) => {
  dispatch({
    type: SET_PREVIOUS_FORKS,
    payload: {
      data: {
        forks,
      },
    },
  });
};

export const resetCompiledHtmlToDefault = (frameworkId) => (dispatch, getState) => {
  const { dependentPackagesForSandboxResolvedHtml } = getState().sandbox;
  dispatch({
    type: SET_COMPILED_CODE_FOR_MICROSERVICE_SANDBOX,
    payload: {
      data: {
        frameworkId,
        dependentPackagesForSandboxResolvedHtml,
      },
    },
  });
};

export const setIsComponentUnmountingForNormal = () => (dispatch) => {
  dispatch({
    type: SET_IS_COMPONENT_UNMOUNTING_FOR_NORMAL,
  });
};

export const setOpen = (value) => (dispatch) => {
  dispatch({
    type: SET_OPEN_DRAWER,
    payload: {
      data: {
        value,
      },
    },
  });
};

export const setButtonClick = (value) => (dispatch) => {
  dispatch({
    type: SET_DRAWER_BUTTON_CLICK,
    payload: {
      data: {
        value,
      },
    },
  });
};