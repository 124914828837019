import { toast } from 'react-toastify';
import { resolvedDependenciesForReactMicroServiceSandbox, resolvedDependenciesForAngularJSMicroServiceSandbox } from '../../Constants/index';

import { initialPathForFileStructure } from '../../configFiles/config';
import fileResolver from '../../utils/fileResolver';
import { showErrorToast, showSuccessToast } from '../../utils/toasts/showToasts';
import { dependencyResolverForSandbox } from '../../utils/dependencyResolver';
import { cardsForHomePage } from '../../AvailableCards/availableCards';

export const CREATE_SANDBOX = 'sandbox/CREATE_SANDBOX';
export const CREATE_SANDBOX_PENDING = 'sandbox/CREATE_SANDBOX_PENDING';
export const CREATE_SANDBOX_FULFILLED = 'sandbox/CREATE_SANDBOX_FULFILLED';
export const CREATE_SANDBOX_REJECTED = 'sandbox/CREATE_SANDBOX_REJECTED';

export const GET_SESSION_FOR_SANDBOX = 'sandbox/GET_SESSION_FOR_SANDBOX';
export const GET_SESSION_FOR_SANDBOX_PENDING = 'sandbox/GET_SESSION_FOR_SANDBOX_PENDING';
export const GET_SESSION_FOR_SANDBOX_FULFILLED = 'sandbox/GET_SESSION_FOR_SANDBOX_FULFILLED';
export const GET_SESSION_FOR_SANDBOX_REJECTED = 'sandbox/GET_SESSION_FOR_SANDBOX_REJECTED';

export const GET_READ_ONLY_SANDBOX = 'sandbox/GET_READ_ONLY_SANDBOX';
export const GET_READ_ONLY_SANDBOX_PENDING = 'sandbox/GET_READ_ONLY_SANDBOX_PENDING';
export const GET_READ_ONLY_SANDBOX_FULFILLED = 'sandbox/GET_READ_ONLY_SANDBOX_FULFILLED';
export const GET_READ_ONLY_SANDBOX_REJECTED = 'sandbox/GET_READ_ONLY_SANDBOX_REJECTED';

export const START_PRIVATE_SANDBOX = 'sandbox/START_PRIVATE_SANDBOX';
export const START_PRIVATE_SANDBOX_PENDING = 'sandbox/START_PRIVATE_SANDBOX_PENDING';
export const START_PRIVATE_SANDBOX_FULFILLED = 'sandbox/START_PRIVATE_SANDBOX_FULFILLED';
export const START_PRIVATE_SANDBOX_REJECTED = 'sandbox/START_PRIVATE_SANDBOX_REJECTED';

export const FETCH_FORKS_FOR_SANDBOX = 'sandbox/FETCH_FORKS_FOR_SANDBOX';
export const FETCH_FORKS_FOR_SANDBOX_PENDING = 'sandbox/FETCH_FORKS_FOR_SANDBOX_PENDING';
export const FETCH_FORKS_FOR_SANDBOX_FULFILLED = 'sandbox/FETCH_FORKS_FOR_SANDBOX_FULFILLED';
export const FETCH_FORKS_FOR_SANDBOX_REJECTED = 'sandbox/FETCH_FORKS_FOR_SANDBOX_REJECTED';

export const CLOSE_FILE = 'sandbox/CLOSE_FILE';
export const OPEN_FILE = 'sandbox/OPEN_FILE';

export const CHANGE_CURRENT_PATH_OF_FILE = 'sandbox/CHANGE_CURRENT_PATH_OF_FILE';
export const CHANGE_PATH_AND_DESCRIPTOR_ID_OF_FILE_TO_OPEN = 'sandbox/CHANGE_PATH_AND_DESCRIPTOR_ID_OF_FILE_TO_OPEN';
export const ADD_DESCRIPTOR_OF_THE_SELECTED_FILE = 'sandbox/ADD_DESCRIPTOR_OF_THE_SELECTED_FILE';
export const DELETE_DESCRIPTOR_OF_THE_SAVED_FILE = 'sandbox/DELETE_DESCRIPTOR_OF_THE_SAVED_FILE';

export const GET_ALL_FILES_OF_SANDBOX = 'sandbox/GET_ALL_FILES_OF_SANDBOX';
export const GET_ALL_FILES_OF_SANDBOX_PENDING = 'sandbox/GET_ALL_FILES_OF_SANDBOX_PENDING';
export const GET_ALL_FILES_OF_SANDBOX_FULFILLED = 'sandbox/GET_ALL_FILES_OF_SANDBOX_FULFILLED';
export const GET_ALL_FILES_OF_SANDBOX_REJECTED = 'sandbox/GET_ALL_FILES_OF_SANDBOX_REJECTED';

export const UPDATE_CURRENT_CODE = 'sandbox/UPDATE_CURRENT_CODE';
export const CREATE_NEW_FILE_OR_FOLDER = 'sandbox/CREATE_NEW_FILE_OR_FOLDER';
export const CREATE_NEW_FILE_OR_FOLDER_PENDING = 'sandbox/CREATE_NEW_FILE_OR_FOLDER_PENDING';
export const CREATE_NEW_FILE_OR_FOLDER_FULFILLED = 'sandbox/CREATE_NEW_FILE_OR_FOLDER_FULFILLED';
export const CREATE_NEW_FILE_OR_FOLDER_REJECTED = 'sandbox/CREATE_NEW_FILE_OR_FOLDER_REJECTED';

export const UPDATE_FILE_CONTENT = 'sandbox/UPDATE_FILE_CONTENT';
export const UPDATE_FILE_CONTENT_PENDING = 'sandbox/UPDATE_FILE_CONTENT_PENDING';
export const UPDATE_FILE_CONTENT_FULFILLED = 'sandbox/UPDATE_FILE_CONTENT_FULFILLED';
export const UPDATE_FILE_CONTENT_REJECTED = 'sandbox/UPDATE_FILE_CONTENT_REJECTED';

export const DELETE_FILE_OF_SANDBOX = 'sandbox/DELETE_FILE_OF_SANDBOX';
export const DELETE_FILE_OF_SANDBOX_PENDING = 'sandbox/DELETE_FILE_OF_SANDBOX_PENDING';
export const DELETE_FILE_OF_SANDBOX_FULFILLED = 'sandbox/DELETE_FILE_OF_SANDBOX_FULFILLED';
export const DELETE_FILE_OF_SANDBOX_REJECTED = 'sandbox/DELETE_FILE_OF_SANDBOX_REJECTED';

export const SAVE_SANDBOX_FILE = 'sandbox/SAVE_SANDBOX_FILE';
export const SAVE_SANDBOX_FILE_PENDING = 'sandbox/SAVE_SANDBOX_FILE_PENDING';
export const SAVE_SANDBOX_FILE_FULFILLED = 'sandbox/SAVE_SANDBOX_FILE_FULFILLED';
export const SAVE_SANDBOX_FILE_REJECTED = 'sandbox/SAVE_SANDBOX_FILE_REJECTED';

export const FETCH_DEPENDENT_PACKAGES = 'sandbox/FETCH_DEPENDENT_PACKAGES';
export const FETCH_DEPENDENT_PACKAGES_PENDING = 'sandbox/FETCH_DEPENDENT_PACKAGES_PENDING';
export const FETCH_DEPENDENT_PACKAGES_FULFILLED = 'sandbox/FETCH_DEPENDENT_PACKAGES_FULFILLED';
export const FETCH_DEPENDENT_PACKAGES_REJECTED = 'sandbox/FETCH_DEPENDENT_PACKAGES_REJECTED';
export const GET_CODE_COMPILE_SANDBOX_URL = 'sandbox/GET_CODE_COMPILE_SANDBOX_URL';
export const GET_CODE_COMPILE_SANDBOX_URL_PENDING = 'sandbox/GET_CODE_COMPILE_SANDBOX_URL_PENDING';
export const GET_CODE_COMPILE_SANDBOX_URL_FULFILLED = 'sandbox/GET_CODE_COMPILE_SANDBOX_URL_FULFILLED';
export const GET_CODE_COMPILE_SANDBOX_URL_REJECTED = 'sandbox/GET_CODE_COMPILE_SANDBOX_URL_REJECTED';

export const SET_FRAMEWORK_ID_AND_DEPENDENCIES = 'sandbox/SET_FRAMEWORK_ID_AND_DEPENDENCIES';
export const SET_DEFAULT_VALUES_ON_UNMOUNT = 'sandbox/SET_DEFAULT_VALUES_ON_UNMOUNT';
export const SET_IS_COMPONENT_UNMOUNTING = 'sandbox/SET_IS_COMPONENT_UNMOUNTING';

const defaultState = {
  sandboxId: undefined,
  frameworkId: undefined,
  isInitializingSandbox: false,
  isFetchingSandboxForViewing: false,
  isFetchingPrivateSandbox: false,
  isFetchingForks: false,
  filesInFileSystemSandbox: {},
  openFilesInFileSystemSandbox: {},
  pathOfTheFile: initialPathForFileStructure,
  pathOfFileToOpen: initialPathForFileStructure,
  descriptorIdToOpenFileInOpenFileView: '',
  currentSelectedDescriptorIdOfTheFile: {},
  isFileSystemEnabled: false,
  isFetchingFiles: false,
  totalBatchesToFetchForFiles: undefined,
  totalBatchesReceivedForFiles: {},
  resolvedFilesForSandbox: undefined,
  isCreatingNewFileOrFolder: false,
  isUpdatingFileContent: false,
  isDeletingFile: false,
  filesWithUpdatedContentInSandbox: {},
  fileDescriptorsMappedWithPath: {},
  isFetchingDependentPackagesForSandbox: false,
  dependentPackagesForSandbox: {},
  isFetchingCodeCompileSandboxUrl: false,
  dependentPackagesForSandboxResolvedHtml: '',
  isComponentUnmounting: false,
};

const sandbox = (state = defaultState, action) => {
  switch (action.type) {
  case CREATE_SANDBOX: {
    return {
      ...state,
      filesInFileSystemSandbox: {},
      isInitializingSandbox: true,
    };
  }

  case CREATE_SANDBOX_PENDING: {
    return {
      ...state,
      filesInFileSystemSandbox: {},
      isInitializingSandbox: true,
    };
  }

  case CREATE_SANDBOX_FULFILLED: {
    const { data } = action.payload;
    if (!data) {
      toast.error('Please retry', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

      return {
        ...state,
        isInitializingSandbox: false,
      };
    }
    const {
      sandboxId,
      frameworkId,
      withFileSystem,
    } = data;

    let totalBatchesToFetch;

    if (withFileSystem) {
      const {
        totalFiles,
        filesToFetchPerBatch,
      } = data;

      totalBatchesToFetch = Math.ceil((totalFiles / filesToFetchPerBatch));
    }
    return {
      ...state,
      isInitializingSandbox: false,
      sandboxId,
      frameworkId,
      isFileSystemEnabled: withFileSystem,
      totalBatchesToFetchForFiles: totalBatchesToFetch,
    };
  }

  case CREATE_SANDBOX_REJECTED: {
    return {
      ...state,
      isInitializingSandbox: false,
    };
  }

  case GET_SESSION_FOR_SANDBOX: {
    return {
      ...state,
      isInitializingSandbox: true,
    };
  }

  case GET_SESSION_FOR_SANDBOX_PENDING: {
    return {
      ...state,
      isInitializingSandbox: true,
    };
  }

  case GET_SESSION_FOR_SANDBOX_FULFILLED: {
    const { data } = action.payload;
    const {
      sandbox,
    } = data;

    if (!sandbox) {
      return {
        ...state,
        isInitializingSandbox: false,
      };
    }

    const {
      frameworkId,
      sandboxId,
      withFileSystem,
    } = sandbox;

    let totalBatchesToFetch;

    if (withFileSystem) {
      const {
        totalFiles,
        filesPerBatch,
      } = data;

      totalBatchesToFetch = Math.ceil((totalFiles / filesPerBatch));
    }
    
    return {
      ...state,
      isInitializingSandbox: false,
      frameworkId,
      sandboxId,
      isFileSystemEnabled: withFileSystem,
      totalBatchesToFetchForFiles: totalBatchesToFetch,
    };
  }

  case GET_SESSION_FOR_SANDBOX_REJECTED: {
    return {
      ...state,
      isInitializingSandbox: false,
    };
  }

  case GET_READ_ONLY_SANDBOX: {
    return {
      ...state,
      isFetchingSandboxForViewing: true,
    };
  }

  case GET_READ_ONLY_SANDBOX_PENDING: {
    return {
      ...state,
      isFetchingSandboxForViewing: true,
    };
  }

  case GET_READ_ONLY_SANDBOX_FULFILLED: {
    const { data } = action.payload;
    if (!data) {
      return {
        ...state,
        isFetchingSandboxForViewing: false,
      };
    }

    const {
      details,
      withFileSystem,
    } = data;

    if (!details) {
      return {
        ...state,
        isFetchingSandboxForViewing: false,
      };
    }

    const {
      sandboxId,
      frameworkId,
    } = details;

    let totalBatchesToFetch;
    
    if (withFileSystem) {
      const {
        totalFiles,
        filesToFetchPerBatch,
      } = data;

      totalBatchesToFetch = Math.ceil((totalFiles / filesToFetchPerBatch));
    }

    return {
      ...state,
      isFetchingSandboxForViewing: false,
      frameworkId,
      sandboxId,
      isFileSystemEnabled: withFileSystem,
      totalBatchesToFetchForFiles: totalBatchesToFetch,
    };
  }

  case GET_READ_ONLY_SANDBOX_REJECTED: {
    return {
      ...state,
      isFetchingSandboxForViewing: false,
    };
  }

  case START_PRIVATE_SANDBOX: {
    return {
      ...state,
      isFetchingPrivateSandbox: true,
    };
  }

  case START_PRIVATE_SANDBOX_PENDING: {
    return {
      ...state,
      isFetchingPrivateSandbox: true,
    };
  }

  case START_PRIVATE_SANDBOX_FULFILLED: {
    const { data } = action.payload;
    if (!data) {
      return {
        ...state,
        isFetchingPrivateSandbox: false,
      };
    }

    const {
      frameworkId,
      sandboxId,
      withFileSystem,
    } = data;
    

    let totalBatchesToFetch;
    
    if (withFileSystem) {
      const {
        totalFiles,
        filesToFetchPerBatch,
      } = data;

      totalBatchesToFetch = Math.ceil((totalFiles / filesToFetchPerBatch));
    }

    return {
      ...state,
      isFetchingPrivateSandbox: false,
      frameworkId,
      sandboxId,
      isFileSystemEnabled: withFileSystem,
      totalBatchesToFetchForFiles: totalBatchesToFetch,
    };
  }

  case START_PRIVATE_SANDBOX_REJECTED: {
    return {
      ...state,
      isFetchingPrivateSandbox: false,
    };
  }

  case FETCH_FORKS_FOR_SANDBOX: {
    return {
      ...state,
      isFetchingForks: true,
    };
  }

  case FETCH_FORKS_FOR_SANDBOX_PENDING: {
    return {
      ...state,
      isFetchingForks: true,
    };
  }

  case FETCH_FORKS_FOR_SANDBOX_FULFILLED: {
    return {
      ...state,
      isFetchingForks: false,
    };
  }

  case FETCH_FORKS_FOR_SANDBOX_REJECTED: {
    return {
      ...state,
      isFetchingForks: false,
    };
  }

  case CLOSE_FILE: {
    const { openFilesInFileSystemSandbox } = state;
    const { data } = action.payload;
    const { fileId } = data;
    const copyOfOpenFilesInFileSystemSandbox = Object.assign({},openFilesInFileSystemSandbox);
    delete copyOfOpenFilesInFileSystemSandbox[fileId];
    return {
      ...state,
      openFilesInFileSystemSandbox: copyOfOpenFilesInFileSystemSandbox,
    };
  }

  case OPEN_FILE: {
    const {
      openFilesInFileSystemSandbox,
      // filesInFileSystemSandbox,
    } = state;
    const { data } = action.payload;
    const { descriptorId } = data;
    // console.log(descriptorId);
    const newFileToOpenInFileSystemSandbox = {};
    // newFileToOpenInFileSystemSandbox[descriptorId] = filesInFileSystemSandbox[descriptorId];
    newFileToOpenInFileSystemSandbox[descriptorId] = descriptorId;
    const copyOfOpenFilesInFileSystemSandbox = Object.assign(openFilesInFileSystemSandbox, newFileToOpenInFileSystemSandbox);
    return {
      ...state,
      openFilesInFileSystemSandbox: copyOfOpenFilesInFileSystemSandbox,
    };
  }
  case CHANGE_CURRENT_PATH_OF_FILE: {
    // const { pathOfTheFile } = state;
    const { data } = action.payload;
    const { currentPath } = data;
    return {
      ...state,
      pathOfTheFile: currentPath,
    };
  }

  case CHANGE_PATH_AND_DESCRIPTOR_ID_OF_FILE_TO_OPEN: {
    const { data } = action.payload;
    const { path, descriptorId } = data;
    return {
      ...state,
      pathOfFileToOpen: path,
      descriptorIdToOpenFileInOpenFileView: descriptorId,
    };
  }

  case ADD_DESCRIPTOR_OF_THE_SELECTED_FILE: {
    const { currentSelectedDescriptorIdOfTheFile } = state;
    const { data } = action.payload;
    if (!data){
      toast.error('Error occured, try again');
      return {
        ...state,
      };
    }
    const { descriptorId } = data;
    if (!descriptorId){
      toast.error('Error occured, try again');
      return {
        ...state,
      };
    }
    const newSelectedDescriptorIdOfTheFile = {};
    newSelectedDescriptorIdOfTheFile[descriptorId] = descriptorId;
    const copyOfOpenFilesInFileSystemSandbox = Object.assign(currentSelectedDescriptorIdOfTheFile, newSelectedDescriptorIdOfTheFile);

    return {
      ...state,
      currentSelectedDescriptorIdOfTheFile: copyOfOpenFilesInFileSystemSandbox,
    };
  }

  case DELETE_DESCRIPTOR_OF_THE_SAVED_FILE: {
    const {
      currentSelectedDescriptorIdOfTheFile,
      filesInFileSystemSandbox,
      filesWithUpdatedContentInSandbox,
    } = state;
    const { data } = action.payload;
    if (!data){
      toast.error('Error occured, try again');
      return {
        ...state,
      };
    }
    const { descriptorId } = data;
    if (!descriptorId){
      toast.error('Error occured, try again');
      return {
        ...state,
      };
    }

    const previousCopyOfFiles = Object.assign({}, filesInFileSystemSandbox);
    const previousFile = previousCopyOfFiles[descriptorId];
    const previousFileCopy = Object.assign({}, previousFile);

    const previousFileContent = previousFileCopy.content;

    const newSelectedDescriptorIdOfTheFile = currentSelectedDescriptorIdOfTheFile;
    delete newSelectedDescriptorIdOfTheFile[descriptorId];
    const copyOfOpenFilesInFileSystemSandbox = Object.assign(currentSelectedDescriptorIdOfTheFile, newSelectedDescriptorIdOfTheFile);

    const copyOfUpdatedContent = Object.assign({}, filesWithUpdatedContentInSandbox);

    copyOfUpdatedContent[descriptorId].content = previousFileContent;

    return {
      ...state,
      currentSelectedDescriptorIdOfTheFile: copyOfOpenFilesInFileSystemSandbox,
      filesWithUpdatedContentInSandbox: copyOfUpdatedContent,
    };
  }

  case GET_ALL_FILES_OF_SANDBOX: {
    return {
      ...state,
      isFetchingFiles: true,
    };
  }

  case GET_ALL_FILES_OF_SANDBOX_PENDING: {
    return {
      ...state,
      isFetchingFiles: true,
    };
  }

  case GET_ALL_FILES_OF_SANDBOX_FULFILLED: {
    const { data } = action.payload;

    if (!data) {
      return {
        ...state,
        isFetchingFiles: false,
      };
    }

    const {
      files,
      batchNumber,
    } = data;

    if (!files || (files && files.length === 0)) {
      return {
        ...state,
        isFetchingFiles: false,
      };
    }

    const {
      totalBatchesReceivedForFiles,
      filesInFileSystemSandbox,
      totalBatchesToFetchForFiles,
      fileDescriptorsMappedWithPath,
    } = state;

    const updatedFilesReceivedForSandbox = Object.assign({}, totalBatchesReceivedForFiles);

    updatedFilesReceivedForSandbox[batchNumber] = files.length;

    const updatedFilesForSandbox = Object.assign({}, filesInFileSystemSandbox);
    const pathMappedFileDescriptorsForBatch = Object.assign({},fileDescriptorsMappedWithPath);
    files.forEach((file) => {
      const fileDescriptor = file.fileDescriptor;
      const filePath = file.path;
      const fileName = file.name;
      const pathToCurrentFile = file.isDirectory ? `${filePath}${fileName}/` : `${filePath}${fileName}`;
      pathMappedFileDescriptorsForBatch[pathToCurrentFile] = fileDescriptor;
      updatedFilesForSandbox[fileDescriptor] = file;
    });

    let resolvedFilesForSandbox;

    const filesWithUpdatedContentInSandbox = Object.assign({}, updatedFilesForSandbox);

    if (Object.keys(updatedFilesReceivedForSandbox).length === totalBatchesToFetchForFiles) {
      resolvedFilesForSandbox = fileResolver({}, updatedFilesForSandbox);
      // const indexFileDescriptor = pathMappedFileDescriptorsForBatch[baseFilePath];
      
      // const openFilesInFileSystemSandbox = {};
      // openFilesInFileSystemSandbox[indexFileDescriptor] = indexFileDescriptor;
      return {
        ...state,
        isFetchingFiles: false,
        filesInFileSystemSandbox: updatedFilesForSandbox,
        resolvedFilesForSandbox,
        fileDescriptorsMappedWithPath: pathMappedFileDescriptorsForBatch,
        filesWithUpdatedContentInSandbox,
        // openFilesInFileSystemSandbox,
      };
    }

    return {
      ...state,
      isFetchingFiles: true,
      filesInFileSystemSandbox: updatedFilesForSandbox,
      filesWithUpdatedContentInSandbox,
      fileDescriptorsMappedWithPath: pathMappedFileDescriptorsForBatch,
      totalBatchesReceivedForFiles: updatedFilesReceivedForSandbox,
    };
  }

  case GET_ALL_FILES_OF_SANDBOX_REJECTED: {
    return {
      ...state,
      isFetchingFiles: false,
    };
  }

  case UPDATE_CURRENT_CODE: {
    const {
      filesWithUpdatedContentInSandbox,
    } = state;
    const { data } = action.payload;
    const { descriptorId, code } = data;
    
    const copyOfFilesInFileSystemSandbox = Object.assign({}, filesWithUpdatedContentInSandbox);
  
    const fileToModify = copyOfFilesInFileSystemSandbox[descriptorId];
    const copyOfFileToModify = Object.assign({}, fileToModify);
    copyOfFileToModify.content = code;

    copyOfFilesInFileSystemSandbox[descriptorId] = copyOfFileToModify;

    return {
      ...state,
      filesWithUpdatedContentInSandbox: copyOfFilesInFileSystemSandbox,
    };
  }
  case CREATE_NEW_FILE_OR_FOLDER: {
    return {
      ...state,
      isCreatingNewFileOrFolder: true,
    };
  }

  case CREATE_NEW_FILE_OR_FOLDER_PENDING: {
    return {
      ...state,
      isCreatingNewFileOrFolder: true,
    };
  }

  case CREATE_NEW_FILE_OR_FOLDER_FULFILLED: {
    const { data } = action.payload;
    // console.log('::::::::::::::::::::::::::::::',data);
    if (!data){
      return {
        ...state,
        isCreatingNewFileOrFolder: false,
      };
    }
    const { file } = data;
    if (!file){
      return {
        ...state,
        isCreatingNewFileOrFolder: false,
      };
    }
    const fileDescriptor = file.fileDescriptor;
    const { filesInFileSystemSandbox, filesWithUpdatedContentInSandbox,fileDescriptorsMappedWithPath } = state;
    const filesInSandboxWithNewFile = Object.assign({},filesInFileSystemSandbox);
    const copyOfFilesWithUpdatedContentInSandbox = Object.assign({},filesWithUpdatedContentInSandbox);
    filesInSandboxWithNewFile[fileDescriptor] = file;
    copyOfFilesWithUpdatedContentInSandbox[fileDescriptor] = file;
    const updateResolvedFiles = fileResolver({}, filesInSandboxWithNewFile);
    const copyOfFileDescriptorsMappedWithPath = Object.assign({},fileDescriptorsMappedWithPath);
    const filePath = file.path;
    const fileName = file.name;
    const pathToCurrentFile = file.isDirectory ? `${filePath}${fileName}/` : `${filePath}${fileName}`;
    copyOfFileDescriptorsMappedWithPath[pathToCurrentFile] = fileDescriptor;

    const isDirectory = file.isDirectory;

    const toastMessage = `Successfully created the ${isDirectory ? 'folder' : 'file'}`;
    showSuccessToast(toastMessage);
    return {
      ...state,
      isCreatingNewFileOrFolder: false,
      filesInFileSystemSandbox: filesInSandboxWithNewFile,
      filesWithUpdatedContentInSandbox: copyOfFilesWithUpdatedContentInSandbox,
      resolvedFilesForSandbox: updateResolvedFiles,
      fileDescriptorsMappedWithPath: copyOfFileDescriptorsMappedWithPath,
    };
  }

  case CREATE_NEW_FILE_OR_FOLDER_REJECTED: {
    toast.error('Couldnt create a file');
    return {
      ...state,
      isCreatingNewFileOrFolder: false,
    };
  }

  case UPDATE_FILE_CONTENT: {
    return {
      ...state,
      isUpdatingFileContent: true,
    };
  }

  case UPDATE_FILE_CONTENT_PENDING: {
    return {
      ...state,
      isUpdatingFileContent: true,
    };
  }

  case UPDATE_FILE_CONTENT_FULFILLED: {
    const { data } = action.payload;
    if (!data) {
      return {
        ...state,
        isUpdatingFileContent: false,
      };
    }

    const {
      fileDescriptor,
    } = data;

    if (!fileDescriptor) {
      return {
        ...state,
        isUpdatingFileContent: false,
      };
    }

    const {
      filesWithUpdatedContentInSandbox,
      filesInFileSystemSandbox,
      currentSelectedDescriptorIdOfTheFile,
      isComponentUnmounting,
    } = state;

    const copyOfFilesInFileSystemSandbox = Object.assign({}, filesInFileSystemSandbox);

    const updatedContentForFile = filesWithUpdatedContentInSandbox[fileDescriptor].content;

    copyOfFilesInFileSystemSandbox[fileDescriptor].content = updatedContentForFile;

    
    const newSelectedDescriptorIdOfTheFile = Object.assign({}, currentSelectedDescriptorIdOfTheFile);

    delete newSelectedDescriptorIdOfTheFile[fileDescriptor];

    const fileName = copyOfFilesInFileSystemSandbox[fileDescriptor].name;
    if (!isComponentUnmounting) {
      showSuccessToast(`File ${fileName} saved successfully`);
    }

    return {
      ...state,
      isUpdatingFileContent: false,
      filesInFileSystemSandbox: copyOfFilesInFileSystemSandbox,
      currentSelectedDescriptorIdOfTheFile: newSelectedDescriptorIdOfTheFile,
      isComponentUnmounting: false,
    };
  }

  case UPDATE_FILE_CONTENT_REJECTED: {
    return {
      ...state,
      isUpdatingFileContent: false,
      isComponentUnmounting: false,
    };
  }

  case DELETE_FILE_OF_SANDBOX: {
    return {
      ...state,
      isDeletingFile: true,
    };
  }

  case DELETE_FILE_OF_SANDBOX_PENDING: {
    return {
      ...state,
      isDeletingFile: true,
    };
  }

  case DELETE_FILE_OF_SANDBOX_FULFILLED: {
    const { data } = action.payload;
    if (!data) {
      return {
        ...state,
        isDeletingFile: false,
      };
    }

    const { fileDescriptor } = data;
    if (!fileDescriptor) {
      return {
        ...state,
        isDeletingFile: false,
      };
    }

    const {
      filesInFileSystemSandbox,
      fileDescriptorsMappedWithPath,
      openFilesInFileSystemSandbox,
      filesWithUpdatedContentInSandbox,
    } = state;

    const updatedFilesForSandbox = Object.assign({}, filesInFileSystemSandbox);

    const fileName = updatedFilesForSandbox[fileDescriptor].name;
    const filePath = updatedFilesForSandbox[fileDescriptor].path;
    
    const wasFileADirectory = updatedFilesForSandbox[fileDescriptor].isDirectory;

    delete updatedFilesForSandbox[fileDescriptor];

    const updateResolvedFiles = fileResolver({}, updatedFilesForSandbox);
    // May also require removing the file from open files object.

    const copyOfOpenFiles = Object.assign({}, openFilesInFileSystemSandbox);
    delete copyOfOpenFiles[fileDescriptor];

    const fullPathOfDeletedFile = `${filePath}${fileName}`;
    const copyOfFileDescriptorsMappedWithDescriptors = Object.assign({}, fileDescriptorsMappedWithPath);
    delete copyOfFileDescriptorsMappedWithDescriptors[fullPathOfDeletedFile];

    const copyOfUpdatedContent = Object.assign({}, filesWithUpdatedContentInSandbox);
    delete copyOfUpdatedContent[fileDescriptor];

    showSuccessToast(`${wasFileADirectory ? 'Folder' : 'File'} ${fileName} deleted successfully`);

    return {
      ...state,
      isDeletingFile: false,
      filesInFileSystemSandbox: updatedFilesForSandbox,
      resolvedFilesForSandbox: updateResolvedFiles,
      openFilesInFileSystemSandbox: copyOfOpenFiles,
      fileDescriptorsMappedWithPath: copyOfFileDescriptorsMappedWithDescriptors,
      filesWithUpdatedContentInSandbox: copyOfUpdatedContent,
    };
  }

  case DELETE_FILE_OF_SANDBOX_REJECTED: {
    // toast.error('Can not delete a non-empty file');
    showErrorToast('Could not delete file. Please try again');
    return {
      ...state,
      isDeletingFile: false,
    };
  }

  case FETCH_DEPENDENT_PACKAGES: {
    return {
      ...state,
      isFetchingDependentPackagesForSandbox: true,
    };
  }

  case FETCH_DEPENDENT_PACKAGES_PENDING: {
    return {
      ...state,
      isFetchingDependentPackagesForSandbox: true,
    };
  }

  case FETCH_DEPENDENT_PACKAGES_FULFILLED: {
    const { data } = action.payload;
    const { dependencies } = data;
    if ( !data ) {
      return {
        ...state,
        isFetchingDependentPackagesForSandbox: false,
      };
    }
    const resolvedDependentPackages = dependencyResolverForSandbox(dependencies);
    return {
      ...state,
      dependentPackagesForSandbox: dependencies,
      dependentPackagesForSandboxResolvedHtml: resolvedDependentPackages,
      isFetchingDependentPackagesForSandbox: false,
    };
  }

  case FETCH_DEPENDENT_PACKAGES_REJECTED: {
    return {
      ...state,
      isFetchingDependentPackagesForSandbox: false,
    };
  }
  case GET_CODE_COMPILE_SANDBOX_URL: {
    return {
      ...state,
      isFetchingCodeCompileSandboxUrl: true,
    };
  }

  case GET_CODE_COMPILE_SANDBOX_URL_PENDING: {
    return {
      ...state,
      isFetchingCodeCompileSandboxUrl: true,
    };
  }

  case GET_CODE_COMPILE_SANDBOX_URL_FULFILLED: {
    return {
      ...state,
      isFetchingCodeCompileSandboxUrl: false,
    };
  }

  case GET_CODE_COMPILE_SANDBOX_URL_REJECTED: {
    showErrorToast('Request could not be completed');
    return {
      ...state,
      isFetchingCodeCompileSandboxUrl: false,
    };
  }

  case SET_FRAMEWORK_ID_AND_DEPENDENCIES: {
    const { data } = action.payload;
    const {
      frameworkId,
    } = data;

    let resolvedDependencies = '';

    if (frameworkId === cardsForHomePage.ReactJS.frameworkId) {
      resolvedDependencies = resolvedDependenciesForReactMicroServiceSandbox;
    }

    else if (frameworkId === cardsForHomePage.AngularJS.frameworkId){
      resolvedDependencies = resolvedDependenciesForAngularJSMicroServiceSandbox;
    }

    return {
      ...state,
      frameworkId,
      dependentPackagesForSandboxResolvedHtml: resolvedDependencies,
    };
  }

  case SET_DEFAULT_VALUES_ON_UNMOUNT: {

    return {
      ...state,
      openFilesInFileSystemSandbox: {},
      resolvedFilesForSandbox: undefined,
      filesWithUpdatedContentInSandbox: {},
    };
  }

  case SET_IS_COMPONENT_UNMOUNTING: {
    
    return {
      ...state,
      isComponentUnmounting: true,
    };
  }

  default:
    return state;
  }
};

export default sandbox;
