/* eslint-disable react/prop-types */
import React from 'react';
// import { connect } from 'react-redux';

import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/theme-monokai';
import PropTypes from 'prop-types';
// import { changeCurrentCode } from '../../redux/code/actions';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/snippets/javascript';
import './JavaScriptEditor.css';

class JavaScriptEditor extends React.Component {
  constructor (props, context) {
    super(props, context);
    this.onChange = this.onChange.bind(this);
  }

  onChange (newValue) {
    const language = 'javascript';
    const { changeCurrentCode, frameworkId } = this.props;
    changeCurrentCode(language, frameworkId, newValue);
  }

  render () {
    const {
      editorTheme,
      isAutoCompleteEnabled,
      currentCode,
      isNotEditable,
      indentationForEditors,
      isInMicroService,
      frameworkId,
    } = this.props;
    return (
      <div>
        <div >
          <AceEditor
            mode="javascript"
            theme={editorTheme}
            onChange={this.onChange}
            name="JAVASCRIPT_EDITOR"
            value={currentCode[frameworkId] && currentCode[frameworkId].javascript}
            editorProps={{
              $blockScrolling: true,
            }}
            setOptions={{
              showLineNumbers: true,
              showPrintMargin: false,
              useWorker: false,
            }}
            tabSize = {indentationForEditors}
            enableBasicAutocompletion={isAutoCompleteEnabled}
            enableLiveAutocompletion={isAutoCompleteEnabled}
            enableSnippets={isAutoCompleteEnabled}
            readOnly={isNotEditable}
            height={(isInMicroService ? '82vh' : (window.location !== window.parent.location) ? '92vh' : (isNotEditable ? '92.3vh' : '82vh'))}
            width={isInMicroService ? '50vw' : (isNotEditable ? '48.9vw' : '48.6%')}
            className="AceEditor"
            style = {{ borderRight: 'none', borderTop: 'none' }}
            highlightActiveLine= {!isNotEditable}
            highlightGutterLine= {isNotEditable}
          />
        </div>
      </div>
    );
  }
}

JavaScriptEditor.propTypes = {
  currentCode: PropTypes.object.isRequired,
  isNotEditable: PropTypes.bool.isRequired,
  isAutoCompleteEnabled: PropTypes.bool.isRequired,
  editorTheme: PropTypes.string.isRequired,
  changeCurrentCode: PropTypes.func.isRequired,
  indentationForEditors: PropTypes.number.isRequired,
  frameworkId: PropTypes.string,
};

JavaScriptEditor.defaultProps = {
  currentCode: {
    HTML: {
      html: '',
      css: '',
      javascript: '',
    },
    REACT_JS: {
      javascript: '',
      css: '',
    },
  },
  isNotEditable: false,
  isAutoCompleteEnabled: true,
  editorTheme: 'LIGHT',
  indentationForEditors: 2,
  changeCurrentCode: () => {

  },
};

// const mapStateToProps = state => ({
//   editorTheme: state.editor.selectedTheme,
//   currentCode: state.code.currentCode,
//   isAutoCompleteEnabled: state.editor.isAutoCompleteEnabled,
//   indentationForEditors: state.editor.indentationForEditors,
// });

// const mapDispatchToProps = dispatch => ({
//   changeCurrentCode: (language, code) => {
//     dispatch(changeCurrentCode(language, code));
//   },
// });

// export default connect(mapStateToProps, mapDispatchToProps)(JavaScriptEditor);
export default JavaScriptEditor;
