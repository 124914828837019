import {
  htmlBundler,
  htmlRebundler,
  reactBundler,
  reactRebundler,
} from '../../utils/bundleCreator';
import {
// dummyFilesInSandboxForReactSandbox,
// rebundleTestFile,
} from '../../Constants';
// import fileResolver from '../../utils/fileResolver';
import pathResolver from '../../utils/bundleCreator/pathResolver';
import {
  CREATE_BUNDLE_FILE,
  REBUNDLE_FILES,
  REVOKE_BUNDLE_URL,
  SET_BUNDLE_ERROR_MESSAGE,
  CREATE_REACT_BUNDLE_FILE,
  REBUNDLE_REACT_FILES,
  SET_DEFAULT_VALUES_ON_UNMOUNT,
} from './reducers';
import {
  rootLevelPathIndentifier,
  baseFileName,
  baseFilePath,
  baseFileNameForReactFileSystemSandbox,
} from '../../configFiles/config';

const createHTMLBundle = (dispatch, getState) => {
  // const htmlString = dummyFilesInSandbox['descriptor_1'].content;
  // const resolvedTree = fileResolver({}, dummyFilesInSandbox);
  const {
    resolvedFilesForSandbox,
    filesWithUpdatedContentInSandbox,
  } = getState().sandbox;

  const baseFile = resolvedFilesForSandbox[rootLevelPathIndentifier];

  if (baseFile) {
    const baseFileDescriptor = baseFile[baseFileName];
    if (!baseFileDescriptor) {
      // Refresh the page to fetch the page descriptor of the base file.
      window.location.reload();
    }
    const fileContent = filesWithUpdatedContentInSandbox[baseFileDescriptor].content;
    dispatch({
      type: CREATE_BUNDLE_FILE,
      payload: htmlBundler(fileContent, resolvedFilesForSandbox, filesWithUpdatedContentInSandbox),
    });
  } else {
    // Show error toast.
    // Take the user to the home page.
    window.location.assign('/');
  }
};

const createReactJsBundle = (dispatch, getState) => {

  const {
    resolvedFilesForSandbox,
    filesWithUpdatedContentInSandbox,
    dependentPackagesForSandboxResolvedHtml,
  } = getState().sandbox;

  const baseFile = resolvedFilesForSandbox[rootLevelPathIndentifier];


  // if (baseFile) {
  const baseFileDescriptor = baseFile[baseFileNameForReactFileSystemSandbox];
  // const fileContent = filesWithUpdatedContentInSandbox[baseFileDescriptor].content;

  const nameToBeSentInPathResolver = filesWithUpdatedContentInSandbox[baseFileDescriptor].name;
  const pathOfBaseFileToBeSent = filesWithUpdatedContentInSandbox[baseFileDescriptor].path;

  const canonicalFilePath = pathResolver('', nameToBeSentInPathResolver, pathOfBaseFileToBeSent);
  const reactBundle = reactBundler(canonicalFilePath, filesWithUpdatedContentInSandbox,dependentPackagesForSandboxResolvedHtml);

  dispatch({
    type: CREATE_REACT_BUNDLE_FILE,
    payload: reactBundle,
  });
};

export const createInitialBundle = (type = 'HTML') => (dispatch, getState) => {
  if (type === 'HTML') {
    createHTMLBundle(dispatch, getState);
    // createReactJsBundle(dispatch, getState);
  }

  else if (type === 'REACT_JS') {
    createReactJsBundle(dispatch, getState);
  }
};

const rebundleHTMLBundle = (dispatch, currentBundle, fileDescriptor, allFilesInSandbox, shouldRecreateBundle) => {
  const fileToUpdate = allFilesInSandbox[fileDescriptor];
  
  if (!fileToUpdate) {
    dispatch({
      type: SET_BUNDLE_ERROR_MESSAGE,
      payload: {
        data: 'File not found. Cannot rebundle. Please refresh',
      },
    });
  }

  const {
    path,
    name,
  } = fileToUpdate;

  const pathToFile = `${path}${name}`;
  if ((pathToFile === `${baseFilePath}` && name === baseFileName) || shouldRecreateBundle) {
    dispatch(createInitialBundle('HTML'));
  } else {
    // Get files in the sandbox from the store.
    dispatch({
      type: REBUNDLE_FILES,
      payload: htmlRebundler(currentBundle, fileDescriptor, allFilesInSandbox),
    });
  }
};

const rebundleReactBundle = (
  dispatch, fileDescriptor, allFilesInSandbox,
  shouldRecreateBundle, resultentModulesFromReactBundler, bundleCssStringInReactFiles, bundleDocumentForHtmlWithCss,
  dependentPackagesForSandboxResolvedHtml) => {
  
  const fileToUpdate = allFilesInSandbox[fileDescriptor];

  // console.log(':::::::::::::::::::fileToUpdate',fileToUpdate);
  // console.log(':::::::::::resultentModulesFromReactBundler iiiiii',resultentModulesFromReactBundler);
  
  if (!fileToUpdate) {
    dispatch({
      type: SET_BUNDLE_ERROR_MESSAGE,
      payload: {
        data: 'File not found. Cannot rebundle. Please refresh',
      },
    });
  }

  const {
    path,
    name,
  } = fileToUpdate;

  const pathToFile = `${path}${name}`;
  // console.log('::::::::::::::::pathToFile',pathToFile);
  if ((pathToFile === `${baseFileNameForReactFileSystemSandbox}` && name === baseFileNameForReactFileSystemSandbox) || shouldRecreateBundle) {
    dispatch(createInitialBundle('REACT_JS'));
  } else {
    // Get files in the sandbox from the store.
    dispatch({
      type: REBUNDLE_REACT_FILES,
      payload: reactRebundler(
        fileDescriptor, allFilesInSandbox,
        resultentModulesFromReactBundler, bundleCssStringInReactFiles, bundleDocumentForHtmlWithCss,
        dependentPackagesForSandboxResolvedHtml),
    });
  }
};

export const rebundleBundle = (type = 'HTML', fileDescriptor, allFilesInSandbox, shouldRecreateBundle) => (dispatch, getState) => {
  const {
    bundleString,
    bundleCreationError,
    resultentModulesFromReactBundler,
    bundleCssStringInReactFiles,
    bundleDocumentForHtmlWithCss,
  } = getState().bundle;

  const {
    dependentPackagesForSandboxResolvedHtml,
  } = getState().sandbox;

  // console.log('::::::::::::::::::bundleString in action',bundleString);
  // console.log(':::::::::::::::::::::::::::::::::::::::resultentModulesFromReactBundler in action',resultentModulesFromReactBundler);

  if (!bundleString && !bundleCreationError) {
    dispatch({
      type: SET_BUNDLE_ERROR_MESSAGE,
      payload: {
        data: {
          errorMessage: 'Could not create bundle. Please refresh',
        },
      },
    });
  } else {
    if (type === 'HTML') {
      rebundleHTMLBundle(dispatch, bundleString, fileDescriptor, allFilesInSandbox, shouldRecreateBundle);
    }
    else if (type === 'REACT_JS') {
      rebundleReactBundle(dispatch,
        fileDescriptor, allFilesInSandbox,
        shouldRecreateBundle, resultentModulesFromReactBundler,
        bundleCssStringInReactFiles, bundleDocumentForHtmlWithCss,
        dependentPackagesForSandboxResolvedHtml);

    }
    
  }
};

export const revokeBundleUrl = () => ({
  type: REVOKE_BUNDLE_URL,
});

export const setDefaultValueOnComponentUnmount = () => (dispatch) => {
  dispatch({
    type: SET_DEFAULT_VALUES_ON_UNMOUNT,
  });
};
