import { Button } from '@material-ui/core';
import React, { Component } from 'react';
// import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';

// import { getSessionForSandbox } from '../../redux/sandbox/actions';

import CssEditor from '../CssEditor';
import HtmlEditorView from '../HtmlEditorView';
import JavaScriptEditor from '../JavaScriptEditor';
import Loader from '../Loader';
import Preview from '../Preview';
import SideNav from '../SideNav';
import './JsHtmlCssPreviewWindow.css';
import { TOTAl_NUMBER_OF_FORKS_AVAILABLE, fullNameWithTheirAbbreviation } from '../../configFiles/config';
import { handleConnectionDown } from '../../utils/utilityFunctions';

class JsHtmlCssPreviewWindow extends Component {
  constructor (props) {
    super(props);

    this.state = {
      togggleHtmlEditor: true,
      toggleJavaScriptEditor: false,
      toggleCssEditor: false,
    };
  }
  

  handleHtmlRender=() => {
    this.setState(() => ({
      togggleHtmlEditor: true,
      toggleJavaScriptEditor: false,
      toggleCssEditor: false,
    }));
  }
   
  handleJavaScriptRender=() => {
    this.setState(() => ({
      toggleJavaScriptEditor: true,
      togggleHtmlEditor: false,
      toggleCssEditor: false,
    }));
  }

  handleCssRender=() => {
    this.setState(() => ({
      toggleCssEditor: true,
      toggleJavaScriptEditor: false,
      togggleHtmlEditor: false,
    }));
  }

  componentDidMount (){
    window.addEventListener('offline', () => {
      handleConnectionDown( 'Internet connection is lost', toast.POSITION.TOP_RIGHT );
    });
  }

  

  render () {
    const { togggleHtmlEditor,toggleJavaScriptEditor,toggleCssEditor } = this.state;
    const {
      isSavingCurrentCode,
      isForkingCode,
      isFetchingForks,
      forkCounter,
      isInMicroService,
      isFileSystemEnabled,
    } = this.props;

    var styleDecideForTopNav =
      (window.location !== window.parent.location) ?
        'topNav-sideIframe'
        :
        'topNav-side';

    var styleDecideForEditorNavigation =
    (isInMicroService) ?
      'navbar_microsevice'
      :
      'navbar';

    var styleDecideForEditorSide =
    (isInMicroService) ?
      'editor-side-microservice'
      :
      'editor-side';
    var styleDecideForPreviewSide =
    (isInMicroService) ?
      'preview-side-microservice'
      :
      'preview-side';


    return (
      <div>
        {/*
        <br></br><br></br><br></br><br></br><br></br>
        <div className='navbar'>
        <Button color="primary" variant="contained"  onClick={this.handleHtmlRender} className="button">HTML</Button>
          <Button color="primary" variant="contained" onClick={this.handleCssRender} className="button" >CSS</Button>
          <Button color="primary" variant="contained"  onClick={this.handleJavaScriptRender} className="button">JavaScript</Button>
          
        </div>
        {togggleHtmlEditor && <HtmlEditorView />}
        {toggleJavaScriptEditor && <JavaScriptEditor />}
        {toggleCssEditor && <CssEditor />} */}
        <div className='outer-div'>
          <div className={styleDecideForTopNav}>

          </div>
          {
            isInMicroService
              ?
              null
              :
              <div className='sideNav-side'>
                <SideNav
                  forkCounter={forkCounter}
                  isFileSystemEnabled={isFileSystemEnabled}
                />
              </div>
          }
          {/* <div className='sideNav-side'>
            <SideNav
              forkCounter={forkCounter}
            />
          </div> */}
          <div>
            <div className={styleDecideForEditorSide}>
              {/* <div className='editor-side-option'> */}
              <div className={styleDecideForEditorNavigation}>
                <Button
                  className={togggleHtmlEditor ? 'editorButtonInHTMLBasicSandboxStyleWhenActive button' :
                    'editorButtonInHTMLBasicSandboxStyle button'}
                  variant="contained"
                  onClick={this.handleHtmlRender}
                >
                  HTML
                </Button>
                <Button
                  className={toggleCssEditor ? 'editorButtonInHTMLBasicSandboxStyleWhenActive' :
                    'editorButtonInHTMLBasicSandboxStyle'}
                  variant="contained"
                  onClick={this.handleCssRender}
                >
                  CSS
                </Button>
                <Button
                  className={toggleJavaScriptEditor ? 'editorButtonInHTMLBasicSandboxStyleWhenActive button' :
                    'editorButtonInHTMLBasicSandboxStyle button'}
                  variant="contained"
                  onClick={this.handleJavaScriptRender}
                >
                  <span className='full-text'>{Object.keys(fullNameWithTheirAbbreviation)[0]}</span>
                  <span className='short-text'>{fullNameWithTheirAbbreviation.JAVASCRIPT}</span>
                </Button>
              </div>
              {/* </div> */}
              <div className='editor-side-editor'>
                {togggleHtmlEditor && <HtmlEditorView isInMicroService={isInMicroService}/>}
                {toggleJavaScriptEditor && <JavaScriptEditor isInMicroService={isInMicroService}/>}
                {toggleCssEditor && <CssEditor isInMicroService={isInMicroService}/>}
              </div>
            </div>
            <div className={styleDecideForPreviewSide}>
              <Preview
                isSandBoxPrivate={false}
                isInMicroService={isInMicroService}
              />
            </div>
          </div>
          {/* <div className='footer-side'>
          </div> */}
        </div>
        {
          isSavingCurrentCode || isForkingCode || isFetchingForks ?
            <Loader />
            :
            null
        }
        <ToastContainer
          newestOnTop={true}
          enableMultiContainer={true}
          limit={5}
          position={toast.POSITION.BOTTOM_RIGHT}
          toastId="TOAST_CONTAINER_FOR_SAVING_CODE"
          hideProgressBar={true}
        />
      </div>
    );
  }
}

JsHtmlCssPreviewWindow.propTypes = {
  isSavingCurrentCode: PropTypes.bool.isRequired,
  forkCounter: PropTypes.number.isRequired,
  isFetchingForks: PropTypes.bool.isRequired,
  isForkingCode: PropTypes.bool.isRequired,
  isInMicroService: PropTypes.bool.isRequired,
  isFileSystemEnabled: PropTypes.bool,
};

JsHtmlCssPreviewWindow.defaultProps = {
  isSavingCurrentCode: false,
  forkCounter: TOTAl_NUMBER_OF_FORKS_AVAILABLE,
  isFetchingForks: false,
  isForkingCode: false,
  isInMicroService: false,
};

// const mapStateToProps = state => ({
//   token: state.auth.token,
//   isSavingCurrentCode: state.code.isSavingCurrentCode,
//   isForkingCode: state.code.isForkingCode,
//   isFetchingForks: state.sandbox.isFetchingForks,
//   forkCounter: state.code.forkCounter,
// });

// const mapDispatchToProps = dispatch => ({
//   getSandboxData: (sandboxId, history) => {
//     dispatch(getSessionForSandbox(sandboxId, history));
//   },
// });

// export default connect(mapStateToProps, mapDispatchToProps)(JsHtmlCssPreviewWindow);
export default JsHtmlCssPreviewWindow;