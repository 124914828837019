import { toast } from 'react-toastify';

export const handleConnectionDown = ( message, position = toast.POSITION.BOTTOM_RIGHT ) => {
  toast.error(message, {
    position: position,
    autoClose: false,
  });
};

export const createResultantBundleStringForReact = (resolvedDependency, bundleCssStringInReactFiles, resultentReactScript) => {
  const resultantReactBundle =
 `<html>
 <head>
 ${resolvedDependency}
 <style>
 ${bundleCssStringInReactFiles}
 </style>
 </head>
 <body>
   <div id="root"></div>
   <script type="text/babel">
   ${resultentReactScript}
   </script>
 </body>
 </html>`;

  return resultantReactBundle;
};