import { Divider } from '@material-ui/core';
import React, { Component } from 'react';
import './FAQ.css';
import TopNavBar from '../TopNavBar/TopNavBar';
import { frontEndUrl,TOTAl_NUMBER_OF_FORKS_AVAILABLE } from '../../configFiles/config';

export default class FAQ extends Component {
  handleAccordian  (){
    const accordian = document.getElementsByClassName('accordion');
    // var i;
    // console.log(':::::::::::::::::::::::',acc);
    // acc.array.forEach(element => {
    //   console.log('::::::::::::::::::::::', element);
    // });
    Array.from(accordian).forEach(function (element) {
      // console.log('::::::::::::::::::',element);
      // this.myfunction(element);
      element.addEventListener('click', function () {
        this.classList.toggle('active');
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + 'px';
        }
      });
    });

    // for (i = 0; i < acc.length; i++) {
    //   acc[i].addEventListener('click', function () {
    //     this.classList.toggle('active');
    //     var panel = this.nextElementSibling;
    //     if (panel.style.maxHeight) {
    //       panel.style.maxHeight = null;
    //     } else {
    //       panel.style.maxHeight = panel.scrollHeight + 'px';
    //     }
    //   });
    // }

    

  }

  componentDidMount (){
    this.handleAccordian();
  }

  render () {

    return (
      <div>
        <TopNavBar />
        <h2 style={{ textAlign: 'center', color: 'var(--primaryColor)',marginTop: '6%' }}>Need Help?</h2>
        <h4 style={{ textAlign: 'center',color: 'var(--primaryColor)' }}>Don&apos;t worry! We have got you covered</h4>

        <div style={{ paddingLeft: '20px',paddingRight: '20px' }}>
          <button className = "accordion">What is CodeFrame?</button>
          <div className="panel">
            <p>CodeFrame is a live coding platform powered by MyAnatomy <a href="https://myanatomy.in/#/home">Visit myanatomy.in</a>, that lets the user see the real time implementation of your code.  </p>
          </div>
          <Divider />

          <button className="accordion">Is CodeFrame free to use?</button>
          <div className="panel">
            <p>There are two types of SandBox available :-<br/>
            1. Public platform <a href="https://codeframe.myanatomy.in/">codeframe.myanatomy.in</a> :- Free for everyone to use.<br/>
            2. A private SandBox:- Inclusive with MAPIT platform of MyAnatomy (with added services), contact: support@myanatomy.in<br/>
            </p>
          </div>
          <Divider />


          <button className="accordion">How to get started with a framework ?</button>
          <div className="panel">
            <p>From the <a href="https://codeframe.myanatomy.in/">Home page</a>, just click on -`Get Started` on the framework you want to
            work and select the Sandbox of your choice and voila!.</p>
          </div>
          <Divider />

          <button className="accordion">What is meant by `Save as` and `Available Fork` ?</button>
          <div className="panel">
            <p>You can save the code snippet you currently have by using `Save as` and giving it a name (mandatory) and a description
            for your reference, these refrences would be available at Available forks.
            </p>
          </div>
          <Divider />

          <button className="accordion">How to get back to current code which was there before forking ?</button>
          <div className="panel">
            <p>Just click on back button, (positioned just after available forks) to get the current code you were working on.</p>
          </div>
          <Divider />

          <button className="accordion">How many times can one fork the code ?</button>
          <div className="panel">
            <p>For every SandBox you can fork the code {TOTAl_NUMBER_OF_FORKS_AVAILABLE} times. </p>
          </div>
          <Divider />

          <button className="accordion">What is the use of saving the URL upon clicking `Exit SandBox`?</button>
          <div className="panel">
            <p>You can restart the same SandBox, by using the saved URL of current SandBox. </p>
          </div>
          <Divider />

          <button className="accordion">What are the frameworks available?</button>
          <div className="panel">
            <p>Currently you can work on HTML framework ,but stay tuned ,there is a lot to come.  </p>
          </div>
          <Divider />
        </div>
      </div>
    );
  }
}
