import { rootLevelPathIndentifier } from '../configFiles/config';

const createObjectForPath = (path, index, isDirectory, fileDescriptor) => {
  if (index === (path.length)) {
    if (isDirectory) {
      return {};
    }
    return fileDescriptor;
  }
  const filePath = createObjectForPath(path, index + 1, isDirectory, fileDescriptor);
  const parsedPath = {};
  parsedPath[path[index]] = filePath;
  return parsedPath;
};

const buildTree = (resolvedTree = {}, path) => {
  if (Object.keys(resolvedTree).length === 0) {
    // If there's nothing present in the tree, make current path the tree.
    resolvedTree = Object.assign({}, path);
  } else {
    // Process the tree.
    const currentKey = Object.keys(path)[0];
    // console.log('resolvedTree[currentKey]', resolvedTree[currentKey]);
    if (typeof resolvedTree[currentKey] === 'object') {
      const newPath = resolvedTree[currentKey];
      const parsedPath = path[currentKey];
      resolvedTree[currentKey] = buildTree(newPath, parsedPath);
    } else if (typeof resolvedTree[currentKey] === 'string') {
      throw Object({ error: 'Can\'t resolve the module' });
    } else {
      const parsedPath = path[currentKey];
      resolvedTree[currentKey] = parsedPath;
    }
  }
  return resolvedTree;
};

export default (resolvedTree = {}, files) => {
  const allFiles = Object.values(files);

  let dummyTree = {};

  allFiles.map((file) => {
    const fileName = file.name;
    const filePath = file.path;
    const isDirectory = file.isDirectory;

    const fileDescriptor = file.fileDescriptor;

    const filePathWithFileName = `${filePath}${fileName}`;

    const filteredFilePath = filePathWithFileName.split('/').filter(path => path !== '');
    if (filteredFilePath[0] !== rootLevelPathIndentifier) {
      throw Object({ error: 'Invalid file path' });
    }
    const currentFilePath = createObjectForPath(filteredFilePath, 0, isDirectory, fileDescriptor);
    dummyTree = buildTree(dummyTree, currentFilePath);
    return null;
  });

  resolvedTree = Object.assign({}, dummyTree);
  return resolvedTree;
};
