import React, { Component } from 'react';
import AceEditor from 'react-ace';
import './MultiLanguageCodeEditorView.css';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-csharp';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/mode-typescript';
import 'ace-builds/src-noconflict/mode-golang';
import 'ace-builds/src-noconflict/mode-mysql';
import 'ace-builds/src-noconflict/mode-ruby';
import 'ace-builds/src-noconflict/mode-sass';
import 'ace-builds/src-noconflict/mode-xml';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-handlebars';
import 'ace-builds/src-noconflict/mode-elixir';
import 'ace-builds/src-noconflict/mode-c_cpp';
import 'ace-builds/src-noconflict/mode-php';
import 'ace-builds/src-noconflict/mode-perl';
import 'ace-builds/src-noconflict/mode-r';
import 'ace-builds/src-noconflict/mode-scala';
import 'ace-builds/src-noconflict/mode-plain_text';
import 'ace-builds/src-noconflict/theme-crimson_editor';
import 'ace-builds/src-noconflict/ext-language_tools';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import GmailLogo from '../../AvailableLogos/logo-gmail.png';
import OutlookLogo from '../../AvailableLogos/Microsoft-Outlook-logo.jpg';
import {
  defaultCompileOutputMessage,
  formattingForProgrammingLanguages,
  languagesToBeShownInDropdown,
  validFolderNameRegularExpression,
} from '../../configFiles/config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Loader from '../Loader';

import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';

class MultiLanguageCodeEditorView extends Component {
  constructor (props, context) {
    super(props, context);
    this.onChange = this.onChange.bind(this);
    const { languageSelected, modeSelectedInEditor } = this.props;
    this.state = {
      languageSelected: modeSelectedInEditor,
      languageDropDownValueSelected: languageSelected,
      openDownloadDialogModal: false,
      openDialogModalForEmailSharing: false,
      fileName: '',
    };
  }

  onChange = e => {
    let newValueForLanguageDropdown = e.target.value;
    let newValueForLanguage = newValueForLanguageDropdown;
    if (newValueForLanguage === 'c' || newValueForLanguage === 'c++' ) {
      newValueForLanguage = 'c_cpp';
    }
    else if (newValueForLanguage === 'java'){
      toast.info(`The class name should be Main`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false,
        closeOnClick: true,
      });
    }

    this.setState({
      languageSelected: newValueForLanguage,
      languageDropDownValueSelected: newValueForLanguageDropdown,
    });
    const { changeCurrentContentOfEditor } = this.props;
    changeCurrentContentOfEditor('CODE_EDITOR', '', newValueForLanguageDropdown);
  }
  changeCodeForLanguage = newValue => {
    const { changeCurrentContentOfEditor, codeEditorSelectedLanguage } = this.props;
    changeCurrentContentOfEditor('CODE_EDITOR',newValue,codeEditorSelectedLanguage);

  };

  handleOpenDownloadDialog = () => {
    this.setState({
      openDownloadDialogModal: true,
    });
  };

  handleCloseDownloadDialog = () => {
    this.setState({
      openDownloadDialogModal: false,
      fileName: '',
    });
  };

  handleOpenEmailSendDialog = () => {
    this.setState({
      openDialogModalForEmailSharing: true,
    });
  };

  handleCloseEmailSendDialog = () => {
    this.setState({
      openDialogModalForEmailSharing: false,
    });
  };

  handleFileNameInput = (evt) => {
    this.setState({
      fileName: evt.target.value,
    });
  
  };

  handleDownLoadEditorContent = () => {
    const { codeEditorContent }  = this.props;
    const { fileName } = this.state;

    const element = document.createElement('a');
    const file = new Blob([codeEditorContent], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = `${fileName}.doc`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    this.setState({
      fileName: '',
    });

  };

  getCompileAgentForCodeCompileSandbox = () => {
    const {
      getCompileAgentUrl,
    } = this.props;

    getCompileAgentUrl();
  };

  toggleOutputVisiblity = () => {
    const {
      toggleCompiledOutputVisibility,
      isCompiledOutputVisible,
    } = this.props;

    toggleCompiledOutputVisibility(!isCompiledOutputVisible);
  };

  render () {
    const {
      languageSelected,
      languageDropDownValueSelected,
      openDownloadDialogModal,
      openDialogModalForEmailSharing,
      fileName,
    } = this.state;

    const {
      shouldShowDownloadAndShareOptions,
      showCompileButton,
      codeEditorContent,
      isFetchingCompileAgent,
      isAwaitingCompileAgentResponse,
      isCompiledOutputVisible,
      compiledOutput,
      doesCompiledOutputContainAnError,
    } = this.props;

    const gmailLinkForsharing = `https://mail.google.com/mail/?view=cm&fs=1&to=someone@example.com&su=Editor Content &body=${codeEditorContent}`;
    const outlookLinkForSharing = `https://outlook.live.com/?path=/mail/action/compose&to=someone@example.com&subject=Editor Content&body=${codeEditorContent}`;
    const styleDecideForDownloadButton = (fileName.length === 0 || fileName.match(validFolderNameRegularExpression)
      ?
      'buttonDownloadEditorContent button-disabled'
      :
      'buttonDownloadEditorContent downloadButton')
      ;

    return (
      <div>
        <div className='codeEditor-outer-div'>
          {
            isFetchingCompileAgent ||
            isAwaitingCompileAgentResponse ?
              <Loader />
              :
              null
          }
          <div className='header'>
            {
              shouldShowDownloadAndShareOptions ?
                <div className="codeEditorDownloadAndShareButtonsContainer">
                  <Button
                    onClick={this.handleOpenDownloadDialog}
                    className='buttonForSendingAndDownloading'
                  >
                    Download Content
                  </Button>
                  <Button
                    onClick={this.handleOpenEmailSendDialog}
                    className='sendMailButtonStyle'
                    id= 'sendMailButton'
                  >
                    Share Via Mail
                  </Button>
                </div>
                :
                null
            }
            {
              showCompileButton ?
                <div
                  className="codeEditorCompileButtonContainer"
                  style={
                    (
                      !codeEditorContent ||
                      (codeEditorContent && codeEditorContent.length === 0) ||
                      codeEditorContent === ''
                    ) ?
                      {
                        cursor: 'not-allowed',
                      }
                      :
                      {
                        cursor: 'pointer',
                      }
                  }
                  title={
                    (
                      !codeEditorContent ||
                      (codeEditorContent && codeEditorContent.length === 0) ||
                      codeEditorContent === ''
                    ) ?
                      'Type code to enable'
                      :
                      'Compile your code'
                  }
                >
                  <Button
                    onClick={this.getCompileAgentForCodeCompileSandbox}
                    className={
                      (
                        !codeEditorContent ||
                        (codeEditorContent && codeEditorContent.length === 0) ||
                        codeEditorContent === ''
                      ) ?
                        'disabledButtonForSendingAndDownloading'
                        :
                        'buttonForSendingAndDownloading'
                    }
                    disabled={
                      !codeEditorContent ||
                      (codeEditorContent && codeEditorContent.length === 0) ||
                      codeEditorContent === ''
                    }
                  >
                    Compile
                  </Button>
                </div>
                :
                null
            }
          </div>

          <AceEditor
            mode = { formattingForProgrammingLanguages[languageSelected] }
            theme = 'crimson_editor'
            onChange = {this.changeCodeForLanguage}
            name = "ACE_EDITOR_FOR_MICRO_SERVICE"
            editorProps = {{
              $blockScrolling: true,
            }}
            value = { codeEditorContent }
            enableLiveAutocompletion = {true}
            setOptions = {{
              showLineNumbers: true,
              tabSize: 2,
              showPrintMargin: false,
            }}
            // height = '90%'
            height = '82.5vh'
            width = '100%'
            className = 'AceEditor_editorView'
          />
          {
            showCompileButton ?
              isCompiledOutputVisible ?
                <div>
                  <div className = "viewCompiledOutput">
                    <div
                      className='hideCompiledOutputButton'
                      onClick={this.toggleOutputVisiblity}
                    >
                      <strong id='hideOutputButton'>
                        Hide output
                      </strong>
                      <IconButton
                        style={{
                          color: 'white',
                        }}
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </div>
                    <div id='displayCompiledOutput' className={
                      doesCompiledOutputContainAnError ?
                        'compiledOutputErrorText'
                        :
                        'compiledOutputText'
                    }>
                      <pre>
                        {compiledOutput}
                      </pre>
                    </div>
                  </div>
                </div>
                :
                <div>
                  <div
                    className = "compileOutputDrawer"
                    onClick={this.toggleOutputVisiblity}
                  >
                    <div className='viewCompiledOutputButton'>
                      <strong id='viewOutputButton'>
                        View output
                      </strong>
                      <IconButton
                        style={{
                          color: 'white',
                        }}
                      >
                        <ExpandLessIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              :
              null
          }
          <FormControl
            variant = "outlined"
            className = "languageDropDown"
          >
            <InputLabel>Language</InputLabel>
            <Select
              value = {languageDropDownValueSelected}
              onChange = {this.onChange}
              label = 'Language'
              defaultValue = 'C++'
            >
              {
                Object.keys(languagesToBeShownInDropdown).map(languageKey =>
                  <MenuItem
                    value={languageKey}
                    key = {languageKey}
                    title={formattingForProgrammingLanguages[languageKey] ?
                      null :
                      'This language is not compilable'}
                    className={
                      formattingForProgrammingLanguages[languageKey] ?
                        'selectableCompilableLanguageMenuItem'
                        :
                        'selectableNotCompilableLanguageMenuItem'
                    }
                  >
                    {languagesToBeShownInDropdown[languageKey]}
                  </MenuItem>
                )
              }
            </Select>
          </FormControl>
          <Dialog
            open = { openDialogModalForEmailSharing }
            onClose = {this.handleCloseEmailSendDialog}
          >
            <DialogTitle
              className = "headerEmailEditorContent"
              style = {{ justifyContent: 'center', display: 'flex' }}
            >
              {' Send Mail '}
            </DialogTitle>
            <DialogContent
            
            >
              <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '20px' }}>
                <a href={gmailLinkForsharing}
                  target="_blank" rel="noopener noreferrer"  id = 'gmailSendOption' >
                  <img src={GmailLogo} alt='Gmail Logo' className='gmailLogo' title='Share Via Gmail'
                  >
                  </img>
                </a>
              
                
                <a href={outlookLinkForSharing}
                  className = 'anchorTagForOutlook'
                  target="_blank" rel="noopener noreferrer" id = 'outlookSendOption'>
                  <img src={OutlookLogo} alt='Gmail Logo' className='outlookLogo' title='Share Via Outlook'
                  >
                  </img>
                </a>
              </div>
            
                  
            </DialogContent>
            <DialogActions>
              <Button
                onClick = {this.handleCloseEmailSendDialog}
                color = "primary"
                className = "buttonEmailSendEditorContent emailCancelButton"
                id = 'cancelEmailSendDialogButton'
              >
              Cancel
              </Button>
              <Button
                onClick={this.handleCloseEmailSendDialog}
                color = 'primary'
                className = 'buttonEmailSendEditorContent OkButton'
              >
              OK
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open = { openDownloadDialogModal }
            onClose = {this.handleCloseDownloadDialog}
            maxWidth= 'lg'
          >
            <DialogTitle
              className = "headerDownloadEditorContent"
              style = {{ justifyContent: 'center', display: 'flex' }}
            >
              {' Download Content '}
            </DialogTitle>
            <DialogContent
              style = {{
                display: 'flex',
                color: 'black',
                justifyContent: 'center',
                fontSize: '18px',
              }}
            >
              <div style={{ display: 'flex' , flexDirection: 'row' , justifyContent: 'space-evenly', marginTop: '10px',marginBottom: '10px' }}>
                <span className='full-text' style={{ color: '#082030' }}>Enter the name for the file to be download (without extension):</span>
                <span className='short-text' style={{ color: '#082030' }}>Enter File Name</span>
                <input  value={fileName} onChange={this.handleFileNameInput} className='inputElement' id='fileNameInput'></input>
              </div>
                  
            </DialogContent>
            <DialogActions>
              <Button
                onClick = {this.handleCloseDownloadDialog}
                color = "primary"
                className = "buttonDownloadEditorContent cancelButton"
                id = 'cancelDownloadDialogButton'
              >
              Cancel
              </Button>
              <Button
                onClick={this.handleDownLoadEditorContent}
                color = 'primary'
                className = {styleDecideForDownloadButton}
                disabled={(fileName.match(validFolderNameRegularExpression))}
                id = 'downloadButton'
              >
              Download
              </Button>
            </DialogActions>
          </Dialog>

        </div>
      </div>
    );
  }
}

MultiLanguageCodeEditorView.propTypes = {
  languageSelected: PropTypes.string.isRequired,
  modeSelectedInEditor: PropTypes.string.isRequired,
  changeCurrentContentOfEditor: PropTypes.func.isRequired,
  codeEditorSelectedLanguage: PropTypes.string.isRequired,
  codeEditorContent: PropTypes.string.isRequired,
  isFetchingCompileAgent: PropTypes.bool,
  isAwaitingCompileAgentResponse: PropTypes.bool,
  getCompileAgentUrl: PropTypes.func,
  shouldShowDownloadAndShareOptions: PropTypes.bool,
  showCompileButton: PropTypes.bool,
  isCompiledOutputVisible: PropTypes.bool,
  compiledOutput: PropTypes.string,
  doesCompiledOutputContainAnError: PropTypes.bool,
  toggleCompiledOutputVisibility: PropTypes.func,
};

MultiLanguageCodeEditorView.defaultProps = {
  languageSelected: 'c++',
  modeSelectedInEditor: 'c_cpp',
  changeCurrentContentOfEditor: () => {
  },
  codeEditorSelectedLanguage: 'c++',
  codeEditorContent: '',
  isFetchingCompileAgent: false,
  isAwaitingCompileAgentResponse: false,
  shouldShowDownloadAndShareOptions: false,
  showCompileButton: false,
  isCompiledOutputVisible: false,
  compiledOutput: defaultCompileOutputMessage,
  doesCompiledOutputContainAnError: false,
  toggleCompiledOutputVisibility: () => {},
};

export default MultiLanguageCodeEditorView;
