import {
  createInitialBundle,
  rebundleBundle,
  setDefaultValueOnComponentUnmount,
} from '../../redux/bundle/actions';

export const mapStateToProps = state => ({
  compiledHTMLCode: state.code.compiledHTMLCode,
  bundleFile: state.bundle.bundleString,
  bundleCreationError: state.bundle.bundleCreationError,
  isFileSystemEnabled: state.sandbox.isFileSystemEnabled,
  resolvedFilesForSandbox: state.sandbox.resolvedFilesForSandbox,
  frameworkId: state.sandbox.frameworkId,
  reactBundleString: state.bundle.bundleStringForReactScript,
  reactStyleString: state.bundle.bundleCssStringInReactFiles,
});

export const mapDispatchToProps = dispatch => ({
  initializeBundle: (frameworkId) => {
    dispatch(createInitialBundle(frameworkId));
  },
  rebundle: () => {
    dispatch(rebundleBundle());
  },
  setDefaultValueOnUnmount: () => {
    dispatch(setDefaultValueOnComponentUnmount());
  },
});
