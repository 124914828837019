import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
// import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
// import Typography from '@material-ui/core/Typography';
// import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';
// import ReplayIcon from '@material-ui/icons/Replay';
// import ResetToDefaultCode from '../ResetToDefaultCode';
// import ForkCode from '../ForkCode/ForkCode';
// import ForkedCodeView from '../ForkedCodeView/ForkedCodeView';
// import ThemeButton from '../ThemeButton/ThemeButton';
// import CallSplitIcon from '@material-ui/icons/CallSplit';
// import SaveButton from '../SaveButton/SaveButton';
// import SaveIcon from '@material-ui/icons/Save';
// import Brightness4Icon from '@material-ui/icons/Brightness4';
// import ArchiveIcon from '@material-ui/icons/Archive';
// import './SideNav.css';
// import BackButtonForHome from '../BackButtonForHome';
// import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
// import ChangeIndentationView from '../ChangeIndentationView';
// import DescriptionIcon from '@material-ui/icons/Description';
// import FileCopyIcon from '@material-ui/icons/FileCopy';
import FileStructureView from '../FileStructureView';
import { GoFileDirectory } from 'react-icons/go';

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // height: '65px',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 25,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: '#2b2a2a',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(5) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
    background: '#2b2a2a',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(2,1),
    backgroundColor: 'var(--primaryColor)',
    color: 'white',
    cursor: 'pointer',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const FileExplorerDrawer  = ({ changeSelectedFileOnFileOpen, isNotEditable, selectedFile }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [onButtonClick, setButtonClick] = React.useState(false);

  //   const openDrawerOnButtonClick = () => {
  //     setButtonClick(true);
  //     setOpen(true);
  //   };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    if (open && !onButtonClick) {
      setOpen(false);
    }
    setButtonClick(false);
  };


  return (
    <div className={classes.root}>
      <CssBaseline />
      <ClickAwayListener onClickAway={handleDrawerClose} >
        <Drawer
          // onMouseEnter={handleDrawerOpen}
          // onMouseLeave={handleDrawerClose}
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          open={open}
          anchor="right"
        >
          {/* <div
            className={classes.toolbar}
            onClick={handleDrawerClose}
            title="Click to close"
          >
            <IconButton>
              {theme.direction === 'ltr' ? <ChevronLeftIcon style={{ color: 'white' }} /> : <ChevronRightIcon style={{ color: 'white' }} /> }
            </IconButton>
          </div> */}

          {/* <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
           </div> */}
          <div
            className={classes.toolbar}
            onClick={handleDrawerClose}
            title="Click to close"
          >
            <IconButton style={{ marginRight: '85%' }}>
              {/* {theme.direction === 'rtl' ? <ChevronRightIcon style={{ color: 'white' }} /> : <ChevronLeftIcon style={{ color: 'white' }} />} */}
              {theme.direction === 'rtl' ? <ChevronLeftIcon style={{ color: 'white' }}/> : <ChevronRightIcon style={{ color: 'white' }}/>}
            </IconButton>
          </div>

          <ListItem button onClick={handleDrawerOpen}
            title="Explore Files"
            // style = {{ borderBottom: '1px white solid' }}
          >
            <ListItemIcon className="MuiIcon-fontSizeInherit" >
              {/* <FileCopyIcon
                style = {{ color: 'white' }}
              /> */}
              <GoFileDirectory
                style = {{ color: 'white' }}
                size={23}
              />
              <FileStructureView
                changeSelectedFileOnFileOpen = {changeSelectedFileOnFileOpen}
                calledFromFileExplorer = {true}
                isNotEditable={isNotEditable}
                selectedFile = {selectedFile}
              />
            </ListItemIcon>
          </ListItem>
          
        </Drawer>
      </ClickAwayListener>
    </div>
  );
};

FileExplorerDrawer.propTypes = {
  changeSelectedFileOnFileOpen: PropTypes.func,
  isNotEditable: PropTypes.bool,
  selectedFile: PropTypes.string,
};

FileExplorerDrawer.defaultProps = {
  changeSelectedFileOnFileOpen: () => {

  },
};

export default FileExplorerDrawer;
