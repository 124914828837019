import React, { Component } from 'react';
import './AutoCompleteToggleView.css';
import PropTypes from 'prop-types';

class AutoCompleteToggleView extends Component {
  constructor (props, context) {
    super(props, context);
    this.toggleAutoComplete = this.toggleAutoComplete.bind(this);
  }
    
    toggleAutoComplete = (toggleAutoCompleteValue) => {
      const {
        toggleEditorAutoComplete,
      } = this.props;
      toggleEditorAutoComplete(toggleAutoCompleteValue);
    }

    render () {
      const { isAutoCompleteEnabled, isAutoCompleteDisabled } = this.props;
      return (
        <div className='themeButtonAutocompleteToggleView'>
          <div className='innerButtonAutocompleteToggleView'>
            <label className={!isAutoCompleteDisabled ? 'switchAutoCompleteToggleView' : 'switchAutoCompleteToggleViewDisabled'}>
              <input
                className="switchInputAutocompleteToggleView"
                onChange={!isAutoCompleteDisabled ? (() => this.toggleAutoComplete( (isAutoCompleteEnabled === true) ? false : true )) : null}
                type="checkbox"
                checked={!isAutoCompleteDisabled ? isAutoCompleteEnabled : false}
              />
              <span className="switchLabelAutocompleteToggleView" data-on="On" data-off="off"></span>
              <span className="switchHandleAutocompleteToggleView"></span>
            </label>
          </div>
                  
        </div>
      );
    }
}

AutoCompleteToggleView.propTypes = {
  isAutoCompleteEnabled: PropTypes.bool.isRequired,
  toggleEditorAutoComplete: PropTypes.func.isRequired,
  isAutoCompleteDisabled: PropTypes.bool.isRequired,
};

AutoCompleteToggleView.defaultProps = {
  isAutoCompleteEnabled: true,
  toggleEditorAutoComplete: () => {

  },
};

export default AutoCompleteToggleView;