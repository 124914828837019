import { toast } from 'react-toastify';
import {
  externalRequestGet,
  post,
} from '../../utils/fetchRequests';
import { backendUrl, timeGapForCheckingCompileAgentResponse, totalTimeForCheckingCompileAgentResponse } from '../../configFiles/config';
import {
  SAVE_EXTERNAL_CLIENT_DETAILS,
  UPDATE_EXTERNAL_EDITOR_CONTENT,
  UPDATE_CURRENT_EDITOR_CONTENT,
  UPDATE_WINDOW_TYPE_FOR_EDITOR_MICRO_SERVICE,
  SET_DOWNLOAD_AND_SHARE_BUTTONS_VISIBILITY,
  GET_COMPILE_AGENT_FOR_SANDBOX,
  COMPILE_CODE_USING_COMPILE_AGENT,
  UPDATE_COMPILE_OUTPUT_REJECTED,
  UPDATE_COMPILE_OUTPUT_FULFILLED,
  TOGGLE_COMPILE_OUTPUT_DRAWER,
  SET_EDITOR_PURPOSE_ID,
  UPDATE_COMPILE_OUTPUT_OF_CODESHARE_FULFILLED,
  TOGGLE_COMPILE_OUTPUT_DRAWER_IN_CODE_SHARE,
} from './reducers';
import { showErrorToast } from '../../utils/toasts/showToasts';

export const saveClientDetails = (clientUrl, serviceType, clientDetails) => (dispatch) => {
  dispatch({
    type: SAVE_EXTERNAL_CLIENT_DETAILS,
    payload: post(
      `${backendUrl}/api/v1/service/store/client`,
      {
        clientUrl,
        serviceType,
        clientDetails,
      },
    ),
  })
    .catch(() => {
      toast.error('External client details are not saved. Please try again', {
        toastId: 'TOAST_CONTAINER_FOR_SAVING_CLIENT_DETAILS',
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    });
};

export const updateEditorContent = (purposeId, clientDetails, editorType, windowType, content) => (dispatch) => {
  dispatch({
    type: UPDATE_EXTERNAL_EDITOR_CONTENT,
    payload: post(
      `${backendUrl}/api/v1/service/update/editor`,
      {
        purposeId,
        clientDetails,
        editorType,
        windowType,
        content,
      },
    ),
  })
    .catch(() => {
      toast.error('External editor content is not updated. Please try again', {
        toastId: 'TOAST_CONTAINER_FOR_UPDATING_EDITOR_CONTENT',
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    });
};

export const changeCurrentContentOfEditor = (editor, content, language) => (dispatch) => {
  dispatch({
    type: UPDATE_CURRENT_EDITOR_CONTENT,
    payload: {
      data: {
        editor,
        content,
        language,
      },
    },
  });
};

export const updateWindowType = (windowType) => (dispatch) => {
  dispatch({
    type: UPDATE_WINDOW_TYPE_FOR_EDITOR_MICRO_SERVICE,
    payload: {
      data: {
        windowType,
      },
    },
  });
};

export const setDownloadAndShareButtonVisibility = (editorType, shouldShowButtons) => (dispatch) => {
  dispatch({
    type: SET_DOWNLOAD_AND_SHARE_BUTTONS_VISIBILITY,
    payload: {
      data: {
        editorType,
        shouldShowButtons,
      },
    },
  });
};

const getExternalAgentCompileResponse = (
  attempt,
  totalAttempts,
  timeGapForCheckingCompileAgentResponse,
  compileAgent,
  isCalledFromCodeShareEditor,
  dispatch,
) => {
  externalRequestGet(compileAgent)
    .then((response) => {
      if (isCalledFromCodeShareEditor){
        dispatch({
          type: UPDATE_COMPILE_OUTPUT_OF_CODESHARE_FULFILLED,
          payload: {
            data: response,
          },
        });
      } else {
        dispatch({
          type: UPDATE_COMPILE_OUTPUT_FULFILLED,
          payload: {
            data: response,
          },
        });
      }
      
    })
    .catch(() => {
      const attemptedCount = attempt + 1;
      if (attemptedCount <= totalAttempts) {
        setTimeout(() => {
          getExternalAgentCompileResponse(
            attemptedCount,
            totalAttempts,
            timeGapForCheckingCompileAgentResponse,
            compileAgent,
            isCalledFromCodeShareEditor,
            dispatch
          );
        }, timeGapForCheckingCompileAgentResponse);
      } else {
        dispatch({
          type: UPDATE_COMPILE_OUTPUT_REJECTED,
        });
      }
    });
};

export const compileCodeUsingAgent = (isCalledFromCodeShareEditor = false, meetingId, participantToken) => (dispatch, getState) => {
  const { token } = getState().auth;
  const {
    editorContent,
    purposeId,
    clientId,
  } = getState().microService;

  let purposeIdToBeSent = '';
  let clientIdToBeSent = '';

  if (isCalledFromCodeShareEditor) {
    purposeIdToBeSent = meetingId;
    clientIdToBeSent = participantToken;
  } else {
    purposeIdToBeSent = purposeId;
    clientIdToBeSent = clientId;
  }

  if (Object.keys(editorContent).length > 0) {
    const {
      CODE_EDITOR,
      SHARE_CODE_EDITOR,
    } = editorContent;

    if (Object.keys(CODE_EDITOR).length > 0 || Object.keys(SHARE_CODE_EDITOR).length > 0 ) {
      // const {
      //   content,
      //   language,
      // } = CODE_EDITOR;
      let contentToBeSent = '';
      let languageSelected = '';
      // console.log('::::::iscalled from code share', isCalledFromCodeShareEditor);
      if (isCalledFromCodeShareEditor){
        const {
          content,
          language,
        } = SHARE_CODE_EDITOR;
        contentToBeSent = content;
        languageSelected = language;
      } else {
        const {
          content,
          language,
        } = CODE_EDITOR;
        contentToBeSent = content;
        languageSelected = language;
      }

      let requestUrl = `${backendUrl}/api/v1/code/compile/anonymousAgent`;

      if (token) {
        requestUrl = `${backendUrl}/api/v1/code/compile/externalAgent`;
      }

      dispatch({
        type: GET_COMPILE_AGENT_FOR_SANDBOX,
        payload: post(
          requestUrl,
          {
            code: contentToBeSent,
            language: languageSelected,
            purposeId: purposeIdToBeSent,
            clientId: clientIdToBeSent,
          },
          {
            authorization: token,
          },
        ),
      })
        .then(async ({ action: { payload: { data } } }) => {
          const {
            compileAgent,
          } = data;

          if (!compileAgent) {
            showErrorToast('Could not compile. Please try again');
          } else {
            //  Check the agent for a response.
            dispatch({
              type: COMPILE_CODE_USING_COMPILE_AGENT,
            });
            const totalAttempts = Math.ceil(totalTimeForCheckingCompileAgentResponse / timeGapForCheckingCompileAgentResponse);
            let attempt = 1;
            getExternalAgentCompileResponse(attempt, totalAttempts,
              timeGapForCheckingCompileAgentResponse, compileAgent,
              isCalledFromCodeShareEditor, dispatch);
          }
        });
    }
  }
};

export const toggleCompiledOutputDrawerVisibility = (isCompiledOutputVisible) => (dispatch) => {
  dispatch({
    type: TOGGLE_COMPILE_OUTPUT_DRAWER,
    payload: {
      data: {
        isCompiledOutputVisible,
      },
    },
  });
};

export const toggleCompiledOutputDrawerVisibilityInCodeShare = (isCompiledOutputVisibleInCodeShare) => (dispatch) => {
  dispatch({
    type: TOGGLE_COMPILE_OUTPUT_DRAWER_IN_CODE_SHARE,
    payload: {
      data: {
        isCompiledOutputVisibleInCodeShare,
      },
    },
  });
};

export const setEditorPurposeId = (purposeId, clientId) => (dispatch) => {
  dispatch({
    type: SET_EDITOR_PURPOSE_ID,
    payload: {
      data: {
        purposeId,
        clientId,
      },
    },
  });
};
