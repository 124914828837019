import React, { Component } from 'react';
// import { connect } from 'react-redux';
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import PropTypes from 'prop-types';
// import { changeEditorIndentation } from '../../redux/editor/actions';
import { availableIndentation } from '../../configFiles/config';

class ChangeIndentationView extends Component {
  constructor (props, context) {
    super(props, context);
    this.onChange = this.onChange.bind(this);
  }
    
  onChange = event => {
    const valueForIndentation = event.target.value;
      
    const { changeEditorIndentation } = this.props;
    changeEditorIndentation(valueForIndentation);
  }

  render () {
    const { indentationForEditors } = this.props;
    return (
      <>
        <FormControl
          variant="outlined"
        >
          <InputLabel
            id="demo-simple-select-outlined-label"
          >
            Spaces
          </InputLabel>
          <Select
            style={{ width: '19vh', marginBottom: '2px', height: '5vh' }}
            menustyle={{ width: '20vw' }}
            label="Spaces"
            value={indentationForEditors}
            onChange = {this.onChange}
          >
            {
              availableIndentation.map((space) => {
                return (
                  <MenuItem value = {space} data-testid = {space} key = {space}> {space} </MenuItem>
                );
              })
            }
            {/* <MenuItem value = {1}>1</MenuItem>
            <MenuItem value = {2}>2</MenuItem>
            <MenuItem value = {4}>4</MenuItem>
            <MenuItem value = {6}>6</MenuItem>
            <MenuItem value = {8}>8</MenuItem> */}
          </Select>
        </FormControl>
      </>
    );
  }
}

ChangeIndentationView.propTypes = {
  indentationForEditors: PropTypes.number.isRequired,
  changeEditorIndentation: PropTypes.func.isRequired,
};
  
ChangeIndentationView.defaultProps = {
  indentationForEditors: 2,
  changeEditorIndentation: () => {

  },
};

// const mapStateToProps = state => ({
//     indentationForEditors: state.editor.indentationForEditors,
//   });
  
//   const mapDispatchToProps = dispatch => ({
//     changeEditorIndentation: (spaces) => {
//       dispatch(changeEditorIndentation(spaces));
//     },
//   });
  
export default ChangeIndentationView;