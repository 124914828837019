import {
  fetchForkedCode,
  fetchCurrentCodeAfterForkCodeView,
  setOpen,
  setButtonClick,
} from '../../redux/code/actions';

export const mapStateToProps = state => ({
  forkedCode: state.code.forkedCode,
  copyOfCurrentCodeWhileShowingForkedCode: state.code.copyOfCurrentCodeWhileShowingForkedCode,
  isEditorShowingCurrentCode: state.code.isEditorShowingCurrentCode,
  frameworkId: state.sandbox.frameworkId,
});

export const mapDispatchToProps = dispatch => ({
  fetchForkedCode: (forkId, frameworkId) => {
    dispatch(fetchForkedCode(forkId, frameworkId));
  },
  fetchCurrentCodeAfterForkCodeView: (frameworkId) => {
    dispatch(fetchCurrentCodeAfterForkCodeView(frameworkId));
  },
  setOpen: (value) => {
    dispatch(setOpen(value));
  },
  setButtonClick: (value) => {
    dispatch(setButtonClick(value));
  },
});