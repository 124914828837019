import { saveCurrentCode, setIsComponentUnmountingForNormal } from '../../redux/code/actions';
import { saveSandboxOpenedFile, setIsComponentUnmounting } from '../../redux/sandbox/actions';

export const mapStateToProps = state => ({
  editorTheme: state.editor.selectedTheme,
  currentCode: state.code.currentCode,
  frameworkId: state.sandbox.frameworkId,
  isAutoCompleteEnabled: state.editor.isAutoCompleteEnabled,
  indentationForEditors: state.editor.indentationForEditors,
  isFileSystemEnabled: state.sandbox.isFileSystemEnabled,
});
export const mapDispatchToProps = dispatch => ({
  saveCode: (theme, code, frameworkId, indentationForEditors, isAutoCompleteEnabled) => {
    dispatch(saveCurrentCode(theme, code, frameworkId, indentationForEditors, isAutoCompleteEnabled));
  },
  saveFileSystemCode: (currentlySelectedFile, isCalledFromComponentWillUnmount) => {
    dispatch(saveSandboxOpenedFile(currentlySelectedFile, isCalledFromComponentWillUnmount));
  },
  setIsComponentUnmounting: () => {
    dispatch(setIsComponentUnmounting());
  },
  setIsComponentUnmountingForNormal: () => {
    dispatch(setIsComponentUnmountingForNormal());
  },
});