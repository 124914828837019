export const CHANGE_LANGUAGE = 'codeShare/CHANGE_LANGUAGE';
export const VALIDATE_USER_FOR_CODESHARE = 'codeShare/VALIDATE_USER_FOR_CODESHARE';
export const VALIDATE_USER_FOR_CODESHARE_PENDING = 'codeShare/VALIDATE_USER_FOR_CODESHARE_PENDING';
export const VALIDATE_USER_FOR_CODESHARE_FULFILLED = 'codeShare/VALIDATE_USER_FOR_CODESHARE_FULFILLED';
export const VALIDATE_USER_FOR_CODESHARE_REJECTED = 'codeShare/VALIDATE_USER_FOR_CODESHARE_REJECTED';

const defaultState = {
  languageSelected: 'c++',
  isValidatingUserForCodeShare: false,
  codeShareToken: undefined,
};

const codeShare = (state = defaultState, action) => {
  switch (action.type) {
    
  case CHANGE_LANGUAGE: {
    const { data } = action.payload;
    const { language } = data;
    
    return {
      ...state,
      languageSelected: language,
    };
  }

  case VALIDATE_USER_FOR_CODESHARE: {
    return {
      ...state,
      isValidatingUserForCodeShare: true,
    };
  }

  case VALIDATE_USER_FOR_CODESHARE_PENDING: {
    return {
      ...state,
      isValidatingUserForCodeShare: true,
    };
  }

  case VALIDATE_USER_FOR_CODESHARE_FULFILLED: {
    const { data } = action.payload;

    if (!data) {
      return {
        ...state,
        isValidatingUserForCodeShare: false,
      };
    }

    const { codeShareToken } = data;

    if (!codeShareToken) {
      return {
        ...state,
        isValidatingUserForCodeShare: false,
      };
    }

    return {
      ...state,
      isValidatingUserForCodeShare: false,
      codeShareToken,
    };
  }

  case VALIDATE_USER_FOR_CODESHARE_REJECTED: {
    return {
      ...state,
      isValidatingUserForCodeShare: false,
    };
  }

  default:
    return state;
  }
};

export default codeShare;
