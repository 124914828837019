import { changeCurrentContentOfEditor } from '../../redux/microService/actions';

export const mapStateToProps = state => ({
  markdownEditorContent: state.microService.editorContent.MARKDOWN.content,
});
  
export const mapDispatchToProps = dispatch => ({
  changeCurrentContentOfEditor: (editor,content,language) => {
    dispatch(changeCurrentContentOfEditor(editor,content,language));
  },
});