const createHTMLBundleDocument = (htmlString) => {
  // Create the HTML bundle document.
  const bundleDocument = document.implementation.createHTMLDocument('HTML bundle');

  // Set the HTML string as the bundle document's content.
  bundleDocument.documentElement.innerHTML = htmlString;
  return bundleDocument;
};

export default (newBundleString, changedFileDescriptor, filesInSandbox) => {
  return new Promise((resolve, reject) => {
    try {
      // const newBundleString = bundleDocument.documentElement.innerHTML;

      const newBundle = createHTMLBundleDocument(newBundleString);

      const updatedFile = filesInSandbox[changedFileDescriptor];

      if (!updatedFile) {
        reject({
          data: {
            errorMessage: `File not found for fileDescriptor : ${changedFileDescriptor}`,
          },
        });
      }
      
      const updatedContent = updatedFile.content;
      const pathToFile = updatedFile.path;
      const fileName = updatedFile.name;
      
      const pathOfFile = `${pathToFile}${fileName}`;

      const bundleElementToUpdate = newBundle.getElementById(pathOfFile);

      if (!bundleElementToUpdate) {
        resolve({
          data: {
            bundle: newBundleString,
            bundleDocument: newBundle,
          },
        });
      }

      bundleElementToUpdate.innerHTML = updatedContent;

      const updatedBundleString = newBundle.documentElement.innerHTML;
      resolve({
        data: {
          bundle: updatedBundleString,
          bundleDocument: newBundle,
        },
      });
    } catch (ex) {
      reject({
        data: {
          errorMessage: ex,
        },
      });
    }
  });
};
