export const frontEndUrl = 'https://codeframe.myanatomy.in';

export const backendUrl = 'https://codeframe.myanatomy.in';

export const lightTheme = 'github';
export const darkTheme = 'monokai';
export const TOTAl_NUMBER_OF_FORKS_AVAILABLE = 3;

export const availableIndentation = [1,2,4,6,8];

export const defaultIndenation = 2;

export const rootLevelPathIndentifier = 'root';

export const initialPathForFileStructure = `/${rootLevelPathIndentifier}/`;

export const baseFileName = `index.html`;

export const baseFileNameForReactFileSystemSandbox = 'index.js';

export const baseFilePath = `/${rootLevelPathIndentifier}/${baseFileName}`;

export const baseFilePathInReactFileSystemSandBox = `/${rootLevelPathIndentifier}/${baseFileNameForReactFileSystemSandbox}`;

export const validExtensionForFiles = {
  // 'html': true,
  // 'css': true,
  // 'js': true,
  // 'jsx': true,
  HTML: {
    'html': 'html',
    'css': 'css',
    'js': 'js',
  },
  REACT_JS: {
    'css': 'css',
    'js': 'js',
  },
};

export const blobTypesForExtension = {
  'html': 'text/html',
  'css': 'text/plain',
  'js': 'text/javascript',
  'jsx': 'text/jsx',
};

export const Available_Languages_In_Editor = {
  c: 'c_cpp',
  'c++': 'c_cpp',
  java: 'java',
  javascript: 'javascript',
  python: 'python',
  python3: 'python',
  ruby: 'ruby',
  php: 'php',
  html: 'html',
  css: 'css',
  plain_text: 'plain_text',
  typescript: 'typescript',
  sass: 'sass',
  elixir: 'elixir',
  handlebars: 'handlebars',
  r: 'r',
  csharp: 'csharp',
  perl: 'perl',
  mysql: 'mysql',
  json: 'json',
  golang: 'golang',
  scala: 'scala',
  swift: 'swift',
};

export const validMicroServiceEditorTypes = ['CODE_EDITOR', 'MARKDOWN', 'CODE_FRAME', 'SCRIBBLE'];

export const tinyMceAPIKey = 'btx4uecj59zydw9vadls5aif2ndsknmrf3pdlqudxgbza9hm';

export const extensionMap = {
  js: 'javascript',
  html: 'html',
  css: 'css',
};

// Valid folder name can contain a-z, A-Z, 0-9, -, _ .
export const validFolderNameRegularExpression = /[^a-zA-Z0-9-_]/;

// Valid file name can contain a-z, A-Z, 0-9, -, ., _, .
export const validFileNameRegularExpression = /[^a-zA-Z0-9-_.]/;

export const fullNameWithTheirAbbreviation = {
  JAVASCRIPT: 'JS' ,
  ANGULAR: 'ANG',
};

export const sandboxUrl = `${frontEndUrl}/sandbox`;
export const viewSandboxUrl = `${frontEndUrl}/view/sandbox`;

export const formattingForProgrammingLanguages = {
  'java': 'java',
  'c++': 'c_cpp',
  'c': 'c_cpp',
  'python': 'python',
  'python3': 'python',
  'ruby': 'ruby',
  'php': 'php',
  'javascript': 'javascript',
  'csharp': 'csharp',
  'r': 'r',
  'scala': 'scala',
  'vb': 'plain_text',
  'swift': 'plain_text',
  'perl': 'perl',
};

export const allowedCompilableLanguages = {
  'JAVA': 'java',
  'C': 'c_cpp',
  'CPP': 'c_cpp',
  'PYTHON': 'python',
  'PYTHON3': 'python',
  'RUBY': 'ruby',
  'PHP': 'php',
  'JAVASCRIPT': 'javascript',
  'CSHARP': 'csharp',
  'R': 'r',
  'SCALA': 'scala',
  'VB': 'vb',
  'PERL': 'perl',
  'SWIFT': 'swift',
};

export const serviceEditorTypeKeyForCodeCompileSandbox = 'COMPILE';

export const windowTypeForCodeCompileSandbox = 'WHITE_BOARD';

// Total time should be 40 seconds.
export const totalTimeForCheckingCompileAgentResponse = 50000;

export const timeGapForCheckingCompileAgentResponse = 5000;

export const languagesToBeShownInDropdown = {
  'c++': 'C++',
  'c': 'C',
  'java': 'Java',
  'javascript': 'Javascript',
  'python': 'Python',
  'python3': 'Python3',
  'plain_text': 'Plain Text',
  'r': 'R',
  'csharp': 'C#',
  'html': 'HTML',
  'css': 'CSS',
  'mysql': 'MySQL',
  'scala': 'Scala',
  'php': 'PHP',
  'perl': 'Perl',
  'ruby': 'Ruby',
  'sass': 'Sass',
  'swift': 'SWIFT',
  'json': 'JSON',
  'handlebars': 'Handlebars',
  'golang': 'Golang',
  'elixir': 'Elixir',
  'typescript': 'Typescript',
  'vb': ' VB ',
};

export const windowTypeSelectionDropdown = {
  code: 'Code Editor',
  markdown: 'Markdown Editor',
  codeframeHtml: 'HTML CodeFrame Sandbox',
  codeframeReact: 'React CodeFrame Sandbox',
  codeframeAngular: 'AngularJS CodeFrame Sandbox',
  paint: 'Flow Diagram',
};

export const defaultCompileOutputMessage = 'Please compile to view the output of your code.';


export const basicHtmlStructure = `
<HEAD>
    <TITLE>My first HTML document</TITLE>
  </HEAD>
  <BODY>
  </BODY>
</HTML>

`;

export const extraParametersForStringifyObject = {
  indent: '  ',
  singleQuotes: false,
  // inlineCharacterLimit: 12
};


export const SOCKET_SAVE_INTERNAL = 2000;
export const PAUSE_SAVE_TIMEOUT = 10000;


export const codeshareBackendUrl = 'https://codeshare.myanatomy.in';
export const clientUrlForCodeShareSavingCompiledCode = 'https://interview.myanatomy.in';

export const myanatomyHomePageUrl = 'https://myanatomy.in/#/home';
