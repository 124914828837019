import {
  allowedCompilableLanguages,
  backendUrl,
  darkTheme,
  lightTheme,
  serviceEditorTypeKeyForCodeCompileSandbox,
  windowTypeForCodeCompileSandbox,
} from '../../configFiles/config';

import {
  deleteCall,
  get,
  // get,
  post,
} from '../../utils/fetchRequests';

import {
  changeCurrentContentOfEditor,
  setDownloadAndShareButtonVisibility,
} from '../microService/actions';

import {
  CREATE_SANDBOX,
  GET_SESSION_FOR_SANDBOX,
  GET_READ_ONLY_SANDBOX,
  START_PRIVATE_SANDBOX,
  FETCH_FORKS_FOR_SANDBOX,
  CLOSE_FILE,
  OPEN_FILE,
  CHANGE_CURRENT_PATH_OF_FILE,
  CHANGE_PATH_AND_DESCRIPTOR_ID_OF_FILE_TO_OPEN,
  ADD_DESCRIPTOR_OF_THE_SELECTED_FILE,
  DELETE_DESCRIPTOR_OF_THE_SAVED_FILE,
  GET_ALL_FILES_OF_SANDBOX,
  UPDATE_CURRENT_CODE,
  CREATE_NEW_FILE_OR_FOLDER,
  UPDATE_FILE_CONTENT,
  DELETE_FILE_OF_SANDBOX,
  FETCH_DEPENDENT_PACKAGES,
  GET_CODE_COMPILE_SANDBOX_URL,
  SET_FRAMEWORK_ID_AND_DEPENDENCIES,
  SET_DEFAULT_VALUES_ON_UNMOUNT,
  SET_IS_COMPONENT_UNMOUNTING,
} from './reducers';

import {
  setSessionToken,
} from '../auth/actions';
import { toast } from 'react-toastify';

import {
  changeEditorTheme,
  changeEditorIndentation,
  toggleEditorAutoComplete,
  updateAutoCompleteToggleDisableFlag,
} from '../editor/actions';
import {
  setCodeForFramework,
  setForks,
} from '../code/actions';

import {
  rebundleBundle,
} from '../bundle/actions';

export const createSandbox = (frameworkId, history, withFileSystem = false) => (dispatch) => {
  dispatch({
    type: CREATE_SANDBOX,
    payload: post(
      `${backendUrl}/api/v1/sandbox/public/provision/newSandbox`,
      {
        frameworkId,
        withFileSystem,
      },
    ),
  })
    .then(({ action: { payload: { data } } }) => {
      const { token, fetchDependencies } = data;
      dispatch(setSessionToken(token));

      if (fetchDependencies) {
        dispatch(fetchSandboxDependentPackages());
      }
      const {
        sandboxId,
      } = data;
      history.push(`/sandbox/${sandboxId}`);
      if (withFileSystem) {
        const {
          totalFiles,
          filesToFetchPerBatch,
        } = data;
        dispatch(fetchFilesForSandbox(totalFiles, filesToFetchPerBatch));
      }
    })
    .catch(() => {
      toast.error('Could not create sandbox. Please try again', {
        toastId: 'TOAST_CONTAINER_FOR_SAVING_CODE',
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    });
};

export const getSessionForSandbox = (sandboxId, history) => (dispatch) => {
  dispatch({
    type: GET_SESSION_FOR_SANDBOX,
    payload: post(
      `${backendUrl}/api/v1/sandbox/public/edit/sandbox`,
      {
        sandboxId,
      },
      {

      },
    ),
  })
    .then(({ action: { payload: { data } } }) => {
      const {
        token,
        sandbox,
        shouldFetchForks,
        fetchDependencies,
      } = data;

      if (!sandbox) {
        toast.error('Sandbox not found. Please try again', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setTimeout(() => {
          history.push('/');
        }, 2000);
      }
      const {
        theme,
        code,
        frameworkId,
        autoCompleteEnabled,
        indentation,
        withFileSystem,
      } = sandbox;

      const setTheme = theme === 'LIGHT' ? lightTheme : darkTheme;
      dispatch(changeEditorTheme(setTheme));
      dispatch(setSessionToken(token));
      
      dispatch(toggleEditorAutoComplete(autoCompleteEnabled));
      dispatch(changeEditorIndentation(indentation));

      if (!withFileSystem) {
        if (fetchDependencies) {
          dispatch(fetchSandboxDependentPackages(setCodeForFramework(frameworkId, code)));
        }
        dispatch(setCodeForFramework(frameworkId, code));
        if (shouldFetchForks) {
          // Fetch forks.
          dispatch(fetchForksWithTokenProvided(token));
        }
      } else {
        // Get all the files in the sandbox.
        const {
          totalFiles,
          filesPerBatch,
        } = data;

        if (totalFiles > 0) {
          if (fetchDependencies) {
            dispatch(fetchSandboxDependentPackages());
          }
          dispatch(fetchFilesForSandbox(totalFiles, filesPerBatch));
        }
      }
    })
    .catch(() => {
      toast.error('Error while fetching the sandbox');
      setTimeout(() => {
        history.push('/');
      }, 2000);
    });
};

export const viewReadOnlySandbox = (sandboxId) => (dispatch) => {
  dispatch({
    type: GET_READ_ONLY_SANDBOX,
    payload: post(
      `${backendUrl}/api/v1/sandbox/get/sandboxData`,
      {
        sandboxId,
      },
      {

      },
    ),
  })
    .then(({ action: { payload: { data } } }) => {
      if (!data) {
        toast.error(`Could not fetch sandbox`);
        setTimeout(() => {
          window.open('/', '_self');
        }, 2000);
      }

      const {
        details,
        withFileSystem,
        fetchDependencies,
      } = data;

      if (!details) {
        toast.error(`Could not fetch sandbox`);
        window.open('/', '_self');
      }

      const {
        code,
        frameworkId,
      } = details;

      if (!code) {
        toast.error(`Could not fetch sandbox`);
        setTimeout(() => {
          window.open('/', '_self');
        }, 2000);
      }

      if (withFileSystem) {
        const {
          totalFiles,
          filesToFetchPerBatch,
        } = data;

        if (totalFiles > 0) {
          if (fetchDependencies) {
            dispatch(fetchSandboxDependentPackages());
          }
          dispatch(fetchFilesForSandbox(totalFiles, filesToFetchPerBatch, true));
        }
      } else {
        if (fetchDependencies) {
          dispatch(fetchSandboxDependentPackages(setCodeForFramework(frameworkId, code)));
        } else {
          dispatch(setCodeForFramework(frameworkId, code));
        }
      }
    })
    .catch(() => {
      toast.error(`Could not fetch sandbox`);
      setTimeout(() => {
        window.open('/', '_self');
      }, 2000);
    });
};

export const fetchPrivateSandboxUsingRedirection = (sessionId, history, sandboxId) => (dispatch) => {
  dispatch({
    type: START_PRIVATE_SANDBOX,
    payload: post(
      `${backendUrl}/api/v1/sandbox/start/session/${sessionId}?sandboxId=${sandboxId}`,
      {},
      {},
    ),
  })
    .then(({ action: { payload: { data } } }) => {
      if (!data) {
        toast.error(`Could not fetch sandbox`);
        window.open('/', '_self');
      }

      const {
        token,
        theme,
        code,
        frameworkId,
        sandboxId,
        shouldFetchForks,
        withFileSystem,
        autoCompleteEnabled,
        indentation,
        fetchDependencies,
        isAutoCompleteDisabled,
      } = data;
      dispatch(setSessionToken(token));
      // dispatch(setCodeForFramework(frameworkId, code));
      const setTheme = theme === 'LIGHT' ? lightTheme : darkTheme;
      dispatch(changeEditorTheme(setTheme));
      dispatch(toggleEditorAutoComplete(autoCompleteEnabled));
      dispatch(changeEditorIndentation(indentation));
      dispatch(updateAutoCompleteToggleDisableFlag(isAutoCompleteDisabled));
      history.push(`/sandbox/${sandboxId}`);

      if (!withFileSystem) {
        if (fetchDependencies) {
          dispatch(fetchSandboxDependentPackages(setCodeForFramework(frameworkId, code)));
        }
        dispatch(setCodeForFramework(frameworkId, code));
        if (shouldFetchForks) {
          // Fetch forks.
          dispatch(fetchForksWithTokenProvided(token));
        }
      } else {
        // Get all the files in the sandbox.
        const {
          totalFiles,
          filesToFetchPerBatch,
        } = data;

        if (totalFiles > 0) {
          if (fetchDependencies) {
            dispatch(fetchSandboxDependentPackages());
          }
          dispatch(fetchFilesForSandbox(totalFiles, filesToFetchPerBatch));
        }
      
      
        if (shouldFetchForks) {
          dispatch(fetchForksWithTokenProvided(token));
        }
      }
    })
    .catch(() => {
      toast.error(`Could not fetch sandbox`);
      window.open('/', '_self');
    });
};

export const fetchForksWithTokenProvided = (token) => (dispatch) => {
  dispatch({
    type: FETCH_FORKS_FOR_SANDBOX,
    payload: get(
      `${backendUrl}/api/v1/fork/get/forks`,
      {
        authorization: token,
      },
    ),
  })
    .then(({ action: { payload: { data } } }) => {
      if (!data) {
        toast.error(`Could not fetch forks. Try again`);
      } else {
        const { forks } = data;
        dispatch(setForks(forks));
      }
    })
    .catch(() => {
      toast.error(`Error occurred while fetching forks`);
    });
};

export const fetchForksForSandbox = () => (dispatch, getState) => {
  const { token } = getState().auth;
  dispatch({
    type: FETCH_FORKS_FOR_SANDBOX,
    payload: get(
      `${backendUrl}/api/v1/fork/get/forks`,
      {
        authorization: token,
      }
    ),
  });
};

export const closeSelectedFile = (fileId) => (dispatch) => {
  dispatch({
    type: CLOSE_FILE,
    payload: {
      data: {
        fileId,
      },
    },
  });
};

export const openSelectedFileFromFileExplorerDrawer = (descriptorId) => (dispatch) => {
  dispatch({
    type: OPEN_FILE,
    payload: {
      data: {
        descriptorId,
      },
    },
  });
};

export const changePathOftheFile = (currentPath) => (dispatch) => {
  dispatch({
    type: CHANGE_CURRENT_PATH_OF_FILE,
    payload: {
      data: {
        currentPath,
      },
    },
  });
};

export const changePathAndDescriptorIdOfTheFileToOpen = (path, descriptorId) => (dispatch) => {
  dispatch({
    type: CHANGE_PATH_AND_DESCRIPTOR_ID_OF_FILE_TO_OPEN,
    payload: {
      data: {
        path,
        descriptorId,
      },
    },
  });
};

export const addDescriptorIdOfCurretlySelectedFile = (descriptorId) => (dispatch) => {
  dispatch({
    type: ADD_DESCRIPTOR_OF_THE_SELECTED_FILE,
    payload: {
      data: {
        descriptorId,
      },
    },
  });
};

export const deleteDescriptorIdOfCurretlySelectedFile = (descriptorId) => (dispatch, getState) => {
  dispatch({
    type: DELETE_DESCRIPTOR_OF_THE_SAVED_FILE,
    payload: {
      data: {
        descriptorId,
      },
    },
  });
  const {
    filesWithUpdatedContentInSandbox,
    frameworkId,
  } = getState().sandbox;
  dispatch(rebundleBundle(frameworkId, descriptorId, filesWithUpdatedContentInSandbox));
};

export const fetchFilesInBatches = (batch, filesPerBatch, shouldFetchWithToken = false) => (dispatch, getState) => {
  if (!shouldFetchWithToken) {
    const {
      token,
    } = getState().auth;
    dispatch({
      type: GET_ALL_FILES_OF_SANDBOX,
      payload: get(
        `${backendUrl}/api/v1/files/getAll?batch=${batch}&limit=${filesPerBatch}`,
        {
          Authorization: token,
        },
      ),
    });
  } else {
    const {
      sandboxId,
    } = getState().sandbox;
    dispatch({
      type: GET_ALL_FILES_OF_SANDBOX,
      payload: get(
        `${backendUrl}/api/v1/files/getAllFilesForReading/${sandboxId}?batch=${batch}&limit=${filesPerBatch}`,
      ),
    });
  }
};

export const fetchFilesForSandbox = (totalFiles, filesPerBatch, shouldFetchWithToken) =>  (dispatch) => {
  const totalBatches = Math.ceil((totalFiles / filesPerBatch ));

  // Since batch count is starting from zero, total batches to be called should be less than the total batches.
  for (let batch = 0; batch < totalBatches; batch += 1) {
    dispatch(fetchFilesInBatches(batch, filesPerBatch, shouldFetchWithToken));
  }
};

export const updateCurrentCode = (descriptorId, code) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_CURRENT_CODE,
    payload: {
      data: {
        descriptorId,
        code,
      },
    },
  });
  const {
    filesWithUpdatedContentInSandbox,
    frameworkId,
  } = getState().sandbox;
  dispatch(rebundleBundle(frameworkId, descriptorId, filesWithUpdatedContentInSandbox));
};
export const createNewFileOrFolderInSandbox = (fileName, isDirectory, parent, content, extension) => (dispatch, getState) => {
  const {
    token,
  } = getState().auth;
  dispatch({
    type: CREATE_NEW_FILE_OR_FOLDER,
    payload: post(
      `${backendUrl}/api/v1/files/new`,
      {
        fileName,
        isDirectory,
        parent,
        content,
        extension,
      },
      {
        Authorization: token,
      }
    ),
  })
    .then(({ action: { payload: { data } } }) => {
      const {
        filesWithUpdatedContentInSandbox,
        frameworkId,
      } = getState().sandbox;
      const {
        file,
      } = data;
      if (file) {
        const fileDescriptor = file.fileDescriptor;
        if (fileDescriptor) {
          dispatch(rebundleBundle(frameworkId, fileDescriptor, filesWithUpdatedContentInSandbox, true));
        }
      }
    });
};

export const updateFileContent = (fileDescriptor, content) => (dispatch, getState) => {
  const {
    token,
  } = getState().auth;
  const {
    currentSelectedDescriptorIdOfTheFile,
  } = getState().sandbox;

  if (fileDescriptor in currentSelectedDescriptorIdOfTheFile) {
    dispatch({
      type: UPDATE_FILE_CONTENT,
      payload: post(
        `${backendUrl}/api/v1/files/save/${fileDescriptor}`,
        {
          content,
        },
        {
          Authorization: token,
        },
      ),
    });
  }
};

export const deleteFileOfSandbox = (fileDescriptor,isAFolder) => (dispatch, getState) => {
  const {
    token,
  } = getState().auth;

  const {
    filesWithUpdatedContentInSandbox,
    frameworkId,
  } = getState().sandbox;

  if (isAFolder) {
    dispatch({
      type: DELETE_FILE_OF_SANDBOX,
      payload: deleteCall(
        `${backendUrl}/api/v1/folder/delete/${fileDescriptor}`,
        {
          Authorization: token,
        },
      ),
    });
  } else {
    dispatch({
      type: DELETE_FILE_OF_SANDBOX,
      payload: deleteCall(
        `${backendUrl}/api/v1/files/delete/${fileDescriptor}`,
        {
          Authorization: token,
        },
      ),
    })
      .then(({ action: { payload: { data } } }) => {
        const {
          fileDescriptor,
        } = data;
        if (fileDescriptor) {
          dispatch(rebundleBundle(frameworkId, fileDescriptor, filesWithUpdatedContentInSandbox, true));
        }
      });
  }
};

export const saveSandboxOpenedFile = (fileDescriptor, isCalledFromComponentWillUnmount = false) => (dispatch, getState) => {
  const {
    token,
  } = getState().auth;

  const {
    filesWithUpdatedContentInSandbox,
  } = getState().sandbox;
  const content = filesWithUpdatedContentInSandbox[fileDescriptor] && filesWithUpdatedContentInSandbox[fileDescriptor].content;
  if (fileDescriptor in filesWithUpdatedContentInSandbox) {
    dispatch({
      type: UPDATE_FILE_CONTENT,
      payload: post(
        `${backendUrl}/api/v1/files/save/${fileDescriptor}`,
        {
          content,
        },
        {
          Authorization: token,
        },
      ),
    }).then(() => {
      if (isCalledFromComponentWillUnmount) {
        dispatch(setDefaultValueOnComponentUnmount());
      }
    });
  }
  else{
    toast.info('No File opened');
  }
};

export const fetchSandboxDependentPackages = (next = undefined) => (dispatch, getState) => {
  const {
    token,
  } = getState().auth;

  const {
    sandboxId,
  } = getState().sandbox;

  if (!token) {
    dispatch({
      type: FETCH_DEPENDENT_PACKAGES,
      payload: get(
        `${backendUrl}/api/v1/sandbox/dependencies/get/${sandboxId}`,
      ),
    }).then(() => {
      if (next) {
        dispatch(next);
      }
    });
  } else {
    dispatch({
      type: FETCH_DEPENDENT_PACKAGES,
      payload: get(
        `${backendUrl}/api/v1/sandbox/dependency/fetch/${sandboxId}`,
        {
          Authorization: token,
        },
      ),
    }).then(() => {
      if (next) {
        dispatch(next);
      }
    });
  }
};

export const getCodeCompileSandboxUrlWithStoredCode = (codeCompileSandboxId, history) => (dispatch) => {
  dispatch({
    type: GET_CODE_COMPILE_SANDBOX_URL,
    payload: get(
      `${backendUrl}/api/v1/code/retrieve/${codeCompileSandboxId}`,
    ),
  })
    .then(({ action: { payload: { data } } }) => {
      if (!data) {
        window.location.assign('/');
      }

      const {
        userToken,
        language,
        content,
        windowType,
        editorType,
      } = data;

      const windowTypeToShow = (windowType === serviceEditorTypeKeyForCodeCompileSandbox) ? windowTypeForCodeCompileSandbox : 'NOTES';

      if (!userToken) {
        window.location.assign('/');
      }

      const languageSelected = allowedCompilableLanguages[language] || 'c_cpp';

      const redirectionParameters = `type=${editorType}&windowType=${windowTypeToShow}&language=${languageSelected}`;
      history.push(`/service/editor?${redirectionParameters}`);
      dispatch(changeCurrentContentOfEditor(editorType, content, languageSelected));
      dispatch(setDownloadAndShareButtonVisibility(editorType, false));
      dispatch(setSessionToken(userToken));
    })
    .catch(() => {
      window.location.assign('/');
    });
};

export const setFrameworkId = (frameworkId = undefined) => (dispatch) => {
  dispatch({
    type: SET_FRAMEWORK_ID_AND_DEPENDENCIES,
    payload: {
      data: {
        frameworkId,
      },
    },
  });
};

export const setDefaultValueOnComponentUnmount = () => (dispatch) => {
  dispatch({
    type: SET_DEFAULT_VALUES_ON_UNMOUNT,
  });
};

export const setIsComponentUnmounting = () => (dispatch) => {
  dispatch({
    type: SET_IS_COMPONENT_UNMOUNTING,
  });
};