import React from 'react';
import { Switch, Route } from 'react-router-dom';

import {
  HomePageView,
} from './Components';
import Error404View from './Components/Error404View';
import FAQ from './Components/FAQ';
// import ForkCode from './Components/ForkCode/ForkCode';
// import ForkedCodeView from './Components/ForkedCodeView/ForkedCodeView';
import HrCodeView from './Components/HrCodeView';
// import MarkDownEditorView from './Components/MarkDownEditorView/MarkDownEditorView';
// import JavaScriptEditor from './Components/JavaScriptEditor/JavaScriptEditor';
// import JsHtmlCssPreviewWindow from './Components/JsHtmlCssPreviewWindow';
import PrivateSandboxRedirection from './Components/PrivateSandboxRedirection';
// import FileSystemSandbox from './Components/FileSystemSandbox';
// import ResetToDefaultCode from './Components/ResetToDefaultCode';
import SandboxRouter from './Components/SandboxRouter';
// import CreateAFileView from './Components/CreateAFileView';

import ToggleEditorView from './Components/ToggleEditorView';
import ResourceNotAvailableView from './Components/ResourceNotAvailableView';
import CodeCompileSandboxRedirectionComponent from './Components/CodeCompileSandboxRedirection';
import LiveCodeShareView from './Components/LiveCodeShareView';
// import SideNav from './Components/SideNav';



const Routes = () => (
  <Switch>
    {/* <Route
      exact
      path="/javaScriptEditor"
      render={({ history }) => (
        // <AppShell history={history} >
        <JavaScriptEditor history={history} />
        // </AppShell>
      )}
    />

    <Route
      exact
      path="/forkCode"
      render={({ history }) => (
        // <AppShell history={history} >
        <ForkCode  history={history} />
        // </AppShell>
      )}
    />
    <Route
      exact
      path="/resetToDefault"
      render={({ history }) => (
        // <AppShell history={history} >
        <ResetToDefaultCode history={history} />
        // </AppShell>
      )}
    />
    <Route
      exact
      path="/HTML/:sandboxId"
      render={({ history, match }) => (
        // <AppShell history={history} >
        <JsHtmlCssPreviewWindow history={history} match={match}/>
        // </AppShell>
      )}
    />
    <Route
      exact
      path="/SideNav"
      render={({ history }) => (
        // <AppShell history={history} >
        <SideNav history={history}/>
        // </AppShell>
      )}
    />
    <Route
      exact
      path="/forkedCodeView"
      render={({ history }) => (
        // <AppShell history={history} >
        <ForkedCodeView history={history} />
        // </AppShell>
      )}
    /> */}
    <Route
      exact
      path="/sandbox/:sandboxId"
      render={({ history, match }) => (
        <SandboxRouter history={history} match={match} />
      )}
    />
    
    {/* <Route
      exact
      path="/private"
      render ={() => (
        <FileSystemSandbox />
      )}
    /> */}

    <Route
      exact
      path="/start/:sessionId"
      render={({ history, match }) => (
        <PrivateSandboxRedirection
          history={history}
          match={match}
        />
      )}
    />
    <Route
      exact
      path="/view/sandbox/:sandboxId"
      render={({ history, match }) => (
        // <AppShell history={history} >
        <HrCodeView
          history={history}
          match={match}
        />
        // </AppShell>
      )}
    />
    {/* <Route
      exact
      path="/service/editor"
      render={() => (
        <ToggleEditorView />
      )}
    /> */}

    <Route
      exact
      path="/service/editor"
      render={({ history }) => (
        // <AppShell history={history} >
        <ToggleEditorView history= {history} />
        // </AppShell>
      )}
    />
    <Route
      exact
      path="/service/LiveCodeShare"
      render={({ history }) => (
        // <AppShell history={history} >
        <LiveCodeShareView history= {history} />
        // </AppShell>
      )}
    />
    <Route
      path="/resourceNotAllowed"
      exact
      render = {() => (
        <ResourceNotAvailableView />
      )}
    />
    <Route
      exact
      path="/FAQ"
      render={({ history }) => (
        // <AppShell history={history} >
        <FAQ history={history}/>
        // </AppShell>
      )}
    />

    <Route
      exact
      path='/code/compile/:codeCompileSandboxId'
      render={({ history, match }) => (
        <CodeCompileSandboxRedirectionComponent
          history={history}
          match={match}
        />
      )}
    />

    <Route
      exact
      path="/"
      render={({ history }) => (
        // <AppShell history={history} >
        <HomePageView history={history} />
        // </AppShell>
      )}
    />

    {/* <Route
      exact
      path="/markDown"
      render={({ history }) => (
        // <AppShell history={history} >
        <MarkDownEditorView history= {history} />
        // </AppShell>
      )}
    /> */}
    {/* <Route
      exact
      path="/view/sandbox/:sandboxId"
    /> */}
    {/* <Redirect to = "/404" /> */}
    <Route
      path="/*"
      render ={() => (
        <Error404View />
      )
      }
    />
    
    
    {/* <Route
  render = {({history}) => (

  )} */}

  </Switch>
);

export default Routes;
