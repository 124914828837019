import * as babelParser  from '@babel/parser';
import * as babel  from '@babel/standalone';
import traverse from '@babel/traverse';
import pathResolver from '../pathResolver';
import stringifyObject from 'stringify-object';
import { createResultantBundleStringForReact } from '../../../utils/utilityFunctions';
import {
  extraParametersForStringifyObject,
} from '../../../configFiles/config';
import {
  resolvedDependenciesForReactMicroServiceSandbox,
} from '../../../Constants/index';

export default (changedFileDescriptor, filesInSandbox, resultentModulesFromReactBundler,
  bundleCssStringInReactFiles, bundleDocumentForHtmlWithCss, dependentPackagesForSandboxResolvedHtml, reactVersion = 16) => {
  return new Promise((resolve, reject) => {
    try {
      // eslint-disable-next-line
      let moduleObject = Function('"use strict";return (' + resultentModulesFromReactBundler + ')')();

      // console.log(':::::::::::::module object',moduleObject);
      const updatedFile = filesInSandbox[changedFileDescriptor];
      // console.log('::::::::::::::updatedFile',updatedFile);
      if (!updatedFile) {
        reject({
          data: {
            errorMessage: `File not found`,
          },
        });
      }
        
      const updatedContent = updatedFile.content;
      const pathToFile = updatedFile.path;
      const fileName = updatedFile.name;

        
      const pathOfFile = `${pathToFile}${fileName}`;

      // let extensionOfComingFile = fileName.substring(fileName.lastIndexOf('.') + 1);
      let extensionOfComingFile = updatedFile.extension;


      // console.log('::::::::::::::::;updatedContent',updatedContent);
      // console.log(':::::::::::::::::pathOfFile',pathOfFile);

      let updatedModule = '';
      if ( extensionOfComingFile === 'css' ) {

        let currentStyleTags = bundleDocumentForHtmlWithCss.getElementsByTagName('style');
        let flagForAlreadyExistingValue = false;
        Object.keys(currentStyleTags).forEach(( index ) => {
          if (pathOfFile === currentStyleTags[index].id) {
            flagForAlreadyExistingValue = true;
            currentStyleTags[index].innerHTML = updatedContent;
          }
        });
        if ( flagForAlreadyExistingValue === false ){

          let newStyleTag = bundleDocumentForHtmlWithCss.createElement('style');
          newStyleTag.id = pathOfFile;
          newStyleTag.innerHTML = updatedContent;
          let headElement = bundleDocumentForHtmlWithCss.getElementsByTagName('head')[0];
          headElement.appendChild(newStyleTag);
        }
        // console.log(':::::::::::;bundleDocumentForHtmlWithCss updated',bundleDocumentForHtmlWithCss);
        let updatedCurrentStyleTags = bundleDocumentForHtmlWithCss.getElementsByTagName('style');
        let newlyUpdatedCssContent = '';
        Object.keys(updatedCurrentStyleTags).forEach((index) => {
          newlyUpdatedCssContent += String(currentStyleTags[index].innerHTML);
        });
        bundleCssStringInReactFiles = newlyUpdatedCssContent;

        // console.log('::::::::::::::::::bundleCssStringInReactFiles',bundleCssStringInReactFiles);

        updatedModule = `${JSON.stringify(pathOfFile)}: [
          function (require, module, exports) {
            undefined
          },
          {},
        ],`;
      }

      else if (extensionOfComingFile === 'js') {
        const dependencies = [];
        let abstractSyntaxTree = babelParser.parse(updatedContent, {
          sourceType: 'module',
          plugins: [
            'jsx',
            'flow',
          ],
        });

        traverse(abstractSyntaxTree, {
          ImportDeclaration: ({ node }) => {
            dependencies.push(node.source.value);
          },
        });

        let mappedDependencies = {};

        dependencies && dependencies.forEach(relativePath => {
          const fileNameForPathResolver = relativePath.substring(relativePath.lastIndexOf('/') + 1 );
          const absolutePath = pathResolver(fileNameForPathResolver, relativePath, pathToFile);
          // console.log(':::::::::::::absolutePath',absolutePath);
          if (!(absolutePath in moduleObject)) {
            throw new Error('Path being imported does not exist.');
          }

          mappedDependencies[relativePath] = String(absolutePath);
        });

        const { code } = babel.transformFromAst(abstractSyntaxTree, null, {
          presets: ['env',
            'react',
          ],
        });

        updatedModule = `${JSON.stringify(pathOfFile)}: [
        function (require, module, exports) {
          ${code}
        },
        ${JSON.stringify(mappedDependencies)},
      ],`;
        
        
      }

      else {
        throw new Error(`Error occurred. Please refresh.`);
      }
      // eslint-disable-next-line
      let updatedModuleObjectToBeInserted = Function('"use strict";return ({' + updatedModule + '})')();
      let moduleArrayForFunctionAnd = updatedModuleObjectToBeInserted[pathOfFile];

      moduleObject[pathOfFile] = moduleArrayForFunctionAnd;

      const moduleStringForBundler = stringifyObject(moduleObject,extraParametersForStringifyObject);

      
      const resultentReactScript = `
      (function(modules) {
        function require(canonicalFilePath) {
          const [fn, mapping] =   modules[canonicalFilePath];
          function localRequire(name) {
            return require(mapping[name]);
          }
          const module = { exports : {} };
          fn(localRequire, module, module.exports);
          return module.exports;
        }
        require(${JSON.stringify('/root/index.js')});
      })(${moduleStringForBundler})
    `;

     

      let dependentPackagesToBeSent = '';
      if (!dependentPackagesForSandboxResolvedHtml && reactVersion === 16) {
        dependentPackagesToBeSent = resolvedDependenciesForReactMicroServiceSandbox;
      }
      else {
        dependentPackagesToBeSent = dependentPackagesForSandboxResolvedHtml;
      }

      const reactBundle = createResultantBundleStringForReact(
        dependentPackagesToBeSent,
        bundleCssStringInReactFiles,
        resultentReactScript
      );

      // console.log(':::::::::::::::reactBundle',reactBundle);

      resolve({
        data: {
          bundle: reactBundle,
          resultentModules: moduleStringForBundler,
          totalCssContent: bundleCssStringInReactFiles,
          bundleDocumentForHtmlWithCss,
        },
      });
    } catch (ex) {
      // console.log('::::::::::::::::::error occured :::::::::::::::',ex);
      reject({
        data: {
          errorMessage: String(ex.message),
        },
      });
    }
  });
};
  