import { frontEndUrl, rootLevelPathIndentifier, sandboxUrl, viewSandboxUrl } from '../../../configFiles/config';
// import { dummyFilesInSandbox } from '../../../Constants';
import pathResolver from '../pathResolver';

const getContent = (remainingPath, remainingStructure, index) => {
  const key = remainingPath[index];
  if (remainingStructure[key] === null || remainingStructure[key] === undefined) {
    throw Object({ error: `Cannot resolve module "${remainingPath.join('/')}"` });
  }
  if (typeof remainingStructure[key] === 'string') {
    return remainingStructure[key];
  }

  const traversedStructure = remainingStructure[key];
  return getContent(remainingPath, traversedStructure, index + 1);
};

const getFileContent = (path, resolvedFiles, filesInFileSystemSandbox) => {
  const fullPath = path.split('/').filter(pathKey => pathKey !== '');
  const descriptor = getContent(fullPath, resolvedFiles, 0);
  const fileContent = filesInFileSystemSandbox[descriptor].content;
  return fileContent;
};

const generateTagId = (tagType, index) => {
  const tagId = `BUNDLE_${tagType.toUpperCase()}_${index}`;
  return tagId;
};

const checkIfImportIsInternal = (url) => {
  return url.startsWith(`${frontEndUrl}/`);
};

const resolveStyleImports = (resolvedFiles, bundleDocument, currentPath, filesInFileSystemSandbox) => {

  // Find all link tags in the bundle document.
  const styleTags = bundleDocument.getElementsByTagName('link');
  const olderTags = [];

  // Replace the link tag for stylesheets with style tags.
  Object.keys(styleTags).forEach((key, index) => {
    const tag = styleTags[key];

    const fileHref = tag.href;

    // Check if the import requested is internal, if yes, resolve the file.
    const isImportInternal = checkIfImportIsInternal(fileHref);
    // Resolve for stylesheets.
    if (tag.rel === 'stylesheet' && isImportInternal) {
      const tagId = generateTagId('style', index);
      tag.id = tagId;

      // Get the file path.
      const fileHrefPath = fileHref.startsWith(sandboxUrl) ?
        fileHref.split(sandboxUrl)
        :
        fileHref.startsWith(viewSandboxUrl) ?
          fileHref.split(viewSandboxUrl)
          :
          fileHref.split(frontEndUrl);

      const filePath = fileHrefPath[fileHrefPath.length - 1];
      // Resolve the file's path.
      const canonicalFilePath = pathResolver('', filePath, currentPath);

      // Get the file's content.
      const content = getFileContent(canonicalFilePath, resolvedFiles, filesInFileSystemSandbox);

      // Create an empty style tag.
      const styleTag = bundleDocument.createElement('style');

      // Add Id to the tag.
      styleTag.id = canonicalFilePath;

      // Add the file content inside the style tag.
      styleTag.innerHTML = content;

      // Find the element by elements's Id.
      const elementToRemove = bundleDocument.getElementById(tagId);
      olderTags.push(elementToRemove);

      // Remove the element
      // elementToRemove.remove();

      // Find the head element.
      const headElement = bundleDocument.getElementsByTagName('head')[0];

      // Append the style tag to the head element.
      headElement.appendChild(styleTag);
    }
  });
  olderTags.forEach(tag => tag.remove());
};

const resolveScriptImports = (resolvedFiles, bundleDocument, currentPath, filesInFileSystemSandbox) => {
  // Find all style tags in the bundle document.
  const scriptTags = bundleDocument.getElementsByTagName('script');
  const olderTags = [];
  Object.keys(scriptTags).forEach((key, index) => {
    const tag = scriptTags[key];
    const scriptSource = tag.src;

    const isImportInternal = checkIfImportIsInternal(scriptSource);

    // Resolve for internal scripts.
    if (isImportInternal) {
      // Add an Id for the tag.
      const tagId = generateTagId('script', index);
      tag.id = tagId;
      const fileHrefPath = scriptSource.startsWith(sandboxUrl) ?
        scriptSource.split(sandboxUrl)
        :
        scriptSource.startsWith(viewSandboxUrl) ?
          scriptSource.split(viewSandboxUrl)
          :
          scriptSource.split(frontEndUrl);

      const filePath = fileHrefPath[fileHrefPath.length - 1];
      // Resolve the file's path.
      const canonicalFilePath = pathResolver('', filePath, currentPath);

      // Get the file's content.
      const content = getFileContent(canonicalFilePath, resolvedFiles, filesInFileSystemSandbox);

      // Create a new script tag element.
      const scriptTag = bundleDocument.createElement('script');

      // Add the file content to the tag.
      scriptTag.innerHTML = content;

      // Find the older script tag
      const elementToRemove = bundleDocument.getElementById(tagId);
      olderTags.push(elementToRemove);

      // Remove the older element.
      // elementToRemove.remove();

      // Add id to the script tag for rebundling.
      scriptTag.id = canonicalFilePath;

      // Find the head element of the bundle.
      const headElement = bundleDocument.getElementsByTagName('head')[0];

      // Add the script tag with file content to the head.
      headElement.appendChild(scriptTag);
    }
  });
  olderTags.forEach(tag => tag.remove());
};

const createHTMLBundleDocument = (htmlString) => {
  // Create the HTML bundle document.
  const bundleDocument = document.implementation.createHTMLDocument('HTML bundle');

  // Set the HTML string as the bundle document's content.
  bundleDocument.documentElement.innerHTML = htmlString;
  return bundleDocument;
};

const createBundleStringForBundleDocument = (bundleDocument) => {
  const bundleString = bundleDocument.documentElement.innerHTML;
  // const bundleObject = new Blob([bundleString], { type: 'text/html' });

  // const bundleVersion = 1;
  // bundleObject.name = `Bundle_${bundleVersion}`;
  // bundleObject.createdAt = moment().toString();

  // // const bundleString = bundleDocument.
  
  // const bundleFile = window.URL.createObjectURL(bundleObject);

  return bundleString;
};

export default (htmlString, resolvedFiles, filesInFileSystemSandbox, pathToBaseFie) => {
  const baseFilePath = pathToBaseFie || `/${rootLevelPathIndentifier}/`;
  return new Promise((resolve, reject) => {
    try {
      const bundleDocument = createHTMLBundleDocument(htmlString);
      // The bundle document is being updated using a pointer reference.
      resolveStyleImports(resolvedFiles, bundleDocument, baseFilePath, filesInFileSystemSandbox);
      resolveScriptImports(resolvedFiles, bundleDocument, baseFilePath, filesInFileSystemSandbox);
      const bundleString = createBundleStringForBundleDocument(bundleDocument);
      resolve({
        data: {
          bundle: bundleString,
          bundleDocument,
        },
      });
    } catch (ex) {
      reject(ex);
    }
  });
};
