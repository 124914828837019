import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';

class DeleteFolderView extends Component {
  constructor (props) {
    super(props);
    const { isDeleteFolderDialogOpened } = this.props;
    this.state = {
      deleteFolderDialogModal: isDeleteFolderDialogOpened,
    };
  }
  openDeleteFolderDialogModal = () => {
    this.setState({
      deleteFolderDialogModal: true,
    });
  };
  closeDeleteFolderDialogModal = () => {
    const {
      closeDeleteFolderDialog,
    } = this.props;
    this.setState({
      deleteFolderDialogModal: false,
    });
    closeDeleteFolderDialog();
  };
  deleteFolder = () => {
    const {
      fileDescriptor,
      deleteFileOrFolderOfSandbox,
    } = this.props;
    deleteFileOrFolderOfSandbox(fileDescriptor, true);
    this.closeDeleteFolderDialogModal();
  };
  render () {
    const {
      name,
    } = this.props;
    const { deleteFolderDialogModal } = this.state;
    return (
      <div>
        <Dialog
          open = { deleteFolderDialogModal }
          onClose = {this.closeDeleteFolderDialogModal}
          fullWidth
        >
          <DialogTitle
            className = "headerExitSandboxView"
            style = {{ justifyContent: 'center', display: 'flex' }}
          >
            {' Delete Folder '}
          </DialogTitle>
          <DialogContent
            style = {{
              display: 'flex',
              color: 'black',
              justifyContent: 'center',
              fontSize: '18px',
            }}
          >
            { `Are you sure you want to delete the folder ${name} ?` }
          </DialogContent>
          <DialogActions>
            <Button
              onClick = {this.closeDeleteFolderDialogModal}
              color = "primary"
              className = "buttonExitSandboxView button3ExitSandboxView"
              id = 'cancelDeleteFolderButton'
            >
              Cancel
            </Button>
            <Button
              onClick = {this.deleteFolder}
              color = 'primary'
              className = 'buttonExitSandboxView button1ExitSandboxView'
              id = 'deleteFolderButton'
              title = 'Delete Folder'
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

DeleteFolderView.propTypes = {
  name: PropTypes.string.isRequired,
  isDeleteFolderDialogOpened: PropTypes.bool.isRequired,
  closeDeleteFolderDialog: PropTypes.func.isRequired,
  fileDescriptor: PropTypes.string,
  deleteFileOrFolderOfSandbox: PropTypes.func,
};

DeleteFolderView.defaultProps = {
  name: '',
  isDeleteFolderDialogOpened: false,
  closeDeleteFolderDialog: () => {
  },
  deleteFileOrFolderOfSandbox: () => {
  },
};

export default DeleteFolderView;