// import { colors } from '@material-ui/core';
import React, { Component } from 'react';
// import { connect } from 'react-redux';
import './SaveButton.css';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

import { darkTheme, defaultIndenation } from '../../configFiles/config';
// import { saveCurrentCode } from '../../redux/code/actions';

class SaveButton extends Component {
  constructor (props, context) {
    super(props, context);
    this.saveCodeFunction = this.saveCodeFunction.bind(this);
  }
  // comment below line for save issue
  // componentDidMount () {
  //   window.addEventListener('beforeunload', this.saveCodeFunction);
  // }


  componentWillUnmount () {
    let isCalledFromComponentWillUnmount = true;
    this.saveCodeFunction(isCalledFromComponentWillUnmount);
    window.removeEventListener('beforeunload', this.saveCodeFunction);
  }
  
  saveCodeFunction = ( isCalledFromComponentWillUnmount ) => {
    const { saveCode,
      frameworkId,
      editorTheme,
      currentCode,
      isAutoCompleteEnabled,
      indentationForEditors,
      isFileSystemEnabled,
      saveFileSystemCode,
      currentlySelectedFile,
      setIsComponentUnmounting,
      setIsComponentUnmountingForNormal,
    } = this.props;

    const theme = editorTheme === darkTheme ? 'DARK' : 'LIGHT';

    if(isCalledFromComponentWillUnmount) {
      if(isFileSystemEnabled) {
        setIsComponentUnmounting();
      }
      else{
        setIsComponentUnmountingForNormal();
      }
    }
    if (!isFileSystemEnabled) {
      const copyOfCode = Object.assign({}, currentCode);
      const codeToSave = {};
      if (frameworkId === 'HTML') {
        codeToSave.HTML = copyOfCode[frameworkId] && copyOfCode[frameworkId].html;
        codeToSave.CSS = copyOfCode[frameworkId] && copyOfCode[frameworkId].css;
        codeToSave.JS = copyOfCode[frameworkId] && copyOfCode[frameworkId].javascript;
      } else if (frameworkId === 'REACT_JS') {
        codeToSave.CSS = copyOfCode[frameworkId] && copyOfCode[frameworkId].css;
        codeToSave.JS = copyOfCode[frameworkId] && copyOfCode[frameworkId].javascript;
      } else if (frameworkId === 'ANGULAR_JS') {
        codeToSave.CSS = copyOfCode[frameworkId] && copyOfCode[frameworkId].css;
        codeToSave.JS = copyOfCode[frameworkId] && copyOfCode[frameworkId].javascript;
      }
      saveCode(theme, codeToSave, frameworkId, indentationForEditors, isAutoCompleteEnabled);
    } else {
      // Save all files here.
      saveFileSystemCode(currentlySelectedFile, isCalledFromComponentWillUnmount);
    }
  }

  render () {
    return (
      <div >
        <Button
          variant="contained"
          color="primary"
          // onClick={this.saveCodeFunction()}
          onClick = {() => this.saveCodeFunction(false)}
          style={{
            width: '154px',
            background: 'var(--primaryColor)',
            color: 'white',
          }}
        >
          Save
        </Button>
        
      </div>
    );
  }
}

SaveButton.propTypes = {
  saveCode: PropTypes.func.isRequired,
  currentCode: PropTypes.object.isRequired,
  editorTheme: PropTypes.string.isRequired,
  frameworkId: PropTypes.string.isRequired,
  isAutoCompleteEnabled: PropTypes.bool.isRequired,
  indentationForEditors: PropTypes.number.isRequired,
  isFileSystemEnabled: PropTypes.bool,
  saveFileSystemCode: PropTypes.func,
  currentlySelectedFile: PropTypes.string,
};

SaveButton.defaultProps = {
  currentCode: {
    html: '',
    css: '',
    js: '',
  },
  editorTheme: 'LIGHT',
  frameworkId: 'HTML',
  saveCode: () => {

  },
  isAutoCompleteEnabled: true,
  indentationForEditors: defaultIndenation,
};


// const mapStateToProps = state => ({
//   editorTheme: state.editor.selectedTheme,
//   currentCode: state.code.currentCode,
//   frameworkId: state.sandbox.frameworkId,
// });
// const mapDispatchToProps = dispatch => ({
//   saveCode: (theme,code,frameworkId) => {
//     dispatch(saveCurrentCode(theme,code,frameworkId));
//   },
// });

// export default connect(mapStateToProps,mapDispatchToProps)(SaveButton);
export default SaveButton;