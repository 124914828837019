import {
  changeCurrentContentOfEditor,
  compileCodeUsingAgent,
  toggleCompiledOutputDrawerVisibilityInCodeShare,
  saveClientDetails,
} from '../../redux/microService/actions';

import {
  validateUserForCodeShare,
} from '../../redux/codeShare/actions';

import { changeLanguage } from '../../redux/codeShare/actions';

export const mapStateToProps = state => ({
  codeEditorContent: state.microService.editorContent.SHARE_CODE_EDITOR.content,
  showCompileButton: state.microService.editorContent.SHARE_CODE_EDITOR.showCompileButton,
  isCompiledOutputVisibleInCodeShare: state.microService.isCompiledOutputVisibleInCodeShare,
  doesCompiledOutputContainAnError: state.microService.editorContent.SHARE_CODE_EDITOR.isError,
  compiledOutput: state.microService.editorContent.SHARE_CODE_EDITOR.output,
  languageSelected: state.codeShare.languageSelected,
  codeShareToken: state.codeShare.codeShareToken,
  isValidatingUserForCodeShare: state.codeShare.isValidatingUserForCodeShare,
  isAwaitingCompileAgentResponse: state.microService.isAwaitingCompileAgentResponse,
  isFetchingCompileAgent: state.microService.isFetchingCompileAgent,
});
  
export const mapDispatchToProps = dispatch => ({
  changeCurrentContentOfEditor: (editor,content,language) => {
    dispatch(changeCurrentContentOfEditor(editor,content,language));
  },
  
  getCompileAgentUrl: (isCalledFromCodeShareEditor, meetingId, participantToken) => {
    dispatch(compileCodeUsingAgent(isCalledFromCodeShareEditor, meetingId, participantToken));
  },
  toggleCompiledOutputDrawerVisibilityInCodeShare: (isCompiledOutputVisibleInCodeShare) => {
    dispatch(toggleCompiledOutputDrawerVisibilityInCodeShare(isCompiledOutputVisibleInCodeShare));
  },
  changeLanguage: (language) => {
    dispatch(changeLanguage(language));
  },
  validateUserForCodeShare: (meetingId, participantToken, moderatorToken) => {
    dispatch(validateUserForCodeShare(meetingId, participantToken, moderatorToken));
  },
  saveClientDetails: (clientUrl, serviceType, clientDetails) => {
    dispatch(saveClientDetails(clientUrl, serviceType, clientDetails));
  },
});