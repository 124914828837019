import fetch from 'isomorphic-fetch';

export const post = async (endPoint, data, headers = {}, isFormData = false) => {
  const response = await fetch(endPoint, {
    method: 'POST',
    headers: {
      ...(!isFormData && { 'Content-Type': 'application/json' }),
      ...headers,
    },
    body: isFormData ? data : JSON.stringify(data),
  });
  const responseJSON = await response.json();
  return new Promise((resolve, reject) => {
    try {
      const responseData = responseJSON;
      // Check if there is error in the responseData
      const { success } = responseData;
      if (success) {
        resolve(responseData);
      }
      else {
        const { errors } = responseData;
        reject({ errors });
      }
    } catch (ex) {
      reject();
    }
  });
};

export const put = async (endPoint, data, headers = {}, isFormData = false) => {
  const response = await fetch(endPoint, {
    method: 'PUT',
    headers: {
      ...(!isFormData && { 'Content-Type': 'application/json' }),
      ...headers,
    },
    body: JSON.stringify(data),
  });

  const responseJSON = await response.json();
  return new Promise((resolve, reject) => {
    try {
      const responseData = responseJSON;
      // Check if there is error in the responseData
      const { success } = responseData;
      if (success) {
        // Else resolve with responseData
        resolve(responseData);
      }
      else {
        const { errors } = responseData;
        reject({ errors });
      }
    } catch (ex) {
      reject();
    }
  });
};

export const get = async (endPoint, headers = {}) => {
  const response = await fetch(endPoint, {
    headers,
  });
  const responseJSON = await response.json();
  return new Promise((resolve, reject) => {
    // Check if there is error in the responseData
    try {
      const responseData = responseJSON;
      const { success } = responseData;
      if (success) {
        resolve(responseData);
      }
      else {
        const {
          errors,
        } = responseData;
        // In case of error, reject with returning error and errorCode
        reject({ errors });
      }
    } catch (ex) {
      reject();
    }
  });
};

export const externalRequestGet = async (endpoint, headers = {}) => {
  const response = await fetch(endpoint, {
    headers,
  });
  const responseJSON = await response.json();
  return new Promise((resolve, reject) => {
    // Check if there is error in the responseData
    try {
      const responseData = responseJSON;
      resolve(responseData);
    } catch (ex) {
      reject();
    }
  });
};


export const deleteCall = async (endPoint, headers = {}) => {
  const response = await fetch(endPoint, {
    method: 'DELETE',
    headers,
  });
  const responseJSON = await response.json();
  return new Promise((resolve, reject) => {
    try {
      const responseData = responseJSON;
      // Check if there is error in the responseData
      const { success } = responseData;
      if (success) {
        // Else resolve with responseData
        resolve(responseData);
      }
      else {
        const { errors } = responseData;
        // In case of error, reject with returning error and errorCode
        reject({ errors });
      }
    } catch (ex) {
      reject();
    }
  });
};
