import { getSessionForSandbox,
  closeSelectedFile,
  openSelectedFileFromFileExplorerDrawer,
  addDescriptorIdOfCurretlySelectedFile,
  deleteDescriptorIdOfCurretlySelectedFile,
  updateCurrentCode,
  updateFileContent,
  setDefaultValueOnComponentUnmount,
} from '../../redux/sandbox/actions';

export const mapStateToProps = state => ({
  token: state.auth.token,
  isSavingCurrentCode: state.code.isSavingCurrentCode,
  isForkingCode: state.code.isForkingCode,
  isFetchingForks: state.sandbox.isFetchingForks,
  forkCounter: state.code.forkCounter,
  editorTheme: state.editor.selectedTheme,
  isAutoCompleteEnabled: state.editor.isAutoCompleteEnabled,
  currentCode: state.code.currentCode,
  indentationForEditors: state.editor.indentationForEditors,
  openFilesInFileSystemSandbox: state.sandbox.openFilesInFileSystemSandbox,
  currentSelectedDescriptorIdOfTheFile: state.sandbox.currentSelectedDescriptorIdOfTheFile,
  filesInFileSystemSandbox: state.sandbox.filesInFileSystemSandbox,
  isFetchingFiles: state.sandbox.isFetchingFiles,
  isFileSystemEnabled: state.sandbox.isFileSystemEnabled,
  filesWithUpdatedContentInSandbox: state.sandbox.filesWithUpdatedContentInSandbox,
  isDeletingFile: state.sandbox.isDeletingFile,
  isUpdatingFileContent: state.sandbox.isUpdatingFileContent,
  isCreatingNewFileOrFolder: state.sandbox.isCreatingNewFileOrFolder,
});
  
export const mapDispatchToProps = dispatch => ({
  getSandboxData: (sandboxId, history) => {
    dispatch(getSessionForSandbox(sandboxId, history));
  },
  closeSelectedFile: (fileId) => {
    dispatch(closeSelectedFile(fileId));
  },
  openSelectedFileFromFileExplorerDrawer: (descriptorId) => {
    dispatch(openSelectedFileFromFileExplorerDrawer(descriptorId));
  },
  addDescriptorIdOfCurretlySelectedFile: (descriptorId) => {
    dispatch(addDescriptorIdOfCurretlySelectedFile(descriptorId));
  },
  deleteDescriptorIdOfCurretlySelectedFile: (descriptorId) => {
    dispatch(deleteDescriptorIdOfCurretlySelectedFile(descriptorId));
  },
  updateCurrentCode: (descriptorId, code) => {
    dispatch(updateCurrentCode(descriptorId, code));
  },
  saveFileContent: (fileDescriptor, content) => {
    dispatch(updateFileContent(fileDescriptor, content));
  },
  setDefaultValueOnComponentUnmount: () => {
    dispatch(setDefaultValueOnComponentUnmount());
  },
  
});
  