/* eslint-disable react/prop-types */
import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import { resetToDefaultCode } from '../../redux/code/actions';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import './ResetToDefaultCode.css';

class ResetToDefaultCode extends Component {
  constructor (props) {
    super(props);
    this.state = {
      forkingDailogModal: false,
    };
  }
  openForkDailogModal = () => {
    this.setState(() => ({
      forkingDailogModal: true,
    }));
  };

  closeForkDailogModal = () => {
    this.setState(() => ({
      forkingDailogModal: false,
    }));
  };


  handleCodeReset = () => {
    const { resetToDefaultCode } = this.props;
    resetToDefaultCode();
    this.setState(() => ({
      forkingDailogModal: false,
    }));

  };


  render () {
    const { forkingDailogModal } = this.state;
    return (
      <div>
        <Button
          variant="contained"
          
          onClick={this.openForkDailogModal}
          style={{
            width: '122%',
            background: 'var(--primaryColor)',
            color: 'white',
          }}
        >
          Reset Code
        </Button>

        <Dialog
          open={forkingDailogModal}
          onClose={this.closeForkDailogModal}
          fullWidth={true}
        >
          <DialogTitle
            id="alert-dialog-title"
            className="headerForResetToDefaultView"
            style={{
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            {' Reset To Default '}
          </DialogTitle>
          <DialogContent>
            <p style={{ color: '#082030' }}>Your code will be set to default, are you sure you want to reset ?</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeForkDailogModal} color="primary" className="buttonResetToDefaultView button3ResetToDefaultView">
              Cancel
            </Button>
            <Button onClick={this.handleCodeReset} color="primary" className="buttonResetToDefaultView button1ResetToDefaultView"  >
              Reset
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}

ResetToDefaultCode.propTypes = {
  resetToDefaultCode: PropTypes.func.isRequired,
};

ResetToDefaultCode.defaultProps = {
  resetToDefaultCode: () => {

  },
};

// const mapStateToProps = () => ({
//   // editorTheme: state.editor.selectedTheme,
// });

// const mapDispatchToProps = dispatch => ({
//   resetToDefaultCode: () => {
//     dispatch(resetToDefaultCode());
//   },
// });

// export default connect(mapStateToProps, mapDispatchToProps)(ResetToDefaultCode);
export default ResetToDefaultCode;