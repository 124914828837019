// import { Button, CardContent, Dialog } from '@material-ui/core';
import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import  { forkTheCurrentCode,decrementTheForkCount } from '../../redux/editor/actions';
// import { forkTheCurrentCode, decrementTheForkCount } from '../../redux/code/actions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

import './ForkCode.css';

import { TOTAl_NUMBER_OF_FORKS_AVAILABLE } from '../../configFiles/config';


class ForkCode extends Component {
  constructor (props) {
    super(props);
    this.state = {
      forkingDailogModal: false,
      nameForTheProp: '',
      descriptionForTheProp: '',
      chars_left: 200,
      chars_left_Name: 30,
      toggleCharLeftForName: false,
      toggleCharLeftForDescription: false,
    };
  }
  openForkDailogModal = () => {
    this.setState(() => ({
      forkingDailogModal: true,
    }));
  };

  closeForkDailogModal = () => {
    this.setState(() => ({
      forkingDailogModal: false,
      nameForTheProp: '',
      descriptionForTheProp: '',
      chars_left: 200,
      chars_left_Name: 30,
      toggleCharLeftForName: false,
      toggleCharLeftForDescription: false,
    }));
  };

  handleNameChange = (e) => {
    e.persist();
    this.handleToggleForname();
    this.setState(() => ({
      nameForTheProp: e.target.value,
      chars_left_Name: 30 - e.target.value.length,
    }));
  }

  handleToggleForname = () => {
    if (this.state.chars_left >= 0) {
      this.setState(() => ({
        toggleCharLeftForName: true,
        toggleCharLeftForDescription: false,
      }));
    }
    else {
      this.setState(() => ({
        toggleCharLeftForName: true,
      }));
    }
  };

  handleDescriptionChange = (e) => {
    e.persist();
    this.handleToggleForDescription();
    this.setState(() => ({
      descriptionForTheProp: e.target.value,
      chars_left: 200 - e.target.value.length,
    }));
  }

  handleToggleForDescription = () => {
    if (this.state.chars_left_Name >= 0) {
      this.setState(() => ({
        toggleCharLeftForDescription: true,
        toggleCharLeftForName: false,
      }));
    }
    else {
      this.setState(() => ({
        toggleCharLeftForDescription: true,
      }));
    }
  };

  buttonStyle = {
    width: '110%',
    background: 'var(--primaryColor)',
    color: 'white',
  }
  buttonStyleDisabled = {
    width: '110%',
    background: '#cfd1e9',
    color: 'white',
  }


  forkfunction = () => {
    const {
      forkTheCurrentCode,
      // decrementTheForkCount,
      currentCode,
      frameworkId,
    } = this.props;
    const { nameForTheProp, descriptionForTheProp } = this.state;

    let flag = true;

    if (nameForTheProp === '') {
      flag = false;
    }

    if (nameForTheProp.length > 30 || descriptionForTheProp.length > 200) {
      flag = false;
    }

    if (flag === true) {
      const codeToFork = {};
      const copyOfCurrentCode = Object.assign({}, currentCode);
      if (frameworkId === 'HTML') {
        codeToFork.HTML = copyOfCurrentCode[frameworkId] && copyOfCurrentCode[frameworkId].html;
        codeToFork.CSS = copyOfCurrentCode[frameworkId] && copyOfCurrentCode[frameworkId].css;
        codeToFork.JS = copyOfCurrentCode[frameworkId] && copyOfCurrentCode[frameworkId].javascript;
      } else if ( frameworkId === 'REACT_JS' ) {
        codeToFork.CSS = copyOfCurrentCode[frameworkId] && copyOfCurrentCode[frameworkId].css;
        codeToFork.JS = copyOfCurrentCode[frameworkId] && copyOfCurrentCode[frameworkId].javascript;
      } else if ( frameworkId === 'ANGULAR_JS' ) {
        codeToFork.CSS = copyOfCurrentCode[frameworkId] && copyOfCurrentCode[frameworkId].css;
        codeToFork.JS = copyOfCurrentCode[frameworkId] && copyOfCurrentCode[frameworkId].javascript;
      }

      forkTheCurrentCode(nameForTheProp, descriptionForTheProp, codeToFork, frameworkId);
      // decrementTheForkCount();
      this.setState(() => ({
        forkingDailogModal: false,
        nameForTheProp: '',
        descriptionForTheProp: '',
        chars_left: 200,
        chars_left_Name: 30,
        toggleCharLeftForName: false,
        toggleCharLeftForDescription: false,
      }));
    }
  }


  render () {
    const { forkCounter } = this.props;
    const {
      forkingDailogModal,
      errorTextForNameOfProp,
      chars_left,
      chars_left_Name,
      toggleCharLeftForName,
      toggleCharLeftForDescription,
      nameForTheProp,
    } = this.state;
    var decideForParaColor = chars_left < 0 ? 'paragraphErrorForkCodeView' : 'paragraphForkCodeView';
    var decideForParaColorInName = chars_left_Name < 0 ? 'paragraphErrorForNameForkCodeView' : 'paragraphForNameForkCodeView';
    let decideForButtonDisable = nameForTheProp.trim() === '' ||
      chars_left < 0 ||
      chars_left_Name < 0 ||
      forkCounter === 0 ? 'button_disabledForkCodeView' : 'buttonForkCodeView button1ForkCodeView';
    return (
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={this.openForkDailogModal}
          // style={{ width: '112%', background: 'var(--primaryColor)', color: 'white' }}
          style={forkCounter === 0 ? this.buttonStyleDisabled : this.buttonStyle}
          disabled={forkCounter === 0}
        >
          Save code as
        </Button>

        <Dialog
          open={forkingDailogModal}
          onClose={this.closeForkDailogModal}
          fullWidth={true}
        >
          <DialogTitle
            id="alert-dialog-title"
            className="headerForkCodeView"
            style={{
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            {' SAVE AS '}
          </DialogTitle>
          <DialogContent>
            <span className='spanForkCodeView'>Saves Left:</span>&nbsp;<span style={{ color: '#f44336', fontWeight: '700' }}>{forkCounter}</span>
            <h4 style={{ color: 'var(--primaryColor)' }}>Name*:</h4>
            <TextField
              id="outlined-textarea"
              // label="Name"
              placeholder="Name for your reference"
              multiline
              variant="outlined"
              required={true}
              errortext={errorTextForNameOfProp}
              onChange={this.handleNameChange}
              style={{
                justifyContent: 'center',
                display: 'flex',
                width: '100%',
              }}
            />
            <br />

            {toggleCharLeftForName ? <p className={decideForParaColorInName}> Characters Left: {this.state.chars_left_Name}</p> : null}
            <h4 style={{ color: 'var(--primaryColor)' }}>Description:</h4>
            <textarea
              className="textarea"
              rows={5}
              placeholder="Enter Description"
              onChange={this.handleDescriptionChange}
              style={{
                resize: 'none',
                width: '100%',
              }}
            />
            {toggleCharLeftForDescription ? <p className={decideForParaColor}> Characters Left: {this.state.chars_left}</p> : null}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeForkDailogModal} color="primary" className="buttonForkCodeView button3ForkCodeView">
              Cancel
            </Button>
            <Button
              disabled={forkCounter === 0 || chars_left < 0 || chars_left_Name < 0 || nameForTheProp.trim() === ''}
              onClick={this.forkfunction}
              color="primary"
              className={decideForButtonDisable}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ForkCode.propTypes = {
  forkTheCurrentCode: PropTypes.func.isRequired,
  forkCounter: PropTypes.number.isRequired,
  currentCode: PropTypes.object.isRequired,
  frameworkId: PropTypes.string.isRequired,
};

ForkCode.defaultProps = {
  forkCounter: TOTAl_NUMBER_OF_FORKS_AVAILABLE,
  currentCode: {
    html: '',
    css: '',
    js: '',
  },
  frameworkId: 'HTML',
};

// const mapStateToProps = state => ({
//   // editorTheme: state.editor.theme,
//   currentCode: state.code.currentCode,
//   // isAutoCompleteEnabled: state.editor.isAutoCompleteEnabled,
//   // forkCounter: state.editor.forkCounter,
//   forkCounter: state.code.forkCounter,
//   frameworkId: state.sandbox.frameworkId,
// });

// const mapDispatchToProps = dispatch => ({
//   forkTheCurrentCode: (name, description, code, frameworkId) => {
//     dispatch(forkTheCurrentCode(name, description, code, frameworkId));
//   },
//   decrementTheForkCount: () => {
//     dispatch(decrementTheForkCount());
//   },
// });

// export default connect(mapStateToProps, mapDispatchToProps)(ForkCode);
export default ForkCode;