import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';

class DeleteFileView extends Component {
  constructor (props) {
    super(props);
    const { isDeleteFileDialogOpened } = this.props;
    this.state = {
      deleteFileDialogModal: isDeleteFileDialogOpened,
    };
  }
  openDeleteFileDialogModal = () => {
    this.setState({
      deleteFileDialogModal: true,
    });
  };
  closeDeleteFileDialogModal = () => {
    const {
      closeDeleteFileDialog,
    } = this.props;
    this.setState({
      deleteFileDialogModal: false,
    });
    closeDeleteFileDialog();
  };
  deleteFile = () => {
    const {
      fileDescriptor,
      deleteFileOrFolderOfSandbox,
    } = this.props;
    deleteFileOrFolderOfSandbox(fileDescriptor,false);
    this.closeDeleteFileDialogModal();
  };
  render () {
    const {
      name,
    } = this.props;
    const { deleteFileDialogModal } = this.state;
    return (
      <div>
        <Dialog
          open = { deleteFileDialogModal }
          onClose = {this.closeDeleteFileDialogModal}
          fullWidth
        >
          <DialogTitle
            className = "headerExitSandboxView"
            style = {{ justifyContent: 'center', display: 'flex' }}
          >
            {' Delete File '}
          </DialogTitle>
          <DialogContent
            style = {{
              display: 'flex',
              color: 'black',
              justifyContent: 'center',
              fontSize: '18px',
            }}
          >
            { `Are you sure you want to delete the file ${name} ?` }
          </DialogContent>
          <DialogActions>
            <Button
              onClick = {this.closeDeleteFileDialogModal}
              color = "primary"
              className = "buttonExitSandboxView button3ExitSandboxView"
              id = 'cancelDeleteFileButton'
            >
              Cancel
            </Button>
            <Button
              onClick = {this.deleteFile}
              color = 'primary'
              className = 'buttonExitSandboxView button1ExitSandboxView'
              id = 'deleteFileButton'
              title = 'Delete File'
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

DeleteFileView.propTypes = {
  name: PropTypes.string.isRequired,
  isDeleteFileDialogOpened: PropTypes.bool.isRequired,
  closeDeleteFileDialog: PropTypes.func.isRequired,
  fileDescriptor: PropTypes.string.isRequired,
  deleteFileOrFolderOfSandbox: PropTypes.func.isRequired,
};

DeleteFileView.defaultProps = {
  name: '',
  isDeleteFileDialogOpened: false,
  closeDeleteFileDialog: () => {
  },
  fileDescriptor: '',
  deleteFileOrFolderOfSandbox: () => {
  },
};

export default DeleteFileView;