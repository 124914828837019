import React from 'react';
// import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';

// import { fetchPrivateSandboxUsingRedirection } from '../../redux/sandbox/actions';

import TopNavBar from '../TopNavBar';

class PrivateSandboxRedirection extends React.Component {
  componentDidMount () {
    const {
      sessionId,
    } = this.props.match.params;
    // const requestDetails = window.parent.location;
    // if (window.location === window.parent.location) {
    //   window.open('/', '_self');
    // }

    let url = this.props.history.location.search;
    let queryParams = queryString.parse(url);

    const {
      sandboxId,
    } = queryParams;

    const {
      fetchSandbox,
      history,
    } = this.props;

    fetchSandbox(sessionId, history, sandboxId);
  }
  render () {
    return (
      <div>
        <TopNavBar />
      </div>
    );
  }
}

PrivateSandboxRedirection.propTypes = {
  match: PropTypes.object.isRequired,
  fetchSandbox: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

PrivateSandboxRedirection.defaultProps = {
  match: {
    params: {
      sessionId: '',
    },
  },
  history: {
    push: () => {
    },
  },
};

// const mapStateToProps = () => ({

// });

// const mapDispatchToProps = dispatch => ({
//   fetchSandbox: (sessionId, history) => {
//     dispatch(fetchPrivateSandboxUsingRedirection(sessionId, history));
//   },
// });

// export default connect(mapStateToProps, mapDispatchToProps)(PrivateSandboxRedirection);
export default PrivateSandboxRedirection;
