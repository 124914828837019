// import { dummyFilesInSandboxForReactSandbox } from '../../../Constants';
// import { getContent, getFileContent } from '../html/bundler';
// import { createEditor } from './reactWrapperFunctionCreator';
import pathResolver from '../pathResolver';
import fileResolver from '../../../utils/fileResolver';
import traverse from '@babel/traverse';
import * as babel  from '@babel/standalone';
import * as babelParser  from '@babel/parser';
import stringifyObject from 'stringify-object';
import {
// totalCssContent,
  basicHtmlStructure,
  extraParametersForStringifyObject,
} from '../../../configFiles/config';
import {
  resolvedDependenciesForReactMicroServiceSandbox,
} from '../../../Constants/index';
import { createResultantBundleStringForReact } from '../../../utils/utilityFunctions';
// import * as Acorn from "acorn";
// import ObjPath from "object-path";
// import { generate as generateJs } from "escodegen";

let ID = 0;

let resultantCssBundle = '';


const bundleDocumentForHtml = document.implementation.createHTMLDocument('HTML bundle');
bundleDocumentForHtml.documentElement.innerHTML = basicHtmlStructure;

// function isReactNode(node) {
//   const type = node.type; //"ExpressionStatement"
//   const obj = ObjPath.get(node, "expression.callee.object.name");
//   const func = ObjPath.get(node, "expression.callee.property.name");
//   return (
//     type === "ExpressionStatement" &&
//     obj === "React" &&
//     func === "createElement"
//   );
// }

// export function findReactNode(ast) {
//   const { body } = ast;
//   return body.find(isReactNode);
// }

const getContent = (remainingPath, remainingStructure, index) => {
  const key = remainingPath[index];
  if (remainingStructure[key] === null || remainingStructure[key] === undefined) {
    throw Object({ error: `Cannot resolve module "${remainingPath.join('/')}"` });
  }
  if (typeof remainingStructure[key] === 'string') {
    return remainingStructure[key];
  }
  
  const traversedStructure = remainingStructure[key];
  return getContent(remainingPath, traversedStructure, index + 1);
};

const getFileContent = (path, resolvedFiles,filesWithUpdatedContentInSandbox) => {
  const fullPath = path.split('/').filter(pathKey => pathKey !== '');
  const descriptor = getContent(fullPath, resolvedFiles, 0);
  const fileContent = filesWithUpdatedContentInSandbox[descriptor].content;
  return fileContent;
};

// const astreeFromJsx = (code) => {
//   const ast = Acorn.parse(code, {
//     sourceType: "module"
//   });
//   const rnode = findReactNode(ast);

//   if (rnode) {
//     const nodeIndex = ast.body.indexOf(rnode);
//     const createElSrc = generateJs(rnode).slice(0, -1);
//     const renderCallAst = Acorn.parse(`render(${createElSrc})`).body[0];

//     ast.body[nodeIndex] = renderCallAst;
//   }

//   return ast;

// }

function createRequiredFieldsObject (canonicalFilePath,filesWithUpdatedContentInSandbox){
  const resolvedTree = fileResolver({}, filesWithUpdatedContentInSandbox);
  const content = getFileContent(canonicalFilePath, resolvedTree,filesWithUpdatedContentInSandbox);

  let extensionOfAsset = canonicalFilePath.substring(canonicalFilePath.lastIndexOf('.') + 1);


  // let ast = babylon.parse(content, {
  //   sourceType: 'module',
  // });

  const id = ID++;
  const dependencies = [];

  if (extensionOfAsset === 'css'){
    // totalCssContent += content;
    
    const styleTag = bundleDocumentForHtml.createElement('style');
    styleTag.id = canonicalFilePath;
    styleTag.innerHTML = content;
    const headElement = bundleDocumentForHtml.getElementsByTagName('head')[0];
    headElement.appendChild(styleTag);
    
    return {
      id,
      canonicalFilePath,
      dependencies,
      content,
    };
    
  }

  let ast = babelParser.parse(content, {
    sourceType: 'module',
    plugins: [
      'jsx',
      'flow',
      // ["transform-import-css", {
      //   "generateScopedName": "lib-[name]-[local]-[hash:base64:4]"
      // }]
    ],
  });


  traverse(ast, {
    ImportDeclaration: ({ node }) => {
      dependencies.push(node.source.value);
    },
  });

  const { code } = babel.transformFromAst(ast, null, {
    presets: ['env',
      'react',
    ],
    // plugins: [
    //   ["transform-import-css", {
    //     "generateScopedName": "lib-[name]-[local]-[hash:base64:4]"
    //   }]
    // ]
  });
  return {
    id,
    canonicalFilePath,
    dependencies,
    code,
  };
}


function createGraphOfDependentFiles (entry,filesWithUpdatedContentInSandbox) {

  const entryFileForCreatingDependency = createRequiredFieldsObject(entry,filesWithUpdatedContentInSandbox);
  const queueOfAllDependentFiles = [entryFileForCreatingDependency];


  for (const asset of queueOfAllDependentFiles) {
    asset.mapping = {};
    const directoryName = asset.canonicalFilePath.substring(0,asset.canonicalFilePath.lastIndexOf('/') + 1 );
    
    asset.dependencies.forEach(relativePath => {
      // console.log('::::::::::::relativePath',relativePath);
      // console.log('::::::::::::dirname',dirname);

      const fileNameForPathResolver = relativePath.substring(relativePath.lastIndexOf('/') + 1 );
      const absolutePath = pathResolver(fileNameForPathResolver, relativePath, directoryName);
      const immediateDependentFileRequiredFields = createRequiredFieldsObject(absolutePath,filesWithUpdatedContentInSandbox);
      asset.mapping[relativePath] = immediateDependentFileRequiredFields.canonicalFilePath;
      queueOfAllDependentFiles.push(immediateDependentFileRequiredFields);
    });
  }
  return queueOfAllDependentFiles;

}


function createBundle (graph) {

  let modules = '';
  graph.forEach( mod => {
    modules += `${JSON.stringify(mod.canonicalFilePath)}: [
      function (require, module, exports) {
        ${mod.code}
      },
      ${JSON.stringify(mod.mapping)},
    ],`;

  });

  // let indirectCallToEval = eval;
  // let myobj = indirectCallToEval('({' + modules + '})');

  // eslint-disable-next-line
  let moduleObject = Function('"use strict";return ({' + modules + '})')();

 
  // console.log(':::::::::::moduleObject',moduleObject);
 
  const moduleString = stringifyObject(moduleObject,extraParametersForStringifyObject);

  return moduleString;
}




export default (canonicalFilePath,filesWithUpdatedContentInSandbox, dependentPackagesForSandboxResolvedHtml, reactVersion = 16 ) => {
  return new Promise((resolve, reject) => {
    try {
      const graphOfDependentFiles = createGraphOfDependentFiles(canonicalFilePath,filesWithUpdatedContentInSandbox);
      const resultantModules = createBundle(graphOfDependentFiles);

      const styleTags =  bundleDocumentForHtml.getElementsByTagName('style');
      Object.keys(styleTags).forEach((index) => {
        resultantCssBundle += String(styleTags[index].innerHTML);
      });
      // console.log('::::::::dependentPackagesForSandboxResolvedHtml in bundler',dependentPackagesForSandboxResolvedHtml);

      const resultentReactScript = `
      (function(modules) {
        function require(canonicalFilePath) {
          const [fn, mapping] = modules[canonicalFilePath];
          function localRequire(name) {
            return require(mapping[name]);
          }
          const module = { exports : {} };
          fn(localRequire, module, module.exports);
          return module.exports;
        }
        require(${JSON.stringify('/root/index.js')});
      })(${resultantModules})
    `;

      let dependentPackagesToBeSent = '';
      if (!dependentPackagesForSandboxResolvedHtml && reactVersion === 16) {
        dependentPackagesToBeSent = resolvedDependenciesForReactMicroServiceSandbox;
      }
      else {
        dependentPackagesToBeSent = dependentPackagesForSandboxResolvedHtml;
      }

      const reactBundle = createResultantBundleStringForReact(
        dependentPackagesToBeSent,
        resultantCssBundle,
        resultentReactScript
      );
      resolve({
        data: {
          bundle: reactBundle,
          resultantModules,
          resultantCssBundle,
          bundleDocumentForHtmlWithCss: bundleDocumentForHtml,
        },
      });
    } catch (ex) {
      reject(ex);
    }
  });
};


