import {
  changePathAndDescriptorIdOfTheFileToOpen,
  openSelectedFileFromFileExplorerDrawer,
} from '../../redux/sandbox/actions';

export const mapStateToProps = state => ({
  pathOfFileToOpen: state.sandbox.pathOfFileToOpen,
  descriptorIdToOpenFileInOpenFileView: state.sandbox.descriptorIdToOpenFileInOpenFileView,
  filesInFileSystemSandbox: state.sandbox.filesInFileSystemSandbox,
});
  
export const mapDispatchToProps = (dispatch) => ({
  changePathAndDescriptorIdOfTheFileToOpen: (currentPath,descriptorId) => {
    dispatch(changePathAndDescriptorIdOfTheFileToOpen(currentPath, descriptorId));
  },
  openSelectedFileFromFileExplorerDrawer: (descriptorId) => {
    dispatch(openSelectedFileFromFileExplorerDrawer(descriptorId));
  },
});