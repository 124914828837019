import { rootLevelPathIndentifier } from '../../configFiles/config';

// Required:
//  1. Current file path (/root/src/components/);
//  2. Name of the required file (style.css)
//  3. Relative path (./style.css)
//  4. Relative path (../styles/style.css)
//  5. Absolute path (style.css). { Resolved at the root level }

//  6. Complete path is specifed. { /root/src/styles/style.css }

const getRootPath = (fileName) => {
  const path = fileName.startsWith('/') ? `/${rootLevelPathIndentifier}${fileName}` : `/${rootLevelPathIndentifier}/${fileName}`;
  return path;
};

const getFolderRelativePath = (fileName, currentPath) => {
  // console.log('::::::::::::in the reletive path fileName',fileName);
  const path = `${currentPath}${fileName}`;
  return path;
};

// Refactor variables.
const getRelativePath = (fileName, currentPath, requiredPath) => {
  const splitRequiredPath = requiredPath.split('../');
  const moveUpLevels = splitRequiredPath.filter(path => path === '').length;
  const remainingRequiredPath = splitRequiredPath.filter(path => path !== '');

  if (remainingRequiredPath.length === 0) {
    remainingRequiredPath.push(fileName);
  }

  const splitPath = currentPath.split('/');
  const desiredPath = splitPath.splice(0, (splitPath.length - moveUpLevels));
  const fullPath = [];
  desiredPath.forEach(path => fullPath.push(path));
  remainingRequiredPath.forEach(path => fullPath.push(path));

  const path = fullPath.join('/');

  return path;
};

export default (fileName, requiredPath, currentPath) => {
  let filePath = '';
  let typeOfImport = 'ROOT_LEVEL';

  if (requiredPath.startsWith(`${rootLevelPathIndentifier}`) || requiredPath.startsWith(`/${rootLevelPathIndentifier}`)) {
    typeOfImport = 'ABSOLUTE';
  } else if (requiredPath.startsWith('./')) {
    typeOfImport = 'FOLDER_RELATIVE';
  } else if (requiredPath.startsWith('../')) {
    typeOfImport = 'RELATIVE';
  }

  switch (typeOfImport) {
  case 'ROOT_LEVEL':
    filePath = getRootPath(requiredPath);
    break;

  case 'FOLDER_RELATIVE':
    filePath = getFolderRelativePath(fileName, currentPath);
    break;

  case 'RELATIVE':
    filePath = getRelativePath(fileName, currentPath, requiredPath);
    break;
  
  default:
    filePath = requiredPath;
  }

  return filePath;
};
