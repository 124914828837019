
import { connect } from 'react-redux';
import CreateAFileView from './CreateAFileView';

import {
  mapStateToProps,
  mapDispatchToProps,
} from './props';


export default connect(mapStateToProps,mapDispatchToProps)(CreateAFileView);



