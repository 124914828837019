import { connect } from 'react-redux';
import CssEditor from './CssEditor';

import {
  mapStateToProps,
  mapDispatchToProps,
} from './props';
  
export default connect(mapStateToProps,mapDispatchToProps)(CssEditor);
// export default CssEditor;
