import {
  UPDATE_TOKEN,
} from './reducers';

export const setSessionToken = (token) => (dispatch) => {
  dispatch({
    type: UPDATE_TOKEN,
    payload: {
      data: {
        token,
      },
    },
  });
};
