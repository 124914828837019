import { Button } from '@material-ui/core';
import React, { Component } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';
import CssEditor from '../CssEditor';
import JavaScriptEditor from '../JavaScriptEditor';
import Loader from '../Loader';
import Preview from '../Preview';
import SideNav from '../SideNav';
import { TOTAl_NUMBER_OF_FORKS_AVAILABLE } from '../../configFiles/config';
import { handleConnectionDown } from '../../utils/utilityFunctions';
import './ReactBasicSandbox.css';

class ReactBasicSandbox extends Component {
  constructor (props) {
    super(props);
    this.state = {
      toggleReactEditor: true,
      toggleCssEditor: false,
    };
  }

  handleReactRender=() => {
    this.setState(() => ({
      toggleReactEditor: true,
      toggleCssEditor: false,
    }));
  }

  handleCssRender=() => {
    this.setState(() => ({
      toggleCssEditor: true,
      toggleReactEditor: false,
    }));
  }

  componentDidMount (){
    window.addEventListener('offline', () => {
      handleConnectionDown( 'Internet connection is lost', toast.POSITION.TOP_RIGHT );
    });
  }

  render () {
    const {
      toggleReactEditor,
      toggleCssEditor,
    } = this.state;
    const {
      isSavingCurrentCode,
      isForkingCode,
      isFetchingForks,
      forkCounter,
      isInMicroService,
      isFileSystemEnabled,
    } = this.props;

    var styleDecideForTopNav =
      (window.location !== window.parent.location) ?
        'topNav-sideIframe'
        :
        'topNav-side';

    var styleDecideForEditorNavigation =
    (isInMicroService) ?
      'navbar_microsevice'
      :
      'navbar';

    var styleDecideForEditorSide =
    (isInMicroService) ?
      'editor-side-microservice'
      :
      'editor-side';
    var styleDecideForPreviewSide =
    (isInMicroService) ?
      'preview-side-microservice'
      :
      'preview-side';


    return (
      <div>
        <div className='outer-div'>
          <div className={styleDecideForTopNav}>
          </div>
          {
            isInMicroService
              ?
              null
              :
              <div className='sideNav-side'>
                <SideNav
                  forkCounter={forkCounter}
                  isFileSystemEnabled={isFileSystemEnabled}
                />
              </div>
          }
          <div>
            <div className={styleDecideForEditorSide}>
              <div className={styleDecideForEditorNavigation}>
                <Button
                  className={toggleReactEditor ? 'editorButtonInReactBasicSandboxStyleWhenActive button' :
                    'editorButtonInReactBasicSandboxStyle button'}
                  id = 'ReactEditorButtonInReactBasicSandbox'
                  variant="contained"
                  onClick={() => this.handleReactRender()}
                >
                  React
                </Button>
                <Button
                  className={toggleCssEditor ? 'editorButtonInReactBasicSandboxStyleWhenActive button' :
                    'editorButtonInReactBasicSandboxStyle button'}
                  id = 'CssEditorButtonInReactBasicSandbox'
                  variant="contained"
                  onClick={() => this.handleCssRender()}
                >
                  CSS
                </Button>
              </div>
              <div className='editor-side-editor'>
                {toggleReactEditor && <JavaScriptEditor isInMicroService={isInMicroService}/>}
                {toggleCssEditor && <CssEditor isInMicroService={isInMicroService}/>}
              </div>
            </div>
            <div className={styleDecideForPreviewSide}>
              <Preview
                isSandBoxPrivate={false}
                isInMicroService={isInMicroService}
              />
            </div>
          </div>
        </div>
        {
          isSavingCurrentCode || isForkingCode || isFetchingForks ?
            <Loader />
            :
            null
        }
        <ToastContainer
          newestOnTop={true}
          enableMultiContainer={true}
          limit={5}
          position={toast.POSITION.BOTTOM_RIGHT}
          toastId="TOAST_CONTAINER_FOR_SAVING_CODE"
          hideProgressBar={true}
        />
      </div>
    );
  }
}

ReactBasicSandbox.propTypes = {
  isSavingCurrentCode: PropTypes.bool.isRequired,
  forkCounter: PropTypes.number.isRequired,
  isFetchingForks: PropTypes.bool.isRequired,
  isForkingCode: PropTypes.bool.isRequired,
  isInMicroService: PropTypes.bool.isRequired,
  isFileSystemEnabled: PropTypes.bool,
};

ReactBasicSandbox.defaultProps = {
  isSavingCurrentCode: false,
  forkCounter: TOTAl_NUMBER_OF_FORKS_AVAILABLE,
  isFetchingForks: false,
  isForkingCode: false,
  isInMicroService: false,
};

export default ReactBasicSandbox;