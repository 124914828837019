import { changeCurrentCode } from '../../redux/code/actions';

export const mapStateToProps = state => ({
  editorTheme: state.editor.selectedTheme,
  currentCode: state.code.currentCode,
  isAutoCompleteEnabled: state.editor.isAutoCompleteEnabled,
  indentationForEditors: state.editor.indentationForEditors,
  frameworkId: state.sandbox.frameworkId,
});

export const mapDispatchToProps = dispatch => ({
  changeCurrentCode: (language, frameworkId, code) => {
    dispatch(changeCurrentCode(language, frameworkId, code));
  },
});