export const UPDATE_TOKEN = 'auth/UPDATE_TOKEN';

const defaultState = {
  token: undefined,
};

const auth = (state = defaultState, action) => {
  switch (action.type) {
    
  case UPDATE_TOKEN: {
    const { data } = action.payload;
    const {
      token,
    } = data;
    return {
      ...state,
      token,
    };
  }

  default:
    return state;
  }
};

export default auth;
