import React, { Component } from 'react';

class ResourceNotAvailableView extends Component {
  render () {
    return (
      <div style = {{ fontSize: '20px', color: 'black', justifyContent: 'center', display: 'flex'  }}>
        The requested resource cannot be opened.
      </div>
    );
  }
}

export default ResourceNotAvailableView;