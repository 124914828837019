export const javascriptDefaultCode = `/*function myFunction () {
   alert('Hi');
}*/
`;
export const htmlDefaultCode = `<HTML>
<HEAD>
   <TITLE>My first HTML document</TITLE>
</HEAD>
<BODY>
   <P>Hello world!</P>
</BODY>
</HTML>`;
export const cssDefaultCode = `/*
.class_name{
   color:red
}
*/`;
export const reactDefaultCode = `
class App extends React.Component {
   render() {
     return (
       <div>
       <p>Hello world!</p>
       </div>
     )
   }
 }
 ReactDOM.render(<App />, document.querySelector('#root'));
 `;

export const angularJSDefaultCode = `
<head>
</head>
<body>
<div ng-app="myApp" ng-controller="myCtrl" class = 'class_name'>
  {{name}}
</div>
<script>
  var app = angular.module('myApp', []);
  app.controller('myCtrl', function($scope) {
  $scope.name = "Hello world";
});
</script>
</body>
`;