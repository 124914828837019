import { toast } from 'react-toastify';
import {
  lightTheme,
  defaultIndenation,
} from '../../configFiles/config';

export const CHANGE_THEME = 'editor/CHANGE_THEME';
export const CHANGE_INDENTATION = 'editor/CHANGE_INDENTATION';
export const TOGGLE_AUTOCOMPLETE = 'editor/TOGGLE_AUTOCOMPLETE';
export const UPDATE_AUTO_COMPLETE_DISABLED_FLAG = 'editor/UPDATE_AUTO_COMPLETE_DISABLED_FLAG';

const defaultState = {
  selectedTheme: lightTheme,
  isAutoCompleteEnabled: true,
  isPersonAnHR: true,
  indentationForEditors: defaultIndenation,
  isAutoCompleteDisabled: false,
};

const editor = (state = defaultState, action) => {
  switch (action.type) {
  case CHANGE_THEME: {
    const { data } = action.payload;
    const { editorTheme } = data;

    return {
      ...state,
      selectedTheme: editorTheme,
    };
  }

  case CHANGE_INDENTATION: {
    const { data } = action.payload;
    const { spaces } = data;
    return {
      ...state,
      indentationForEditors: spaces,
    };
  }

  case TOGGLE_AUTOCOMPLETE: {
    const { data } = action.payload;
    if (!data){
      toast.error('Error occured, try again');
      return {
        ...state,
      };
    }
    const { autoCompleteToggleValue } = data;
    if (autoCompleteToggleValue === null){
      toast.error('Error occured, try again');
      return {
        ...state,
      };
    }

    return {
      ...state,
      isAutoCompleteEnabled: autoCompleteToggleValue,
    };
  }

  case UPDATE_AUTO_COMPLETE_DISABLED_FLAG: {
    const { payload: { data } } = action;
    if (!data) {
      return {
        ...state,
      };
    }

    const { isAutoCompleteDisabled } = data;

    if (isAutoCompleteDisabled === null) {
      return {
        ...state,
      };
    }
    
    return {
      ...state,
      isAutoCompleteDisabled: isAutoCompleteDisabled,
    };
  }

  default:
    return state;
  }
};

export default editor;
