import { frontEndUrl } from '../../configFiles/config';

const checkIfImportIsInternal = (url) => {
  return url.startsWith(`${frontEndUrl}/`);
};

export const filterHTML = (code) => {
  if (code) {
    const originalCode = document.implementation.createHTMLDocument('Filtered HTML');
    originalCode.documentElement.innerHTML = code;
    const allLinkTags = originalCode.getElementsByTagName('link');

    Object.values(allLinkTags).forEach((tag) => {
      if (tag.rel === 'stylesheet' & checkIfImportIsInternal(tag.href)) {
        tag.remove();
      }
    });

    const allScriptTags = originalCode.getElementsByTagName('script');

    Object.values(allScriptTags).forEach((tag) => {
      if (checkIfImportIsInternal(tag.src)) {
        tag.remove();
      }
    });

    const allImageTags = originalCode.getElementsByTagName('img');

    Object.values(allImageTags).forEach((tag) => {
      if (checkIfImportIsInternal(tag.src)) {
        tag.remove();
      }
    });


    const filteredHTML = originalCode.documentElement.innerHTML;

    return filteredHTML;
  } else return '';
};

export const handleNavigation = (code) => {
  if (code) {
    const originalCode = document.implementation.createHTMLDocument('Filtered HTML');
    originalCode.documentElement.innerHTML = code;
    const allATags = originalCode.getElementsByTagName('a');

    let shouldBeProvideNavigationHandler = false;

    Object.values(allATags).forEach((tag) => {
      const tagHref = tag.href;
      if (checkIfImportIsInternal(tagHref)) {
        // const idNavigationIndex = tagHref.indexOf('#');
        // if (idNavigationIndex >= 0) {
        shouldBeProvideNavigationHandler = true;
        // }
      }
    });

    let handledNavigations = shouldBeProvideNavigationHandler ?
      `
      <style>
        html {
          scroll-behavior: smooth;
        }
      </style>
      <script>
        const scrollToElement = (navigate) => {
          if (navigate === '') {
            window.scrollTo(0, 0);
          } else {
            const element = document.getElementById(navigate);
            element.scrollIntoView(true)
          }
        };

        const frontEndUrl = "${frontEndUrl}";
        const sandboxUrl = "${frontEndUrl}/sandbox/"

        const checkIfImportIsInternal = (url) => {
          return url.startsWith(frontEndUrl);
        };

        document.addEventListener('click', function (event) {
          // scrollToElement(navigationPath);
          const sourceElement = event.srcElement;
          const nodeName = sourceElement.nodeName;

          const parentNode = sourceElement.parentNode;

          if (nodeName === 'A' || (parentNode && parentNode.nodeName === 'A')) {
            const sourceHref = nodeName === 'A' ? sourceElement.href : (parentNode && parentNode.href);
            const idNavigationIndex = sourceHref ? sourceHref.indexOf('#') : -1;

            if (checkIfImportIsInternal(sourceHref) && idNavigationIndex >= 0) {
              event.preventDefault();
              const navigationPath = sourceHref.slice(idNavigationIndex + 1);
              scrollToElement(navigationPath);
            } else {
              event.preventDefault();
              window.location.href = '/resourceNotAllowed';
            }
          }
        });
      </script>`
      :
      '';

    return handledNavigations;
  } else return '';
};
