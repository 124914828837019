import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { validExtensionForFiles, initialPathForFileStructure } from '../../configFiles/config';
import PropTypes from 'prop-types';
import FileStructureView from '../FileStructureView';

export class OpenFileView extends Component {
  constructor (props) {
    super(props);
    const { openDialogByDefault } = this.props;
    this.state = {
      dialogModal: openDialogByDefault,
    };
  }
  openDialogModal = () => {
    this.setState(() => ({
      dialogModal: true,
    }));
  };

  closeDialogModal = () => {
    const { closeDialog, changePathAndDescriptorIdOfTheFileToOpen } = this.props;
    this.setState(() => ({
      dialogModal: false,
    }));
    if (closeDialog) {
      closeDialog();
    }
    changePathAndDescriptorIdOfTheFileToOpen(initialPathForFileStructure,'');
  };

  openFile = () => {
    const {
      openSelectedFileFromFileExplorerDrawer,
      changeSelectedFileOnFileOpen,
      descriptorIdToOpenFileInOpenFileView,
    } = this.props;
    openSelectedFileFromFileExplorerDrawer(descriptorIdToOpenFileInOpenFileView);
    setTimeout(() => { changeSelectedFileOnFileOpen(descriptorIdToOpenFileInOpenFileView); }, 10);
    this.closeDialogModal();
  }

  componentDidMount () {
    const { changePathAndDescriptorIdOfTheFileToOpen } = this.props;
    changePathAndDescriptorIdOfTheFileToOpen(initialPathForFileStructure,'');
  }

  render () {
    const { dialogModal } = this.state;
    const {
      pathOfFileToOpen,
      descriptorIdToOpenFileInOpenFileView,
      filesInFileSystemSandbox,
    } = this.props;
    let extension = '';
    if ( descriptorIdToOpenFileInOpenFileView !== 'folder' && descriptorIdToOpenFileInOpenFileView !== '') {
      extension = filesInFileSystemSandbox[descriptorIdToOpenFileInOpenFileView].extension;
    }
    const isOpenButtonDisabled = (Object.keys(validExtensionForFiles['HTML'])).includes(extension) ? false : true;
    return (
      <div>
        <div
          style = {{
            display: 'flex',
            justifyContent: 'center',
            // marginTop: '2%',
          }}
        >
          {/* <Button
            variant="contained"
            onClick={this.openDialogModal}
            style={{ width: '10vw', background: 'var(--primaryColor)', color: 'white', height: '6vh' }}
            id = 'openFileButton'
          >
            Open File
          </Button> */}
        </div>

        <Dialog
          open = { dialogModal }
          onClose = { this.closeDialogModal }
          fullWidth = { true }
          maxWidth = 'xs'
        >
          <DialogTitle
            className = "headerExitSandboxView"
            style = {{ justifyContent: 'center', display: 'flex' }}
          >
            {' Open file '}
          </DialogTitle>
          <DialogContent>
            <div>
              <input value = { pathOfFileToOpen }
                style = {{
                  height: '3vh',
                  border: '1px solid var(--primaryColor)',
                  width: '100%',
                  marginTop: '2%',
                  marginBottom: '2%',
                }}
                readOnly
              >
              </input>
            </div>
            <div>
              <div
                style = {{
                  backgroundColor: 'var(--primaryColor)',
                  height: '35vh',
                  overflow: 'scroll',
                  width: '100%',
                  scrollbarWidth: 'none',
                }}
              >
                <FileStructureView />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick = {this.openFile}
              color = 'primary'
              className = {
                isOpenButtonDisabled
                  ?
                  'disabled-button-cursor button_disabledExitSandboxView buttonExitSandboxView'
                  :
                  'buttonExitSandboxView button1ExitSandboxView'
              }
              id = 'openButton'
              title = { isOpenButtonDisabled ? 'Choose a valid file to open' : '' }
              disabled = {isOpenButtonDisabled}
            >
              Open
            </Button>
            <Button
              onClick = { this.closeDialogModal }
              color = "primary"
              className = "buttonExitSandboxView button3ExitSandboxView"
              id = 'cancelButton'
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

OpenFileView.propTypes = {
  pathOfFileToOpen: PropTypes.string.isRequired,
  openDialogByDefault: PropTypes.bool,
  closeDialog: PropTypes.func,
  changePathAndDescriptorIdOfTheFileToOpen: PropTypes.func.isRequired,
  openSelectedFileFromFileExplorerDrawer: PropTypes.func,
  changeSelectedFileOnFileOpen: PropTypes.func,
  descriptorIdToOpenFileInOpenFileView: PropTypes.string.isRequired,
  filesInFileSystemSandbox: PropTypes.object.isRequired,
};

OpenFileView.defaultProps = {
  pathOfFileToOpen: initialPathForFileStructure,
  openDialogByDefault: false,
  closeDialog: () => {
  },
  changePathAndDescriptorIdOfTheFileToOpen: () => {
  },
  openSelectedFileFromFileExplorerDrawer: () => {
  },
  changeSelectedFileOnFileOpen: () => {
  },
  descriptorIdToOpenFileInOpenFileView: '',
  filesInFileSystemSandbox: {},
};

export default OpenFileView;
