import { forkTheCurrentCode, decrementTheForkCount } from '../../redux/code/actions';

export const mapStateToProps = state => ({
  // editorTheme: state.editor.theme,
  currentCode: state.code.currentCode,
  // isAutoCompleteEnabled: state.editor.isAutoCompleteEnabled,
  // forkCounter: state.editor.forkCounter,
  forkCounter: state.code.forkCounter,
  frameworkId: state.sandbox.frameworkId,
});

export const mapDispatchToProps = dispatch => ({
  forkTheCurrentCode: (name, description, code, frameworkId) => {
    dispatch(forkTheCurrentCode(name, description, code, frameworkId));
  },
  decrementTheForkCount: () => {
    dispatch(decrementTheForkCount());
  },
});