import React from 'react';
import {
  AppBar,
  CssBaseline,
  // IconButton,
  Toolbar,
} from '@material-ui/core';
// import MenuIcon from '@material-ui/icons/Menu';


class TopNavBar extends React.Component {
  render () {
    return (
      <div>
        <CssBaseline />
        <AppBar
          style={{
            background: 'var(--primaryColor)',
            height: '72px',
          }}
          position="fixed"
          // className={}
        >
      
          <Toolbar>
          
            {/* <IconButton
              color="inherit"
              aria-label="open drawer"
              // onClick={handleDrawerOpen}
              // onMouseEnter={handleDrawerOpen}
              edge="start"
              // className={clsx(classes.menuButton, {
              //   [classes.hide]: open,
              // })}
            >
              <MenuIcon />
            </IconButton> */}
            <div className='company'>
              <div className='company-logo'>
                <a href='https://myanatomy.in/#/home'><img src="/images/myAnatomyLogo.png" alt=''/></a>
              </div>
            </div>

          </Toolbar>
        
        </AppBar>
      </div>
    );
  }
}

export default TopNavBar;
