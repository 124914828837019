// Absolute imports.
import React, { Component } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

// CSS
import './HrCodeView.css';

// Relative imports
import HtmlEditorView from '../HtmlEditorView';
// import { connect } from 'react-redux';
import CssEditor from '../CssEditor';
import JavaScriptEditor from '../JavaScriptEditor';
import Preview from '../Preview/';
// import { viewReadOnlySandbox } from '../../redux/sandbox/actions';
import Loader from '../Loader';
// import { setEmptyCode } from '../../redux/code/actions';
import FileSystemSandbox from '../FileSystemSandbox';
import { cardsForHomePage } from '../../AvailableCards/availableCards';

class HrCodeView extends Component {
  constructor (props) {
    super(props);
  
    this.state = {
      togggleHtmlEditor: true,
      toggleJavaScriptEditor: false,
      toggleCssEditor: false,
    };
  }

  componentDidMount () {
    const {
      openSandbox,
      showNoCodeInitially,
      frameworkId,
    } = this.props;

    showNoCodeInitially(frameworkId);
    const {
      sandboxId,
    } = this.props.match.params;

    openSandbox(sandboxId);
  }

  shouldComponentUpdate (nextProps) {
    const {
      frameworkId,
    } = nextProps;

    if (frameworkId && frameworkId === cardsForHomePage.ReactJS.frameworkId && frameworkId !== this.props.frameworkId) {
      this.handleJavaScriptRender();
    }
    if (frameworkId && frameworkId === cardsForHomePage.AngularJS.frameworkId && frameworkId !== this.props.frameworkId) {
      this.handleJavaScriptRender();
    }
    return true;
  }
  

  handleHtmlRender=() => {
    this.setState(() => ({
      togggleHtmlEditor: true,
      toggleJavaScriptEditor: false,
      toggleCssEditor: false,
    }));
  }
   
  handleJavaScriptRender=() => {
    this.setState(() => ({
      toggleJavaScriptEditor: true,
      togggleHtmlEditor: false,
      toggleCssEditor: false,
    }));
  }

  handleCssRender=() => {
    this.setState(() => ({
      toggleCssEditor: true,
      toggleJavaScriptEditor: false,
      togggleHtmlEditor: false,
    }));
  }

  render () {
    const { togggleHtmlEditor,toggleJavaScriptEditor,toggleCssEditor } = this.state;
    const {
      isPersonAnHR,
      isFetchingSandboxForViewing,
      isFileSystemEnabled,
      frameworkId,
    } = this.props;
    return (
      <div>
        {
          isFetchingSandboxForViewing ?
            <Loader />
            :
            null
        }
        {
          isFileSystemEnabled ?
            null
            :
            <div ><Preview isThePersonAnHr={isPersonAnHR}/></div>
        }
        
        
        {
          isFileSystemEnabled ?
            <FileSystemSandbox isNotEditable={isPersonAnHR} />
            :
            frameworkId === cardsForHomePage.HTML.frameworkId ?
              <div>
                <div
                  className='hrCodeNavbar'
                >
                  <Button
                    className={togggleHtmlEditor ? 'editorButtonInHTMLBasicSandboxStyleWhenActive button' :
                      'editorButtonInHTMLBasicSandboxStyle button'}
                    variant="contained"
                    onClick={this.handleHtmlRender}
                  >
                  HTML
                  </Button>
                  <Button
                    className={toggleCssEditor ? 'editorButtonInHTMLBasicSandboxStyleWhenActive button' :
                      'editorButtonInHTMLBasicSandboxStyle button'}
                    variant="contained"
                    onClick={this.handleCssRender}
                  >
                  CSS
                  </Button>
                  <Button
                    className={toggleJavaScriptEditor ? 'editorButtonInHTMLBasicSandboxStyleWhenActive button' :
                      'editorButtonInHTMLBasicSandboxStyle button'}
                    variant="contained"
                    onClick={this.handleJavaScriptRender}
                  >
                  JavaScript
                  </Button>
                </div>
                <div >
                  {togggleHtmlEditor && <HtmlEditorView isNotEditable={isPersonAnHR}/>}
                  {toggleJavaScriptEditor && <JavaScriptEditor isNotEditable={isPersonAnHR} />}
                  {toggleCssEditor && <CssEditor isNotEditable={isPersonAnHR} />}
                </div>
              </div>
              :
              <div>
                <div
                  className='hrCodeNavbar'
                >
                  <Button
                    className={toggleJavaScriptEditor ? 'editorButtonInReactBasicSandboxStyleWhenActive button' :
                      'editorButtonInReactBasicSandboxStyle button'}
                    variant="contained"
                    onClick={this.handleJavaScriptRender}
                  >
                    JavaScript
                  </Button>
                  <Button
                    className={toggleCssEditor ? 'editorButtonInReactBasicSandboxStyleWhenActive button' :
                      'editorButtonInReactBasicSandboxStyle button'}
                    variant="contained"
                    onClick={this.handleCssRender}
                  >
                  CSS
                  </Button>
                </div>
                <div className='editor-side-editor'>
                  {toggleJavaScriptEditor && <JavaScriptEditor isNotEditable={isPersonAnHR}/>}
                  {toggleCssEditor && <CssEditor isNotEditable={isPersonAnHR}/>}
                </div>
              </div>
        }


        <ToastContainer
          newestOnTop={true}
          enableMultiContainer={true}
          limit={5}
          position={toast.POSITION.BOTTOM_RIGHT}
          toastId="TOAST_CONTAINER_FOR_FETCHING_VIEW"
          hideProgressBar={true}
        />
      </div>
    );
  }
}

HrCodeView.propTypes = {
  isPersonAnHR: PropTypes.bool.isRequired,
  isFetchingSandboxForViewing: PropTypes.bool.isRequired,
  viewReadOnlySandbox: PropTypes.func.isRequired,
  setEmptyCode: PropTypes.func.isRequired,
  isFileSystemEnabled: PropTypes.bool,
  match: PropTypes.object,
  sandboxId: PropTypes.string,
  showNoCodeInitially: PropTypes.func,
  openSandbox: PropTypes.func,
  frameworkId: PropTypes.string,
};

HrCodeView.defaultProps = {
  isPersonAnHR: true,
  isFetchingSandboxForViewing: false,
  setEmptyCode: () => {

  },
  viewReadOnlySandbox: () => {

  },
};

// const mapStateToProps = state => ({
//   isPersonAnHR: state.editor.isPersonAnHR,
//   isFetchingSandboxForViewing: state.sandbox.isFetchingSandboxForViewing,
// });

// const mapDispatchToProps = dispatch => ({
//   // changeCurrentCode: (language, code) => {
//   //   dispatch(changeCurrentCode(language, code));
//   // }
//   openSandbox: (sandboxId) => {
//     dispatch(viewReadOnlySandbox(sandboxId));
//   },
//   showNoCodeInitially: () => {
//     dispatch(setEmptyCode());
//   },
// });

// export default connect(mapStateToProps, mapDispatchToProps)(HrCodeView);
export default HrCodeView;