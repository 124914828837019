import { viewReadOnlySandbox } from '../../redux/sandbox/actions';
import { setEmptyCode } from '../../redux/code/actions';

export const mapStateToProps = state => ({
  isPersonAnHR: state.editor.isPersonAnHR,
  isFetchingSandboxForViewing: state.sandbox.isFetchingSandboxForViewing,
  isFileSystemEnabled: state.sandbox.isFileSystemEnabled,
  frameworkId: state.sandbox.frameworkId,
});

export const mapDispatchToProps = dispatch => ({
  // changeCurrentCode: (language, code) => {
  //   dispatch(changeCurrentCode(language, code));
  // }
  openSandbox: (sandboxId) => {
    dispatch(viewReadOnlySandbox(sandboxId));
  },
  showNoCodeInitially: (frameworkId) => {
    dispatch(setEmptyCode(frameworkId));
  },
});