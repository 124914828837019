import React from 'react';

import PropTypes from 'prop-types';

class PreviewErrorBoundary extends React.Component {
  constructor (props) {
    super(props);
    
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch () {
    this.setState(() => ({
      hasError: true,
    }));
  }

  render () {
    const {
      bundleCreationError,
    } = this.props;

    const {
      hasError,
    } = this.state;

    if (hasError || bundleCreationError !== '') {
      return (
        <div>
          Error: {bundleCreationError}
        </div>
      );
    }

    return this.props.children;
  }
}

PreviewErrorBoundary.propTypes = {
  bundleCreationError: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
};

PreviewErrorBoundary.defaultProps = {
  bundleCreationError: 'Error occurred while bundling your files. Please refresh.',
  children: {},
};

export default PreviewErrorBoundary;
