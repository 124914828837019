import React, { Component } from 'react';
// import { connect } from 'react-redux';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/snippets/html';

import PropTypes from 'prop-types';

// import { changeCurrentCode } from '../../redux/code/actions';
import './HtmlEditorView.css';

class HtmlEditorView extends Component {
  constructor (props, context) {
    super(props, context);
    this.onChange = this.onChange.bind(this);
  }

  onChange = newValue => {
    const language = 'html';
    const { changeCurrentCode, frameworkId } = this.props;
    changeCurrentCode(language, frameworkId, newValue);
  }

  render () {
    const {
      editorTheme,
      isAutoCompleteEnabled,
      currentCode,
      isNotEditable,
      indentationForEditors,
      isInMicroService,
      frameworkId,
    } = this.props;
    // console.log('::::::::::inhtml ediotr', isInMicroService);
    return (
      <div>
        <AceEditor
          mode="html"
          theme={editorTheme}
          onChange={this.onChange}
          name="UNIQUE_ID_OF_DIV"
          editorProps={{
            $blockScrolling: true,
          }}
          value={currentCode[frameworkId] && currentCode[frameworkId].html}
          enableLiveAutocompletion={isAutoCompleteEnabled}
          enableSnippets={isAutoCompleteEnabled}
          setOptions={{
            showLineNumbers: true,
            showPrintMargin: false,
          }}
          autoIndentOnPaste = {true}
          tabSize = {indentationForEditors}
          height={(isInMicroService ? '82vh' : (window.location !== window.parent.location) ? '92vh' : (isNotEditable ? '92.3vh' : '82vh'))}
          width={isInMicroService ? '50vw' : (isNotEditable ? '48.9vw' : '48.6%')}
          className="AceEditor"
          style = {{ borderRight: 'none', borderTop: 'none' }}
          readOnly={isNotEditable}
          highlightActiveLine= {!isNotEditable}
          highlightGutterLine= {!isNotEditable}
        />
      </div>
    );
  }
}

HtmlEditorView.propTypes = {
  currentCode: PropTypes.object.isRequired,
  isNotEditable: PropTypes.bool.isRequired,
  isAutoCompleteEnabled: PropTypes.bool.isRequired,
  editorTheme: PropTypes.string.isRequired,
  changeCurrentCode: PropTypes.func.isRequired,
  indentationForEditors: PropTypes.number.isRequired,
  isInMicroService: PropTypes.bool.isRequired,
  frameworkId: PropTypes.string,
};

HtmlEditorView.defaultProps = {
  currentCode: {
    html: '',
    css: '',
    js: '',
  },
  isNotEditable: false,
  isAutoCompleteEnabled: true,
  editorTheme: 'LIGHT',
  indentationForEditors: 2,
  changeCurrentCode: () => {

  },
  isInMicroService: false,
};

// const mapStateToProps = state => ({
//   editorTheme: state.editor.selectedTheme,
//   isAutoCompleteEnabled: state.editor.isAutoCompleteEnabled,
//   currentCode: state.code.currentCode,
//   indentationForEditors: state.editor.indentationForEditors,
// });

// const mapDispatchToProps = dispatch => ({
//   changeCurrentCode: (language, code) => {
//     dispatch(changeCurrentCode(language, code));
//   },
// });

// export default connect(mapStateToProps, mapDispatchToProps)(HtmlEditorView);
export default HtmlEditorView;