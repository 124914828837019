import { resetCompiledHtmlToDefault } from '../../redux/code/actions';
import {
  saveClientDetails,
  setEditorPurposeId,
  // updateEditorContent,
  updateWindowType,
} from '../../redux/microService/actions';
import { changeCurrentContentOfEditor } from '../../redux/microService/actions';
import { setFrameworkId } from '../../redux/sandbox/actions';

export const mapStateToProps = state => ({
  purposeId: state.microService.purposeId,
  clientDetails: state.microService.clientDetails,
  windowType: state.microService.windowType,
  frameworkId: state.sandbox.frameworkId,
});
  
export const mapDispatchToProps = dispatch => ({
  saveClientDetails: (clientUrl, serviceType, clientDetails) => {
    dispatch(saveClientDetails(clientUrl,serviceType,clientDetails));
  },
  // updateEditorContentDetails: (purposeId, clientDetails, editorType, windowType, content) => {
  //   dispatch(updateEditorContent(purposeId, clientDetails, editorType, windowType, content));
  // },
  changeCurrentContentOfEditor: (editor,content,language) => {
    dispatch(changeCurrentContentOfEditor(editor,content,language));
  },
  updateWindowType: (windowType) => {
    dispatch(updateWindowType(windowType));
  },
  setFrameworkIdForMicroServiceSandbox: (frameworkId = undefined) => {
    dispatch(setFrameworkId(frameworkId));
  },
  resetPreview: (frameworkId = undefined) => {
    dispatch(resetCompiledHtmlToDefault(frameworkId));
  },
  setPurposeId: (purposeId, clientId) => {
    dispatch(setEditorPurposeId(purposeId, clientId));
  },
});