import React from 'react';

class DiagramsMicroserviceView extends React.Component {
  constructor (props) {
    super (props);
    this.state = {

    };
  }

  render () {
    return (
      <div>
        <iframe
          seamless
          style = {{ marginTop: '10vh', height: '90vh', width: '100vw' }}
          sandbox="allow-same-origin allow-scripts allow-popups allow-downloads"
          target = '_self'
          src = 'https://sketchpad.app/en/'
          title = 'paintWindow'
        ></iframe>
      </div>
    );
  }
}

export default DiagramsMicroserviceView;
