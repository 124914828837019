import React, { Component } from 'react';
// import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './Preview.css';

import PreviewErrorBoundary from './PreviewErrorBoundary';

class Preview extends Component {
  constructor (props) {
    super(props);

    // Test code to call the bundler.
    // const {
    //   initializeBundle,
    //   rebundle,
    // } = props;
    
    // initializeBundle();

    // setTimeout(() => {
    //   rebundle();
    // }, 1000);
    this.state = {

    };
  }

  componentWillUnmount () {
    const { setDefaultValueOnUnmount } = this.props;
    setDefaultValueOnUnmount();
  }
    
  
  initializeBundleFile = () => {
    const {
      initializeBundle,
      frameworkId,
    } = this.props;

    initializeBundle(frameworkId);
  }

  render () {
    const {
      compiledHTMLCode,
      isThePersonAnHr,
      isSandBoxPrivate,
      isInMicroService,
      bundleFile,
      bundleCreationError,
      isFileSystemEnabled,
      resolvedFilesForSandbox,
    } = this.props;
    // const blobObject = new Blob([compiledHTMLCode], { type: 'text/html' });
    // const blobObjectURL = window.URL.createObjectURL(blobObject);
    // console.log('::::::::::isThePersonAnHr',isThePersonAnHr);

    var styleDecide = (
      isInMicroService ?
        'previewForMicroservice'
        :
        ( ((window.location !== window.parent.location) && isSandBoxPrivate)
          ?
          'previewForIframeInPrivateSandboxInMapit'
          :
          
          isThePersonAnHr ?
            'previewHr'
            :
            isSandBoxPrivate ?
              'previewForIframeInPrivateSandBox'
              :
              (window.location !== window.parent.location) ?
                'previewForIframe'
                :
                'preview'
              
        )
    );

    // Initialize bundle.
    if (resolvedFilesForSandbox && !bundleFile && !bundleCreationError) {
      this.initializeBundleFile();
    }

    return (
      <div>
        {
          isFileSystemEnabled ?
            <PreviewErrorBoundary bundleCreationError={bundleCreationError}>
              <iframe
                id = "previewWindowForFileSystem"
                className={styleDecide}
                sandbox="allow-scripts allow-modals allow-popups"
                srcDoc={bundleFile}
                // src={bundleFile}
                title="previewWindow"
              ></iframe>
            </PreviewErrorBoundary>
            :
            <PreviewErrorBoundary bundleCreationError={bundleCreationError}>
              <iframe
                id = "previewWindowForBasicSandbox"
                className={styleDecide}
                sandbox="allow-scripts allow-modals allow-popups"
                srcDoc={compiledHTMLCode}
                // src={bundleFile}
                title="previewWindow"
              ></iframe>
            </PreviewErrorBoundary>
        }
      </div>
    );
  }
}

Preview.propTypes = {
  compiledHTMLCode: PropTypes.string.isRequired,
  isThePersonAnHr: PropTypes.bool,
  isSandBoxPrivate: PropTypes.bool.isRequired,
  isInMicroService: PropTypes.bool.isRequired,
  bundleFile: PropTypes.string.isRequired,
  initializeBundle: PropTypes.func.isRequired,
  rebundle: PropTypes.func.isRequired,
  bundleCreationError: PropTypes.string.isRequired,
  isFileSystemEnabled: PropTypes.bool.isRequired,
  resolvedFilesForSandbox: PropTypes.object,
  frameworkId: PropTypes.string.isRequired,
  setDefaultValueOnUnmount: PropTypes.func,
};

Preview.defaultProps = {
  compiledHTMLCode: '',
  isSandBoxPrivate: false,
  isInMicroService: false,
  bundleFile: '',
  initializeBundle: () => {
  },
  rebundle: () => {
  },
  bundleCreationError: '',
  isFileSystemEnabled: false,
};

// const mapStateToProps = state => ({
//   compiledHTMLCode: state.code.compiledHTMLCode,
// });

// export default connect(mapStateToProps)(Preview);
export default Preview;