import { changePathOftheFile,createNewFileOrFolderInSandbox } from '../../redux/sandbox/actions';

export const mapStateToProps = state => ({
  pathOfTheFile: state.sandbox.pathOfTheFile,
  fileDescriptorsMappedWithPath: state.sandbox.fileDescriptorsMappedWithPath,
  filesInFileSystemSandbox: state.sandbox.filesInFileSystemSandbox,
  frameworkId: state.sandbox.frameworkId,
});
  
export const mapDispatchToProps = dispatch => ({
  changePathOftheFile: (path) => {
    dispatch(changePathOftheFile(path));
  },
  createNewFileOrFolder: (fileName, isDirectory, parent, content, extension) => {
    dispatch(createNewFileOrFolderInSandbox(fileName, isDirectory, parent, content, extension));
  },
});
