import React from 'react';
import styled from 'styled-components';
import { fade, hexToRgb } from '@material-ui/core/styles/colorManipulator';
import PropTypes from 'prop-types';

import brand from '../../images/myAnatomyLogo.png';
import CircularProgress from '@material-ui/core/CircularProgress';

import './appBar.css';

const BrandLogo = styled.img`
  margin: auto;
  max-height: 36px;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: ${fade(hexToRgb('#FFFF'), 0.87)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

class CodeCompileSandboxRedirectionComponent extends React.Component {
  componentDidMount () {
    const { match, history, getSandboxLink } = this.props;
    const {
      codeCompileSandboxId,
    } = match.params;
    if (!codeCompileSandboxId) {
      window.location.assign('/');
    } else {
      getSandboxLink(codeCompileSandboxId, history);
    }
  }

  render () {
    const {
      isFetchingCodeCompileSandboxUrl,
    } = this.props;
    return (
      <div>
        <div className="appbarContainer">
          <BrandLogo src={brand} alt="Logo" />
        </div>
        {
          <LoaderWrapper>
            {
              isFetchingCodeCompileSandboxUrl ?
                <CircularProgress />
                :
                null
            }
          </LoaderWrapper>
        }
      </div>
    );
  }
}

CodeCompileSandboxRedirectionComponent.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getSandboxLink: PropTypes.func.isRequired,
  isFetchingCodeCompileSandboxUrl: PropTypes.bool,
};

CodeCompileSandboxRedirectionComponent.defaultProps = {
  isFetchingCodeCompileSandboxUrl: false,
};

export default CodeCompileSandboxRedirectionComponent;
