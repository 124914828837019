import React, { Component } from 'react';
// import { changeEditorTheme } from '../../redux/editor/actions';
// import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './ThemeButton.css';
import {
  lightTheme,
  darkTheme,
} from '../../configFiles/config';

class ThemeButton extends Component {
  constructor (props, context) {
    super(props, context);
    this.themeChange = this.themeChange.bind(this);
  }
    
    themeChange = (newTheme) => {
      const {
        changeCurrenttheme,
      } = this.props;
      changeCurrenttheme(newTheme);
    }
    
    render () {
      const { editorTheme } = this.props;
      return (
        <div className="theme-button">
          <div className='inner-button'>
            <label className="switch">
              <input
                className="switch-input"
                onChange={() => this.themeChange(editorTheme === darkTheme ? lightTheme : darkTheme)}
                type="checkbox"
                checked={editorTheme === darkTheme}
              />
              <span className="switch-label" data-on="DARK" data-off="light"></span> <span className="switch-handle"></span>
            </label>
          </div>
                
        </div>
      );
    }
}

ThemeButton.propTypes = {
  editorTheme: PropTypes.string.isRequired,
  changeCurrenttheme: PropTypes.func.isRequired,
};

ThemeButton.defaultProps = {
  editorTheme: 'LIGHT',
  changeCurrenttheme: () => {

  },
};

// const mapStateToProps = state => ({
//   editorTheme: state.editor.selectedTheme,
// });
// const mapDispatchToProps = dispatch => ({
//   changeCurrenttheme: (newTheme) => {
//     dispatch(changeEditorTheme(newTheme));
//   },
// });
  
// export default connect(mapStateToProps,mapDispatchToProps)(ThemeButton);
export default ThemeButton;



