import React from 'react';

// import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// import { getSessionForSandbox } from '../../redux/sandbox/actions';
import JsHtmlCssPreviewWindow from '../JsHtmlCssPreviewWindow';
import Loader from '../Loader';
import TopNavBar from '../TopNavBar';

import FileSystemSandbox from '../FileSystemSandbox';
import ReactBasicSandbox from '../ReactBasicSandbox';
import AngularJSBasicSandbox from '../AngularJSBasicSandbox';

class SandboxRouter extends React.Component {
  constructor (props) {
    super(props);
    const {
      token,
      getSandboxData,
      history,
    } = this.props;

    const {
      sandboxId,
    } = this.props.match.params;

    const urlLocation = window.location.href;

    if (!sandboxId) {
      history.push('/');
    } else if (urlLocation.includes('#')) {
      window.open('/notfound', '_self', 'noopener noreferrer');
    } else {
      if (!token) {
        // Sandbox directly opened from the URL.
        getSandboxData(sandboxId, history);
      }
  
      this.state = {
  
      };
    }
  }

  getSandboxForFramework = (frameworkId) => {
    const {
      isFileSystemEnabled,
    } = this.props;

    if (isFileSystemEnabled) {
      return <FileSystemSandbox />;
    }

    switch (frameworkId) {
    case 'HTML': {
      return <JsHtmlCssPreviewWindow/>;
    }
    case 'REACT_JS': {
      return <ReactBasicSandbox />;
    }
    case 'ANGULAR_JS': {
      return <AngularJSBasicSandbox />;
    }
    default:
      return null;
    }
  }

  render () {
    const {
      frameworkId,
      showInitializingLoader,
      token,
    } = this.props;
    
    return (
      <div>
        {
          !frameworkId ?
            <TopNavBar />
            :
            null
        }
        {
          !token ?
            null
            :
            <div>
              {this.getSandboxForFramework(frameworkId)}
            </div>
        }
        {
          showInitializingLoader ?
            <Loader />
            :
            null
        }
      </div>
    );
  }
}

SandboxRouter.propTypes = {
  frameworkId: PropTypes.string.isRequired,
  showInitializingLoader: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  getSandboxData: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  isFileSystemEnabled: PropTypes.bool.isRequired,
};

SandboxRouter.defaultProps = {
  frameworkId: 'HTML',
  showInitializingLoader: false,
  history: {
    push: () => {
    },
  },
  match: {
    params: {
      sandboxId: '',
    },
  },
  token: '',
  getSandboxData: () => {
  },
  isFileSystemEnabled: false,
};

// const mapStateToProps = state => ({
//   frameworkId: state.sandbox.frameworkId,
//   token: state.auth.token,
//   showInitializingLoader: state.sandbox.isInitializingSandbox,
// });

// const mapDispatchToProps = dispatch => ({
//   getSandboxData: (sandboxId, history) => {
//     dispatch(getSessionForSandbox(sandboxId, history));
//   },
// });

// export default connect(mapStateToProps, mapDispatchToProps)(SandboxRouter);
export default SandboxRouter;
