import {
  CHANGE_THEME,
  CHANGE_INDENTATION,
  TOGGLE_AUTOCOMPLETE,
  UPDATE_AUTO_COMPLETE_DISABLED_FLAG,
} from './reducers';

// import { get, post } from '../../utils/fetchRequests';

// export const testGetFunction = () => (dispatch, getState) => {
//   console.log(getState);
//   dispatch({
//     type: REDUCER_NAME,
//     payload: get(
//       'https://qa.myanatomy.in',
//       {
//         // Headers go here.
//       },
//     ),
//   });
// }

// export const testPostFunction = () => (dispatch, getState) => {
//   console.log(getState);
//   dispatch({
//     type: REDUCER_NAME,
//     payload: post(
//       'https://qa.myanatomy.in',
//       {
//         // Body,
//       },
//       {
//         // Headers
//       },
//     ),
//   });
// }

export const changeEditorTheme = (editorTheme) => (dispatch) => {
  dispatch({
    type: CHANGE_THEME,
    payload: {
      data: {
        editorTheme: editorTheme,
      },
    },
  });
};

export const changeEditorIndentation = (spaces) => (dispatch) => {
  
  dispatch({
    type: CHANGE_INDENTATION,
    payload: {
      data: {
        spaces: spaces,
      },
    },
  });
};

export const toggleEditorAutoComplete = (autoCompleteToggleValue) => (dispatch) => {
  dispatch({
    type: TOGGLE_AUTOCOMPLETE,
    payload: {
      data: {
        autoCompleteToggleValue: autoCompleteToggleValue,
      },
    },
  });
};

export const updateAutoCompleteToggleDisableFlag = (isAutoCompleteDisabled) => (dispatch) => {
  dispatch({
    type: UPDATE_AUTO_COMPLETE_DISABLED_FLAG,
    payload: {
      data: {
        isAutoCompleteDisabled,
      },
    },
  });
};

