
import {
  createSandbox,
} from '../../redux/sandbox/actions';

export const mapStateToProps = state => ({
  showInitializingLoader: state.sandbox.isInitializingSandbox,
});

export const mapDispatchToProps = dispatch => ({
  create: (frameworkId, history, withFileSystem) => {
    dispatch(createSandbox(frameworkId, history, withFileSystem));
  },
});
