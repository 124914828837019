import React, { Component, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { AiOutlineFile } from 'react-icons/ai';
import { DiHtml5 } from 'react-icons/di';
import { SiJavascript } from 'react-icons/si';
import { IoLogoCss3 } from 'react-icons/io';
import {
  MdChevronRight,
  MdExpandMore,
  MdDeleteForever,
} from 'react-icons/md';
// import fileResolver from '../../utils/fileResolver';
import './FileStructureView.css';
import {
  AiFillFileAdd,
  AiFillFolderAdd,
} from 'react-icons/ai';
import {
  validExtensionForFiles,
  validFolderNameRegularExpression,
  validFileNameRegularExpression,
  baseFilePath,
  initialPathForFileStructure,
  baseFilePathInReactFileSystemSandBox,
} from '../../configFiles/config';
import DeleteFileView from '../DeleteFileView';
import DeleteFolderView from '../DeleteFolderView';
import { toast } from 'react-toastify';

let currentlySelectedFileDescriptor = '';

const FILE_ICONS = {
  js: <SiJavascript color = 'yellow' />,
  css: <IoLogoCss3 color = 'blue' />,
  html: <DiHtml5 color = 'orange' />,
};

const StyledNode = styled.div`
  line-height: 1.5;
`;
const StyledFile = styled.div`
  padding-left: 20px;
  display: flex;
  align-items: center;
  div {
    margin-left: 5px;
  }
`;
const StyledFolder = styled.div`
  padding-left: 20px;

  .folder--label {
    display: flex;
    align-items: center;
    span {
      margin-left: 5px;
    }
  }
`;
const Collapsible = styled.div`
  height: ${p => (p.isOpen ? 'auto' : '0')};
  overflow: hidden;
`;

const File = (props) => {
  const {
    name,
    openFile,
    descriptor,
    changePathAndDescriptorIdOfTheFileToOpen,
    path,
    calledFromFileExplorer,
    deleteFileOrFolderOfSandbox,
    isNotEditable,
  } = props;
  const [deleteFileDialogModal, setDeleteFileDialogModal] = useState(false);
  const deleteFile = () => {
    setDeleteFileDialogModal(false);
  };
  const splitName = name.split('.');
  let extension = splitName[splitName.length - 1];
  return (
    <StyledFile>
      {FILE_ICONS[extension] || <AiOutlineFile color = 'white' />}
      <button
        // style={{ borderBottom: '1px white solid', color: 'white' }}
        className={descriptor === currentlySelectedFileDescriptor ? 'selectedFile' : 'fileNameInFileStructure' }
        onClick = {() => {
          if (calledFromFileExplorer){
            currentlySelectedFileDescriptor = descriptor;
            openFile(descriptor);
          } else {
            changePathAndDescriptorIdOfTheFileToOpen(path, descriptor);
          }
        }}
      >
        {name}
      </button>
      {
        isNotEditable ?
          null
          :
          calledFromFileExplorer && ((path !== baseFilePath) &&  (path !== baseFilePathInReactFileSystemSandBox)) ?
            <MdDeleteForever
              color = 'red'
              size = {20}
              style = {{ verticalAlign: 'center' }}
              onClick = {() => {setDeleteFileDialogModal(true);}}
              id = 'deleteIconForFile'
              title='Delete this file'
            />
            :
            null
      }
      {
        isNotEditable ?
          null
          :
          deleteFileDialogModal &&
            <DeleteFileView
              name = {name}
              isDeleteFileDialogOpened = {deleteFileDialogModal}
              closeDeleteFileDialog = {deleteFile}
              fileDescriptor={descriptor}
              deleteFileOrFolderOfSandbox={deleteFileOrFolderOfSandbox}
            />
      }
    </StyledFile>
  );
};

const Folder = (props) => {
  const {
    name,
    children,
    path,
    changePathOftheFile,
    changePathAndDescriptorIdOfTheFileToOpen,
    calledFromFileExplorer,
    pathOfTheFile,
    createNewFileOrFolder,
    // eslint-disable-next-line react/prop-types
    fileDescriptorsMappedWithPath,
    isNotEditable,
    deleteFileOrFolderOfSandbox,
    // frameworkId,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [toggleInputElement, setInputElement] = useState(false);
  const [toggleFolderInputElement, setFolderInputElement] = useState(false);
  const [inputElementContent, setInputElementContent] = useState('');
  const [folderInputElementContent, setFolderInputElementContent] = useState('');
  const [,handlePathSave] = useState('');
  const [,setExtensionValidation] = useState(false);
  const [isFolderNameValidated, setFolderNameValidation] = useState(true);
  const [isFileNameValidated, setFileNameValidation] = useState(true);
  const [deleteFolderDialogModal, setDeleteFolderDialogModal] = useState(false);

  const handleToggle = e => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleFileAdd = ()  => {
    // eslint-disable-next-line react/prop-types
    const { pathOfTheFile, fileDescriptorsMappedWithPath, frameworkId } = props;
    const splitName = inputElementContent.split('.');
    const nameOfFileWithoutExtension = splitName[0];
    const extension = splitName[splitName.length - 1];
    // const checkIfExtensionIsValid = frameworkId === 'REACT_JS' ?
    //   Object.keys(validExtensionForFiles['REACT_JS']).includes(extension)
    //   :
    //   Object.keys(validExtensionForFiles['HTML']).includes(extension);
    let checkIfExtensionIsValid = false;
    if (frameworkId === 'REACT_JS'){
      checkIfExtensionIsValid = Object.keys(validExtensionForFiles['REACT_JS']).includes(extension);
    }
    else if (frameworkId === 'HTML'){
      checkIfExtensionIsValid = Object.keys(validExtensionForFiles['HTML']).includes(extension);
    }

    // console.log('::::::::::frameworkId',frameworkId);
    if ((nameOfFileWithoutExtension.length !== 0) &&
      (inputElementContent.indexOf('.') !== -1 && inputElementContent.indexOf('.') !== inputElementContent.length - 1)
        && (checkIfExtensionIsValid)
    ) {
      setExtensionValidation(true);
      setInputElement(false);
      setInputElementContent('');
      setFileNameValidation(true);
      const pathofNewFile = `${pathOfTheFile}${inputElementContent}`;
      const isFileExist = pathofNewFile in fileDescriptorsMappedWithPath;
      if (isFileExist){
        toast.error('Cannot create two files with the same name at the same folder level');
      } else {
        // Replace root with the fileDescriptor of the parent (folder under which this file is being created)
        createNewFileOrFolder(inputElementContent, false, fileDescriptorsMappedWithPath[pathOfTheFile], '', extension);
        setIsOpen(true);
      }
    }
    else {
      setFileNameValidation(false);
    }
  };

  const handleFolderAdd = () => {
    if (isFolderNameValidated && folderInputElementContent.length > 0 ) {
      setFolderInputElement(false);
      setFolderInputElementContent('');
      const pathofNewFolder = `${pathOfTheFile}${folderInputElementContent}/`;
      const isFolderExist = pathofNewFolder in fileDescriptorsMappedWithPath;
      if (isFolderExist){
        toast.error('Can not create two folder with the same name at the same folder level');
      } else {
        // Replace root with the fileDescriptor of the parent (folder under which this folder is being created)
        createNewFileOrFolder(folderInputElementContent, true, fileDescriptorsMappedWithPath[pathOfTheFile]);
        setIsOpen(true);
      }
    }
    else {
      setFolderNameValidation(false);
    }
  };

  function handleContentChange (evt) {
    // evt.persist();
    const newFileInputValue = evt.target.value;
    setInputElementContent(newFileInputValue);
    if (newFileInputValue.match(validFileNameRegularExpression)) {
      setFileNameValidation(false);
    } else {
      // Validation for name is correct, create the file.
      setFileNameValidation(true);
      handlePathSave(pathOfTheFile);
    }
  }

  const handleFolderContentChange = (e) => {
    e.persist();
    const newFolderInputValue = e.target.value;
    setFolderInputElementContent(newFolderInputValue);
    if (newFolderInputValue.match(validFolderNameRegularExpression)) {
      setFolderNameValidation(false);
    } else {
      setFolderNameValidation(true);
    }
    handlePathSave(pathOfTheFile);
  };

  const deleteFolder = () => {
    setDeleteFolderDialogModal(false);
  };

  let descriptorOfCurrentFolder = fileDescriptorsMappedWithPath[path];

  return (
    <StyledFolder>
      <div style = {{ display: 'flex', flexDirection: 'row' }} >
        <div className = "folder--label" onClick = {handleToggle} >
          {
            isOpen ?
              <MdChevronRight
                color='white'
              />
              :
              <MdExpandMore
                color='white'
              />
          }
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <button
              // className='FileStructure_button'
              className={descriptorOfCurrentFolder === currentlySelectedFileDescriptor ? 'selectedFile' : 'FileStructure_button' }
              onClick = {() => {
                if ( !calledFromFileExplorer ){
                  changePathAndDescriptorIdOfTheFileToOpen(path,'folder');
                }
                changePathOftheFile(path);
                currentlySelectedFileDescriptor = descriptorOfCurrentFolder;
              }}
            >
              {name}
            </button>
          </div>
        </div>
          &nbsp;
          &nbsp;
        {
          isNotEditable ?
            null
            :
            calledFromFileExplorer ?
              <div
                style = {{
                  color: 'white',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <AiFillFileAdd
                  size={16}
                  id='fileInputButton'
                  onClick={() => {
                    if ( !calledFromFileExplorer ){
                      changePathAndDescriptorIdOfTheFileToOpen(path,'folder');
                    }
                    changePathOftheFile(path);
                    setInputElement(!toggleInputElement);
                    if (toggleInputElement) {
                      setInputElementContent('');
                      setFileNameValidation(true);
                      setExtensionValidation(false);
                    }
                  }}
                  style = {{ verticalAlign: 'center', marginTop: '2px' }}
                  title='Create a new file'
                />
                &nbsp;
                <AiFillFolderAdd
                  size = {20}
                  color = '#ffad33'
                  id = 'folderInputButton'
                  onClick = {() => {
                    if ( !calledFromFileExplorer ){
                      changePathAndDescriptorIdOfTheFileToOpen(path,'folder');
                    }
                    changePathOftheFile(path);
                    setFolderInputElement(!toggleFolderInputElement);
                    if (toggleFolderInputElement) {
                      setFolderInputElementContent('');
                      setFolderNameValidation(true);
                    }
                  }}
                  style = {{ verticalAlign: 'center' }}
                  title='Create a new folder'
                />
                { path !== initialPathForFileStructure && !(children && children.props && (Object.keys(children.props.data).length > 0)) ?
                  <MdDeleteForever
                    color = 'red'
                    size = {20}
                    style = {{ verticalAlign: 'center' }}
                    onClick = {() => { setDeleteFolderDialogModal(true); }}
                    id = 'deleteIconForFolder'
                    title='Delete this folder'
                  />
                  :
                  path !== initialPathForFileStructure && (children && children.props && (Object.keys(children.props.data).length > 0)) ?
                    <MdDeleteForever
                      color = 'lightgray'
                      size = {20}
                      style = {{
                        verticalAlign: 'center',
                        cursor: 'not-allowed',
                      }}
                      id = 'deleteIconForFolderDisabled'
                      title='Cannot delete a non-empty folder'
                    />
                    :
                    null
                }
              </div>
              :
              null
        }
      </div>
      <div>
        {
          toggleInputElement && (path === pathOfTheFile)
            ?
            <div id = {path}>
              <input
                style = {{
                  width: '9vw',
                  marginLeft: '20px',
                }}
                type= 'text'
                value={inputElementContent}
                onChange={handleContentChange}
                placeholder='Dummy_File.html'
              />
              &nbsp;
              <AiFillFileAdd
                size={13}
                id='fileInputButton'
                onClick={() => {
                  handleFileAdd();
                  // if (toggleInputElement) {
                  //   setInputElementContent('');
                  //   setFileNameValidation(true);
                  //   setExtensionValidation(false);
                  // }
                }}
                style={{ color: 'white' }}
                title='Create a new file'
              />
            </div>
            :
            null
        }
      </div>
      <div>
        {
          toggleFolderInputElement && (path === pathOfTheFile)
            ?
            <div id = {path}>
              <input
                style = {{
                  width: '9vw',
                  marginLeft: '20px',
                }}
                type = 'text'
                value = {folderInputElementContent}
                onChange = {handleFolderContentChange}
                placeholder = 'Folder name'
              />
              &nbsp;
              <AiFillFolderAdd
                size = {15}
                color = '#ffad33'
                id = 'folderInputButton'
                onClick = {() => {
                  handleFolderAdd();
                }}
                style = {{ color: 'white' }}
                title='Create a new folder'
              />
            </div>
            :
            null
        }
        {
          !isFolderNameValidated && toggleFolderInputElement && (path === pathOfTheFile)
            ?
            <span
              style = {{
                color: '#ff0000',
                fontSize: '14px',
                marginLeft: '20px',
                width: '9vw',
              }}
            > Enter valid Folder name </span>
            :
            null
        }
        {
          !isFileNameValidated && toggleInputElement && (path === pathOfTheFile)
            ?
            <span
              style = {{
                color: '#ff0000',
                fontSize: '14px',
                marginLeft: '20px',
                width: '9vw',
              }}
            > Enter valid name and ext.</span>
            :
            null
        }
      </div>
      {
        deleteFolderDialogModal &&
        <DeleteFolderView
          name = {name}
          isDeleteFolderDialogOpened = {deleteFolderDialogModal}
          closeDeleteFolderDialog = {deleteFolder}
          fileDescriptor={ fileDescriptorsMappedWithPath[path] }
          deleteFileOrFolderOfSandbox={ deleteFileOrFolderOfSandbox }
        />
      }
      <Collapsible isOpen = {isOpen}>{children}</Collapsible>
      
    </StyledFolder>
  );
};

const FileStructure = ( props ) => {
  let {
    data,
    path,
    openFile,
    changePathOftheFile,
    pathOfTheFile,
    changePathAndDescriptorIdOfTheFileToOpen,
    calledFromFileExplorer,
    createNewFileOrFolder,
    deleteFileOrFolderOfSandbox,
    fileDescriptorsMappedWithPath,
    isNotEditable,
    frameworkId,
  } = props;
  const parentPath = path;
  return Object.keys(data).map(itemName => {
    const item = data[itemName];
    if (typeof(item) === 'string') {
      path = `${parentPath}${itemName}`;
      return (
        <File
          name = {itemName}
          path = {path}
          key = {path}
          descriptor = {item}
          openFile = {openFile}
          changePathOftheFile = {changePathOftheFile}
          pathOfTheFile = {pathOfTheFile}
          changePathAndDescriptorIdOfTheFileToOpen = {changePathAndDescriptorIdOfTheFileToOpen}
          calledFromFileExplorer = {calledFromFileExplorer}
          deleteFileOrFolderOfSandbox={deleteFileOrFolderOfSandbox}
          isNotEditable={isNotEditable}
        />
      );
    }
    if (typeof (item) === 'object') {
      path = `${parentPath}${itemName}/`;
      return (
        <Folder
          name = {itemName}
          path = {path}
          key = {path}
          changePathOftheFile={changePathOftheFile}
          pathOfTheFile={pathOfTheFile}
          changePathAndDescriptorIdOfTheFileToOpen = {changePathAndDescriptorIdOfTheFileToOpen}
          calledFromFileExplorer = {calledFromFileExplorer}
          createNewFileOrFolder={createNewFileOrFolder}
          deleteFileOrFolderOfSandbox={deleteFileOrFolderOfSandbox}
          fileDescriptorsMappedWithPath={fileDescriptorsMappedWithPath}
          isNotEditable={isNotEditable}
          frameworkId ={frameworkId}
        >
          <FileStructure
            data = {item}
            path = {path}
            openFile = {openFile}
            changePathOftheFile={changePathOftheFile}
            pathOfTheFile={pathOfTheFile}
            changePathAndDescriptorIdOfTheFileToOpen = {changePathAndDescriptorIdOfTheFileToOpen}
            calledFromFileExplorer = { calledFromFileExplorer }
            createNewFileOrFolder={createNewFileOrFolder}
            deleteFileOrFolderOfSandbox={deleteFileOrFolderOfSandbox}
            fileDescriptorsMappedWithPath={fileDescriptorsMappedWithPath}
            isNotEditable={isNotEditable}
            frameworkId={frameworkId}
          />
        </Folder>
      );
    }
    return null;
  });
};

const FileNode = ( props ) => {
  const {
    data,
    openFile,
    changePathOftheFile,
    pathOfTheFile,
    changePathAndDescriptorIdOfTheFileToOpen,
    calledFromFileExplorer,
    createNewFileOrFolder,
    deleteFileOrFolderOfSandbox,
    // eslint-disable-next-line react/prop-types
    fileDescriptorsMappedWithPath,
    isNotEditable,
    frameworkId,
  } = props;

  return (
    <StyledNode>
      <FileStructure
        key = {'root'}
        data = {data}
        path = '/'
        openFile = {openFile}
        changePathOftheFile={changePathOftheFile}
        pathOfTheFile={pathOfTheFile}
        changePathAndDescriptorIdOfTheFileToOpen = { changePathAndDescriptorIdOfTheFileToOpen }
        calledFromFileExplorer = { calledFromFileExplorer }
        createNewFileOrFolder={createNewFileOrFolder}
        deleteFileOrFolderOfSandbox={deleteFileOrFolderOfSandbox}
        fileDescriptorsMappedWithPath={fileDescriptorsMappedWithPath}
        isNotEditable={isNotEditable}
        frameworkId={frameworkId}
      />
    </StyledNode>
  );
};

FileNode.File = File;
FileNode.Folder = Folder;

class FileStructureView extends Component {
  constructor (props) {
    super(props);
    this.state = {
    };
  }

  openFile = (descriptorId) => {
    const {
      openSelectedFileFromFileExplorerDrawer,
      changeSelectedFileOnFileOpen,
    } = this.props;
    openSelectedFileFromFileExplorerDrawer(descriptorId);
    setTimeout(() => {
      changeSelectedFileOnFileOpen(descriptorId);
    }, 10);
  }

  render () {
    const {
      changePathOftheFile,
      pathOfTheFile,
      changePathAndDescriptorIdOfTheFileToOpen,
      calledFromFileExplorer,
      resolvedFilesForSandbox,
      createNewFileOrFolder,
      deleteFileOrFolderOfSandbox,
      filesInFileSystemSandbox,
      // eslint-disable-next-line react/prop-types
      fileDescriptorsMappedWithPath,
      isNotEditable,
      selectedFile,
      frameworkId,
    } = this.props;

    currentlySelectedFileDescriptor = selectedFile;

    return (
      <div style = {{ scrollbarWidth: 'none' }} >
        <FileNode
          id = 'rootNode'
          data = {resolvedFilesForSandbox}
          key = {'rootNode'}
          openFile = {this.openFile}
          changePathOftheFile={changePathOftheFile}
          pathOfTheFile={pathOfTheFile}
          changePathAndDescriptorIdOfTheFileToOpen={changePathAndDescriptorIdOfTheFileToOpen}
          calledFromFileExplorer = {calledFromFileExplorer}
          createNewFileOrFolder={createNewFileOrFolder}
          deleteFileOrFolderOfSandbox={deleteFileOrFolderOfSandbox}
          filesInFileSystemSandbox = {filesInFileSystemSandbox}
          fileDescriptorsMappedWithPath={fileDescriptorsMappedWithPath}
          isNotEditable={isNotEditable}
          frameworkId={frameworkId}
        />
      </div>
    );
  }
}

File.propTypes = {
  name: PropTypes.string.isRequired,
  'name.split': PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  descriptor: PropTypes.string.isRequired,
  openFile: PropTypes.func.isRequired,
  changePathOftheFile: PropTypes.func.isRequired,
  changePathAndDescriptorIdOfTheFileToOpen: PropTypes.func.isRequired,
  calledFromFileExplorer: PropTypes.bool,
  deleteFileOrFolderOfSandbox: PropTypes.func.isRequired,
  isNotEditable: PropTypes.bool,
};

File.defaultProps = {
  name: '',
  'name.split': () => {
  },
  path: '',
  descriptor: '',
  openFile: () => {

  },
  changePathOftheFile: () => {
  },
  changePathAndDescriptorIdOfTheFileToOpen: () => {
  },
  calledFromFileExplorer: false,
  deleteFileOrFolderOfSandbox: () => {
  },
};

Folder.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.object,
  path: PropTypes.string.isRequired,
  openFile: PropTypes.func.isRequired,
  changePathOftheFile: PropTypes.func.isRequired,
  pathOfTheFile: PropTypes.string.isRequired,
  changePathAndDescriptorIdOfTheFileToOpen: PropTypes.func.isRequired,
  calledFromFileExplorer: PropTypes.bool,
  createNewFileOrFolder: PropTypes.func.isRequired,
  isNotEditable: PropTypes.bool,
  deleteFileOrFolderOfSandbox: PropTypes.func,
  frameworkId: PropTypes.string,
};

Folder.defaultProps = {
  name: '',
  path: '',
  openFile: () => {

  },
  changePathOftheFile: () => {
  },
  pathOfTheFile: '',
  changePathAndDescriptorIdOfTheFileToOpen: () => {
  },
  calledFromFileExplorer: false,
  // createNewFileOrFolder: () => {
  // },
};

FileNode.propTypes = {
  data: PropTypes.object.isRequired,
  openFile: PropTypes.func.isRequired,
  changePathOftheFile: PropTypes.func.isRequired,
  pathOfTheFile: PropTypes.string.isRequired,
  changePathAndDescriptorIdOfTheFileToOpen: PropTypes.func.isRequired,
  calledFromFileExplorer: PropTypes.bool,
  createNewFileOrFolder: PropTypes.func.isRequired,
  deleteFileOrFolderOfSandbox: PropTypes.func.isRequired,
  isNotEditable: PropTypes.bool,
  frameworkId: PropTypes.string,
};

FileNode.defaultProps = {
  data: {},

  openFile: () => {

  },
  changePathAndDescriptorIdOfTheFileToOpen: () => {
  },
  calledFromFileExplorer: false,
  // createNewFileOrFolder: () => {
  // },
  deleteFileOrFolderOfSandbox: () => {
  },
};

FileStructureView.propTypes = {
  openSelectedFileFromFileExplorerDrawer: PropTypes.func.isRequired,
  changeSelectedFileOnFileOpen: PropTypes.func.isRequired,
  changePathOftheFile: PropTypes.func.isRequired,
  pathOfTheFile: PropTypes.string.isRequired,
  filesInFileSystemSandbox: PropTypes.object,
  changePathAndDescriptorIdOfTheFileToOpen: PropTypes.func.isRequired,
  calledFromFileExplorer: PropTypes.bool,
  resolvedFilesForSandbox: PropTypes.object,
  createNewFileOrFolder: PropTypes.func.isRequired,
  deleteFileOrFolderOfSandbox: PropTypes.func.isRequired,
  isNotEditable: PropTypes.bool,
  selectedFile: PropTypes.string,
  frameworkId: PropTypes.string,
};

FileStructureView.defaultProps = {
  openSelectedFileFromFileExplorerDrawer: () => {

  },
  changeSelectedFileOnFileOpen: () => {

  },
  changePathOftheFile: () => {
  },
  pathOfTheFile: '',
  changePathAndDescriptorIdOfTheFileToOpen: () => {
  },
  calledFromFileExplorer: false,
  // filesInFileSystemSandbox: {},
  // resolvedFilesForSandbox: undefined,
  // createNewFileOrFolder: () => {
  // },
  deleteFileOrFolderOfSandbox: () => {
  },
};


export default FileStructureView;