/* eslint-disable react/prop-types */
import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import { resetToDefaultCode } from '../../redux/code/actions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import './ExitSandboxButton.css';
import FileCopyIcon from '@material-ui/icons/FileCopy';
// import { Input } from '@material-ui/core';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';

class ExitSandboxButton  extends Component {
  constructor (props) {
    super(props);
    this.state = {
      forkingDailogModal: false,
      name: '',
      copied: false,
    };
  }
  openForkDailogModal = () => {
    this.setState(() => ({
      forkingDailogModal: true,
    }));
  };

  closeForkDailogModal = () => {
    this.setState(() => ({
      forkingDailogModal: false,
      copied: false,
    }));
  };


  redirectToHomePage = () => {
  
    setTimeout(() => {
      window.open('/','_self');
    });
  }

  // copyLinkToClipboard = () => {
  //   var copyText = document.getElementById('SANDBOX_URL');
  //   copyText.select();
  //   copyText.setSelectionRange(0, 99999);
  //   const success = document.execCommand('copy');
  //   if (success) {
  //     toast.success('Copied');
  //   } else {
  //     toast.error('Could not copy');
  //   }
  // }
  
  handleNameChange = (event) => {
    this.setState({
      name: event.target.value,
    });
  }
  onCopy = () => {
    this.setState({ copied: true });
    toast.success('Link Copied successfully');
    setTimeout(() => {
      this.setState({ copied: false });
    }
    , 5000);
  };

  render () {
    const { forkingDailogModal } = this.state;
    return (
      <div>
        <Button
          variant="contained"
          
          onClick={this.openForkDailogModal}
          style={{ width: '112%', background: 'var(--primaryColor)', color: 'white' }}
        >
          Exit Sandbox
        </Button>

        <Dialog
          open={forkingDailogModal}
          onClose={this.closeForkDailogModal}
          fullWidth={true}
        >
          <DialogTitle
            id="alert-dialog-title"
            className="headerExitSandboxView"
            style={{
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            {' Exit Sandbox '}
          </DialogTitle>
          <DialogContent>
            <p style={{ color: '#082030' }}>Please make sure you have saved the sandbox URL for using it again.</p>
            {/* <Button onClick={() => {navigator.clipboard.writeText(this.state.textToCopy)}}>Copy to Clipboard</Button> */}
            {/* <button onClick={() => {navigator.clipboard.writeText(this.state.textToCopy);}}>Copy text</button> */}
            <div
              style = {{  display: 'flex' }}
            >
              <input
                id="SANDBOX_URL"
                type="text"
                value={String(window.location.href)}
                disabled
                style={{ width: '85%', display: 'flex' }}
              />
            
            &nbsp;
              {/* <Button
              onMouseDown={this.copyLinkToClipboard}
              title="Click to copy the sandbox URL to clipboard"
            >
              <FileCopyIcon style={{ color: 'var(--primaryColor)' }}/>
            </Button> */}
            &nbsp;&nbsp;
              <CopyToClipboard onCopy={this.onCopy} text={window.location.href}>
                <button style = {{ display: 'flex' }}><FileCopyIcon/></button>
              </CopyToClipboard>
              
            </div>
            {this.state.copied ? <span style={{ color: 'green' }}>
            Copied.
            </span> : null}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeForkDailogModal} color="primary" className="buttonExitSandboxView button3ExitSandboxView">
              Cancel
            </Button>
            <Button onClick={this.redirectToHomePage} color="primary" className="buttonExitSandboxView button1ExitSandboxView"  >
              Exit
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}


// const mapStateToProps = () => ({
//   // editorTheme: state.editor.selectedTheme,
// });

// const mapDispatchToProps = dispatch => ({
//   resetToDefaultCode: () => {
//     dispatch(resetToDefaultCode());
//   },
// });

// export default connect(mapStateToProps, mapDispatchToProps)(BackButtonForHome );
export default ExitSandboxButton;